interface ICATALOG_ACTION_TYPES {
  AUTHORS: {
    SEARCH: 'disembark/catalog/authors/search_authors',
    SEARCHED: 'disembark/catalog/authors/authors_searched',
  },
  AUTHOR: {
    CREATE: 'disembark/catalog/author/create_author',
    CREATED: 'disembark/catalog/author/author_created',
    FETCH: 'disembark/catalog/author/fetch_author',
    FETCHED: 'disembark/catalog/author/author_fetched',
  },
  PUBLISHERS: {
    SEARCH: 'disembark/catalog/publishers/search_publishers',
    SEARCHED: 'disembark/catalog/publishers/publishers_searched',
  },
  PUBLISHER: {
    CREATE: 'disembark/catalog/publisher/create_publisher',
    CREATED: 'disembark/catalog/publisher/publisher_created',
    FETCH: 'disembark/catalog/publishers/fetch_publisher',
    FETCHED: 'disembark/catalog/publishers/publisher_fetched'
  },
  RECORDS: {
    FETCH: 'disembark/catalog/fetch_records',
    FETCHED: 'disembark/catalog/records_fetched',
    DESTROY: 'disembark/catalog/destroy_records',
    DESTROYED: 'disembark/catalog/records_destroyed',
    SEARCH: 'disembark/catalog/search_records',
    SEARCHED: 'disembark/catalog/records_searched',
    FETCH_SEARCH_RESULTS: 'disembark/catalog/fetch_search_results',
    SEARCH_RESULTS_FETCHED: 'disembark/catalog/search_results_fetched',
    CLEAR: 'disembark/catalog/records/clear_records',
    CLEAR_SEARCH: 'disembark/catalog/records/clear_search',
    IMPORT: 'disembark/catalog/import_records',
    IMPORTED: 'disembark/catalog/records_imported',
  },
  RECORD: {
    FETCH: 'disembark/catalog/record/fetch_record',
    FETCHED: 'disembark/catalog/record/record_fetched',
    CREATE: 'disembark/catalog/record/create_record',
    CREATED: 'disembark/catalog/record/record_created',
    UPDATE: 'disembark/catalog/record/update_record',
    UPDATED: 'disembark/catalog/record/record_updated',
    DESTROY: 'disembark/catalog/record/destroy_record',
    DESTROYED: 'disembark/catalog/record/record_destroyed',
    CLEAR: 'disembark/catalog/record/clear_record',
    SEARCH_BY_ISBN: 'disembark/catalog/search_by_isbn',
    ISBN_SEARCH_RESULTS: 'disembark/catalog/isbn_search_results'
  },
  LISTS: {
    FETCH: 'disembark/catalog/lists/fetch_lists',
    FETCHED: 'disembark/catalog/lists/lists_fetched',
    DESTROY: 'disembark/catalog/destroy_lists',
    DESTROYED: 'disembark/catalog/lists_destroyed',
    SEARCH: 'disembark/catalog/lists/search_lists',
    SEARCHED: 'disembark/catalog/lists/lists_searched',
    CLEAR_SEARCH: 'disembark/catalog/lists/clear_search'
  },
  LIST: {
    FETCH: 'disembark/catalog/list/fetch_list',
    FETCHED: 'disembark/catalog/list/list_fetched',
    CREATE: 'disembark/catalog/list/create_list',
    CREATED: 'disembark/catalog/list/list_created',
    UPDATE: 'disembark/catalog/list/update_list',
    UPDATED: 'disembark/catalog/list/list_updated',
    DESTROY: 'disembark/catalog/list/destroy_list',
    DESTROYED: 'disembark/catalog/list/list_destroyed',
    ADD_RECORD: 'disembark/catalog/list/add_record',
    RECORD_ADDED: 'disembark/catalog/list/record_added',
    ADD_RECORDS: 'disembark/catalog/list/add_records',
    RECORDS_ADDED: 'disembark/catalog/list/records_added',
    REMOVE_RECORD: 'disembark/catalog/list/remove_record',
    RECORD_REMOVED: 'disembark/catalog/list/record_removed',
    CLEAR: 'disembark/catalog/list/clear_current_list'
  },
  NOTES: {
    FETCH: 'disembark/catalog/notes/fetch_notes,',
    FETCHED: 'disembark/catalog/notes/notes_fetched',
    SEARCH: 'disembark/catalog/notes/search_notes',
    SEARCHED: 'disembark/catalog/notes/notes_searched',
    CLEAR_SEARCH: 'disembark/catalog/notes/clear_search'
  },
  NOTE: {
    FETCH: 'disembark/catalog/note/fetch_note',
    FETCHED: 'disembark/catalog/note/note_fetched',
    CREATE: 'disembark/catalog/note/create_note',
    CREATED: 'disembark/catalog/note/note_created',
    UPDATE: 'disembark/catalog/note/update_note',
    UPDATED: 'disembark/catalog/note/note_updated',
    DESTROY: 'disembark/catalog/note/destroy_note',
    DESTROYED: 'disembark/catalog/note/note_destroyed',
    CLEAR: 'disembark/catalog/note/clear_current_note'
  },
  REVIEWS: {
    FETCH: 'disembark/catalog/reviews/fetch_reviews,',
    FETCHED: 'disembark/catalog/reviews/reviews_fetched',
    SEARCH: 'disembark/catalog/reviews/search_reviews',
    SEARCHED: 'disembark/catalog/reviews/reviews_searched',
    CLEAR_SEARCH: 'disembark/catalog/reviews/clear_search'
  },
  REVIEW: {
    FETCH: 'disembark/catalog/review/fetch_review',
    FETCHED: 'disembark/catalog/review/review_fetched',
    CREATE: 'disembark/catalog/review/create_review',
    CREATED: 'disembark/catalog/review/review_created',
    UPDATE: 'disembark/catalog/review/update_review',
    UPDATED: 'disembark/catalog/review/review_updated',
    DESTROY: 'disembark/catalog/review/destroy_review',
    DESTROYED: 'disembark/catalog/review/review_destroyed',
    CLEAR: 'disembark/catalog/review/clear_current_review'
  },
  RECORD_IMAGES: {
    FETCH: 'disembark/catalog/record_images/fetch_record_images,',
    FETCHED: 'disembark/catalog/record_images/record_images_fetched',
    SEARCH: 'disembark/catalog/record_images/search_record_images',
    SEARCHED: 'disembark/catalog/record_images/record_images_searched',
    CLEAR_SEARCH: 'disembark/catalog/record_images/clear_search'
  },
  RECORD_IMAGE: {
    FETCH: 'disembark/catalog/record_image/fetch_record_image',
    FETCHED: 'disembark/catalog/record_image/record_image_fetched',
    CREATE: 'disembark/catalog/record_image/create_record_image',
    CREATED: 'disembark/catalog/record_image/record_image_created',
    UPDATE: 'disembark/catalog/record_image/update_record_image',
    UPDATED: 'disembark/catalog/record_image/record_image_updated',
    DESTROY: 'disembark/catalog/record_image/destroy_record_image',
    DESTROYED: 'disembark/catalog/record_image/record_image_destroyed',
    CLEAR: 'disembark/catalog/record_image/clear_current_record_image'
  },
  RECORD_INSTANCES: {
    FETCH: 'disembark/catalog/record_instances/fetch_record_instances,',
    FETCHED: 'disembark/catalog/record_instances/record_instances_fetched',
    SEARCH: 'disembark/catalog/record_instances/search_record_instances',
    SEARCHED: 'disembark/catalog/record_instances/record_instances_searched',
    CLEAR_SEARCH: 'disembark/catalog/record_instances/clear_search'
  },
  RECORD_INSTANCE: {
    FETCH: 'disembark/catalog/record_instance/fetch_record_instance',
    FETCHED: 'disembark/catalog/record_instance/record_instance_fetched',
    CREATE: 'disembark/catalog/record_instance/create_record_instance',
    CREATED: 'disembark/catalog/record_instance/record_instance_created',
    UPDATE: 'disembark/catalog/record_instance/update_record_instance',
    UPDATED: 'disembark/catalog/record_instance/record_instance_updated',
    DESTROY: 'disembark/catalog/record_instance/destroy_record_instance',
    DESTROYED: 'disembark/catalog/record_instance/record_instance_destroyed',
    CLEAR: 'disembark/catalog/record_instance/clear_current_record_instance'
  },
  CATEGORIES: {
    FETCH: 'disembark/catalog/categories/fetch_categories,',
    FETCHED: 'disembark/catalog/categories/categories_fetched',
    SEARCH: 'disembark/catalog/categories/search_categories',
    SEARCHED: 'disembark/catalog/categories/categories_searched',
    CLEAR_SEARCH: 'disembark/catalog/categories/clear_search'
  },
  CATEGORY: {
    FETCH: 'disembark/catalog/category/fetch_category',
    FETCHED: 'disembark/catalog/category/category_fetched',
    CREATE: 'disembark/catalog/category/create_category',
    CREATED: 'disembark/catalog/category/category_created',
    UPDATE: 'disembark/catalog/category/update_category',
    UPDATED: 'disembark/catalog/category/category_updated',
    DESTROY: 'disembark/catalog/category/destroy_category',
    DESTROYED: 'disembark/catalog/category/category_destroyed',
    CLEAR: 'disembark/catalog/category/clear_current_category'
  },
  GENRES: {
    FETCH: 'disembark/catalog/genres/fetch_genres,',
    FETCHED: 'disembark/catalog/genres/genres_fetched',
    SEARCH: 'disembark/catalog/genres/search_genres',
    SEARCHED: 'disembark/catalog/genres/genres_searched',
    CLEAR_SEARCH: 'disembark/catalog/genres/clear_search'
  },
  GENRE: {
    FETCH: 'disembark/catalog/genre/fetch_genre',
    FETCHED: 'disembark/catalog/genre/genre_fetched',
    CREATE: 'disembark/catalog/genre/create_genre',
    CREATED: 'disembark/catalog/genre/genre_created',
    UPDATE: 'disembark/catalog/genre/update_genre',
    UPDATED: 'disembark/catalog/genre/genre_updated',
    DESTROY: 'disembark/catalog/genre/destroy_genre',
    DESTROYED: 'disembark/catalog/genre/genre_destroyed',
    CLEAR: 'disembark/catalog/genre/clear_current_genre'
  },
  SUBJECTS: {
    FETCH: 'disembark/catalog/subjects/fetch_subjects,',
    FETCHED: 'disembark/catalog/subjects/subjects_fetched',
    SEARCH: 'disembark/catalog/subjects/search_subjects',
    SEARCHED: 'disembark/catalog/subjects/subjects_searched',
    CLEAR_SEARCH: 'disembark/catalog/subjects/clear_search'
  },
  SUBJECT: {
    FETCH: 'disembark/catalog/subject/fetch_subject',
    FETCHED: 'disembark/catalog/subject/subject_fetched',
    CREATE: 'disembark/catalog/subject/create_subject',
    CREATED: 'disembark/catalog/subject/subject_created',
    UPDATE: 'disembark/catalog/subject/update_subject',
    UPDATED: 'disembark/catalog/subject/subject_updated',
    DESTROY: 'disembark/catalog/subject/destroy_subject',
    DESTROYED: 'disembark/catalog/subject/subject_destroyed',
    CLEAR: 'disembark/catalog/subject/clear_current_subject'
  },
  TAGS: {
    FETCH: 'disembark/catalog/tags/fetch_tags,',
    FETCHED: 'disembark/catalog/tags/tags_fetched',
    SEARCH: 'disembark/catalog/tags/search_tags',
    SEARCHED: 'disembark/catalog/tags/tags_searched',
    CLEAR_SEARCH: 'disembark/catalog/tags/clear_search'
  },
  TAG: {
    FETCH: 'disembark/catalog/tag/fetch_tag',
    FETCHED: 'disembark/catalog/tag/tag_fetched',
    CREATE: 'disembark/catalog/tag/create_tag',
    CREATED: 'disembark/catalog/tag/tag_created',
    UPDATE: 'disembark/catalog/tag/update_tag',
    UPDATED: 'disembark/catalog/tag/tag_updated',
    DESTROY: 'disembark/catalog/tag/destroy_tag',
    DESTROYED: 'disembark/catalog/tag/tag_destroyed',
    CLEAR: 'disembark/catalog/tag/clear_current_tag'
  },
  BOOKMARKS: {
    FETCH: 'disembark/catalog/bookmarks/fetch_bookmarks,',
    FETCHED: 'disembark/catalog/bookmarks/bookmarks_fetched',
    SEARCH: 'disembark/catalog/bookmarks/search_bookmarks',
    SEARCHED: 'disembark/catalog/bookmarks/bookmarks_searched',
    CLEAR_SEARCH: 'disembark/catalog/bookmarks/clear_search'
  },
  BOOKMARK: {
    FETCH: 'disembark/catalog/bookmark/fetch_bookmark',
    FETCHED: 'disembark/catalog/bookmark/bookmark_fetched',
    CREATE: 'disembark/catalog/bookmark/create_bookmark',
    CREATED: 'disembark/catalog/bookmark/bookmark_created',
    UPDATE: 'disembark/catalog/bookmark/update_bookmark',
    UPDATED: 'disembark/catalog/bookmark/bookmark_updated',
    DESTROY: 'disembark/catalog/bookmark/destroy_bookmark',
    DESTROYED: 'disembark/catalog/bookmark/bookmark_destroyed',
    CLEAR: 'disembark/catalog/bookmark/clear_current_bookmark'
  }
}

export const CATALOG_ACTION_TYPES:ICATALOG_ACTION_TYPES = {
  AUTHORS: {
    SEARCH: 'disembark/catalog/authors/search_authors',
    SEARCHED: 'disembark/catalog/authors/authors_searched',
  },
  AUTHOR: {
    CREATE: 'disembark/catalog/author/create_author',
    CREATED: 'disembark/catalog/author/author_created',
    FETCH: 'disembark/catalog/author/fetch_author',
    FETCHED: 'disembark/catalog/author/author_fetched',
  },
  PUBLISHERS: {
    SEARCH: 'disembark/catalog/publishers/search_publishers',
    SEARCHED: 'disembark/catalog/publishers/publishers_searched',
  },
  PUBLISHER: {
    CREATE: 'disembark/catalog/publisher/create_publisher',
    CREATED: 'disembark/catalog/publisher/publisher_created',
    FETCH: 'disembark/catalog/publishers/fetch_publisher',
    FETCHED: 'disembark/catalog/publishers/publisher_fetched',
  },
  RECORDS: {
    FETCH: 'disembark/catalog/fetch_records',
    FETCHED: 'disembark/catalog/records_fetched',
    DESTROY: 'disembark/catalog/destroy_records',
    DESTROYED: 'disembark/catalog/records_destroyed',
    SEARCH: 'disembark/catalog/search_records',
    SEARCHED: 'disembark/catalog/records_searched',
    FETCH_SEARCH_RESULTS: 'disembark/catalog/fetch_search_results',
    SEARCH_RESULTS_FETCHED: 'disembark/catalog/search_results_fetched',
    CLEAR: 'disembark/catalog/records/clear_records',
    CLEAR_SEARCH: 'disembark/catalog/records/clear_search',
    IMPORT: 'disembark/catalog/import_records',
    IMPORTED: 'disembark/catalog/records_imported',
  },
  RECORD: {
    FETCH: 'disembark/catalog/record/fetch_record',
    FETCHED: 'disembark/catalog/record/record_fetched',
    CREATE: 'disembark/catalog/record/create_record',
    CREATED: 'disembark/catalog/record/record_created',
    UPDATE: 'disembark/catalog/record/update_record',
    UPDATED: 'disembark/catalog/record/record_updated',
    DESTROY: 'disembark/catalog/record/destroy_record',
    DESTROYED: 'disembark/catalog/record/record_destroyed',
    CLEAR: 'disembark/catalog/record/clear_record',
    SEARCH_BY_ISBN: 'disembark/catalog/search_by_isbn',
    ISBN_SEARCH_RESULTS: 'disembark/catalog/isbn_search_results',
  },
  LISTS: {
    FETCH: 'disembark/catalog/lists/fetch_lists',
    FETCHED: 'disembark/catalog/lists/lists_fetched',
    DESTROY: 'disembark/catalog/destroy_lists',
    DESTROYED: 'disembark/catalog/lists_destroyed',
    SEARCH: 'disembark/catalog/lists/search_lists',
    SEARCHED: 'disembark/catalog/lists/lists_searched',
    CLEAR_SEARCH: 'disembark/catalog/lists/clear_search',
  },
  LIST: {
    FETCH: 'disembark/catalog/list/fetch_list',
    FETCHED: 'disembark/catalog/list/list_fetched',
    CREATE: 'disembark/catalog/list/create_list',
    CREATED: 'disembark/catalog/list/list_created',
    UPDATE: 'disembark/catalog/list/update_list',
    UPDATED: 'disembark/catalog/list/list_updated',
    DESTROY: 'disembark/catalog/list/destroy_list',
    DESTROYED: 'disembark/catalog/list/list_destroyed',
    ADD_RECORD: 'disembark/catalog/list/add_record',
    ADD_RECORDS: 'disembark/catalog/list/add_records',
    RECORDS_ADDED: 'disembark/catalog/list/records_added',
    RECORD_ADDED: 'disembark/catalog/list/record_added',
    REMOVE_RECORD: 'disembark/catalog/list/remove_record',
    RECORD_REMOVED: 'disembark/catalog/list/record_removed',
    CLEAR: 'disembark/catalog/list/clear_current_list',
  },
  NOTES: {
    FETCH: 'disembark/catalog/notes/fetch_notes,',
    FETCHED: 'disembark/catalog/notes/notes_fetched',
    SEARCH: 'disembark/catalog/notes/search_notes',
    SEARCHED: 'disembark/catalog/notes/notes_searched',
    CLEAR_SEARCH: 'disembark/catalog/notes/clear_search',
  },
  NOTE: {
    FETCH: 'disembark/catalog/note/fetch_note',
    FETCHED: 'disembark/catalog/note/note_fetched',
    CREATE: 'disembark/catalog/note/create_note',
    CREATED: 'disembark/catalog/note/note_created',
    UPDATE: 'disembark/catalog/note/update_note',
    UPDATED: 'disembark/catalog/note/note_updated',
    DESTROY: 'disembark/catalog/note/destroy_note',
    DESTROYED: 'disembark/catalog/note/note_destroyed',
    CLEAR: 'disembark/catalog/note/clear_current_note',
  },
  REVIEWS: {
    FETCH: 'disembark/catalog/reviews/fetch_reviews,',
    FETCHED: 'disembark/catalog/reviews/reviews_fetched',
    SEARCH: 'disembark/catalog/reviews/search_reviews',
    SEARCHED: 'disembark/catalog/reviews/reviews_searched',
    CLEAR_SEARCH: 'disembark/catalog/reviews/clear_search',
  },
  REVIEW: {
    FETCH: 'disembark/catalog/review/fetch_review',
    FETCHED: 'disembark/catalog/review/review_fetched',
    CREATE: 'disembark/catalog/review/create_review',
    CREATED: 'disembark/catalog/review/review_created',
    UPDATE: 'disembark/catalog/review/update_review',
    UPDATED: 'disembark/catalog/review/review_updated',
    DESTROY: 'disembark/catalog/review/destroy_review',
    DESTROYED: 'disembark/catalog/review/review_destroyed',
    CLEAR: 'disembark/catalog/review/clear_current_review',
  },
  RECORD_IMAGES: {
    FETCH: 'disembark/catalog/record_images/fetch_record_images,',
    FETCHED: 'disembark/catalog/record_images/record_images_fetched',
    SEARCH: 'disembark/catalog/record_images/search_record_images',
    SEARCHED: 'disembark/catalog/record_images/record_images_searched',
    CLEAR_SEARCH: 'disembark/catalog/record_images/clear_search',
  },
  RECORD_IMAGE: {
    FETCH: 'disembark/catalog/record_image/fetch_record_image',
    FETCHED: 'disembark/catalog/record_image/record_image_fetched',
    CREATE: 'disembark/catalog/record_image/create_record_image',
    CREATED: 'disembark/catalog/record_image/record_image_created',
    UPDATE: 'disembark/catalog/record_image/update_record_image',
    UPDATED: 'disembark/catalog/record_image/record_image_updated',
    DESTROY: 'disembark/catalog/record_image/destroy_record_image',
    DESTROYED: 'disembark/catalog/record_image/record_image_destroyed',
    CLEAR: 'disembark/catalog/record_image/clear_current_record_image',
  },
  RECORD_INSTANCES: {
    FETCH: 'disembark/catalog/record_instances/fetch_record_instances,',
    FETCHED: 'disembark/catalog/record_instances/record_instances_fetched',
    SEARCH: 'disembark/catalog/record_instances/search_record_instances',
    SEARCHED: 'disembark/catalog/record_instances/record_instances_searched',
    CLEAR_SEARCH: 'disembark/catalog/record_instances/clear_search',
  },
  RECORD_INSTANCE: {
    FETCH: 'disembark/catalog/record_instance/fetch_record_instance',
    FETCHED: 'disembark/catalog/record_instance/record_instance_fetched',
    CREATE: 'disembark/catalog/record_instance/create_record_instance',
    CREATED: 'disembark/catalog/record_instance/record_instance_created',
    UPDATE: 'disembark/catalog/record_instance/update_record_instance',
    UPDATED: 'disembark/catalog/record_instance/record_instance_updated',
    DESTROY: 'disembark/catalog/record_instance/destroy_record_instance',
    DESTROYED: 'disembark/catalog/record_instance/record_instance_destroyed',
    CLEAR: 'disembark/catalog/record_instance/clear_current_record_instance',
  },
  CATEGORIES: {
    FETCH: 'disembark/catalog/categories/fetch_categories,',
    FETCHED: 'disembark/catalog/categories/categories_fetched',
    SEARCH: 'disembark/catalog/categories/search_categories',
    SEARCHED: 'disembark/catalog/categories/categories_searched',
    CLEAR_SEARCH: 'disembark/catalog/categories/clear_search',
  },
  CATEGORY: {
    FETCH: 'disembark/catalog/category/fetch_category',
    FETCHED: 'disembark/catalog/category/category_fetched',
    CREATE: 'disembark/catalog/category/create_category',
    CREATED: 'disembark/catalog/category/category_created',
    UPDATE: 'disembark/catalog/category/update_category',
    UPDATED: 'disembark/catalog/category/category_updated',
    DESTROY: 'disembark/catalog/category/destroy_category',
    DESTROYED: 'disembark/catalog/category/category_destroyed',
    CLEAR: 'disembark/catalog/category/clear_current_category',
  },
  GENRES: {
    FETCH: 'disembark/catalog/genres/fetch_genres,',
    FETCHED: 'disembark/catalog/genres/genres_fetched',
    SEARCH: 'disembark/catalog/genres/search_genres',
    SEARCHED: 'disembark/catalog/genres/genres_searched',
    CLEAR_SEARCH: 'disembark/catalog/genres/clear_search',
  },
  GENRE: {
    FETCH: 'disembark/catalog/genre/fetch_genre',
    FETCHED: 'disembark/catalog/genre/genre_fetched',
    CREATE: 'disembark/catalog/genre/create_genre',
    CREATED: 'disembark/catalog/genre/genre_created',
    UPDATE: 'disembark/catalog/genre/update_genre',
    UPDATED: 'disembark/catalog/genre/genre_updated',
    DESTROY: 'disembark/catalog/genre/destroy_genre',
    DESTROYED: 'disembark/catalog/genre/genre_destroyed',
    CLEAR: 'disembark/catalog/genre/clear_current_genre',
  },
  SUBJECTS: {
    FETCH: 'disembark/catalog/subjects/fetch_subjects,',
    FETCHED: 'disembark/catalog/subjects/subjects_fetched',
    SEARCH: 'disembark/catalog/subjects/search_subjects',
    SEARCHED: 'disembark/catalog/subjects/subjects_searched',
    CLEAR_SEARCH: 'disembark/catalog/subjects/clear_search',
  },
  SUBJECT: {
    FETCH: 'disembark/catalog/subject/fetch_subject',
    FETCHED: 'disembark/catalog/subject/subject_fetched',
    CREATE: 'disembark/catalog/subject/create_subject',
    CREATED: 'disembark/catalog/subject/subject_created',
    UPDATE: 'disembark/catalog/subject/update_subject',
    UPDATED: 'disembark/catalog/subject/subject_updated',
    DESTROY: 'disembark/catalog/subject/destroy_subject',
    DESTROYED: 'disembark/catalog/subject/subject_destroyed',
    CLEAR: 'disembark/catalog/subject/clear_current_subject',
  },
  TAGS: {
    FETCH: 'disembark/catalog/tags/fetch_tags,',
    FETCHED: 'disembark/catalog/tags/tags_fetched',
    SEARCH: 'disembark/catalog/tags/search_tags',
    SEARCHED: 'disembark/catalog/tags/tags_searched',
    CLEAR_SEARCH: 'disembark/catalog/tags/clear_search',
  },
  TAG: {
    FETCH: 'disembark/catalog/tag/fetch_tag',
    FETCHED: 'disembark/catalog/tag/tag_fetched',
    CREATE: 'disembark/catalog/tag/create_tag',
    CREATED: 'disembark/catalog/tag/tag_created',
    UPDATE: 'disembark/catalog/tag/update_tag',
    UPDATED: 'disembark/catalog/tag/tag_updated',
    DESTROY: 'disembark/catalog/tag/destroy_tag',
    DESTROYED: 'disembark/catalog/tag/tag_destroyed',
    CLEAR: 'disembark/catalog/tag/clear_current_tag',
  },
  BOOKMARKS: {
    FETCH: 'disembark/catalog/bookmarks/fetch_bookmarks,',
    FETCHED: 'disembark/catalog/bookmarks/bookmarks_fetched',
    SEARCH: 'disembark/catalog/bookmarks/search_bookmarks',
    SEARCHED: 'disembark/catalog/bookmarks/bookmarks_searched',
    CLEAR_SEARCH: 'disembark/catalog/bookmarks/clear_search',
  },
  BOOKMARK: {
    FETCH: 'disembark/catalog/bookmark/fetch_bookmark',
    FETCHED: 'disembark/catalog/bookmark/bookmark_fetched',
    CREATE: 'disembark/catalog/bookmark/create_bookmark',
    CREATED: 'disembark/catalog/bookmark/bookmark_created',
    UPDATE: 'disembark/catalog/bookmark/update_bookmark',
    UPDATED: 'disembark/catalog/bookmark/bookmark_updated',
    DESTROY: 'disembark/catalog/bookmark/destroy_bookmark',
    DESTROYED: 'disembark/catalog/bookmark/bookmark_destroyed',
    CLEAR: 'disembark/catalog/bookmark/clear_current_bookmark',
  },
};

export default CATALOG_ACTION_TYPES;
