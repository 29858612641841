import * as React from 'react';
import { Link } from 'react-router-dom';

interface LinkProps {
  color: 'blue-green' | 'black'
  to?: string
  href?: string
  children: React.ReactNode
  target?: '_blank' | '_parent' | '_self' | '_top'
}

function HTMLLink(props:LinkProps) {
  const {
    to, color, children, href, target,
  } = props;
  if (to !== undefined) {
    return <Link className={`di-link-${color}`} to={to}>{children}</Link>;
  }
  return <a target={target} className={`di-link-${color}`} href={href}>{children}</a>;
}

HTMLLink.defaultProps = {
  to: undefined,
  href: undefined,
  target: undefined,
};

export default HTMLLink;
