interface IAPPLICATION_ACTION_TYPES {
  API_UNAUTHORIZED: 'disembark/errors/api_unauthorized',
  API_ERROR: 'disembark/errors/api_error',
  CLEAR_ERRORS: 'disembark/errors/clear_errors'
}

export const APPLICATION_ACTION_TYPES:IAPPLICATION_ACTION_TYPES = {
  API_UNAUTHORIZED: 'disembark/errors/api_unauthorized',
  API_ERROR: 'disembark/errors/api_error',
  CLEAR_ERRORS: 'disembark/errors/clear_errors',
};

export default APPLICATION_ACTION_TYPES;
