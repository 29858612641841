import FilterContainer from '@components/base/list-filters/FilterContainer';
import FilterOption from '@components/base/list-filters/FilterOption';
import MainSearch from '@components/base/search/MainSearch';
import SearchResult from '@components/base/search/SearchResult';
import PatronSERP from '@components/base/serp/PatronSERP';
import PageContainer from '@components/layout/generic/PageContainer';
import APIList from '@containers/application/APIList';
import { ROUTES } from '@routes';
import { PATRON_ACTION_TYPES } from '@store/patrons/actionTypes';
import { fetchPatrons, searchPatrons } from '@store/patrons/patrons/actions';
import { Patron } from '@store/patrons/patrons/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';

interface DispatchProps {
  dispatchSearchPatrons: (searchTerm:string)=>void,
  dispatchFetchSearchResults: (query:string)=>void
}
interface StateProps {
  results:Patron[]
  patrons:Patron[]
}

type PatronSearchResultsProps =
  DispatchProps & StateProps;

function PatronSearchResults(props:PatronSearchResultsProps) {
  const {
    dispatchFetchSearchResults, dispatchSearchPatrons, patrons, results,
  } = props;
  const location = useLocation();
  const [queryParameters] = React.useState(new URLSearchParams(location.search));
  const query = queryParameters.get('q');

  React.useEffect(() => {
    dispatchFetchSearchResults(query);
  }, [location]);

  return (
    <PageContainer>
      <MainSearch
        id="di-search-patrons"
        name="di-search-patrons"
        searchFunction={dispatchSearchPatrons}
        searchResultRoute={ROUTES.CATALOG.RECORDS.SEARCH_RESULTS()}
        defaultValue={queryParameters.get('q')}
      >
        { results.map(
          (result:Patron) => (
            <SearchResult
              key={result.id}
              to={ROUTES.PATRONS.PATRONS.DETAILS(result.id)}
            >
              {result.name}
            </SearchResult>
          ),
        )}
      </MainSearch>
      <FilterContainer>
        <FilterOption tabIndex={0} filter="all" icon="all">All</FilterOption>
        <FilterOption tabIndex={0} filter="available" icon="gear">Available</FilterOption>
        <FilterOption tabIndex={0} filter="overdue" icon="gear">Overdue</FilterOption>
      </FilterContainer>
      <APIList action={PATRON_ACTION_TYPES.PATRONS.FETCH}>
        { patrons.map(
          (patron) => (
            <PatronSERP
              key={patron.id}
              patron={patron}
              to={ROUTES.PATRONS.PATRONS.DETAILS(patron.id)}
            />
          ),
        )}
      </APIList>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  results: state.patrons.searchResults,
  patrons: state.patrons.patrons,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchSearchPatrons: (searchTerm:string):void => {
    dispatch(searchPatrons(searchTerm));
  },
  dispatchFetchSearchResults: (query:string):void => {
    dispatch(fetchPatrons({ query }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PatronSearchResults);
