import FlexContainer from '@components/layout/generic/FlexContainer';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import RightAlignedFlexContainer from '@components/layout/generic/RightAlignedFlexContainer';
import * as React from 'react';

interface StepAccordionStepClosedProps {
  children: React.ReactNode
  currentStep?: number
  goToStep?: (step:number)=>void
  step?: number
}

function StepAccordionStepClosed(props:StepAccordionStepClosedProps) {
  const {
    children, currentStep, step, goToStep,
  } = props;
  return (
    <MultiColumnFlex className="di-step-accordion-step-closed-container">
      <FlexContainer flexValue={1}>
        { children }
      </FlexContainer>
      <RightAlignedFlexContainer>
        { step < currentStep && <button type="button" onClick={() => goToStep(step)}>change</button>}
      </RightAlignedFlexContainer>
    </MultiColumnFlex>
  );
}
StepAccordionStepClosed.defaultProps = {
  currentStep: undefined,
  goToStep: undefined,
  step: undefined,
};

export default StepAccordionStepClosed;
