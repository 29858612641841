import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import {
  FetchGenresAction,
  FetchGenreAction,
  CreateGenreAction,
  UpdateGenreAction,
  DestroyGenreAction,
} from '@store/catalog/genres/types';
import {
  genresFetched,
  genreFetched,
  genreCreated,
  genreUpdated,
  genreDestroyed,
} from '@store/catalog/genres/actions';
import { Observable } from 'rxjs';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { Action } from 'redux';
import CONFIG from '@config/environments/base';
import { RootStateOrAny } from 'react-redux';

const fetchGenresEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.GENRES.FETCH),
  switchMap((action:FetchGenresAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/genres/`,
    method: 'GET',
    success: genresFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchGenreEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.GENRE.FETCH),
  switchMap((action:FetchGenreAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/genres/${action.id}/`,
    method: 'GET',
    success: genreFetched,
    auth: true,
    state$,
    action,
  })),
);

const createGenreEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.GENRE.CREATE),
  switchMap((action:CreateGenreAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/genres/`,
    method: 'POST',
    success: genreCreated,
    auth: true,
    state$,
    action,
    body: action.genre,
  })),
);

const updateGenreEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.GENRE.UPDATE),
  switchMap((action:UpdateGenreAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/genres/${action.id}/`,
    method: 'PUT',
    success: genreUpdated,
    auth: true,
    state$,
    action,
    body: action.genre,
  })),
);

const destroyGenreEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.GENRE.DESTROY),
  switchMap((action:DestroyGenreAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/genres/${action.id}`,
    method: 'DELETE',
    success: genreDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchGenresEpic,
  fetchGenreEpic,
  createGenreEpic,
  updateGenreEpic,
  destroyGenreEpic,
];
