import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { PATRON_ACTION_TYPES } from '@store/patrons/actionTypes';
import { destroyPatron, fetchPatron, updatePatron } from '@store/patrons/patrons/actions';
import { Patron } from '@store/patrons/patrons/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import { RootState } from '@store/root';

interface StateProps {
  patron:Patron
}
interface DispatchProps {
  dispatchFetchPatron:(id:string)=>void
  dispatchUpdatePatron:(e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchDestroyPatron:(e:React.MouseEvent<HTMLFormElement>)=>void
}

type EditAPatronProps = StateProps & DispatchProps;

function EditAPatron(props:EditAPatronProps) {
  const {
    dispatchFetchPatron, patron, dispatchUpdatePatron, dispatchDestroyPatron,
  } = props;
  const params = useParams();
  const { id } = params;

  React.useEffect(() => {
    dispatchFetchPatron(id);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Edit A Patron</TextHeader>
      <APIForm
        id="di-edit-a-patron"
        onSubmit={dispatchUpdatePatron}
        action={PATRON_ACTION_TYPES.PATRON.UPDATE}
        onSuccessRoute={ROUTES.PATRONS.PATRONS.DETAILS}
        onSuccessAction={PATRON_ACTION_TYPES.PATRON.UPDATED}
        entityID={patron.id}
      >

        <input type="hidden" name="id" defaultValue={patron.id} />
        <InputText id="di-patron-name" type="text" name="name" defaultValue={patron.name} />
        <InputText id="di-patron-email" type="email" name="email" defaultValue={patron.email} />
        <InputText id="di-patron-email" type="tel" name="phone" defaultValue={patron.phone} />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>

      <APIForm
        id="di-destroy-a-patron"
        onSubmit={dispatchDestroyPatron}
        action={PATRON_ACTION_TYPES.PATRON.DESTROY}
        onSuccessRoute={ROUTES.PATRONS.PATRONS.ALL(true)}
        onSuccessAction={PATRON_ACTION_TYPES.PATRON.DESTROYED}
        entityID={patron.id}
      >
        <input type="hidden" name="id" defaultValue={patron.id} />
        <SubmitButton color="blue-green" fill="outline" value="Delete" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  patron: state.patrons.currentPatron || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchPatron: (id:string):void => {
    dispatch(fetchPatron(id));
  },
  dispatchUpdatePatron: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updatePatron(data));
  },
  dispatchDestroyPatron: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyPatron(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAPatron);
