import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import {
  CreateFeeAction,
  FeeCreatedAction,
  FetchFeeAction,
  FetchFeesAction,
  FeeFetchedAction,
  FeesFetchedAction,
  Fee,
  UpdateFeeAction,
  FeeUpdatedAction,
  DestroyFeeAction,
  FeeDestroyedAction,
  FetchFeesOptions,
} from '@store/circulation/fees/types';

export function fetchFees(options:FetchFeesOptions = undefined):FetchFeesAction {
  return {
    type: CIRCULATION_ACTION_TYPES.FEES.FETCH,
    options,
  };
}

export function feesFetched(response:Fee[]):FeesFetchedAction {
  return {
    type: CIRCULATION_ACTION_TYPES.FEES.FETCHED,
    fees: response,
  };
}

export function fetchFee(id:number|string):FetchFeeAction {
  return {
    type: CIRCULATION_ACTION_TYPES.FEE.FETCH,
    id,
  };
}

export function feeFetched(fee:Fee):FeeFetchedAction {
  return {
    type: CIRCULATION_ACTION_TYPES.FEE.FETCHED,
    fee,
  };
}

export const createFee = (fee:FormData):CreateFeeAction => ({
  type: CIRCULATION_ACTION_TYPES.FEE.CREATE,
  fee,
});

export const feeCreated = (fee:Fee):FeeCreatedAction => ({
  type: CIRCULATION_ACTION_TYPES.FEE.CREATED,
  fee,
  id: fee.id,
});

export const updateFee = (fee:FormData):UpdateFeeAction => ({
  type: CIRCULATION_ACTION_TYPES.FEE.UPDATE,
  fee,
  id: fee.get('id') as string|number,
});

export const feeUpdated = (fee:Fee):FeeUpdatedAction => ({
  type: CIRCULATION_ACTION_TYPES.FEE.UPDATED,
  fee,
});

export const destroyFee = (fee:FormData):DestroyFeeAction => ({
  type: CIRCULATION_ACTION_TYPES.FEE.DESTROY,
  id: fee.get('id') as string|number,
});

export const feeDestroyed = (id:number|string):FeeDestroyedAction => ({
  type: CIRCULATION_ACTION_TYPES.FEE.DESTROYED,
  id,
});
