import { createEpicMiddleware } from 'redux-observable';
import { createStore, applyMiddleware, Store } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from '@redux-devtools/extension';
import { rootReducer, rootEpic } from '@store/root';

const epicMiddleware = createEpicMiddleware();

function configureStore() : Store {
  const store:Store = createStore(
    rootReducer,
    process.env.NODE_ENV === 'production' ? applyMiddleware(epicMiddleware) : composeWithDevTools(applyMiddleware(epicMiddleware)),
  );

  epicMiddleware.run(rootEpic);

  return store;
}
export default configureStore;
