import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import {
  CreateLocationAction,
  LocationCreatedAction,
  FetchLocationAction,
  FetchLocationsAction,
  LocationFetchedAction,
  LocationsFetchedAction,
  Location,
  UpdateLocationAction,
  LocationUpdatedAction,
  DestroyLocationAction,
  LocationDestroyedAction,
  FetchLocationsOptions,
} from '@store/circulation/locations/types';

export function fetchLocations(options:FetchLocationsOptions = undefined):FetchLocationsAction {
  return {
    type: CIRCULATION_ACTION_TYPES.LOCATIONS.FETCH,
    options,
  };
}

export function locationsFetched(response:Location[]):LocationsFetchedAction {
  return {
    type: CIRCULATION_ACTION_TYPES.LOCATIONS.FETCHED,
    locations: response,
  };
}

export function fetchLocation(id:number|string):FetchLocationAction {
  return {
    type: CIRCULATION_ACTION_TYPES.LOCATION.FETCH,
    id,
  };
}

export function locationFetched(location:Location):LocationFetchedAction {
  return {
    type: CIRCULATION_ACTION_TYPES.LOCATION.FETCHED,
    location,
  };
}

export const createLocation = (location:FormData):CreateLocationAction => ({
  type: CIRCULATION_ACTION_TYPES.LOCATION.CREATE,
  location,
});

export const locationCreated = (location:Location):LocationCreatedAction => ({
  type: CIRCULATION_ACTION_TYPES.LOCATION.CREATED,
  location,
  id: location.id,
});

export const updateLocation = (location:FormData):UpdateLocationAction => ({
  type: CIRCULATION_ACTION_TYPES.LOCATION.UPDATE,
  location,
  id: location.get('id') as string|number,
});

export const locationUpdated = (location:Location):LocationUpdatedAction => ({
  type: CIRCULATION_ACTION_TYPES.LOCATION.UPDATED,
  location,
  id: location.id,
});

export const destroyLocation = (location:FormData):DestroyLocationAction => ({
  type: CIRCULATION_ACTION_TYPES.LOCATION.DESTROY,
  id: location.get('id') as string|number,
});

export const locationDestroyed = (id:number|string):LocationDestroyedAction => ({
  type: CIRCULATION_ACTION_TYPES.LOCATION.DESTROYED,
  id,
});
