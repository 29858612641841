import * as React from 'react';

function NoRoute() {
  return (
    <div>
      404: It&apos;s not here.
    </div>
  );
}

export default NoRoute;
