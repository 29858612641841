import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import { destroyBudget, fetchBudget, updateBudget } from '@store/procurement/budgets/actions';
import { Budget } from '@store/procurement/budgets/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import InputText from '@components/base/form-fields/InputText';
import Label from '@components/base/form-fields/Label';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import { RootState } from '@store/root';

interface StateProps {
  budget:Budget
}
interface DispatchProps {
  dispatchFetchBudget:(id:number|string)=>void
  dispatchUpdateBudget:(e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchDestroyBudget:(e:React.MouseEvent<HTMLFormElement>)=>void
}
type EditABudgetProps = StateProps & DispatchProps;

function EditABudget(props:EditABudgetProps) {
  const {
    dispatchFetchBudget, budget, dispatchUpdateBudget, dispatchDestroyBudget,
  } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);
  React.useEffect(() => {
    dispatchFetchBudget(id);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Edit A Budget</TextHeader>
      <APIForm
        id="di-edit-a-budget"
        onSubmit={dispatchUpdateBudget}
        action={PROCUREMENT_ACTION_TYPES.BUDGET.UPDATE}
        onSuccessRoute={ROUTES.PROCUREMENT.BUDGETS.DETAILS}
        onSuccessAction={PROCUREMENT_ACTION_TYPES.BUDGET.UPDATED}
        entityID={budget.id}
      >

        <input type="hidden" name="id" defaultValue={budget.id} />
        <Label htmlFor="name">Name:</Label>
        <InputText id="di-budget-name" type="text" name="name" defaultValue={budget.name} />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>

      <APIForm
        id="di-destroy-a-budget"
        onSubmit={dispatchDestroyBudget}
        action={PROCUREMENT_ACTION_TYPES.BUDGET.DESTROY}
        onSuccessRoute={ROUTES.PROCUREMENT.BUDGETS.ALL(true)}
        onSuccessAction={PROCUREMENT_ACTION_TYPES.BUDGET.DESTROYED}
        entityID={budget.id}
      >
        <input type="hidden" name="id" defaultValue={budget.id} />
        <SubmitButton color="blue-green" fill="outline" value="Delete" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  budget: state.budgets.currentBudget,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchBudget: (id:number|string):void => {
    dispatch(fetchBudget(id));
  },
  dispatchUpdateBudget: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updateBudget(data));
  },
  dispatchDestroyBudget: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyBudget(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditABudget);
