import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import { Purchase } from '@store/procurement/purchases/types';
import APIList from '@containers/application/APIList';
import * as React from 'react';
import { ROUTES } from '@routes';

interface RequiredPurchaseListProps {
  purchases: Purchase[]
  currentPage: number
  totalPages: number
  sortable: boolean
  selectable: boolean
}
interface OptionalPurchaseListProps {
  getSelected?: (purchases:Purchase[])=>void
  filterable?: boolean
}
type PurchaseListProps = OptionalPurchaseListProps & RequiredPurchaseListProps;

function PurchaseList(props:PurchaseListProps) {
  const [selected, setSelected] = React.useState([]);
  const {
    purchases, getSelected, filterable, currentPage, totalPages, sortable, selectable,
  } = props;

  const toggleAll = (e:React.MouseEvent) => {
    e.stopPropagation();
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected([...purchases]);
    }
    getSelected(selected);
  };

  const toggleSelected = (selectedPurchase:Purchase) => {
    if (selected.filter((record:Purchase) => record.id === selectedPurchase.id).length > 0) {
      setSelected(selected.filter((record:Purchase) => record.id !== selectedPurchase.id));
    } else {
      setSelected([...selected, selectedPurchase]);
    }
    getSelected(selected);
  };

  return (
    <APIList action={PROCUREMENT_ACTION_TYPES.PURCHASES.FETCH}>
      <PaginatedTable
        showHeader={filterable}
        empty={purchases.length === 0}
        currentPage={currentPage}
        totalPages={totalPages}
      >
        <TableHeader>
          <TableRow>
            { selectable && <TableHeaderColumn resizable={false} onClick={toggleAll} sortable={false}><input readOnly type="checkbox" checked={selected.length === purchases.length && purchases.length > 0} /></TableHeaderColumn> }
            <TableHeaderColumn resizable attribute="purchaseDate" sortable={sortable}>Purchase Date</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="title" sortable={sortable}> Title</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="status" sortable={sortable}>Status</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="supplier" sortable={sortable}> Supplier </TableHeaderColumn>
            <TableHeaderColumn resizable attribute="budget" sortable={sortable}> Budget </TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          { purchases.map((purchase:Purchase) => (
            <TableRow key={purchase.id} to={ROUTES.PROCUREMENT.PURCHASES.DETAILS(purchase.id)}>
              { selectable && (
                <TableColumn resizable={false} tabIndex={0}>
                  <input
                    readOnly
                    type="checkbox"
                    checked={selected.filter(
                      (selectedPurchase:Purchase) => selectedPurchase.id === purchase.id,
                    ).length > 0}
                    onClick={
                      (e:React.MouseEvent) => { e.stopPropagation(); toggleSelected(purchase); }
                    }
                  />
                </TableColumn>
              )}
              <TableColumn tabIndex={0} resizable>
                {purchase.purchaseDate}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {purchase.title}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {purchase.status}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" to={ROUTES.PROCUREMENT.SUPPLIERS.DETAILS(purchase.supplier?.id)}>{purchase.supplier?.name}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" to={ROUTES.PROCUREMENT.BUDGETS.DETAILS(purchase.budget?.id)}>{purchase.budget?.name}</HTMLLink>
              </TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
    </APIList>
  );
}

PurchaseList.defaultProps = {
  getSelected: undefined,
  filterable: false,
};
export default PurchaseList;
