import StarRating from '@components/compound/ratings/StarRating';
import { Review } from '@store/catalog/reviews/types';
import * as React from 'react';
import DescriptionText from '@components/base/text/DescriptionText';
import SubHeader from '@components/base/text/SubHeader';
import TextHeader from '@components/base/text/TextHeader';

interface ReviewListItemProps {
  review: Review
}

function ReviewListItem(props:ReviewListItemProps) {
  const { review } = props;
  return (
    <li className="di-review-list-item">
      <StarRating rating={review.rating} onRate={undefined} />
      <TextHeader size="h6">{review.title}</TextHeader>
      <SubHeader>{review.createdAt}</SubHeader>
      <DescriptionText>{review.description}</DescriptionText>
    </li>
  );
}

export default ReviewListItem;
