import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import { fetchHolds } from '@store/circulation/holds/actions';
import { Hold } from '@store/circulation/holds/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import HoldList from '@containers/circulation/holds/shared/HoldsList';
import SubHeader from '@components/base/text/SubHeader';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import FlexContainer from '@components/layout/generic/FlexContainer';
import RightAlignedFlexContainer from '@components/layout/generic/RightAlignedFlexContainer';
import Button from '@components/base/buttons-and-links/Button';
import { ROUTES } from '@routes';

interface StateProps {
  holds: Hold[],
  loading: boolean,
  currentPage: number,
  totalPages: number,
  numOfRecords: number
}
interface DispatchProps {
  dispatchFetchHolds:()=>void
}
type ShowAllHoldsProps = StateProps & DispatchProps;

function ShowAllHolds(props:ShowAllHoldsProps) {
  const {
    dispatchFetchHolds, numOfRecords,
    loading, holds, currentPage, totalPages,
  } = props;
  React.useEffect(() => {
    dispatchFetchHolds();
  }, []);
  return (
    <PageContainer loading={loading}>
      <MultiColumnFlex>
        <FlexContainer flexValue={1}>
          <TextHeader size="h1">Holds</TextHeader>
          <SubHeader>
            {numOfRecords}
            {' '}
            holds
          </SubHeader>
        </FlexContainer>
        <RightAlignedFlexContainer>
          <Button fill="solid" color="blue-green" to={ROUTES.CIRCULATION.HOLDS.NEW(true)}>New</Button>
        </RightAlignedFlexContainer>
      </MultiColumnFlex>

      <HoldList
        filterable
        clickable
        sortable
        selectable={false}
        holds={holds}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  holds: state.holds.holds,
  loading: state.holds.loading,
  currentPage: state.holds.currentPage,
  totalPages: state.holds.totalPages,
  numOfRecords: state.holds.results,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchHolds: ():void => {
    dispatch(fetchHolds());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllHolds);
