import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';
import { destroyOrganization, fetchOrganization, updateOrganization } from '@store/organizations/organizations/actions';
import { Organization } from '@store/organizations/organizations/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import { RootState } from '@store/root';

interface StateProps {
  organization:Organization
}
interface DispatchProps {
  dispatchFetchOrganization:(id:number|string)=>void
  dispatchUpdateOrganization:(e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchDestroyOrganization:(e:React.MouseEvent<HTMLFormElement>)=>void
}

type EditAnOrganizationProps = StateProps & DispatchProps;

function EditAnOrganization(props:EditAnOrganizationProps) {
  const {
    dispatchFetchOrganization, organization,
    dispatchDestroyOrganization, dispatchUpdateOrganization,
  } = props;
  const params = useParams();
  const { id } = params;

  React.useEffect(() => {
    dispatchFetchOrganization(id);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Edit A Organization</TextHeader>
      <APIForm
        id="di-edit-a-organization"
        onSubmit={dispatchUpdateOrganization}
        action={ORGANIZATION_ACTION_TYPES.ORGANIZATION.UPDATE}
        onSuccessRoute={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.DETAILS(id)}
        onSuccessAction={ORGANIZATION_ACTION_TYPES.ORGANIZATION.UPDATED}
        entityID={organization.id}
      >

        <input type="hidden" name="id" defaultValue={organization.id} />
        <InputText id="di-organization-name" type="text" name="title" defaultValue={organization.title} />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>

      <APIForm
        id="di-destroy-a-organization"
        onSubmit={dispatchDestroyOrganization}
        action={ORGANIZATION_ACTION_TYPES.ORGANIZATION.DESTROY}
        onSuccessRoute={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.DEFAULT(true)}
        onSuccessAction={ORGANIZATION_ACTION_TYPES.ORGANIZATION.DESTROYED}
        entityID={organization.id}
      >
        <input type="hidden" name="id" defaultValue={organization.id} />
        <SubmitButton color="blue-green" fill="outline" value="Delete" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  organization: state.organizations.currentOrganization || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchOrganization: (id:number|string):void => {
    dispatch(fetchOrganization(id));
  },
  dispatchUpdateOrganization: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updateOrganization(data));
  },
  dispatchDestroyOrganization: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyOrganization(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAnOrganization);
