import { faCaretDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface MainMenuTabProps {
  to: string
  children: React.ReactNode
  selected?: boolean
  icon: IconDefinition
}

function MainMenuTab(props:MainMenuTabProps) {
  const {
    to, children, selected, icon,
  } = props;
  return (
    <li className={`di-main-menu-tab ${selected ? 'selected' : ''}`}>
      <Link to={to}>
        <span className="di-main-menu-tab-icon"><FontAwesomeIcon icon={icon} /></span>
        { children }
        <span className="di-main-menu-tab-selector">{ selected ? <FontAwesomeIcon icon={faCaretDown} /> : undefined }</span>
      </Link>
    </li>
  );
}

MainMenuTab.defaultProps = {
  selected: false,
};
export default MainMenuTab;
