import { ApplicationMessage } from '@store/application/reducer';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

// This injects parent params into the route
// TODO: Refactor all this routing garbage.
// React router changes made a bunch of this
// not feasible.
interface ParentParams {
  prefix: string
  key: string
  value: number|string
}

interface StateProps {
  errors:ApplicationMessage[]
  events:ApplicationMessage[]
}

interface RequiredOwnProps {
  children: React.ReactNode
  onSubmit: (e:React.MouseEvent<HTMLFormElement>)=>void
  action:string
  id:string
}

interface OptionalOwnProps {
  onSuccessRoute?: string | ((id:number|string)=>string)
  onSuccessAction?:string
  onSuccess?: (id:string|number)=>void
  entityID?:number|string
  parentRouteParams?:ParentParams[]
}

type OwnProps = StateProps & OptionalOwnProps;
type APIFormProps = OwnProps & RequiredOwnProps;

function APIForm(props:APIFormProps) {
  const [submitted, setSubmitted] = React.useState(false);
  const {
    onSuccessRoute, events, entityID, onSuccessAction, onSubmit,
    errors, children, id, action, onSuccess, parentRouteParams,
  } = props;
  // findIndex provides a 0-based index
    // TODO: Incorporte e.timestamp to associate success events with initial events
  const event = events.find(
    (message:ApplicationMessage) => message.action === onSuccessAction
    && (entityID === undefined || message.id === entityID),
  );

  const successEvent = events.find(
    (message:ApplicationMessage) => message.action === onSuccessAction
    && (entityID === undefined || message.id === entityID),
  );

  const getSuccessRoute = () => {
    if (typeof onSuccessRoute === 'string') {
      return onSuccessRoute;
    }
    if (parentRouteParams !== undefined) {
      let route = onSuccessRoute(undefined);
      parentRouteParams.forEach((param:ParentParams) => {
        route = route.replace(param.key, param.value.toString());
        route = param.prefix + route;
      });
      return route.replace(':id', successEvent.id.toString());
    }
    return onSuccessRoute(successEvent.id);
  };

  const submitForm = (e:React.MouseEvent<HTMLFormElement>) => {
    onSubmit(e);
    setSubmitted(true);
  };

  return (
    <form onSubmit={submitForm} id={id}>
      <ul>
        {
        // Filter down to the errors this form cares about.
        errors.filter((error:ApplicationMessage) => error.target === action)
        // Extract the errors from the objects
          .reduce((ownErrors, current) => [...ownErrors, current.errors], [])
        // Return the error span
          .map((error) => (
            <li key={error}>{error}</li>
          ))
        }
      </ul>
      { children }

      {/* Redirect to route once the API call has completed successfully */}
      { submitted && event !== undefined
            && onSuccessRoute !== undefined && (<Navigate replace to={getSuccessRoute()} />)}

      {/* If a success function was provided instead, call that function */}
      { submitted && event !== undefined
        && onSuccess !== undefined && onSuccess(successEvent.id)}
    </form>
  );
}

APIForm.defaultProps = {
  onSuccessRoute: undefined,
  onSuccessAction: undefined,
  onSuccess: undefined,
  entityID: undefined,
  parentRouteParams: undefined,
};

const mapStateToProps = (state:RootState) => ({
  events: state.application,
  errors: state.application.filter((message:ApplicationMessage) => message.state === 'error'),
});

export default connect(mapStateToProps, null)(APIForm);
