import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import { Budget } from '@store/procurement/budgets/types';
import { createPurchase } from '@store/procurement/purchases/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '@store/root';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import Label from '@components/base/form-fields/Label';
import { fetchBudgets } from '@store/procurement/budgets/actions';
import StepAccordion from '@components/compound/accordions/StepAccordion';
import StepAccordionStep from '@components/base/accordions/StepAccordionStep';
import StepAccordionStepOpen from '@components/base/accordions/StepAccordionStepOpen';
import StepAccordionStepClosed from '@components/base/accordions/StepAccordionStepClosed';
import InputSelect from '@components/base/form-fields/InputSelect';
import Section from '@components/layout/generic/Section';
import GenericHR from '@components/base/dividers/GenericHR';
import { Supplier } from '@store/procurement/suppliers/types';
import { fetchSupplier, searchSuppliers } from '@store/procurement/suppliers/actions';
import InlineSearchResult from '@components/base/search/InlineSearchResult';
import InlineSearch from '@components/base/search/InlineSearch';
import InputText from '@components/base/form-fields/InputText';
import { LineItem } from '@store/procurement/lineItems/types';
import BudgetSummary from '../budgets/shared/BudgetSummary';
import SupplierSummary from '../suppliers/shared/SupplierSummary';
import LineItemSummary from './lineItems/shared/LineItemSummary';

interface DispatchProps {
  dispatchCreatePurchase:(e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchFetchBudgets: ()=>void
  dispatchSearchSuppliers: (term:string)=>void
  dispatchFetchSupplierDetails: (id:number|string)=>void
}
interface StateProps {
  budgets:Budget[]
  supplierSearchResults:Supplier[]
  supplier: Supplier
}
type CreateAPurchaseProps = DispatchProps & StateProps;

function CreateAPurchase(props:CreateAPurchaseProps) {
  const {
    dispatchFetchBudgets, dispatchCreatePurchase, dispatchSearchSuppliers,
    budgets, supplierSearchResults, supplier, dispatchFetchSupplierDetails,
  } = props;
  const [currentBudget, setCurrentBudget] = React.useState(undefined);
  const [lineItems, setLineItems] = React.useState([]);

  React.useEffect(() => {
    dispatchFetchBudgets();
  }, []);

  const handleBudgetChange = (e:React.MouseEvent<HTMLSelectElement>) => {
    const selectedBudget = budgets.find(
      (budget) => budget.id === parseInt(e.currentTarget.value, 10),
    );

    setCurrentBudget(selectedBudget);
  };

  const addALineItem = (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const item = Object.fromEntries(data);
    setLineItems([item, ...lineItems]);
  };
  console.log(lineItems);

  return (
    <PageContainer>
      <TextHeader size="h1">Add A Purchase</TextHeader>
      <GenericHR />
      <StepAccordion initialStep={1}>
        <StepAccordionStep title="Choose the budget for this purchase" step={1}>
          <StepAccordionStepOpen disabled={currentBudget === undefined}>
            <Section>
              { currentBudget !== undefined && (
                <Section>
                  <BudgetSummary budget={currentBudget} />
                  <GenericHR />
                </Section>
              )}
            </Section>
            <Section>
              <Label htmlFor="budgets">Select a budget</Label>
              <InputSelect onChange={handleBudgetChange} name="budgets" id="di-budgets">
                <option>--------</option>
                {budgets.map((budget:Budget) => (
                  <option
                    key={budget.id}
                    value={budget.id}
                  >
                    {budget.name}
                  </option>
                ))}
              </InputSelect>
            </Section>
          </StepAccordionStepOpen>
          <StepAccordionStepClosed>
            { currentBudget !== undefined && <BudgetSummary budget={currentBudget} />}
          </StepAccordionStepClosed>
        </StepAccordionStep>
        <StepAccordionStep title="Choose a supplier" step={2}>
          <StepAccordionStepOpen disabled={supplier === undefined}>
            { supplier !== undefined && (
            <Section>
              <SupplierSummary supplier={supplier} />
              <GenericHR />
            </Section>
            )}
            <Label htmlFor="name">{supplier !== undefined ? 'Search for a different supplier:' : 'Please choose a supplier:'}</Label>
            <InlineSearch
              searchFunction={dispatchSearchSuppliers}
              id="di-supplier-search"
              name="name"
            >
              {supplierSearchResults.map((result:Supplier) => (
                <InlineSearchResult
                  onSelect={() => dispatchFetchSupplierDetails(result.id)}
                  key={result.id}
                >
                  {result.name}
                </InlineSearchResult>
              ))}
            </InlineSearch>
          </StepAccordionStepOpen>
          <StepAccordionStepClosed>
            { supplier !== undefined && <SupplierSummary supplier={supplier} />}
          </StepAccordionStepClosed>
        </StepAccordionStep>
        <StepAccordionStep title="Add Line Items" step={3}>
          <StepAccordionStepOpen disabled={lineItems.length === 0}>
            { lineItems.length > 0 && (
            <Section>
              {lineItems.map((lineItem:LineItem) => <LineItemSummary lineItem={lineItem} />)}
              <GenericHR />
            </Section>
            )}
            <form onSubmit={addALineItem}>
              <InputText type="text" id="di-line-item-name" name="name" />
              <SubmitButton color="blue-green" fill="solid" value="add" />
            </form>
          </StepAccordionStepOpen>
          <StepAccordionStepClosed>
            {lineItems.map((lineItem:LineItem) => <LineItemSummary lineItem={lineItem} />)}
          </StepAccordionStepClosed>
        </StepAccordionStep>
        <StepAccordionStep title="Finalize purchase" step={4}>
          <StepAccordionStepOpen>asd</StepAccordionStepOpen>
          <StepAccordionStepClosed>asd</StepAccordionStepClosed>
        </StepAccordionStep>
      </StepAccordion>
      <APIForm
        id="di-add-a-purchase-form"
        action={PROCUREMENT_ACTION_TYPES.PURCHASE.CREATE}
        onSubmit={dispatchCreatePurchase}
        onSuccessRoute={ROUTES.PROCUREMENT.PURCHASES.DETAILS}
        onSuccessAction={PROCUREMENT_ACTION_TYPES.PURCHASE.CREATED}
      >
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  budgets: state.budgets.budgets,
  supplierSearchResults: state.suppliers.searchResults,
  supplier: state.suppliers.currentSupplier,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchBudgets: () => {
    dispatch(fetchBudgets({ status: 'active' }));
  },
  dispatchCreatePurchase: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createPurchase(data));
  },
  dispatchSearchSuppliers: (term:string) => {
    dispatch(searchSuppliers(term));
  },
  dispatchFetchSupplierDetails: (id:number|string) => {
    dispatch(fetchSupplier(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAPurchase);
