import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { destroyHold, fetchHold, updateHold } from '@store/circulation/holds/actions';
import { Hold } from '@store/circulation/holds/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';

interface StateProps { hold:Hold }
interface DispatchProps {
  dispatchFetchHold:(id:number|string)=>void,
  dispatchUpdateHold:(e:React.MouseEvent<HTMLFormElement>)=>void,
  dispatchDestroyHold:(e:React.MouseEvent<HTMLFormElement>)=>void
}
type EditAHoldProps = StateProps & DispatchProps;

function EditAHold(props:EditAHoldProps) {
  const {
    dispatchFetchHold, dispatchUpdateHold, dispatchDestroyHold, hold,
  } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchHold(id);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Edit A Hold</TextHeader>
      <APIForm
        id="di-edit-a-hold"
        onSubmit={dispatchUpdateHold}
        action={CIRCULATION_ACTION_TYPES.HOLD.UPDATE}
        onSuccessRoute={ROUTES.CIRCULATION.HOLDS.DETAILS(hold.id)}
        onSuccessAction={CIRCULATION_ACTION_TYPES.HOLD.UPDATED}
        entityID={hold.id}
      >

        <input type="hidden" name="id" defaultValue={hold.id} />
        <input type="text" name="name" defaultValue={hold.record.title} />
        <input type="text" name="record" defaultValue={hold.patron.name} />
        <input type="submit" value="Submit" />
      </APIForm>

      <APIForm
        id="di-destroy-a-hold"
        onSubmit={dispatchDestroyHold}
        action={CIRCULATION_ACTION_TYPES.HOLD.DESTROY}
        onSuccessRoute={ROUTES.CIRCULATION.HOLDS.ALL(true)}
        onSuccessAction={CIRCULATION_ACTION_TYPES.HOLD.DESTROYED}
        entityID={hold.id}
      >
        <input type="hidden" name="id" defaultValue={hold.id} />
        <input type="submit" value="Delete" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  hold: state.holds.currentHold || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchHold: (id:number|string):void => {
    dispatch(fetchHold(id));
  },
  dispatchUpdateHold: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updateHold(data));
  },
  dispatchDestroyHold: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyHold(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAHold);
