import Button from '@components/base/buttons-and-links/Button';
import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import { destroyLists, fetchLists } from '@store/catalog/lists/actions';
import { List } from '@store/catalog/lists/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Route, Routes } from 'react-router-dom';
import MultiActionButton from '@components/base/buttons-and-links/MultiActionButton';
import MultiActionButtonItem from '@components/base/buttons-and-links/MultiActionButtonItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from '@components/compound/modals/Modal';
import ListList from './shared/ListList';
import CreateAList from './CreateAList';

interface StateProps {
  lists:List[]
  numOfRecords: number
  currentPage: number
  totalPages: number
}
interface DispatchProps {
  dispatchFetchLists:()=>void
  dispatchDestroyLists:(lists:List[])=>void
}
type ShowAllListsProps = StateProps & DispatchProps;

function ShowAllLists(props:ShowAllListsProps) {
  const [selected, setSelected] = React.useState([]);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const {
    dispatchFetchLists, dispatchDestroyLists, lists, numOfRecords, currentPage, totalPages,
  } = props;
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatchFetchLists();
  }, []);

  const linkSelected = (selectedRecords:List[]):void => {
    setSelected(selectedRecords);
  };

  const actions = [
    <Button key={0} fill="solid" color="blue-green" to={ROUTES.CATALOG.LISTS.NEW(true)}>{ t('catalog.lists.all.addAList') }</Button>,
    (
      <MultiActionButton key={3} tabIndex={0} label="Bulk Actions" disabled={selected.length === 0} style={{ display: 'inline-block' }}>
        <MultiActionButtonItem onClick={() => setShowDeleteModal(true)}>
          <FontAwesomeIcon icon={faTrash} />
          Delete
        </MultiActionButtonItem>
      </MultiActionButton>
    ),
  ];

  return (
    <PageContainer>
      <ListList
        lists={lists}
        currentPage={currentPage}
        totalPages={totalPages}
        numOfRecords={numOfRecords}
        getSelected={linkSelected}
        sortable
        selectable
        showHeader
        actions={actions}
      />

      <Modal title={`Confirm the deletion of ${selected.length} lists?`} exitClick={() => setShowDeleteModal(false)} style={showDeleteModal ? {} : { display: 'none' }}>
        <p>Are you sure you want to delete these records?</p>
        <Button fill="solid" color="blue-green" onClick={() => { dispatchDestroyLists(selected); setShowDeleteModal(false); }}>Yes</Button>
      </Modal>
      <Routes>
        <Route path={ROUTES.CATALOG.LISTS.NEW()} element={<CreateAList />} />
      </Routes>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  lists: state.lists.all,
  numOfRecords: state.lists.results,
  currentPage: state.lists.currentPage,
  totalPages: state.lists.totalPages,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchLists: ():void => {
    dispatch(fetchLists());
  },
  dispatchDestroyLists: (lists:List[]):void => {
    dispatch(destroyLists(lists));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllLists);
