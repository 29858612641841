import Button from '@components/base/buttons-and-links/Button';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import FeeList from '@containers/circulation/fees/shared/FeeList';
import RecordLeaseList from '@containers/circulation/leases/shared/RecordLeaseList';
import { ROUTES } from '@routes';
import { fetchFees } from '@store/circulation/fees/actions';
import { Fee } from '@store/circulation/fees/types';
import { fetchRecordLeases } from '@store/circulation/recordLeases/actions';
import { RecordLease } from '@store/circulation/recordLeases/types';
import { fetchPatron } from '@store/patrons/patrons/actions';
import { Patron } from '@store/patrons/patrons/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

interface StateProps {
  patron:Patron
  recordLeases:RecordLease[]
  fees:Fee[]
}
interface DispatchProps {
  dispatchFetchPatron:(id:string)=>void
  dispatchFetchRecordLeases:(patronId:string)=>void
  dispatchFetchFees:(patronId:string)=>void
}
type ShowPatronDetailsProps = StateProps & DispatchProps;

function ShowPatronDetails(props:ShowPatronDetailsProps) {
  const {
    dispatchFetchPatron, dispatchFetchRecordLeases, dispatchFetchFees,
    recordLeases, fees, patron,
  } = props;
  const params = useParams();
  const { id } = params;
  React.useEffect(() => {
    dispatchFetchPatron(id);
    dispatchFetchRecordLeases(id);
    dispatchFetchFees(id);
  }, []);
  return (
    <PageContainer>
      <TextHeader size="h1">{patron.name}</TextHeader>
      <Button color="blue-green" fill="solid" to={ROUTES.PATRONS.PATRONS.EDIT(patron.id)}>Edit</Button>
      <RecordLeaseList
        recordLeases={recordLeases}
        currentPage={undefined}
        totalPages={undefined}
        numOfRecords={undefined}
        getSelected={undefined}
        sortable={false}
        selectable
        showHeader={false}
      />
      <FeeList
        fees={fees}
        currentPage={undefined}
        totalPages={undefined}
        numOfRecords={undefined}
        selectable={false}
        sortable={false}
        showHeader={false}
      />
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  patron: state.patrons.currentPatron || {},
  recordLeases: state.recordLeases.recordLeases,
  fees: state.fees.fees,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchPatron: (id:string):void => {
    dispatch(fetchPatron(id));
  },
  dispatchFetchRecordLeases: (patronId:string) => {
    dispatch(fetchRecordLeases({ patronId }));
  },
  dispatchFetchFees: (patronId:string) => {
    dispatch(fetchFees({ patronId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowPatronDetails);
