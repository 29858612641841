import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import {
  ClearCurrentRecordLeaseAction,
  ClearRecordLeasesAction,
  ClearRecordLeaseSearchAction,
  CreateRecordLeaseAction,
  DestroyRecordLeaseAction,
  DestroyRecordLeasesAction,
  FetchRecordLeaseAction,
  FetchRecordLeasesAction,
  FetchRecordLeasesOptions,
  RecordLease,
  RecordLeaseCreatedAction,
  RecordLeaseDestroyedAction,
  RecordLeaseFetchedAction,
  RecordLeasesDestroyedAction,
  RecordLeasesFetchedAction,
  RecordLeasesSearchedAction,
  RecordLeaseUpdatedAction,
  SearchRecordLeasesAction,
  UpdateRecordLeaseAction,
} from '@store/circulation/recordLeases/types';

export function fetchRecordLeases(
  options:FetchRecordLeasesOptions = undefined,
):FetchRecordLeasesAction {
  return {
    type: CIRCULATION_ACTION_TYPES.LEASES.FETCH,
    options,
  };
}

export function recordLeasesFetched(
  response:RecordLease[],
):RecordLeasesFetchedAction {
  return {
    type: CIRCULATION_ACTION_TYPES.LEASES.FETCHED,
    leases: response,
  };
}

export function destroyRecordLeases(leases:RecordLease[]):DestroyRecordLeasesAction {
  return {
    type: CIRCULATION_ACTION_TYPES.LEASES.DESTROY,
    leases,
  };
}

export function recordLeasesDestroyed():RecordLeasesDestroyedAction {
  return {
    type: CIRCULATION_ACTION_TYPES.LEASES.DESTROYED,
  };
}

export function fetchRecordLease(id:number|string):FetchRecordLeaseAction {
  return {
    type: CIRCULATION_ACTION_TYPES.LEASE.FETCH,
    id,
  };
}

export function recordLeaseFetched(lease:RecordLease):RecordLeaseFetchedAction {
  return {
    type: CIRCULATION_ACTION_TYPES.LEASE.FETCHED,
    lease,
  };
}

export const createRecordLease = (lease:FormData):CreateRecordLeaseAction => ({
  type: CIRCULATION_ACTION_TYPES.LEASE.CREATE,
  lease,
});

export const recordLeaseCreated = (lease:RecordLease):RecordLeaseCreatedAction => ({
  type: CIRCULATION_ACTION_TYPES.LEASE.CREATED,
  lease,
  id: lease.id,
});

export const updateRecordLease = (lease:FormData):UpdateRecordLeaseAction => ({
  type: CIRCULATION_ACTION_TYPES.LEASE.UPDATE,
  lease,
  id: lease.get('id') as string|number,
});

export const recordLeaseUpdated = (lease:RecordLease):RecordLeaseUpdatedAction => ({
  type: CIRCULATION_ACTION_TYPES.LEASE.UPDATED,
  lease,
  id: lease.id,
});

// AKA Check in
export const destroyRecordLease = (lease:FormData):DestroyRecordLeaseAction => ({
  type: CIRCULATION_ACTION_TYPES.LEASE.DESTROY,
  id: lease.get('id') as string|number,
});

export const recordLeaseDestroyed = (id:number|string):RecordLeaseDestroyedAction => ({
  type: CIRCULATION_ACTION_TYPES.LEASE.DESTROYED,
  id,
});

export const searchRecordLeases = (term:string):SearchRecordLeasesAction => ({
  type: CIRCULATION_ACTION_TYPES.LEASES.SEARCH,
  term,
});

export const recordLeasesSearched = (results:RecordLease[]):RecordLeasesSearchedAction => ({
  type: CIRCULATION_ACTION_TYPES.LEASES.SEARCHED,
  results,
});

export const clearCurrentRecordLease = ():ClearCurrentRecordLeaseAction => ({
  type: CIRCULATION_ACTION_TYPES.LEASE.CLEAR,
});

export const clearRecordLeases = ():ClearRecordLeasesAction => ({
  type: CIRCULATION_ACTION_TYPES.LEASES.CLEAR,
});

export const clearRecordLeaseSearch = ():ClearRecordLeaseSearchAction => ({
  type: CIRCULATION_ACTION_TYPES.LEASES.CLEAR_SEARCH,
});
