import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface FilterDropDownProps {
  children: React.ReactNode
  tabIndex: number
}

interface FilterDropDownState {
  open: boolean
}

class FilterDropDown extends React.Component<FilterDropDownProps, FilterDropDownState> {
  private wrapperRef = React.createRef<HTMLDivElement>();

  private moreButton = React.createRef<HTMLDivElement>();

  private childContainer = React.createRef<HTMLDivElement>();

  constructor(props:FilterDropDownProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClickOutside(event:React.MouseEvent<HTMLDivElement>) {
    const doesntContainItem = !this.wrapperRef.current.contains(event.target as HTMLDivElement);
    if (this.wrapperRef.current && doesntContainItem) {
      document.removeEventListener('click', this.handleClickOutside.bind(this), false);
      this.setState({
        open: false,
      });
    }
  }

  handleInsideClick() {
    document.removeEventListener('click', this.handleClickOutside.bind(this), false);
    this.setState({
      open: false,
    });
  }

  toggleMenuOpen() {
    const { open } = this.state;
    if (!open) {
      document.addEventListener('click', this.handleClickOutside.bind(this), false);
    }
    this.setState({
      open: !open,
    });
  }

  render(): React.ReactNode {
    const { children, tabIndex } = this.props;
    const { open } = this.state;
    return (
      <div className="di-filter-drop-down" ref={this.wrapperRef}>
        <div
          ref={this.moreButton}
          tabIndex={tabIndex}
          role="button"
          onClick={this.toggleMenuOpen.bind(this)}
          className="di-filter-drop-down-menu-item"
          onKeyDown={(e:React.KeyboardEvent<HTMLDivElement>) => { console.log(e.key); if (document.activeElement === this.moreButton.current && e.key === 'space') { this.toggleMenuOpen(); } }}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
          More
        </div>
        <div
          ref={this.childContainer}
          tabIndex={tabIndex}
          role="button"
          onClick={this.handleInsideClick.bind(this)}
          style={open ? {} : { display: 'none' }}
          className="di-fiter-drop-down-children"
          onKeyDown={(e:React.KeyboardEvent<HTMLDivElement>) => { console.log(e.key); if (document.activeElement === this.childContainer.current && e.key === 'space') { this.toggleMenuOpen(); } }}
        >
          { children}
        </div>
      </div>
    );
  }
}

export default FilterDropDown;
