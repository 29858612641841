import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface OwnProps {
  children: React.ReactNode
}

type DescriptionTextProps = OwnProps & WithTranslation;

function DescriptionText(props:DescriptionTextProps) {
  const { t, children } = props;
  const [open, setOpen] = React.useState(false);

  const toggleOpenState = () => {
    setOpen(!open);
  };

  return (
    <div className="di-description-text">
      <p className={open ? 'di-description-text-open' : 'di-description-text-closed'}>
        {children}
      </p>
      {/* <Editor
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          // onEditorStateChange={this.onEditorStateChange}
          /> */}
      <button type="button" onClick={toggleOpenState}>
        <FontAwesomeIcon icon={open ? faCaretUp : faCaretDown} />
        { open ? t('generic.link.showLess') : t('generic.link.showMore') }
      </button>
    </div>
  );
}

export default withTranslation()(DescriptionText);
