import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import {
  FetchSubjectsAction,
  FetchSubjectAction,
  CreateSubjectAction,
  UpdateSubjectAction,
  DestroySubjectAction,
} from '@store/catalog/subjects/types';
import {
  subjectsFetched,
  subjectFetched,
  subjectCreated,
  subjectUpdated,
  subjectDestroyed,
} from '@store/catalog/subjects/actions';
import { Observable } from 'rxjs';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchSubjectsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.SUBJECTS.FETCH),
  switchMap((action:FetchSubjectsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/subjects/`,
    method: 'GET',
    success: subjectsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchSubjectEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.SUBJECT.FETCH),
  switchMap((action:FetchSubjectAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/subjects/${action.id}/`,
    method: 'GET',
    success: subjectFetched,
    auth: true,
    state$,
    action,
  })),
);

const createSubjectEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.SUBJECT.CREATE),
  switchMap((action:CreateSubjectAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/subjects/`,
    method: 'POST',
    success: subjectCreated,
    auth: true,
    state$,
    action,
    body: action.subject,
  })),
);

const updateSubjectEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.SUBJECT.UPDATE),
  switchMap((action:UpdateSubjectAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/subjects/${action.id}/`,
    method: 'PUT',
    success: subjectUpdated,
    auth: true,
    state$,
    action,
    body: action.subject,
  })),
);

const destroySubjectEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.SUBJECT.DESTROY),
  switchMap((action:DestroySubjectAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/subjects/${action.id}/`,
    method: 'DELETE',
    success: subjectDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchSubjectsEpic,
  fetchSubjectEpic,
  createSubjectEpic,
  updateSubjectEpic,
  destroySubjectEpic,
];
