import StepAccordionStep from '@components/base/accordions/StepAccordionStep';
import StepAccordionStepClosed from '@components/base/accordions/StepAccordionStepClosed';
import StepAccordionStepOpen from '@components/base/accordions/StepAccordionStepOpen';
import InputSelect from '@components/base/form-fields/InputSelect';
import Label from '@components/base/form-fields/Label';
import APIForm from '@containers/application/APIForm';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { Record } from '@store/catalog/records/types';
import { createRecordInstance } from '@store/catalog/recordInstances/actions';
import { fetchBranches } from '@store/organizations/branches/actions';
import { Branch } from '@store/organizations/branches/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

interface OwnProps {
  step: number
  currentStep?: number
  nextStep?: ()=>void
  goToStep?: (step:number)=>void
  totalSteps?: number
}

interface StateProps {
  branches: Branch[]
  record: Record
}

interface DispatchProps {
  dispatchFetchBranches: (organizationId:string|number)=>void
  dispatchCreateRecordInstance: (e:React.MouseEvent<HTMLFormElement>)=>void
}

type CreateRecordInstanceStepProps = OwnProps & StateProps & DispatchProps;

function CreateRecordInstanceStep(props:CreateRecordInstanceStepProps) {
  const {
    step, dispatchFetchBranches, dispatchCreateRecordInstance, record, branches,
    currentStep, nextStep, goToStep, totalSteps,
  } = props;

  React.useEffect(() => {
    if (record !== undefined) {
      dispatchFetchBranches(record.organizationId);
    }
  }, [record]);
  return (
    <StepAccordionStep
      currentStep={currentStep}
      nextStep={nextStep}
      goToStep={goToStep}
      totalSteps={totalSteps}
      step={step}
      title="Provide copy details"
    >
      <StepAccordionStepOpen>
        <APIForm
          id="di-create-record-instance"
          onSubmit={dispatchCreateRecordInstance}
          action={CATALOG_ACTION_TYPES.RECORD_INSTANCE.CREATE}
        >
          <Label htmlFor="branchId">Branch:</Label>
          <InputSelect id="di-select-branch" name="branchId">
            {branches.map((branch:Branch) => (
              <option key={branch.id} value={branch.id}>
                {branch.title}
              </option>
            ))}
          </InputSelect>
        </APIForm>
      </StepAccordionStepOpen>
      <StepAccordionStepClosed>
        asd
      </StepAccordionStepClosed>
    </StepAccordionStep>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  branches: state.branches.branches,
  record: state.records.currentRecord,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchBranches: (organizationId:string|number):void => {
    dispatch(fetchBranches({ organizationId }));
  },
  dispatchCreateRecordInstance: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createRecordInstance(data));
  },
});

CreateRecordInstanceStep.defaultProps = {
  currentStep: undefined,
  nextStep: undefined,
  goToStep: undefined,
  totalSteps: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRecordInstanceStep);
