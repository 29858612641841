import FlexContainer from '@components/layout/generic/FlexContainer';
import * as React from 'react';
import TextHeader from '@components/base/text/TextHeader';

interface TabOptionProps {
  children?: React.ReactNode
  title: string
}

function TabOption(props:TabOptionProps) {
  const { children, title } = props;
  return (
    <FlexContainer className="di-tab-option">
      <TextHeader size="h4">{title}</TextHeader>
      {children}
    </FlexContainer>
  );
}

TabOption.defaultProps = {
  children: undefined,
};

export default TabOption;
