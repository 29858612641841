import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';

import {
  ClearRecordInstanceSearchAction,
  CreateRecordInstanceAction,
  DestroyRecordInstanceAction,
  FetchRecordInstanceAction,
  FetchRecordInstancesAction,
  FetchRecordInstancesOptions,
  RecordInstance,
  RecordInstanceCreatedAction,
  RecordInstanceDestroyedAction,
  RecordInstanceFetchedAction,
  RecordInstancesFetchedAction,
  RecordInstancesSearchedAction,
  RecordInstanceUpdatedAction,
  SearchRecordInstancesAction,
  UpdateRecordInstanceAction,
} from '@store/catalog/recordInstances/types';

export const fetchRecordInstances = (
  options:FetchRecordInstancesOptions = undefined,
):FetchRecordInstancesAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_INSTANCES.FETCH,
  options,
});

export const recordInstancesFetched = (
  response:RecordInstance[],
):RecordInstancesFetchedAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_INSTANCES.FETCHED,
  recordInstances: response,
});

export const fetchRecordInstance = (id:number|string):FetchRecordInstanceAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_INSTANCE.FETCH,
  id,
});

export const recordInstanceFetched = (
  recordInstance:RecordInstance,
):RecordInstanceFetchedAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_INSTANCE.FETCHED,
  recordInstance,
  id: recordInstance.id,
});

export const createRecordInstance = (recordInstance:FormData):CreateRecordInstanceAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_INSTANCE.CREATE,
  recordInstance,
});

export const recordInstanceCreated = (
  recordInstance:RecordInstance,
):RecordInstanceCreatedAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_INSTANCE.CREATED,
  recordInstance,
  id: recordInstance.id,
});

export const updateRecordInstance = (recordInstance:FormData):UpdateRecordInstanceAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_INSTANCE.UPDATE,
  recordInstance,
  id: recordInstance.get('id') as string|number,
});

export const recordInstanceUpdated = (
  recordInstance:RecordInstance,
):RecordInstanceUpdatedAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_INSTANCE.UPDATED,
  recordInstance,
  id: recordInstance.id,
});

export const destroyRecordInstance = (recordInstance:FormData):DestroyRecordInstanceAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_INSTANCE.DESTROY,
  id: recordInstance.get('id') as string|number,
});

export const recordInstanceDestroyed = (id:number|string):RecordInstanceDestroyedAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_INSTANCE.DESTROYED,
  id,
});

export const searchRecordInstances = (term:string):SearchRecordInstancesAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_INSTANCES.SEARCH,
  term,
});

export const recordInstancesSearched = (
  response:RecordInstance[],
):RecordInstancesSearchedAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_INSTANCES.SEARCHED,
  recordInstances: response,
});

export const clearRecordInstanceSearch = ():ClearRecordInstanceSearchAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_INSTANCES.CLEAR_SEARCH,
});
