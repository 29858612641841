import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface RequiredOwnProps {
  children: React.ReactNode
  sortable: boolean
}
interface OptionalOwnProps {
  attribute?: string
  onClick?: (e:React.MouseEvent)=>void
  resizable?: boolean
  defaultWidth?: number
}

type TableHeaderColumnProps =
  RequiredOwnProps & OptionalOwnProps;

function TableHeaderColumn(props:TableHeaderColumnProps) {
  const {
    defaultWidth, attribute, sortable, onClick, resizable, children,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const [columnWidth, setColumnWidth] = React.useState(defaultWidth || 200);
  const [initialXPosition, setInitialXPosition] = React.useState(0);

  let icon;
  if (attribute === urlParams.get('s')) {
    icon = urlParams.get('d') === 'asc' ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />;
  }

  const handleOnClick = () => {
    urlParams.set('d', urlParams.get('d') === 'desc' && urlParams.get('s') === attribute ? 'asc' : 'desc');
    urlParams.set('s', attribute);
    navigate({ pathname: location.pathname, search: urlParams.toString() });
  };

  const setInitialWidth = (e:React.DragEvent):void => {
    setInitialXPosition(e.clientX);
  };

  const resizeColumn = (e:React.DragEvent):void => {
    if (e.clientX > 0 && e.clientX !== undefined) {
      setColumnWidth(columnWidth + (e.clientX - initialXPosition));
      setInitialXPosition(e.clientX);
    }
  };
  return (
    <th
      onClick={sortable ? handleOnClick : onClick}
      className={`di-table-header-column ${resizable ? '' : 'di-checkbox-column'}`}
      style={{ width: `${columnWidth}px` }}
    >
      <div className="di-table-header-column-content">
        { children }
        <span className="di-table-header-icon">{ icon }</span>
      </div>
      <div className="di-table-header-drag-handle" draggable="true" onDrag={resizeColumn} onDragStart={setInitialWidth}>
          &nbsp;
      </div>
    </th>
  );
}

TableHeaderColumn.defaultProps = {
  attribute: undefined,
  onClick: undefined,
  resizable: true,
  defaultWidth: undefined,
};

export default TableHeaderColumn;
