import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import {
  FetchAdminsAction,
  FetchAdminAction,
  CreateAdminAction,
  UpdateAdminAction,
  DestroyAdminAction,
} from '@store/organizations/admins/types';
import {
  adminsFetched,
  adminFetched,
  adminCreated,
  adminUpdated,
  adminDestroyed,
} from '@store/organizations/admins/actions';
import { Observable } from 'rxjs';
import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';
import { Action } from 'redux';
import CONFIG from '@config/environments/base';

const fetchAdminsEpic = (action$:any, state$:any) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.STAFF_ADMINS.FETCH),
  switchMap((action:FetchAdminsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/organizations/${action.options.organizationId}/admins/`,
    method: 'GET',
    success: adminsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchAdminEpic = (action$:any, state$:any) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.FETCH),
  switchMap((action:FetchAdminAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/organizations/${action.options.organizationId}/admins/${action.id}/`,
    method: 'GET',
    success: adminFetched,
    auth: true,
    state$,
    action,
  })),
);

const createAdminEpic = (action$:any, state$:any) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.CREATE),
  switchMap((action:CreateAdminAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/organizations/${action.admin.get('organizationId')}/admins/`,
    method: 'POST',
    success: adminCreated,
    auth: true,
    state$,
    action,
    body: action.admin,
  })),
);

const updateAdminEpic = (action$:any, state$:any) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.UPDATE),
  switchMap((action:UpdateAdminAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/organizations/${action.admin.get('organizationId')}/admins/${action.admin.get('id')}/`,
    method: 'PUT',
    success: adminUpdated,
    auth: true,
    state$,
    action,
    body: action.admin,
  })),
);

const destroyAdminEpic = (action$:any, state$:any) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.DESTROY),
  switchMap((action:DestroyAdminAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/organizations/${action.organizationId}/admins/${action.id}/`,
    method: 'DELETE',
    success: adminDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchAdminsEpic,
  fetchAdminEpic,
  createAdminEpic,
  updateAdminEpic,
  destroyAdminEpic,
];
