import { ROUTES } from '@routes';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthenticatedContainer from '@containers/application/AuthenticatedContainer';
import ShowDefaultOrganization from '@containers/organizations/organizations/ShowDefaultOrganization';

function OrganizationManagement() {
  return (
    <AuthenticatedContainer>
      <Routes>
        <Route
          path={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.INDEX()}
          element={<ShowDefaultOrganization />}
        />
      </Routes>
    </AuthenticatedContainer>
  );
}

export default OrganizationManagement;
