import * as React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '@routes';

function ForgotPasswordSuccess() {
  return (
    <div>
      Success!!
      <Link to={ROUTES.AUTHENTICATION.LOGIN}>Back to Login</Link>
    </div>
  );
}

export default ForgotPasswordSuccess;
