import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  CreateBudgetAction,
  DestroyBudgetAction,
  FetchBudgetAction,
  FetchBudgetsAction,
  Budget,
  BudgetCreatedAction,
  BudgetDestroyedAction,
  BudgetFetchedAction,
  BudgetsFetchedAction,
  BudgetUpdatedAction,
  UpdateBudgetAction,
  FetchBudgetsOptions,
} from '@store/procurement/budgets/types';

export const fetchBudgets = (options:FetchBudgetsOptions = undefined):FetchBudgetsAction => ({
  type: PROCUREMENT_ACTION_TYPES.BUDGETS.FETCH,
  options,
});

export const budgetsFetched = (response:Budget[]):BudgetsFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.BUDGETS.FETCHED,

  budgets: response,
});

export const fetchBudget = (id:number|string):FetchBudgetAction => ({
  type: PROCUREMENT_ACTION_TYPES.BUDGET.FETCH,
  id,
});

export const budgetFetched = (budget:Budget):BudgetFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.BUDGET.FETCHED,
  budget,
});

export const createBudget = (budget:FormData):CreateBudgetAction => ({
  type: PROCUREMENT_ACTION_TYPES.BUDGET.CREATE,
  budget,
});

export const budgetCreated = (budget:Budget):BudgetCreatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.BUDGET.CREATED,
  budget,
  id: budget.id,
});

export const updateBudget = (budget:FormData):UpdateBudgetAction => ({
  type: PROCUREMENT_ACTION_TYPES.BUDGET.UPDATE,
  budget,
  id: budget.get('id') as string|number,
});

export const budgetUpdated = (budget:Budget):BudgetUpdatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.BUDGET.UPDATED,
  budget,
  id: budget.id,
});

export const destroyBudget = (budget:FormData):DestroyBudgetAction => ({
  type: PROCUREMENT_ACTION_TYPES.BUDGET.DESTROY,
  id: budget.get('id') as string|number,
});

export const budgetDestroyed = (id:number|string):BudgetDestroyedAction => ({
  type: PROCUREMENT_ACTION_TYPES.BUDGET.DESTROYED,
  id,
});
