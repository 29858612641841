import * as React from 'react';

interface SecondaryMenuProps {
  children: React.ReactNode
}
function SecondaryMenu(props:SecondaryMenuProps) {
  const { children } = props;
  return (
    <ul className="di-secondary-menu">
      { children }
    </ul>
  );
}

export default SecondaryMenu;
