import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface SettingsMenuItemProps {
  to: string
}

function SettingsMenuItem(props:SettingsMenuItemProps) {
  const { to } = props;
  return (
    <Link className="di-settings-menu-item" to={to}>
      <FontAwesomeIcon icon={faCog} />
    </Link>
  );
}

export default SettingsMenuItem;
