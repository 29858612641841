import TextHeader from '@components/base/text/TextHeader';
import * as React from 'react';
import FlexContainer from './generic/FlexContainer';
import MultiColumnFlex from './generic/MultiColumnFlex';
import RightAlignedFlexContainer from './generic/RightAlignedFlexContainer';

interface ItemDetailsHeaderProps {
  title: string
  actions?: React.ReactElement[]
  description?: string
}

function ItemDetailsHeader(props:ItemDetailsHeaderProps) {
  const { title, actions, description } = props;
  return (
    <MultiColumnFlex className="di-paginated-table-header">
      <FlexContainer>
        <TextHeader size="h1">{title}</TextHeader>
      </FlexContainer>
      <FlexContainer flexValue={1}>
        <span className="di-number-of-records">{description}</span>
      </FlexContainer>
      <RightAlignedFlexContainer>
        {actions}
      </RightAlignedFlexContainer>
    </MultiColumnFlex>
  );
}

ItemDetailsHeader.defaultProps = {
  actions: [],
  description: undefined,
};

export default ItemDetailsHeader;
