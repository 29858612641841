import { Record } from '@store/catalog/records/types';

export interface GoodReadsRecord {
  'Book Id': number
  'Title': string
  'Author': string
  'Author l-f': string
  'Additional Authors': string
  'ISBN': string
  'ISBN13': string
  'My Rating': number
  'Average Rating': string
  'Publisher': string
  'Binding': string
  'Number of Pages': string
  'Year Published': string
  'Original Publication Year': string
  'Date Read': string
  'Date Added': string
  'Bookshelves': string
  'Bookshelves with positions': string
  'Exclusive Shelf': string
  'My Review': string
  'Spoiler': string
  'Private Notes': string
  'Read Count': string
  'Recommended For': string
  'Recommended By': string
  'Owned Copies': number
  'Original Purchase Date': string
  'Original Purchase Location': string
  'Condition': string
  'Condition Description': string
  'BCID': string
}

export const importGoodReadsCSV = (
  data:Array<GoodReadsRecord>,
):Record[] => data.map((item) => ({
  title: item.Title,
  isbn: item.ISBN,
  isbn13: item.ISBN13,
  averageRating: parseInt(item['Average Rating'], 10),
  authors: [{
    name: item.Author,
  }],
  numberOfPages: parseInt(item['Number of Pages'], 10),
  reviews: [],
  notes: [],
  // dateRead: new Date(item['Date Read']).toISOString(),
  readCount: parseInt(item['Read Count'], 10),
  yearPublished: parseInt(item['Year Published'], 10),
}));

export default importGoodReadsCSV;
