import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { createNote } from '@store/catalog/notes/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import InputText from '@components/base/form-fields/InputText';
import Label from '@components/base/form-fields/Label';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import Modal from '@components/compound/modals/Modal';
import TextArea from '@components/base/form-fields/TextArea';

interface DispatchProps {
  dispatchCreateNote:(e:React.MouseEvent<HTMLFormElement>) => void
}

type CreateANoteProps = DispatchProps;

function CreateANote(props:CreateANoteProps) {
  const { dispatchCreateNote } = props;
  const params = useParams();
  const { id } = params;
  return (
    <Modal
      title="Add a Note"
      exitPath={ROUTES.CATALOG.RECORDS.DETAILS(id)}
    >
      <APIForm
        onSubmit={dispatchCreateNote}
        action={CATALOG_ACTION_TYPES.NOTE.CREATE}
        id="di-create-password-form"
        onSuccessRoute={ROUTES.CATALOG.RECORDS.DETAILS(id)}
        onSuccessAction={CATALOG_ACTION_TYPES.NOTE.CREATED}
      >
        <Label htmlFor="title">Title:</Label>
        <InputText id="di-note-name" type="text" name="title" />
        <Label htmlFor="content">Note:</Label>
        <TextArea id="di-note-content" name="content" />
        <input type="hidden" name="recordId" value={id} />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreateNote: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createNote(data));
  },
});

export default connect(null, mapDispatchToProps)(CreateANote);
