import * as React from 'react';

interface TableColumnProps {
  children: React.ReactNode
  resizable?: boolean
  defaultWidth?: number
  tabIndex: number
}

function TableColumn(props:TableColumnProps) {
  const clickableColumn = React.useRef(null);
  const {
    children, resizable, defaultWidth, tabIndex,
  } = props;

  const onClick = (e:React.MouseEvent) => {
    if (e.target instanceof HTMLAnchorElement) {
      e.stopPropagation();
    }
  };

  return (
    <td
      tabIndex={tabIndex}
      className={`di-table-column ${resizable ? '' : 'di-checkbox-column'}`}
      style={defaultWidth === undefined ? {} : { width: `${defaultWidth}px` }}
    >
      <div
        ref={clickableColumn}
        className="di-table-column-content"
        onClick={onClick}
        role="button"
        onKeyDown={(e:React.KeyboardEvent<HTMLDivElement>) => { console.log(e.key); if (document.activeElement === clickableColumn.current && e.key === 'space') { onClick(undefined); } }}
        tabIndex={0}
      >
        { children }
      </div>
    </td>
  );
}

TableColumn.defaultProps = {
  resizable: true,
  defaultWidth: undefined,
};

export default TableColumn;
