import * as React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../img/logo.png';

interface LogoMenuItemProps {
  to: string
}

function LogoMenuItem(props:LogoMenuItemProps) {
  const { to } = props;
  return (
    <Link className="di-logo-menu-item" to={to}>
      <img src={Logo} alt="Disembark ILS" height={50} />
    </Link>
  );
}

export default LogoMenuItem;
