import TextHeader from '@components/base/text/TextHeader';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface PageAccordionProps {
  children: React.ReactNode
  defaultOpen: boolean
  title: string
  tabIndex: number
}

function PageAccordion(props:PageAccordionProps) {
  const {
    defaultOpen, tabIndex, title, children,
  } = props;
  const [open, setOpen] = React.useState(defaultOpen);

  const toggleOpenState = () => {
    setOpen(!open);
  };

  return (
    <div className="di-page-accordion">
      <button type="button" tabIndex={tabIndex} onClick={toggleOpenState}>
        <TextHeader size="h3">
          <FontAwesomeIcon icon={open ? faCaretDown : faCaretRight} />
          { title}
        </TextHeader>
      </button>
      <div className={`di-page-accordion-body di-page-accordion-body-${open ? 'open' : 'closed'}`}>
        { children}
      </div>
    </div>
  );
}

export default PageAccordion;
