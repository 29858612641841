import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import {
  Publisher,
  PublisherFetchedAction,
  FetchPublisherAction,
  SearchPublishersAction,
  PublishersSearchedAction,
  CreatePublisherAction,
  PublisherCreatedAction,
} from '@store/catalog/publishers/types';

export function fetchPublisher(id:number|string):FetchPublisherAction {
  return {
    type: CATALOG_ACTION_TYPES.PUBLISHER.FETCH,
    id,
  };
}

export function publisherFetched(publisher:Publisher):PublisherFetchedAction {
  return {
    type: CATALOG_ACTION_TYPES.PUBLISHER.FETCHED,
    publisher,
    id: publisher.id,
  };
}

export function createPublisher(publisher:Publisher):CreatePublisherAction {
  return {
    type: CATALOG_ACTION_TYPES.PUBLISHER.CREATE,
    publisher,
  };
}

export function publisherCreated(publisher:Publisher):PublisherCreatedAction {
  return {
    type: CATALOG_ACTION_TYPES.PUBLISHER.CREATED,
    publisher,
  };
}

export function searchPublishers(term:string):SearchPublishersAction {
  return {
    type: CATALOG_ACTION_TYPES.PUBLISHERS.SEARCH,
    term,
  };
}

export function publishersSearched(results:Publisher[]):PublishersSearchedAction {
  return {
    type: CATALOG_ACTION_TYPES.PUBLISHERS.SEARCHED,
    results,
  };
}
