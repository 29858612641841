import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { PATRON_ACTION_TYPES } from '@store/patrons/actionTypes';
import { destroyPatronGroup, fetchPatronGroup, updatePatronGroup } from '@store/patrons/groups/actions';
import { PatronGroup } from '@store/patrons/groups/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import { RootState } from '@store/root';

interface StateProps {
  group:PatronGroup
}
interface DispatchProps {
  dispatchFetchPatronGroup:(id:number|string)=>void
  dispatchUpdatePatronGroup:(e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchDestroyPatronGroup:(e:React.MouseEvent<HTMLFormElement>)=>void
}
type EditAPatronGroupProps = StateProps & DispatchProps;
function EditAPatronGroup(props:EditAPatronGroupProps) {
  const {
    dispatchFetchPatronGroup, dispatchUpdatePatronGroup, group, dispatchDestroyPatronGroup,
  } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchPatronGroup(id);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Edit A PatronGroup</TextHeader>
      <APIForm
        id="di-edit-a-patron-group"
        onSubmit={dispatchUpdatePatronGroup}
        action={PATRON_ACTION_TYPES.PATRON_GROUP.UPDATE}
        onSuccessRoute={ROUTES.PATRONS.PATRON_GROUPS.DETAILS}
        onSuccessAction={PATRON_ACTION_TYPES.PATRON_GROUP.UPDATED}
        entityID={group.id}
      >

        <input type="hidden" name="id" defaultValue={group.id} />
        <InputText id="di-group-name" type="text" name="title" defaultValue={group.title} />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>

      <APIForm
        id="di-destroy-a-patron-group"
        onSubmit={dispatchDestroyPatronGroup}
        action={PATRON_ACTION_TYPES.PATRON_GROUP.DESTROY}
        onSuccessRoute={ROUTES.PATRONS.PATRON_GROUPS.ALL(true)}
        onSuccessAction={PATRON_ACTION_TYPES.PATRON_GROUP.DESTROYED}
        entityID={group.id}
      >
        <input type="hidden" name="id" defaultValue={group.id} />
        <SubmitButton color="blue-green" fill="outline" value="Delete" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  group: state.groups.currentPatronGroup || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchPatronGroup: (id:number|string):void => {
    dispatch(fetchPatronGroup(id));
  },
  dispatchUpdatePatronGroup: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updatePatronGroup(data));
  },
  dispatchDestroyPatronGroup: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyPatronGroup(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAPatronGroup);
