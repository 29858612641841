import { faEnvelope, faGear, faUsers, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Notification } from '@store/notifications/types';
import * as React from 'react';
import TextHeader from '@components/base/text/TextHeader';
import { Link } from 'react-router-dom';

interface NotificationListItemProps {
  notification: Notification
}

const getNotificationIcon = (icon:string):IconDefinition => {
  switch (icon) {
    case 'email':
      return faEnvelope;
    case 'settings':
      return faGear;
    case 'patrons':
      return faUsers;
    default:
      return faGear;
  }
};

function NotificationListItem(props:NotificationListItemProps) {
  const { notification } = props;
  return (
    <li className="di-notification-list-item">
      <FontAwesomeIcon icon={getNotificationIcon(notification.icon)} />
      <div className="di-notification-list-item-content">
        <TextHeader size="h6">{notification.name}</TextHeader>
        <p>{notification.description}</p>
      </div>
    </li>
  );
}

export default NotificationListItem;
