import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import { fetchSubject } from '@store/catalog/subjects/actions';
import { Subject } from '@store/catalog/subjects/types';
import { fetchRecords } from '@store/catalog/records/actions';
import { Record } from '@store/catalog/records/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import RecordList from '@containers/catalog/records/shared/RecordList';

interface StateProps {
  records: Record[]
  subject:Subject,
  loading:boolean
  currentPage: number
  totalPages: number
  numOfRecords: number
}
interface DispatchProps {
  dispatchFetchRecords: (id:number|string)=>void
  dispatchFetchSubject: (id:number|string)=>void
}
type ShowSubjectDetailsProps =
  StateProps & DispatchProps & WithTranslation;

function ShowSubjectDetails(props:ShowSubjectDetailsProps) {
  const [selected, setSelected] = React.useState([]);
  const {
    dispatchFetchSubject, dispatchFetchRecords, loading, subject,
    currentPage, totalPages, records, numOfRecords,
  } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchSubject(id);
    dispatchFetchRecords(id);
  }, []);

  const linkSelected = (selectedRecords:Record[]) => {
    setSelected(selectedRecords);
    console.log(selected);
  };
  return (
    <PageContainer loading={loading}>
      <TextHeader size="h1">{subject.title}</TextHeader>
      <RecordList
        records={records}
        currentPage={currentPage}
        totalPages={totalPages}
        getSelected={linkSelected}
        selectable
        sortable
        showHeader
        numOfRecords={numOfRecords}
      />
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  records: state.records.records,
  currentPage: state.records.currentPage,
  totalPages: state.records.totalPages,
  subject: state.subjects.currentSubject || {},
  loading: state.subjects.currentSubject === undefined,
  numOfRecords: state.subjects.results,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchRecords: (id:number|string) => {
    dispatch(fetchRecords({ subjectId: id }));
  },
  dispatchFetchSubject: (id:number|string) => {
    dispatch(fetchSubject(id));
  },
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ShowSubjectDetails));
