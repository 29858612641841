import { ROUTES } from '@routes';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import CheckInARecord from '@containers/circulation/leases/CheckInARecord';
import CheckOutARecord from '@containers/circulation/leases/CheckOutARecordInstance';
import RenewARecordLease from '@containers/circulation/leases/RenewARecordLease';
import ShowRecordLeaseDetails from '@containers/circulation/leases/ShowRecordLeaseDetails';

function RecordLeaseRoutes() {
  return (
    <div>
      <Routes>
        <Route path={ROUTES.CIRCULATION.LEASES.CHECK_IN()} element={<CheckInARecord />} />
        <Route path={ROUTES.CIRCULATION.LEASES.CHECK_OUT()} element={<CheckOutARecord />} />
        <Route path={ROUTES.CIRCULATION.LEASES.RENEW()} element={<RenewARecordLease />} />
        <Route path={ROUTES.CIRCULATION.LEASES.ALL()} element={<RenewARecordLease />} />
        <Route
          path={ROUTES.CIRCULATION.LEASES.DETAILS()}
          element={<ShowRecordLeaseDetails />}
        />
      </Routes>
    </div>
  );
}

export default RecordLeaseRoutes;
