import InputText from '@components/base/form-fields/InputText';
import Label from '@components/base/form-fields/Label';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import CenterContainer from '@components/layout/generic/CenterContainer';
import PageContainer from '@components/layout/generic/PageContainer';
import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { confirmEmail } from '@store/authentication/actions';
import { AUTHENTICATION_ACTION_TYPES } from '@store/authentication/actionTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

interface DispatchProps {
  deployConfirmEmail: (e:React.MouseEvent<HTMLFormElement>)=>void
}

type ConfirmEmailProps = DispatchProps;

function ConfirmEmail(props:ConfirmEmailProps) {
  const { deployConfirmEmail } = props;
  return (
    <PageContainer>
      <CenterContainer>
        <APIForm
          onSubmit={deployConfirmEmail}
          action={AUTHENTICATION_ACTION_TYPES.CONFIRM_EMAIL}
          id="di-confirm-email"
          onSuccessRoute={ROUTES.CATALOG.INDEX(true)}
          onSuccessAction={AUTHENTICATION_ACTION_TYPES.EMAIL_CONFIRMED}
        >
          <InputText type="email" id="di-confirmation-email" name="confirmation-email" />
          <Label htmlFor="confirmation-code">Please enter your confirmation code:</Label>
          <InputText type="text" id="di-confirmation-code" name="confirmation-code" />
          <SubmitButton color="blue-green" fill="solid" value="Go" />
        </APIForm>
      </CenterContainer>
    </PageContainer>
  );
}

function mapDispatchToProps(dispatch:Dispatch):DispatchProps {
  return {
    deployConfirmEmail: (e:React.MouseEvent<HTMLFormElement>):void => {
      e.preventDefault();
      const data = new FormData(e.currentTarget);
      dispatch(confirmEmail(data));
    },
  };
}

export default connect(null, mapDispatchToProps)(ConfirmEmail);
