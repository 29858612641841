import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { PATRON_ACTION_TYPES } from '@store/patrons/actionTypes';
import { createPatron } from '@store/patrons/patrons/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import Label from '@components/base/form-fields/Label';

interface DispatchProps { dispatchCreatePatron:(e:React.MouseEvent<HTMLFormElement>)=>void }
type CreateAPatronProps = DispatchProps;

function CreateAPatron(props:CreateAPatronProps) {
  const { dispatchCreatePatron } = props;
  return (
    <PageContainer>
      <TextHeader size="h1">Add A Patron</TextHeader>
      <APIForm
        id="di-add-an-patron--form"
        action={PATRON_ACTION_TYPES.PATRON.CREATE}
        onSubmit={dispatchCreatePatron}
        onSuccessRoute={ROUTES.PATRONS.PATRONS.DETAILS}
        onSuccessAction={PATRON_ACTION_TYPES.PATRON.CREATED}
      >

        <Label htmlFor="name">Name:</Label>
        <InputText type="text" name="name" id="di-patron-name" />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>
    </PageContainer>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreatePatron: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createPatron(data));
  },
});

export default connect(null, mapDispatchToProps)(CreateAPatron);
