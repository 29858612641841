import ProfileMenu from '@components/base/menu-items/ProfileMenuItem';
import { ROUTES } from '@routes';
import { logout } from '@store/authentication/actions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';

interface DispatchProps { dispatchLogout:()=>void }
type UserMenuProps = DispatchProps;

function UserMenu(props:UserMenuProps) {
  const { t } = useTranslation();
  const { dispatchLogout } = props;
  return (
    <ProfileMenu>
      <li>
        <Link to={ROUTES.PROFILE.INDEX(true)}>{ t('menu.profile.profile') }</Link>
      </li>
      <li>
        <Link to={ROUTES.PROFILE.FAMILY_MEMBERS.ALL(true)}>{ t('menu.profile.family') }</Link>
      </li>
      <li>
        <Link to={ROUTES.PROFILE.BILLING(true)}>{ t('menu.profile.billing') }</Link>
      </li>
      <li>
        <Link to={ROUTES.PROFILE.NOTIFICATION_SETTINGS(true)}>{ t('menu.profile.settings') }</Link>
      </li>
      <li>
        <button type="button" onClick={dispatchLogout.bind(this)}>{ t('menu.profile.logout') }</button>
      </li>
    </ProfileMenu>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchLogout: ():void => {
    dispatch(logout());
  },
});

export default connect(null, mapDispatchToProps)(UserMenu);
