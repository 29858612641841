import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { List } from '@store/catalog/lists/types';
import APIList from '@containers/application/APIList';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import { ROUTES } from '@routes';

interface RequiredOwnProps {
  lists: List[]
  currentPage:number
  totalPages:number
  numOfRecords:number
  showHeader: boolean
  selectable: boolean
  sortable: boolean
  actions?: React.ReactElement[]
}

interface OptionalOwnProps {
  getSelected?:(lists: List[])=>void
}

type ListListProps = OptionalOwnProps & RequiredOwnProps;

function ListList(props:ListListProps) {
  const [selected, setSelected] = React.useState([]);
  const {
    lists, getSelected, currentPage, totalPages,
    showHeader, selectable, sortable, actions, numOfRecords,
  } = props;
  const { t } = useTranslation();

  React.useEffect(() => {
    getSelected(selected);
  }, [selected]);

  const toggleAll = (e:React.MouseEvent):void => {
    e.stopPropagation();
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected([...lists]);
    }
  };

  const toggleSelected = (selectedList:List):void => {
    if (selected.filter((list:List) => list.id === selectedList.id).length > 0) {
      setSelected(selected.filter((list:List) => list.id !== selectedList.id));
    } else {
      setSelected([...selected, selectedList]);
    }
  };

  return (
    <APIList action={CATALOG_ACTION_TYPES.LISTS.FETCH}>
      <PaginatedTable
        showHeader={showHeader}
        empty={lists.length === 0}
        currentPage={currentPage}
        totalPages={totalPages}
        title="Lists"
        numOfRecordsString={`${numOfRecords?.toLocaleString()} Lists`}
        actions={actions}
      >
        <TableHeader>
          <TableRow>
            { selectable && (
              <TableHeaderColumn
                onClick={toggleAll}
                sortable={false}
                resizable={false}
              >
                <input
                  readOnly
                  type="checkbox"
                  checked={selected.length === lists.length && lists.length > 0}
                />
              </TableHeaderColumn>
            ) }
            <TableHeaderColumn resizable sortable={sortable}>{ t('catalog.lists.model.name') }</TableHeaderColumn>
            <TableHeaderColumn resizable sortable={sortable}>{ t('catalog.lists.model.createdBy') }</TableHeaderColumn>
            <TableHeaderColumn resizable sortable={sortable}>{ t('catalog.lists.model.numberOfItems') }</TableHeaderColumn>
            <TableHeaderColumn resizable sortable={false}>{ t('generic.table.actions') }</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          { lists.map((list:List) => (
            <TableRow key={list.id}>
              { selectable && (
                <TableColumn
                  tabIndex={0}
                  resizable={false}
                >
                  <input
                    readOnly
                    onClick={(e:React.MouseEvent) => {
                      e.stopPropagation();
                      toggleSelected(list);
                    }}
                    type="checkbox"
                    checked={selected.filter(
                      (selectedList:List) => selectedList.id === list.id,
                    ).length > 0}
                  />
                </TableColumn>
              ) }
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" to={ROUTES.CATALOG.LISTS.DETAILS(list.id)}>{ list.name }</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>{ list.createdBy }</TableColumn>
              <TableColumn tabIndex={0} resizable>{ list.numberOfItems }</TableColumn>
              <TableColumn tabIndex={0} resizable>Actions</TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
    </APIList>
  );
}

ListList.defaultProps = {
  getSelected: undefined,
  actions: [],
};

export default ListList;
