import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { PROFILE_ACTION_TYPES } from '@store/profile/actionTypes';
import { createFamilyMember } from '@store/profile/family/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import InputText from '@components/base/form-fields/InputText';
import Label from '@components/base/form-fields/Label';
import SubmitButton from '@components/base/form-fields/SubmitButton';

interface DispatchProps {
  dispatchCreateFamilyMember:(e:React.MouseEvent<HTMLFormElement>)=>void
}
type CreateAFamilyMemberProps = DispatchProps;

function CreateAFamilyMember(props:CreateAFamilyMemberProps) {
  const { dispatchCreateFamilyMember } = props;
  return (
    <PageContainer>
      <TextHeader size="h1">Add An FamilyMember</TextHeader>
      <APIForm
        id="di-add-an-family-member-form"
        action={PROFILE_ACTION_TYPES.FAMILY_MEMBER.CREATE}
        onSubmit={dispatchCreateFamilyMember}
        onSuccessRoute={ROUTES.PROFILE.FAMILY_MEMBERS.DETAILS}
        onSuccessAction={PROFILE_ACTION_TYPES.FAMILY_MEMBER.CREATED}
      >

        <Label htmlFor="name">Name:</Label>
        <InputText type="text" name="name" id="di-family-member-name" />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>
    </PageContainer>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreateFamilyMember: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createFamilyMember(data));
  },
});

export default connect(null, mapDispatchToProps)(CreateAFamilyMember);
