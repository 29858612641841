import APIList from '@containers/application/APIList';
import { ROUTES } from '@routes';
import { fetchFunds } from '@store/procurement/funds/actions';
import { Fund } from '@store/procurement/funds/types';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '@store/root';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import TableHeader from '@components/base/tables/TableHeader';
import TableRow from '@components/base/tables/TableRow';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import Button from '@components/base/buttons-and-links/Button';

interface StateProps { funds: Fund[] }
interface DispatchProps {
  dispatchFetchFunds:()=>void
}
type ShowAllFundsProps = StateProps & DispatchProps;

function ShowAllFunds(props:ShowAllFundsProps) {
  const { dispatchFetchFunds, funds } = props;
  React.useEffect(() => {
    dispatchFetchFunds();
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Funds</TextHeader>
      <APIList action={PROCUREMENT_ACTION_TYPES.FUNDS.FETCH}>
        <PaginatedTable>
          <TableHeader>
            <TableRow>
              <TableHeaderColumn sortable>
                Name
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            { funds.map((fund:Fund) => (
              <TableRow key={fund.id} to={ROUTES.PROCUREMENT.FUNDS.DETAILS(fund.id)}>
                <TableColumn tabIndex={0}>
                  {fund.name}
                </TableColumn>
              </TableRow>
            ))}
          </TableBody>
        </PaginatedTable>
      </APIList>
      <Button color="blue-green" fill="solid" to={ROUTES.PROCUREMENT.FUNDS.NEW(true)}>Add a Fund</Button>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  funds: state.funds.funds,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchFunds: ():void => {
    dispatch(fetchFunds());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllFunds);
