import * as React from 'react';

interface FlexContainerProps {
  children: React.ReactNode
  flexValue?: 1 | 2 | 3 | 4
  className?: string
  style?: React.CSSProperties
}

function FlexContainer(props:FlexContainerProps) {
  const {
    children, flexValue, className, style,
  } = props;
  const flexClass = flexValue !== undefined ? ` di-flex-container-${flexValue}` : '';
  const privateClassName = className !== undefined ? className : '';
  return (
    <div className={`di-flex-container ${flexClass} ${privateClassName}`} style={style}>
      {children}
    </div>
  );
}
FlexContainer.defaultProps = {
  flexValue: undefined,
  className: undefined,
  style: undefined,
};

export default FlexContainer;
