import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface SearchResultProps {
  children: React.ReactNode
  to: string
  icon?: IconProp
}

function SearchResult(props:SearchResultProps) {
  const { children, to, icon } = props;
  return (
    <li className="di-search-result">

      <div className="di-search-result-icon-container">
        <FontAwesomeIcon icon={icon === undefined ? faSearch : icon} />
      </div>
      <Link to={to}>{children}</Link>
    </li>
  );
}

SearchResult.defaultProps = {
  icon: undefined,
};

export default SearchResult;
