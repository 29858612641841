import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import { ROUTES } from '@routes';
import { Patron } from '@store/patrons/patrons/types';
import APIList from '@containers/application/APIList';
import * as React from 'react';
import { PATRON_ACTION_TYPES } from '@store/patrons/actionTypes';
import HTMLLink from '@components/base/buttons-and-links/HTMLLink';

interface RequiredOwnProps {
  patrons: Patron[]
  currentPage:number
  totalPages:number
  numOfRecords:number
  showHeader: boolean
  selectable: boolean
  sortable: boolean
  actions?: React.ReactElement[]
}

interface OptionalOwnProps {
  getSelected?:(patrons: Patron[])=>void
}

type PatronListProps = OptionalOwnProps & RequiredOwnProps;

function PatronList(props:PatronListProps) {
  const [selected, setSelected] = React.useState([]);
  const {
    patrons, getSelected, currentPage, totalPages,
    showHeader, selectable, sortable, actions, numOfRecords,
  } = props;

  React.useEffect(() => {
    if (getSelected !== undefined) {
      getSelected(selected);
    }
  }, [selected]);

  const toggleAll = (e:React.MouseEvent):void => {
    e.stopPropagation();
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected([...patrons]);
    }
  };

  const toggleSelected = (selectedPatron:Patron):void => {
    if (selected.filter((patron:Patron) => patron.id === selectedPatron.id).length > 0) {
      setSelected(selected.filter((patron:Patron) => patron.id !== selectedPatron.id));
    } else {
      setSelected([...selected, selectedPatron]);
    }
  };

  return (
    <APIList action={PATRON_ACTION_TYPES.PATRONS.FETCH}>
      <PaginatedTable
        showHeader={showHeader}
        empty={patrons.length === 0}
        currentPage={currentPage}
        totalPages={totalPages}
        title="Patrons"
        numOfRecordsString={`${numOfRecords?.toLocaleString()} Patrons`}
        actions={actions}
      >
        <TableHeader>
          <TableRow>
            { selectable && (
              <TableHeaderColumn
                onClick={toggleAll}
                sortable={false}
                resizable={false}
              >
                <input
                  readOnly
                  type="checkbox"
                  checked={selected.length === patrons.length && patrons.length > 0}
                />
              </TableHeaderColumn>
            ) }
            <TableHeaderColumn resizable attribute="name" sortable={sortable}>Name</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="email" sortable={sortable}>Email</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="phone" sortable={sortable}>Phone</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="barcode" sortable={sortable}>Barcode</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="leans" sortable={sortable}>Loans</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="balance" sortable={sortable}>Balance</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          { patrons.map((patron:Patron) => (
            <TableRow key={patron.id}>
              { selectable && (
                <TableColumn
                  tabIndex={0}
                  resizable={false}
                >
                  <input
                    readOnly
                    onClick={(e:React.MouseEvent) => {
                      e.stopPropagation();
                      toggleSelected(patron);
                    }}
                    type="checkbox"
                    checked={selected.filter(
                      (selectedPatron:Patron) => selectedPatron.id === patron.id,
                    ).length > 0}
                  />
                </TableColumn>
              ) }
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" to={ROUTES.PATRONS.PATRONS.DETAILS(patron.id)}>{patron.name}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" href={`mailto:${patron.email}`}>{patron.email}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" href={`tel:${patron.phone}`}>{patron.phone}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {patron.barcode}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {patron.currentRecordLeases}
                {' '}
                /
                {' '}
                {patron.maximumNumberOfRecordLeases}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {patron.balance}
              </TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
    </APIList>
  );
}

PatronList.defaultProps = {
  getSelected: undefined,
  actions: [],
};

export default PatronList;
