import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '@store/root';
import AnonymousContainer from '@containers/application/AnonymousContainer';
import ForgotPasswordSuccess from '@containers/pre-authentication/forgot-password/ForgotPasswordSuccess';
import ForgotPasswordForm from '@containers/pre-authentication/forgot-password/ForgotPasswordForm';

interface ForgotPasswordProps { forgotPasswordSuccess:boolean }

function ForgotPassword(props:ForgotPasswordProps) {
  const { forgotPasswordSuccess } = props;
  const content = forgotPasswordSuccess ? <ForgotPasswordSuccess /> : <ForgotPasswordForm />;
  return (
    <AnonymousContainer>
      {content}
    </AnonymousContainer>
  );
}

const mapStateToProps = (state:RootState) => ({
  forgotPasswordSuccess: state.auth.forgotPasswordSuccess,
});

export default connect(mapStateToProps, null)(ForgotPassword);
