import SecondaryMenuItem from '@components/base/menu-items/SecondaryMenuItem';
import SecondaryMenu from '@components/compound/menus/SecondaryMenu';
import { ROUTES } from '@routes';
import { t } from 'i18next';
import * as React from 'react';

function CirculationMenu() {
  return (
    <SecondaryMenu>
      <SecondaryMenuItem to={ROUTES.CIRCULATION.INDEX(true)} label={t('menu.circulation.home')} />
      <SecondaryMenuItem to={ROUTES.CIRCULATION.LEASES.ALL(true)} label={t('menu.circulation.leases')} />
      <SecondaryMenuItem to={ROUTES.CIRCULATION.HOLDS.ALL(true)} label={t('menu.circulation.holds')} />
      <SecondaryMenuItem to={ROUTES.CIRCULATION.FEES.ALL(true)} label={t('menu.circulation.fees')} />
      <SecondaryMenuItem to={ROUTES.CIRCULATION.TRANSFERS.ALL(true)} label={t('menu.circulation.transfers')} />
    </SecondaryMenu>
  );
}

export default CirculationMenu;
