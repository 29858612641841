import InputSelect from '@components/base/form-fields/InputSelect';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import { ROUTES } from '@routes';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { fetchLists } from '@store/catalog/lists/actions';
import { List } from '@store/catalog/lists/types';
import { addRecordToList } from '@store/catalog/records/actions';
import { RootState } from '@store/root';
import APIForm from '@containers/application/APIForm';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  useParams,
} from 'react-router-dom';
import { Dispatch } from 'redux';

interface StateProps {
  lists: List[]
}

interface DispatchProps {
  dispatchAddRecordToList: (e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchFetchLists: ()=>void
}

type AddRecordToListProps = StateProps & DispatchProps;

class AddRecordToList extends React.Component<AddRecordToListProps, Record<string, never>> {
  componentDidMount(): void {
    const { dispatchFetchLists } = this.props;
    dispatchFetchLists();
  }

  render(): React.ReactNode {
    const { lists, dispatchAddRecordToList } = this.props;
    const params = useParams();
    const id = parseInt(params.id, 10);
    return (
      <APIForm
        onSubmit={dispatchAddRecordToList}
        action={CATALOG_ACTION_TYPES.LIST.ADD_RECORD}
        id="di-add-record-to-list"
        onSuccessRoute={ROUTES.CATALOG.RECORDS.DETAILS(id)}
        onSuccessAction={CATALOG_ACTION_TYPES.LIST.RECORD_ADDED}
      >
        <InputSelect name="listId" id="di=list-id" required>
          { lists.map(
            (list:List) => <option key={list.id} value={list.id}>{list.name}</option>,
          )}
        </InputSelect>
        <input type="hidden" name="recordId" value={id} />
        <SubmitButton color="blue-green" fill="outline" value="Add" />
      </APIForm>
    );
  }
}

const mapStateToProps = (state:RootState):StateProps => ({
  lists: state.lists.all,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchLists: ():void => {
    dispatch(fetchLists());
  },
  dispatchAddRecordToList: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(addRecordToList(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRecordToList);
