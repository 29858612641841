import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import {
  FetchNotesAction,
  FetchNoteAction,
  CreateNoteAction,
  UpdateNoteAction,
  DestroyNoteAction,
  SearchNotesAction,
} from '@store/catalog/notes/types';
import {
  notesFetched,
  noteFetched,
  noteCreated,
  noteUpdated,
  noteDestroyed,
  notesSearched,
} from '@store/catalog/notes/actions';
import { Observable } from 'rxjs';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { Action } from 'redux';
import CONFIG from '@config/environments/base';
import { RootStateOrAny } from 'react-redux';

const fetchNotesEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.NOTES.FETCH),
  switchMap((action:FetchNotesAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/notes/`,
    method: 'GET',
    success: notesFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchNoteEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.NOTE.FETCH),
  switchMap((action:FetchNoteAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/notes/${action.id}/`,
    method: 'GET',
    success: noteFetched,
    auth: true,
    state$,
    action,
  })),
);

const createNoteEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.NOTE.CREATE),
  switchMap((action:CreateNoteAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/notes/`,
    method: 'POST',
    success: noteCreated,
    auth: true,
    state$,
    action,
    body: action.note,
  })),
);

const updateNoteEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.NOTE.UPDATE),
  switchMap((action:UpdateNoteAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/notes/${action.id}/`,
    method: 'PUT',
    success: noteUpdated,
    auth: true,
    state$,
    action,
    body: action.note,
  })),
);

const destroyNoteEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.NOTE.DESTROY),
  switchMap((action:DestroyNoteAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/notes/${action.id}/`,
    method: 'DELETE',
    success: noteDestroyed,
    auth: true,
    state$,
    action,
  })),
);

const searchNotesEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.NOTES.SEARCH),
  switchMap((action:SearchNotesAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/notes/`,
    method: 'POST',
    success: notesSearched,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchNotesEpic,
  fetchNoteEpic,
  createNoteEpic,
  updateNoteEpic,
  destroyNoteEpic,
  searchNotesEpic,
];
