import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RecordImage } from '@store/catalog/recordImages/types';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface RecordImageGalleryProps {
  images: RecordImage[]
  addImagePath: string
  tabIndex: number
}

function RecordImageGallery(props:RecordImageGalleryProps) {
  const { images, addImagePath, tabIndex } = props;
  const [
    currentImage, setCurrentImage,
  ] = React.useState(images.length > 0 ? images[0] : {});

  React.useEffect(() => {
    if (JSON.stringify(currentImage) === '{}' && images.length > 0) {
      setCurrentImage(images[0]);
    }
  }, [currentImage]);

  return (
    <div className="di-record-image-gallery">
      <div className="di-record-image-gallery-main-image">
        <img src={currentImage.url} alt="" />
      </div>
      <div className="di-image-gallery-thumbnails">
        {images.map((image:RecordImage):React.ReactNode => (
          <button
            type="button"
            tabIndex={tabIndex}
            key={image.id}
            onClick={() => { setCurrentImage(image); }}
          >
            <img
              className="di-image-gallery-thumbnail"
              src={image.thumbnail}
              alt="Record"
            />
          </button>
        ))}
        )
        <Link to={addImagePath}><FontAwesomeIcon icon={faPlus} /></Link>
      </div>
    </div>
  );
}

export default RecordImageGallery;
