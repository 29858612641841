import * as React from 'react';

interface TabBodyProps {
  children: React.ReactNode
}

function TabBody(props:TabBodyProps) {
  const { children } = props;
  return (
    <div className="di-tab-body">
      {children}
    </div>
  );
}

export default TabBody;
