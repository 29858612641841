import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { destroyFee, fetchFee, updateFee } from '@store/circulation/fees/actions';
import { Fee } from '@store/circulation/fees/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import TextHeader from '@components/base/text/TextHeader';

interface StateProps { fee:Fee }
interface DispatchProps {
  dispatchFetchFee:(id:number|string)=>void,
  dispatchUpdateFee:(e:React.MouseEvent<HTMLFormElement>)=>void,
  dispatchDestroyFee:(e:React.MouseEvent<HTMLFormElement>)=>void
}
type EditAFeeProps = StateProps & DispatchProps;

function EditAFee(props:EditAFeeProps) {
  const {
    dispatchFetchFee, fee, dispatchUpdateFee, dispatchDestroyFee,
  } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchFee(id);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Edit A Fee</TextHeader>
      <APIForm
        id="di-edit-a-fee"
        onSubmit={dispatchUpdateFee}
        action={CIRCULATION_ACTION_TYPES.FEE.UPDATE}
        onSuccessRoute={ROUTES.CIRCULATION.FEES.DETAILS(fee.id)}
        onSuccessAction={CIRCULATION_ACTION_TYPES.FEE.UPDATED}
        entityID={fee.id}
      >

        <input type="hidden" name="id" defaultValue={fee.id} />
        <InputText id="di-fee-name" type="text" name="name" defaultValue={fee.name} />
        <SubmitButton fill="solid" color="blue-green" value="Submit" />
      </APIForm>

      <APIForm
        id="di-destroy-a-fee"
        onSubmit={dispatchDestroyFee}
        action={CIRCULATION_ACTION_TYPES.FEE.DESTROY}
        onSuccessRoute={ROUTES.CIRCULATION.FEES.ALL(true)}
        onSuccessAction={CIRCULATION_ACTION_TYPES.FEE.DESTROYED}
        entityID={fee.id}
      >
        <input type="hidden" name="id" defaultValue={fee.id} />
        <SubmitButton fill="outline" color="blue-green" value="Submit" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  fee: state.fees.currentFee || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchFee: (id:number|string):void => {
    dispatch(fetchFee(id));
  },
  dispatchUpdateFee: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updateFee(data));
  },
  dispatchDestroyFee: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyFee(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAFee);
