import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import {
  FetchTagsAction,
  FetchTagAction,
  CreateTagAction,
  UpdateTagAction,
  DestroyTagAction,
} from '@store/catalog/tags/types';
import {
  tagFetched,
  tagCreated,
  tagUpdated,
  tagDestroyed,
} from '@store/catalog/tags/actions';
import { Observable } from 'rxjs';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchTagsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.TAGS.FETCH),
  switchMap((action:FetchTagsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/tags/`,
    method: 'GET',
    success: tagCreated,
    auth: true,
    state$,
    action,
  })),
);

const fetchTagEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.TAG.FETCH),
  switchMap((action:FetchTagAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/tags/${action.id}/`,
    method: 'GET',
    success: tagFetched,
    auth: true,
    state$,
    action,
  })),
);

const createTagEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.TAG.CREATE),
  switchMap((action:CreateTagAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/tags/`,
    method: 'POST',
    success: tagCreated,
    auth: true,
    state$,
    action,
    body: action.tag,
  })),
);

const updateTagEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.TAG.UPDATE),
  switchMap((action:UpdateTagAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/tags/${action.id}/`,
    method: 'PUT',
    success: tagUpdated,
    auth: true,
    state$,
    action,
    body: action.tag,
  })),
);

const destroyTagEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.TAG.DESTROY),
  switchMap((action:DestroyTagAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/tags/${action.id}/`,
    method: 'DELETE',
    success: tagDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchTagsEpic,
  fetchTagEpic,
  createTagEpic,
  updateTagEpic,
  destroyTagEpic,
];
