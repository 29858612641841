import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import * as React from 'react';

interface TabHeaderProps {
  children: React.ReactNode
}

function TabHeader(props:TabHeaderProps) {
  const { children } = props;
  return (
    <MultiColumnFlex className="di-tab-header">
      {children}
    </MultiColumnFlex>
  );
}

export default TabHeader;
