import SubHeader from '@components/base/text/SubHeader';
import TextHeader from '@components/base/text/TextHeader';
import FlexContainer from '@components/layout/generic/FlexContainer';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Supplier } from '@store/procurement/suppliers/types';
import * as React from 'react';

interface SupplierSummaryProps {
  supplier: Supplier
}

function SupplierSummary(props:SupplierSummaryProps) {
  const { supplier } = props;
  return (
    <MultiColumnFlex>
      <FlexContainer style={{ width: 84, textAlign: 'center' }}>
        <FontAwesomeIcon style={{ fontSize: 50, margin: 10, color: 'rgb(195, 200, 210)' }} icon={faBuilding} />
      </FlexContainer>
      <FlexContainer>
        <TextHeader size="h4">{supplier?.name}</TextHeader>
        <SubHeader>
          { supplier.name }
          <span> •&nbsp;</span>
          <span>
            {/* {supplier?.amount} */}
          </span>
        </SubHeader>
      </FlexContainer>
    </MultiColumnFlex>
  );
}

export default SupplierSummary;
