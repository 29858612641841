import Button from '@components/base/buttons-and-links/Button';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import { fetchList } from '@store/catalog/lists/actions';
import { List } from '@store/catalog/lists/types';
import { fetchRecords } from '@store/catalog/records/actions';
import { Record } from '@store/catalog/records/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, useParams, Routes } from 'react-router-dom';
import { Dispatch } from 'redux';
import RecordList from '@containers/catalog/records/shared/RecordList';
import AddARecordToList from '@containers/catalog/lists/AddARecordToList';
import MultiActionButton from '@components/base/buttons-and-links/MultiActionButton';
import MultiActionButtonItem from '@components/base/buttons-and-links/MultiActionButtonItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import Modal from '@components/compound/modals/Modal';

interface StateProps {
  list:List
  loading:boolean
  records:Record[]
  currentPage: number
  totalPages: number
  numOfRecords: number
}
interface DispatchProps {
  dispatchFetchList:(id:number|string)=>void
  dispatchFetchRecords:(id:number|string)=>void
}

type ShowListDetailsProps = StateProps & DispatchProps;

function ShowListDetails(props:ShowListDetailsProps) {
  const {
    dispatchFetchList, dispatchFetchRecords, loading, list,
    records, currentPage, totalPages, numOfRecords,
  } = props;
  const [selected, setSelected] = React.useState([]);
  const [showRemoveFromListModal, setShowRemoveFromListModal] = React.useState(false);
  const params = useParams();
  const { id } = params;

  React.useEffect(() => {
    dispatchFetchList(id);
    dispatchFetchRecords(id);
  }, []);
  const linkSelected = (selectedRecords:Record[]):void => {
    setSelected(selectedRecords);
  };
  const actions = [
    <Button key={0} fill="solid" color="blue-green" to={ROUTES.CATALOG.LISTS.ADD_RECORD(id)}>Add a Record</Button>,
    (
      <MultiActionButton key={3} tabIndex={0} label="Bulk Actions" disabled={selected.length === 0} style={{ display: 'inline-block' }}>
        <MultiActionButtonItem onClick={() => setShowRemoveFromListModal(true)}>
          <FontAwesomeIcon icon={faRemove} />
          Remove From List
        </MultiActionButtonItem>
      </MultiActionButton>
    ),
  ];

  return (
    <PageContainer loading={loading}>
      <TextHeader size="h1">{list.name}</TextHeader>
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quis ut
        beatae vero quaerat quod mollitia cum doloremque
        corrupti delectus molestias ipsa quo, nobis at illum consequuntur
        dolorem asperiores soluta aperiam.
      </p>
      <RecordList
        records={records}
        currentPage={currentPage}
        totalPages={totalPages}
        getSelected={linkSelected}
        numOfRecords={numOfRecords}
        actions={actions}
        selectable
        sortable
        showHeader
      />
      <Modal title={`Confirm the removal of ${selected.length} records from ${list.name}?`} exitClick={() => setShowRemoveFromListModal(false)} style={showRemoveFromListModal ? {} : { display: 'none' }}>
        <p>Are you sure you want to delete these records?</p>
        <Button fill="solid" color="blue-green" onClick={() => { console.log('asd'); setShowRemoveFromListModal(false); }}>Yes</Button>
      </Modal>
      <Routes>
        <Route path={ROUTES.CATALOG.LISTS.ADD_RECORD()} element={<AddARecordToList />} />
      </Routes>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  list: state.lists.currentList || {},
  loading: state.lists.currentList === undefined,
  records: state.records.records,
  currentPage: state.records.currentPage,
  totalPages: state.records.totalPages,
  numOfRecords: state.records.results,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchList: (id:number|string) => {
    dispatch(fetchList(id));
  },
  dispatchFetchRecords: (id:number|string) => {
    dispatch(fetchRecords({ listId: id }));
  },
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ShowListDetails));
