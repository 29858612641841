import * as React from 'react';
import { connect } from 'react-redux';
import AnonymousContainer from '@containers/application/AnonymousContainer';
import { ROUTES } from '@routes';
import APIForm from '@containers/application/APIForm';
import { PROFILE_ACTION_TYPES } from '@store/profile/actionTypes';
import { createProfile } from '@store/profile/profile/actions';
import { Dispatch } from 'redux';
import Label from '@components/base/form-fields/Label';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import CenterContainer from '@components/layout/generic/CenterContainer';

interface DispatchProps {
  dispatchCreateProfile:(e:React.MouseEvent<HTMLFormElement>)=>void
}
type SignupProps = DispatchProps;

function Signup(props: SignupProps) {
  const { dispatchCreateProfile } = props;
  return (
    <AnonymousContainer>
      <CenterContainer>
        <APIForm
          onSuccessRoute={ROUTES.AUTHENTICATION.CONFIRM_EMAIL()}
          onSuccessAction={PROFILE_ACTION_TYPES.PROFILE.CREATED}
          onSubmit={dispatchCreateProfile}
          action={PROFILE_ACTION_TYPES.PROFILE.CREATE}
          id="di-signup-form"
        >
          <Label htmlFor="Email">Email </Label>
          <InputText type="email" name="email" id="email" />
          <Label htmlFor="Passsword">Password</Label>
          <InputText type="password" name="password" id="password" />
          <SubmitButton fill="solid" color="blue-green" value="Sign Up" />
        </APIForm>
        <p>
          Already have an account?
          <br />
          <HTMLLink color="blue-green" to={ROUTES.AUTHENTICATION.LOGIN}>Login</HTMLLink>
        </p>
      </CenterContainer>
    </AnonymousContainer>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreateProfile: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createProfile(data));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(Signup);
