import { faBarsStaggered, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Notification } from '@store/notifications/types';
import * as React from 'react';
import NotificationListItem from '@components/base/list-items/NotificationListItem';

interface NotificatiosMenuItemProps {
  notifications: Notification[]
  fetchNotifications: ()=>void
}

function NotificationsMenuItem(props:NotificatiosMenuItemProps) {
  const { fetchNotifications, notifications } = props;
  return (
    <div onMouseEnter={fetchNotifications} className="di-notifications-menu-item">
      <span>
        <FontAwesomeIcon icon={faBell} />
      </span>
      <div className="di-notifications-list">
        <ul>
          {notifications.map(
            (notification:Notification) => (
              <NotificationListItem
                key={notification.id}
                notification={notification}
              />
            ),
          )}
          <li className="di-notification-footer">
            <FontAwesomeIcon icon={faBarsStaggered} />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default NotificationsMenuItem;
