import Button from '@components/base/buttons-and-links/Button';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import { fetchPurchase } from '@store/procurement/purchases/actions';
import { Purchase } from '@store/procurement/purchases/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

interface StateProps {
  purchase:Purchase
}
interface DispatchProps {
  dispatchFetchPurchase:(id:number|string)=>void
}
type ShowPurchaseDetailsProps = StateProps & DispatchProps;

function ShowPurchaseDetails(props:ShowPurchaseDetailsProps) {
  const { dispatchFetchPurchase, purchase } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);
  React.useEffect(() => {
    dispatchFetchPurchase(id);
  }, []);
  return (
    <PageContainer>
      <TextHeader size="h1">{purchase.title}</TextHeader>
      <Button color="blue-green" fill="solid" to={ROUTES.PROCUREMENT.PURCHASES.EDIT(purchase.id)}>Edit</Button>
      {/* <InvoiceRoutes /> */}
      {/* <Route element={LineItemRoutes} />
      <Route element={InvoiceRoutes} /> */}
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  purchase: state.purchases.currentPurchase || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchPurchase: (id:number|string) => {
    dispatch(fetchPurchase(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowPurchaseDetails);
