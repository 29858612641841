import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import { Contract } from '@store/procurement/contracts/types';
import APIList from '@containers/application/APIList';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import { ROUTES } from '@routes';
import { useParams } from 'react-router-dom';

interface RequiredContractListProps {
  contracts: Contract[]
  currentPage: number
  totalPages: number
  sortable: boolean
  selectable: boolean
}
interface OptionalContractListProps {
  getSelected?: (contracts:Contract[])=>void
  filterable?: boolean
}
type ContractListProps = OptionalContractListProps & RequiredContractListProps;

function ContractList(props:ContractListProps) {
  const [selected, setSelected] = React.useState([]);
  const {
    contracts, getSelected, filterable, currentPage, totalPages, sortable, selectable,
  } = props;

  const params = useParams();
  const id = parseInt(params.id, 10);

  const toggleAll = (e:React.MouseEvent) => {
    e.stopPropagation();
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected([...contracts]);
    }
    getSelected(selected);
  };

  const toggleSelected = (selectedContract:Contract) => {
    if (selected.filter((record:Contract) => record.id === selectedContract.id).length > 0) {
      setSelected(selected.filter((record:Contract) => record.id !== selectedContract.id));
    } else {
      setSelected([...selected, selectedContract]);
    }
    getSelected(selected);
  };

  return (
    <APIList action={PROCUREMENT_ACTION_TYPES.CONTRACTS.FETCH}>
      <PaginatedTable
        showHeader={filterable}
        empty={contracts.length === 0}
        currentPage={currentPage}
        totalPages={totalPages}
      >
        <TableHeader>
          <TableRow>
            { selectable && <TableHeaderColumn resizable={false} onClick={toggleAll} sortable={false}><input readOnly type="checkbox" checked={selected.length === contracts.length && contracts.length > 0} /></TableHeaderColumn> }
            <TableHeaderColumn resizable attribute="name" sortable={sortable}>Name</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="description" sortable={sortable}>Description</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="startDate" sortable={sortable}>Start Date</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="endDate" sortable={sortable}>End Date</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="" sortable={false}>Actions</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          { contracts.map((contract:Contract) => (
            <TableRow key={contract.id}>
              { selectable && (
                <TableColumn resizable={false} tabIndex={0}>
                  <input
                    readOnly
                    type="checkbox"
                    checked={selected.filter(
                      (selectedContract:Contract) => selectedContract.id === contract.id,
                    ).length > 0}
                    onClick={(e:React.MouseEvent) => {
                      e.stopPropagation(); toggleSelected(contract);
                    }}
                  />
                </TableColumn>
              )}
              <TableColumn tabIndex={0} resizable>
                {contract.name}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {contract.description}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {contract.startDate}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {contract.endDate}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" href={contract.url} target="_blank">
                  <FontAwesomeIcon icon={faDownload} />
                </HTMLLink>
                {' '}
                <HTMLLink color="blue-green" to={ROUTES.PROCUREMENT.SUPPLIERS.CONTRACTS.DESTROY(id, contract.id)}>
                  <FontAwesomeIcon icon={faTrashCan} />
                </HTMLLink>
              </TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
    </APIList>
  );
}

ContractList.defaultProps = {
  getSelected: undefined,
  filterable: false,
};
export default ContractList;
