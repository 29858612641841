import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import { destroyPurchase, fetchPurchase, updatePurchase } from '@store/procurement/purchases/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '@store/root';
import { Dispatch } from 'redux';
import { Purchase } from '@store/procurement/purchases/types';
import { useParams } from 'react-router-dom';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import Label from '@components/base/form-fields/Label';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';

interface DispatchProps {
  dispatchFetchPurchase:(id:number|string)=>void,
  dispatchUpdatePurchase:(e:React.MouseEvent<HTMLFormElement>)=>void,
  dispatchDestroyPurchase:(e:React.MouseEvent<HTMLFormElement>)=>void
}
interface StateProps { purchase:Purchase }
type EditAPurchaseProps = StateProps & DispatchProps;
function EditAPurchase(props:EditAPurchaseProps) {
  const {
    dispatchFetchPurchase, purchase, dispatchUpdatePurchase, dispatchDestroyPurchase,
  } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchPurchase(id);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Edit A Purchase</TextHeader>
      <APIForm
        id="di-edit-a-purchase"
        onSubmit={dispatchUpdatePurchase}
        action={PROCUREMENT_ACTION_TYPES.PURCHASE.UPDATE}
        onSuccessRoute={ROUTES.PROCUREMENT.PURCHASES.DETAILS(purchase.id)}
        onSuccessAction={PROCUREMENT_ACTION_TYPES.PURCHASE.UPDATED}
        entityID={purchase.id}
      >

        <input type="hidden" name="id" defaultValue={purchase.id} />
        <Label htmlFor="name">Name:</Label>
        <InputText id="di-purchase-name" type="text" name="name" defaultValue={purchase.title} />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>

      <APIForm
        id="di-destroy-a-purchase"
        onSubmit={dispatchDestroyPurchase}
        action={PROCUREMENT_ACTION_TYPES.PURCHASE.DESTROY}
        onSuccessRoute={ROUTES.PROCUREMENT.PURCHASES.ALL(true)}
        onSuccessAction={PROCUREMENT_ACTION_TYPES.PURCHASE.DESTROYED}
        entityID={purchase.id}
      >
        <input type="hidden" name="id" defaultValue={purchase.id} />
        <SubmitButton color="blue-green" fill="outline" value="Delete" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState) => ({
  purchase: state.purchases.currentPurchase,
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  dispatchFetchPurchase: (id:number|string) => {
    dispatch(fetchPurchase(id));
  },
  dispatchUpdatePurchase: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updatePurchase(data));
  },
  dispatchDestroyPurchase: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyPurchase(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAPurchase);
