import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { PROFILE_ACTION_TYPES } from '@store/profile/actionTypes';
import { destroyFamilyMember, fetchFamilyMember, updateFamilyMember } from '@store/profile/family/actions';
import { FamilyMember } from '@store/profile/family/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import InputText from '@components/base/form-fields/InputText';
import Label from '@components/base/form-fields/Label';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import { RootState } from '@store/root';

interface StateProps {
  familyMember:FamilyMember
}
interface DispatchProps {
  dispatchFetchFamilyMember:(id:number|string)=>void
  dispatchUpdateFamilyMember:(e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchDestroyFamilyMember:(e:React.MouseEvent<HTMLFormElement>)=>void
}

type EditAFamilyMemberProps = DispatchProps & StateProps;

function EditAFamilyMember(props:EditAFamilyMemberProps) {
  const {
    dispatchFetchFamilyMember, familyMember,
    dispatchUpdateFamilyMember, dispatchDestroyFamilyMember,
  } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);
  React.useEffect(() => {
    dispatchFetchFamilyMember(id);
  }, []);

  return familyMember === undefined ? <div>Loading</div> : (
    <PageContainer>
      <TextHeader size="h1">Edit A FamilyMember</TextHeader>
      <APIForm
        id="di-edit-a-family-member"
        onSubmit={dispatchUpdateFamilyMember}
        action={PROFILE_ACTION_TYPES.FAMILY_MEMBER.UPDATE}
        onSuccessRoute={ROUTES.PROFILE.FAMILY_MEMBERS.DETAILS}
        onSuccessAction={PROFILE_ACTION_TYPES.FAMILY_MEMBER.UPDATED}
        entityID={familyMember.id}
      >

        <input type="hidden" name="id" defaultValue={familyMember.id} />
        <Label htmlFor="name">Name</Label>
        <InputText id="di-family-member-name" type="text" name="name" defaultValue={familyMember.name} />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>

      <APIForm
        id="di-destroy-a-family-member"
        onSubmit={dispatchDestroyFamilyMember}
        action={PROFILE_ACTION_TYPES.FAMILY_MEMBER.DESTROY}
        onSuccessRoute={ROUTES.PROFILE.FAMILY_MEMBERS.ALL(true)}
        onSuccessAction={PROFILE_ACTION_TYPES.FAMILY_MEMBER.DESTROYED}
        entityID={familyMember.id}
      >
        <input type="hidden" name="id" defaultValue={familyMember.id} />
        <SubmitButton color="blue-green" fill="outline" value="Delete" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  familyMember: state.familyMembers.currentFamilyMember,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchFamilyMember: (id:number|string):void => {
    dispatch(fetchFamilyMember(id));
  },
  dispatchUpdateFamilyMember: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updateFamilyMember(data));
  },
  dispatchDestroyFamilyMember: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyFamilyMember(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAFamilyMember);
