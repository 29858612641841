import Thumbnail from '@components/base/images/Thumbnail';
import SubHeader from '@components/base/text/SubHeader';
import TextHeader from '@components/base/text/TextHeader';
import FlexContainer from '@components/layout/generic/FlexContainer';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import { Author } from '@store/catalog/authors/types';
import { RecordInstance } from '@store/catalog/recordInstances/types';
import * as React from 'react';

interface RecordInstanceSummaryProps {
  recordInstance: RecordInstance
  onClick?: ()=>void
}

function RecordInstanceSummary(props:RecordInstanceSummaryProps) {
  const { recordInstance, onClick } = props;
  return (
    <div onClick={onClick} role="option" aria-selected tabIndex={0} onKeyDown={onClick}>
      <MultiColumnFlex>
        <FlexContainer>
          <Thumbnail size="medium" src={recordInstance?.record.smallThumbnail} alt="" />
        </FlexContainer>
        <FlexContainer>
          <TextHeader size="h4">{recordInstance?.record.title}</TextHeader>
          <SubHeader>
            { recordInstance?.record.authors?.map((author:Author, index:number) => (
              <span key={author.id}>
                {index > 0 ? ', ' : ''}
                {author.name}
              </span>
            ))}
            <span> •&nbsp;</span>
            <span>
              {recordInstance?.record.numberOfPages}
              {' '}
              pages
            </span>
            <span> •&nbsp;</span>
            <span>
              {recordInstance?.record.copiesAvailable}
              /
              {recordInstance?.record.totalCopies}
              {' '}
              available
            </span>
          </SubHeader>
          <p>{recordInstance?.record.serpText}</p>
        </FlexContainer>
      </MultiColumnFlex>
    </div>
  );
}

RecordInstanceSummary.defaultProps = {
  onClick: undefined,
};

export default RecordInstanceSummary;
