import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { Observable } from 'rxjs';
import {
  CreatePublisherAction,
  FetchPublisherAction,
  SearchPublishersAction,
} from '@store/catalog/publishers/types';
import { publisherCreated, publisherFetched, publishersSearched } from '@store/catalog/publishers/actions';
import { Action } from 'redux';
import CONFIG from '@config/environments/base';
import { RootStateOrAny } from 'react-redux';

const fetchPublisherEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.PUBLISHER.FETCH),
  switchMap((action:FetchPublisherAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/publishers/${action.id}/`,
    method: 'GET',
    success: publisherFetched,
    auth: true,
    state$,
    action,
  })),
);

const createPublisherEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.PUBLISHER.CREATE),
  switchMap((action:CreatePublisherAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/publishers/`,
    method: 'POST',
    success: publisherCreated,
    auth: true,
    state$,
    action,
    body: action.publisher,
  })),
);

const searchPublisherEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.PUBLISHERS.SEARCH),
  switchMap((action:SearchPublishersAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/publishers/`,
    method: 'POST',
    success: publishersSearched,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchPublisherEpic,
  createPublisherEpic,
  searchPublisherEpic,
];
