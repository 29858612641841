import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  CreateLineItemAction,
  DestroyLineItemAction,
  FetchLineItemAction,
  FetchLineItemsAction,
  FetchLineItemsOptions,
  LineItem,
  LineItemCreatedAction,
  LineItemDestroyedAction,
  LineItemFetchedAction,
  LineItemsFetchedAction,
  LineItemUpdatedAction,
  UpdateLineItemAction,
} from '@store/procurement/lineItems/types';

export const fetchLineItems = (options:FetchLineItemsOptions = undefined):FetchLineItemsAction => ({
  type: PROCUREMENT_ACTION_TYPES.LINE_ITEMS.FETCH,
  options,
});

export const lineItemsFetched = (response:LineItem[]):LineItemsFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.LINE_ITEMS.FETCHED,
  lineItems: response,
});

export const fetchLineItem = (id:number|string):FetchLineItemAction => ({
  type: PROCUREMENT_ACTION_TYPES.LINE_ITEM.FETCH,
  id,
});

export const lineItemFetched = (lineItem:LineItem):LineItemFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.LINE_ITEM.FETCHED,
  lineItem,
});

export const createLineItem = (lineItem:FormData):CreateLineItemAction => ({
  type: PROCUREMENT_ACTION_TYPES.LINE_ITEM.CREATE,
  lineItem,
});

export const lineItemCreated = (lineItem:LineItem):LineItemCreatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.LINE_ITEM.CREATED,
  lineItem,
  id: lineItem.id,
});

export const updateLineItem = (lineItem:FormData):UpdateLineItemAction => ({
  type: PROCUREMENT_ACTION_TYPES.LINE_ITEM.UPDATE,
  lineItem,
  id: lineItem.get('id') as string|number,
});

export const lineItemUpdated = (lineItem:LineItem):LineItemUpdatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.LINE_ITEM.UPDATED,
  lineItem,
  id: lineItem.id,
});

export const destroyLineItem = (lineItem:FormData):DestroyLineItemAction => ({
  type: PROCUREMENT_ACTION_TYPES.LINE_ITEM.DESTROY,
  id: lineItem.get('id') as string|number,
});

export const lineItemDestroyed = (id:number|string):LineItemDestroyedAction => ({
  type: PROCUREMENT_ACTION_TYPES.LINE_ITEM.DESTROYED,
  id,
});
