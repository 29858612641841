import TextHeader from '@components/base/text/TextHeader';
import { ROUTES } from '@routes';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { importRecords } from '@store/catalog/records/actions';
import { Record } from '@store/catalog/records/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Papa from 'papaparse';
import { GoodReadsRecord, importGoodReadsCSV } from '@store/catalog/importers/GoodReadsImporter';
import PageContainer from '@components/layout/generic/PageContainer';
import Section from '@components/layout/generic/Section';
import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import FlexContainer from '@components/layout/generic/FlexContainer';
import Button from '@components/base/buttons-and-links/Button';
import RecordSummary from './shared/RecordSummary';

interface DispatchProps {
  dispatchImportRecords: (records:Record[])=>void
}

type ImportGoodreadRecordsProps = DispatchProps;

function ImportGoodreadRecords(props:ImportGoodreadRecordsProps) {
  const { dispatchImportRecords } = props;
  // const [parsing, setParsing] = React.useState<boolean>(false);
  const [previewData, setPreviewData] = React.useState<Record[]>([]);

  const parseUpload = (e:FileList) => {
    Papa.parse(e[0], {
      complete(results) {
        setPreviewData(importGoodReadsCSV(results.data as GoodReadsRecord[]));
      },
      header: true,
    });
  };
  return (
    <PageContainer
      pageActions={[CATALOG_ACTION_TYPES.RECORDS.IMPORTED, CATALOG_ACTION_TYPES.RECORDS.IMPORT]}
      redirectAction={CATALOG_ACTION_TYPES.RECORDS.IMPORTED}
      redirectRoute={ROUTES.CATALOG.RECORDS.ALL(true)}
    >
      <MultiColumnFlex alignItems="start">
        <FlexContainer flexValue={1}>
          <TextHeader size="h1">Import books from GoodReads:</TextHeader>
          <Section>
            <ol>
              <li>
                Log into your
                {' '}
                <HTMLLink target="_blank" color="blue-green" href="https://www.goodreads.com">Goodreads</HTMLLink>
                {' '}
                profile.
              </li>
              <li>Click on &quot;My Books&quot;</li>
              <li>
                Navigate to the
                {' '}
                <HTMLLink target="_blank" color="blue-green" href="https://www.goodreads.com/review/import">Import and Export</HTMLLink>
              </li>
              <li>Choose &quot;Export library&quot;. </li>
              <li>Download the generated CSV file.</li>
              <li> Upload the file below:</li>
            </ol>
            <input onChange={(e:any) => parseUpload(e.target.files)} accept=".csv" type="file" name="goodreads_csv" id="goodreads_import" />
          </Section>
        </FlexContainer>
        <FlexContainer flexValue={1}>
          <Button fill="solid" color="blue-green" onClick={() => dispatchImportRecords(previewData)}> Import</Button>
          { previewData.length > 0 && (
          <div>
            <TextHeader size="h2">Results:</TextHeader>
            { previewData.map((record, i) => (
              <RecordSummary
                key={`${record.title}-${1 + i}`}
                record={record}
              />
            ))}
          </div>
          )}
        </FlexContainer>
      </MultiColumnFlex>
    </PageContainer>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchImportRecords: (records:Record[]) => {
    dispatch(importRecords(records));
  },
});

export default connect(null, mapDispatchToProps)(ImportGoodreadRecords);
