import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import {
  CreateTransferAction,
  TransferCreatedAction,
  FetchTransferAction,
  FetchTransfersAction,
  TransferFetchedAction,
  TransfersFetchedAction,
  UpdateTransferAction,
  TransferUpdatedAction,
  DestroyTransferAction,
  TransferDestroyedAction,
  Transfer,
  FetchTransfersOptions,
} from '@store/circulation/transfers/types';

export function fetchTransfers(options:FetchTransfersOptions = undefined):FetchTransfersAction {
  return {
    type: CIRCULATION_ACTION_TYPES.TRANSFERS.FETCH,
    options,
  };
}

export function transfersFetched(response:Transfer[]):TransfersFetchedAction {
  return {
    type: CIRCULATION_ACTION_TYPES.TRANSFERS.FETCHED,
    transfers: response,
  };
}

export function fetchTransfer(id:number|string):FetchTransferAction {
  return {
    type: CIRCULATION_ACTION_TYPES.TRANSFER.FETCH,
    id,
  };
}

export function transferFetched(transfer:Transfer):TransferFetchedAction {
  return {
    type: CIRCULATION_ACTION_TYPES.TRANSFER.FETCHED,
    transfer,
  };
}

export const createTransfer = (transfer:FormData):CreateTransferAction => ({
  type: CIRCULATION_ACTION_TYPES.TRANSFER.CREATE,
  transfer,
});

export const transferCreated = (transfer:Transfer):TransferCreatedAction => ({
  type: CIRCULATION_ACTION_TYPES.TRANSFER.CREATED,
  transfer,
  id: transfer.id,
});

export const updateTransfer = (transfer:FormData):UpdateTransferAction => ({
  type: CIRCULATION_ACTION_TYPES.TRANSFER.UPDATE,
  transfer,
  id: transfer.get('id') as string|number,
});

export const transferUpdated = (transfer:Transfer):TransferUpdatedAction => ({
  type: CIRCULATION_ACTION_TYPES.TRANSFER.UPDATED,
  transfer,
  id: transfer.id,
});

export const destroyTransfer = (transfer:FormData):DestroyTransferAction => ({
  type: CIRCULATION_ACTION_TYPES.TRANSFER.DESTROY,
  id: transfer.get('id') as string|number,
});

export const transferDestroyed = (id:number|string):TransferDestroyedAction => ({
  type: CIRCULATION_ACTION_TYPES.TRANSFER.DESTROYED,
  id,
});
