import * as React from 'react';
import AuthenticatedContainer from '@containers/application/AuthenticatedContainer';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from 'routes';
import ShowAllPatrons from '@containers/patrons/patrons/ShowAllPatrons';
import ShowAllPatronGroups from '@containers/patrons/groups/ShowAllPatronGroups';
import ShowPatronDetails from '@containers/patrons/patrons/ShowPatronDetails';
import EditAPatron from '@containers/patrons/patrons/EditAPatron';
import CreateAPatron from '@containers/patrons/patrons/CreateAPatron';
import ShowPatronGroupDetails from '@containers/patrons/groups/ShowPatronGroupDetails';
import EditAPatronGroup from '@containers/patrons/groups/EditAPatronGroup';
import CreateAPatronGroup from '@containers/patrons/groups/CreateAPatronGroup';
import SearchPatrons from './patrons/SearchPatrons';
import PatronSearchResults from './patrons/PatronSearchResults';

function Patrons() {
  return (
    <AuthenticatedContainer section="patrons">
      <Routes>
        <Route path={ROUTES.PATRONS.PATRON_GROUPS.ALL()} element={<ShowAllPatronGroups />} />
        <Route path={ROUTES.PATRONS.PATRON_GROUPS.NEW()} element={<CreateAPatronGroup />} />
        <Route
          path={ROUTES.PATRONS.PATRON_GROUPS.DETAILS()}
          element={<ShowPatronGroupDetails />}
        />
        <Route path={ROUTES.PATRONS.PATRON_GROUPS.EDIT()} element={<EditAPatronGroup />} />
        <Route path={ROUTES.PATRONS.PATRONS.ALL()} element={<ShowAllPatrons />} />
        <Route path={ROUTES.PATRONS.PATRONS.NEW()} element={<CreateAPatron />} />
        <Route path={ROUTES.PATRONS.PATRONS.DETAILS()} element={<ShowPatronDetails />} />
        <Route path={ROUTES.PATRONS.PATRONS.EDIT()} element={<EditAPatron />} />
        <Route path={ROUTES.PATRONS.PATRONS.SEARCH_RESULTS()} element={<PatronSearchResults />} />
        <Route path={ROUTES.PATRONS.INDEX()} element={<SearchPatrons />} />
      </Routes>
    </AuthenticatedContainer>
  );
}

export default Patrons;
