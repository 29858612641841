import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { AUTHENTICATION_ACTION_TYPES } from '@store/authentication/actionTypes';
import APIForm from '@containers/application/APIForm';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import InputText from '@components/base/form-fields/InputText';
import Label from '@components/base/form-fields/Label';
import { ROUTES } from '@routes';
import { resetPassword } from '@store/authentication/actions';

interface DispatchProps {
  updatePassword: (e:React.MouseEvent<HTMLFormElement>)=>void
}
type PasswordFormProps = DispatchProps & WithTranslation;

function PasswordForm(props:PasswordFormProps) {
  const { t } = useTranslation();
  const { updatePassword } = props;
  return (
    <div>
      <APIForm
        id="di-edit-a-profile"
        onSubmit={updatePassword}
        action={AUTHENTICATION_ACTION_TYPES.RESET_PASSWORD}
        onSuccessRoute={ROUTES.PROFILE.INDEX(true)}
        onSuccessAction={AUTHENTICATION_ACTION_TYPES.SESSION.CREATED}
      >
        <Label htmlFor="password">{ t('generic.label.password') }</Label>
        <InputText type="password" name="password" id="password" />
        <Label htmlFor="password_confirmation">{ t('generic.label.passwordConfirmation') }</Label>
        <InputText type="password" name="password_confirmation" id="password_confirmation" />
        <SubmitButton color="blue-green" fill="solid" value={t('generic.form.submit')} />
      </APIForm>
      <Link to={ROUTES.PROFILE.INDEX(true)}>{ t('profile.links.backToProfile') }</Link>
    </div>
  );
}

const mapDispatchToProps = (dispatch:Dispatch) => ({
  updatePassword: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(resetPassword(data));
  },
});

export default withTranslation()(connect(null, mapDispatchToProps)(PasswordForm));
