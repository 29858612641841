import * as React from 'react';

interface MultiColumnFlexProps {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  alignItems?: 'center' | 'normal' | 'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'baseline' | 'first baseline' | 'last baseline' | 'safe center' | 'unsafe center' | 'inherit' | 'initial' | 'revert' | 'revert-layer' | 'unset'
}

function MultiColumnFlex(props:MultiColumnFlexProps) {
  const {
    children, className, style, alignItems,
  } = props;
  const alignItemsStyle = {
    alignItems,
  };

  const privateClassName = className !== undefined ? className : '';
  return (
    <div className={`di-multi-column-flex ${privateClassName}`} style={{ ...style, ...alignItemsStyle }}>
      {children}
    </div>
  );
}

MultiColumnFlex.defaultProps = {
  className: undefined,
  style: undefined,
  alignItems: 'center',
};

export default MultiColumnFlex;
