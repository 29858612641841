import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';

import { switchMap } from 'rxjs/operators';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { Observable } from 'rxjs';
import {
  CreateFeeAction,
  DestroyFeeAction,
  FetchFeeAction,
  FetchFeesAction,
  UpdateFeeAction,
} from '@store/circulation/fees/types';
import {
  feeCreated, feeDestroyed, feeFetched, feesFetched, feeUpdated,
} from '@store/circulation/fees/actions';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

export const fetchFeesEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.FEES.FETCH),
  switchMap((action:FetchFeesAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/fees/`,
    method: 'GET',
    success: feesFetched,
    auth: true,
    state$,
    action,
  })),
);

export const fetchFeeEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.FEE.FETCH),
  switchMap((action:FetchFeeAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/fees/${action.id}/`,
    method: 'GET',
    success: feeFetched,
    auth: true,
    state$,
    action,
  })),
);

export const createFeeEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.FEE.CREATE),
  switchMap((action:CreateFeeAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/fees/`,
    method: 'POST',
    success: feeCreated,
    auth: true,
    state$,
    action,
    body: action.fee,
  })),
);

export const updateFeeEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.FEE.UPDATE),
  switchMap((action:UpdateFeeAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/fees/${action.id}/`,
    method: 'PUT',
    success: feeUpdated,
    auth: true,
    state$,
    action,
    body: action.fee,
  })),
);

export const destroyFeeEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.FEE.DESTROY),
  switchMap((action:DestroyFeeAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/fees/${action.id}/`,
    method: 'DELETE',
    success: feeDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchFeesEpic,
  fetchFeeEpic,
  createFeeEpic,
  updateFeeEpic,
  destroyFeeEpic,
];
