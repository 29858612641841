import * as React from 'react';

export interface InputTextWithSearchProps {
  searchFunction:(term:string)=>void
  id: string
  name: string
  collection: string[]
}

interface OwnProps {
  defaultValue?: string
}

type SearchProps = InputTextWithSearchProps & OwnProps;

function InputTextWithSearch(props:SearchProps) {
  const [searchInputLength, setSearchInputLength] = React.useState(0);
  const {
    searchFunction, id, name, defaultValue, collection,
  } = props;

  const [selectedValue, setSelectedValue] = React.useState(defaultValue);

  const internalSearchFunction = (e:React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputLength(e.currentTarget.value.length);
    setSelectedValue(e.currentTarget.value);
    searchFunction(e.currentTarget.value);
  };

  return (
    <div className="di-input-text-with-search">
      <div className="di-input-text-with-search-container">
        <input
          id={`${id}-search`}
          name={name}
          onChange={internalSearchFunction}
          type="search"
          value={selectedValue}
        />
      </div>
      <ol
        role="presentation"
        className="di-input-text-with-search-results"
        style={searchInputLength > 0 ? {} : { display: 'none' }}
      >
        { collection.map((item:string) => (
          <li key={item}>
            <button
              type="button"
              aria-label={item}
              onClick={() => { setSelectedValue(item); setSearchInputLength(0); }}
            />
            {item}
          </li>
        ))}
      </ol>
    </div>
  );
}
InputTextWithSearch.defaultProps = {
  defaultValue: undefined,
};

export default InputTextWithSearch;
