import SubHeader from '@components/base/text/SubHeader';
import TextHeader from '@components/base/text/TextHeader';
import FlexContainer from '@components/layout/generic/FlexContainer';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Patron } from '@store/patrons/patrons/types';
import * as React from 'react';

interface PatronSummaryProps {
  patron: Patron
}

function PatronSummary(props:PatronSummaryProps) {
  const { patron } = props;
  return (
    <MultiColumnFlex>
      <FlexContainer style={{ width: 84, textAlign: 'center' }}>
        <FontAwesomeIcon style={{ fontSize: 50, margin: 10, color: 'rgb(195, 200, 210)' }} icon={faUserCircle} />
      </FlexContainer>
      <FlexContainer>
        <TextHeader size="h4">{patron?.name}</TextHeader>
        <SubHeader>
          { patron.email }
          <span> •&nbsp;</span>
          <span>
            {patron?.status}
          </span>
        </SubHeader>
      </FlexContainer>
    </MultiColumnFlex>
  );
}

export default PatronSummary;
