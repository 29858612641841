import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  FetchLineItemsAction,
  FetchLineItemAction,
  CreateLineItemAction,
  UpdateLineItemAction,
  DestroyLineItemAction,
} from '@store/procurement/lineItems/types';
import {
  lineItemsFetched,
  lineItemFetched,
  lineItemCreated,
  lineItemUpdated,
  lineItemDestroyed,
} from '@store/procurement/lineItems/actions';
import { Observable } from 'rxjs';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchLineItemsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.LINE_ITEMS.FETCH),
  switchMap((action:FetchLineItemsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/line-items/`,
    method: 'GET',
    success: lineItemsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchLineItemEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.LINE_ITEM.FETCH),
  switchMap((action:FetchLineItemAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/line-items/${action.id}/`,
    method: 'GET',
    success: lineItemFetched,
    auth: true,
    state$,
    action,
  })),
);

const createLineItemEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.LINE_ITEM.CREATE),
  switchMap((action:CreateLineItemAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/line-items/`,
    method: 'POST',
    success: lineItemCreated,
    auth: true,
    state$,
    action,
    body: action.lineItem,
  })),
);

const updateLineItemEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.LINE_ITEM.UPDATE),
  switchMap((action:UpdateLineItemAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/line-items/${action.id}/`,
    method: 'PUT',
    success: lineItemUpdated,
    auth: true,
    state$,
    action,
    body: action.lineItem,
  })),
);

const destroyLineItemEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.LINE_ITEM.DESTROY),
  switchMap((action:DestroyLineItemAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/line-items/${action.id}/`,
    method: 'DELETE',
    success: lineItemDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchLineItemsEpic,
  fetchLineItemEpic,
  createLineItemEpic,
  updateLineItemEpic,
  destroyLineItemEpic,
];
