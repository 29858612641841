import * as React from 'react';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import StepAccordion from '@components/compound/accordions/StepAccordion';
import SearchByISBNStep from '@containers/catalog/records/fragments/steps/create-a-record/SearchByISBNStep';
import CreateARecordStep from '@containers/catalog/records/fragments/steps/create-a-record/CreateRecordStep';
import AssociateMetadataStep from '@containers/catalog/records/fragments/steps/create-a-record/AssociateMetadataStep';
import CreateARecordInstanceStep from '@containers/catalog/records/fragments/steps/create-a-record/CreateRecordInstanceStep';
import { Record } from '@store/catalog/records/types';

// interface StateProps {}

// interface DispatchProps {}

// type CreateARecordProps = DispatchProps & StateProps;

function CreateARecord() {
  const [selectedRecordTemplate, setSelectedRecordTemplate] = React.useState(undefined);
  return (
    <PageContainer>
      <TextHeader size="h1">Add a Resource</TextHeader>
      <StepAccordion initialStep={1}>
        <SearchByISBNStep
          step={1}
          setRecordTemplate={(record:Record) => setSelectedRecordTemplate(record)}
        />
        <CreateARecordStep template={selectedRecordTemplate} step={2} />
        {/* <AssociatePublisherStep step={3} /> */}
        <AssociateMetadataStep step={3} />
        <CreateARecordInstanceStep step={4} />
      </StepAccordion>
    </PageContainer>
  );
}

export default CreateARecord;
