import InputText from '@components/base/form-fields/InputText';
import PageNumber from '@components/base/tables/PageNumber';
import TextHeader from '@components/base/text/TextHeader';
import FlexContainer from '@components/layout/generic/FlexContainer';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import RightAlignedFlexContainer from '@components/layout/generic/RightAlignedFlexContainer';
import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface RequiredOwnProps {
  children: React.ReactNode
}
interface OptionalOwnProps {
  empty?: boolean
  currentPage?: number
  totalPages?: number
  numOfRecordsString?: string
  title?: string
  actions?: React.ReactElement[]
  showHeader?: boolean
}

type PaginatedTableProps = RequiredOwnProps & OptionalOwnProps;

function getPageNavigation(currentPage:number, totalPages:number) {
  const pageLinks = [];
  for (let index = 1; index <= totalPages; index += 1) {
    pageLinks.push(<PageNumber key={index} page={index} current={index === currentPage} />);
  }
  return pageLinks;
}

function onColumnDrag(e:React.DragEvent) {
  e.currentTarget.querySelectorAll('.di-table-column').forEach((child:HTMLTableCellElement) => {
    const copy = child;
    const target = e.target as Element;
    const parent = target.parentNode as HTMLTableCellElement;
    if (child.cellIndex === parent.cellIndex) {
      copy.style.width = parent.style.width;
    }
  });
}

function PaginatedTable(props:PaginatedTableProps) {
  const {
    showHeader, children, empty, currentPage, totalPages, title, actions, numOfRecordsString,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const submitFilter = (e:React.FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const input = form[0] as HTMLInputElement;
    urlParams.set('f', encodeURIComponent(input.value));
    navigate({ pathname: location.pathname, search: urlParams.toString() });
  };

  const getHeader = () => (
    <MultiColumnFlex className="di-paginated-table-header">
      <FlexContainer>
        <TextHeader size="h2">{title}</TextHeader>
      </FlexContainer>
      <FlexContainer flexValue={1}>
        <span className="di-number-of-records">{numOfRecordsString}</span>
      </FlexContainer>
      <RightAlignedFlexContainer>
        <form className="di-table-filter" onSubmit={submitFilter}>
          <MultiColumnFlex>
            <InputText type="text" name="filter" id="filter" />
            <input className="di-go-submit" type="submit" value="go" />
          </MultiColumnFlex>
        </form>
      </RightAlignedFlexContainer>
      <FlexContainer>
        {actions}
      </FlexContainer>
    </MultiColumnFlex>
  );

  return (
    <div>
      { showHeader ? getHeader() : undefined }
      <table className={`di-paginated-table ${empty ? 'di-paginated-table-empty' : ''}`} onDragCapture={onColumnDrag}>
        { children }
      </table>
      { totalPages === undefined || totalPages < 2
        ? undefined : getPageNavigation(currentPage, totalPages) }
    </div>
  );
}

PaginatedTable.defaultProps = {
  empty: false,
  currentPage: 0,
  totalPages: 0,
  showHeader: false,
  numOfRecordsString: '',
  title: '',
  actions: [],
};

export default PaginatedTable;
