import * as React from 'react';

interface AuthenticatedMenuProps {
  children: React.ReactNode
}
function AuthenticatedMenu(props:AuthenticatedMenuProps) {
  const { children } = props;
  return (
    <nav className="di-authenticated-menu">
      { children }
    </nav>
  );
}

export default AuthenticatedMenu;
