import Button from '@components/base/buttons-and-links/Button';
import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import { fetchHold } from '@store/circulation/holds/actions';
import { Hold } from '@store/circulation/holds/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

interface StateProps {
  hold:Hold
}
interface DispatchProps {
  dispatchFetchHold:(id:number|string)=>void
}
type ShowHoldDetailsProps = StateProps & DispatchProps;

function ShowHoldDetails(props:ShowHoldDetailsProps) {
  const { dispatchFetchHold, hold } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);
  React.useEffect(() => {
    dispatchFetchHold(id);
  }, []);

  return (
    <PageContainer>
      <div>{hold.record.title}</div>
      <div>{ hold.patron?.name }</div>
      <Button color="blue-green" fill="solid" to={ROUTES.CIRCULATION.HOLDS.EDIT(parseInt(params.recordId, 10))}>Edit</Button>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  hold: state.holds.currentHold || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchHold: (id:number|string) => {
    dispatch(fetchHold(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowHoldDetails);
