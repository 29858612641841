import APIList from '@containers/application/APIList';
import { ROUTES } from '@routes';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { fetchReviews } from '@store/catalog/reviews/actions';
import { Review } from '@store/catalog/reviews/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import TextHeader from '@components/base/text/TextHeader';
import Button from '@components/base/buttons-and-links/Button';
import Reviewsist from '@components/compound/lists/ReviewsList';
import ReviewListItem from '@components/base/list-items/ReviewListItem';
import { RootState } from '@store/root';

interface StateProps { reviews: Review[] }
interface DispatchProps { dispatchFetchReviews:()=>void }
type ShowAllReviewsProps = StateProps & DispatchProps;

function ShowAllReviews(props:ShowAllReviewsProps) {
  const { dispatchFetchReviews, reviews } = props;
  const params = useParams();

  React.useEffect(() => {
    dispatchFetchReviews();
  }, []);

  return (
    <div id="di-ratings">
      <TextHeader size="h2">Reviews</TextHeader>
      <APIList action={CATALOG_ACTION_TYPES.REVIEWS.FETCH}>
        <Reviewsist>
          {reviews.map(
            (review:Review) => <ReviewListItem key={review.id} review={review} />,
          )}
        </Reviewsist>
      </APIList>
      <Button color="blue-green" fill="solid" to={ROUTES.CATALOG.RECORDS.REVIEWS.NEW(parseInt(params.id, 10))}>Add an Review</Button>
    </div>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  reviews: state.reviews.reviews,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchReviews: ():void => {
    dispatch(fetchReviews());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllReviews);
