import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { addRecordToList, fetchRecord, searchRecords } from '@store/catalog/records/actions';
import { connect } from 'react-redux';
import * as React from 'react';
import { Dispatch } from 'redux';
import { RootState } from '@store/root';
import { Record } from '@store/catalog/records/types';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Modal from '@components/compound/modals/Modal';
import Label from '@components/base/form-fields/Label';
import InlineSearch from '@components/base/search/InlineSearch';
import InlineSearchResult from '@components/base/search/InlineSearchResult';
import GenericHR from '@components/base/dividers/GenericHR';
import Section from '@components/layout/generic/Section';
import RecordSummary from '../records/shared/RecordSummary';

interface DispatchProps {
  addARecordToList:(e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchSearchRecords:(searchTerm:string)=>void
  dispatchFetchRecordDetails:(id:number|string)=>void
}
interface StateProps {
  record: Record
  recordSearchResults: Record[]
}
type AddARecordToListProps =
  DispatchProps & StateProps;

function AddARecordToList(props:AddARecordToListProps) {
  const {
    dispatchSearchRecords, dispatchFetchRecordDetails,
    addARecordToList, recordSearchResults, record,
  } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);
  const { t } = useTranslation();
  return (
    <Modal exitPath={ROUTES.CATALOG.LISTS.DETAILS(id)}>
      { JSON.stringify(record) !== '{}' && (
        <Section>
          <RecordSummary record={record} />
          <GenericHR />
        </Section>
      ) }

      <Label htmlFor="title">{record !== undefined ? 'Search for a different record:' : 'Please choose a record:'}</Label>
      <InlineSearch
        searchFunction={dispatchSearchRecords}
        id="di-record-search"
        name="title"
      >
        {recordSearchResults.map((result:Record) => (
          <InlineSearchResult
            onSelect={() => dispatchFetchRecordDetails(result.id)}
            key={result.id}
          >
            {result.title}
          </InlineSearchResult>
        ))}
      </InlineSearch>
      <APIForm
        onSubmit={addARecordToList}
        action={CATALOG_ACTION_TYPES.LIST.ADD_RECORD}
        id="di-add-record-to-list"
        onSuccessRoute={ROUTES.CATALOG.LISTS.DETAILS(id)}
        onSuccessAction={CATALOG_ACTION_TYPES.LIST.RECORD_ADDED}
      >
        <input type="hidden" name="listId" value={id} />
        <input type="hidden" name="recordId" defaultValue={record.id} />
        <SubmitButton color="blue-green" fill="solid" value={t('generic.form.submit')} />
      </APIForm>
    </Modal>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  record: state.records.currentRecord || {},
  recordSearchResults: state.records.searchResults,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchRecordDetails: (id:number|string) => {
    dispatch(fetchRecord(id));
  },
  dispatchSearchRecords: (searchTerm:string) => {
    dispatch(searchRecords(searchTerm));
  },
  addARecordToList: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(addRecordToList(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddARecordToList);
