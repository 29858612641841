import { createRecordInstance, fetchRecordInstances } from '@store/catalog/recordInstances/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import APIForm from '@containers/application/APIForm';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { ROUTES } from '@routes';
import { RootState } from '@store/root';
import { RecordInstance } from '@store/catalog/recordInstances/types';
import { clearCurrentRecord, searchRecords } from '@store/catalog/records/actions';
import PageContainer from '@components/layout/generic/PageContainer';
import { useLocation } from 'react-router-dom';
import StepAccordion from '@components/compound/accordions/StepAccordion';
import StepAccordionStep from '@components/base/accordions/StepAccordionStep';
import StepAccordionStepOpen from '@components/base/accordions/StepAccordionStepOpen';
import StepAccordionStepClosed from '@components/base/accordions/StepAccordionStepClosed';
import InlineSearch from '@components/base/search/InlineSearch';
import Label from '@components/base/form-fields/Label';
import { Record } from '@store/catalog/records/types';
import InlineSearchResult from '@components/base/search/InlineSearchResult';
import Section from '@components/layout/generic/Section';
import GenericHR from '@components/base/dividers/GenericHR';
import { Branch } from '@store/organizations/branches/types';
import BranchSummary from '@containers/organizations/organizations/branches/shared/BranchSummary';
import { clearCurrentBranch, fetchBranch, searchBranches } from '@store/organizations/branches/actions';
import RecordInstanceSummary from '../instances/shared/RecordInstanceSummary';

interface StateProps {
  branch:Branch
  recordInstances:RecordInstance[]
  recordSearchResults: Record[]
  branchSearchResults: Branch[]
}
interface DispatchProps {
  dispatchCreateRecordLease: (e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchClearCurrentBranch: ()=>void
  dispatchClearCurrentRecord: ()=>void
  dispatchSearchBranches: (term:string)=>void
  dispatchFetchBranch: (id:number|string)=>void
  dispatchFetchRecordInstances: (recordId:number)=>void
  dispatchSearchRecords: (term:string)=>void
}

type CreateATransferInstanceProps = StateProps & DispatchProps;
function CreateATransferInstance(props:CreateATransferInstanceProps) {
  const {
    dispatchClearCurrentBranch, dispatchClearCurrentRecord,
    dispatchCreateRecordLease, branch, recordInstances, dispatchFetchRecordInstances,
    recordSearchResults, branchSearchResults, dispatchSearchRecords, dispatchSearchBranches,
    dispatchFetchBranch,
  } = props;
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [selectedRecordInstance, setSelectedRecordInstance] = React.useState(undefined);

  React.useEffect(() => {
    dispatchClearCurrentBranch();
    dispatchClearCurrentRecord();

    if (urlParams.get('rid') !== null) {
      const recordId = parseInt(urlParams.get('rid'), 10);
      dispatchFetchRecordInstances(recordId);
    }

    if (urlParams.get('pid') !== null) {
      const branchId = parseInt(urlParams.get('pid'), 10);
      dispatchFetchBranch(branchId);
    }
  }, []);

  const chooseSelectedRecordInstance = (instance:RecordInstance) => {
    setSelectedRecordInstance(instance);
  };

  return (
    <PageContainer>
      <StepAccordion>
        <StepAccordionStep step={1} title="Select a copy of a record to check out:">
          <StepAccordionStepOpen
            nextButtonText="Next: Find a branch"
            disabled={selectedRecordInstance === undefined}
          >
            { recordInstances.length > 0 && (
              <Section>
                {/* <RecordSummary record={record} /> */}
                {recordInstances.map(
                  (instance:RecordInstance) => (
                    <RecordInstanceSummary
                      key={instance.id}
                      recordInstance={instance}
                      onClick={() => chooseSelectedRecordInstance(instance)}
                    />
                  ),
                )}
                <GenericHR />
              </Section>
            )}
            <Section>
              <Label htmlFor="title">{recordInstances.length > 0 ? 'Search for a different record:' : 'Please choose a record:'}</Label>
              <InlineSearch
                id="di-record-search"
                name="title"
                searchFunction={dispatchSearchRecords}
              >
                {recordSearchResults.map((result:Record) => (
                  <InlineSearchResult
                    onSelect={() => dispatchFetchRecordInstances(result.id)}
                    key={result.id}
                  >
                    {result.title}
                  </InlineSearchResult>
                ))}
              </InlineSearch>
            </Section>
          </StepAccordionStepOpen>
          <StepAccordionStepClosed>
            { selectedRecordInstance !== undefined && (
            <RecordInstanceSummary
              recordInstance={selectedRecordInstance}
            />
            )}
          </StepAccordionStepClosed>
        </StepAccordionStep>
        <StepAccordionStep step={2} title="Select a branch:">
          <StepAccordionStepOpen
            nextButtonText="Next: Finalize the checkout process"
            disabled={branch === undefined}
          >
            { branch !== undefined && (
              <Section>
                <BranchSummary branch={branch} />
                <GenericHR />
              </Section>
            )}
            <Section>
              <Label htmlFor="name">{branch !== undefined ? 'Search for a different branch:' : 'Please choose a branch:'}</Label>
              <InlineSearch
                searchFunction={dispatchSearchBranches}
                id="di-branch-search"
                name="name"
              >
                {branchSearchResults.map((result:Branch) => (
                  <InlineSearchResult
                    onSelect={() => dispatchFetchBranch(result.id)}
                    key={result.id}
                  >
                    {result.title}
                  </InlineSearchResult>
                ))}
              </InlineSearch>
            </Section>
          </StepAccordionStepOpen>
          <StepAccordionStepClosed>
            { branch !== undefined && <BranchSummary branch={branch} />}
          </StepAccordionStepClosed>
        </StepAccordionStep>
        <StepAccordionStep step={3} title="Check out the resource">
          <StepAccordionStepOpen>
            asd
          </StepAccordionStepOpen>
          <StepAccordionStepClosed>
            asd
          </StepAccordionStepClosed>
        </StepAccordionStep>
      </StepAccordion>
      { recordInstances.map((lease:RecordInstance) => (
        <div key={lease.id}>
          {/* <span>{ lease.recordInstance?.record.title }</span> */}
        </div>
      ))}
      <APIForm
        onSubmit={dispatchCreateRecordLease}
        action={CIRCULATION_ACTION_TYPES.LEASE.CREATE}
        id="di-create-password-form"
        onSuccessRoute={ROUTES.CIRCULATION.LEASES.CHECK_OUT(true)}
        onSuccessAction={CIRCULATION_ACTION_TYPES.LEASE.CREATED}
      >
        <input type="hidden" name="branchId" defaultValue={branch?.id} />
        <input type="hidden" name="recordId" defaultValue={selectedRecordInstance?.id} />
        <input type="submit" value="Checkout" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  branch: state.branches.currentBranch,
  recordInstances: state.recordInstances.recordInstances,
  recordSearchResults: state.records.searchResults,
  branchSearchResults: state.branches.searchResults,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreateRecordLease: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createRecordInstance(data));
  },
  dispatchClearCurrentBranch: () => {
    dispatch(clearCurrentBranch());
  },
  dispatchClearCurrentRecord: () => {
    dispatch(clearCurrentRecord());
  },
  dispatchFetchRecordInstances: (recordId:number) => {
    dispatch(fetchRecordInstances({ recordId }));
  },
  dispatchSearchRecords: (term:string) => {
    dispatch(searchRecords(term));
  },
  dispatchSearchBranches: (term:string) => {
    dispatch(searchBranches(term));
  },
  dispatchFetchBranch: (id:number|string) => {
    dispatch(fetchBranch(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateATransferInstance);
