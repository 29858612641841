import * as React from 'react';
import AuthenticatedContainer from '@containers/application/AuthenticatedContainer';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from 'routes';
import CreateARecord from '@containers/catalog/records/CreateARecord';
import SearchRecords from '@containers/catalog/records/SearchRecords';
import ImportRecords from '@containers/catalog/records/ImportRecords';
import EditARecord from '@containers/catalog/records/EditARecord';
import ShowRecordDetails from '@containers/catalog/records/ShowRecordDetails';
import ShowAuthorDetails from '@containers/catalog/authors/ShowAuthorDetails';
import CatalogSearchResults from '@containers/catalog/records/CatalogSearchResults';
import ShowAllRecords from '@containers/catalog/records/ShowAllRecords';
import ShowPublisherDetails from '@containers/catalog/publishers/ShowPublisherDetails';
import ShowTagDetails from '@containers/catalog/tags/ShowTagDetails';
import ShowGenreDetails from '@containers/catalog/genres/ShowGenreDetails';
import ShowSubjectDetails from '@containers/catalog/subjects/ShowSubjectDetails';
import EditAList from './lists/EditAList';
import ShowListDetails from './lists/ShowListDetails';
import ShowAllLists from './lists/ShowAllLists';
import CreateAList from './lists/CreateAList';

function Catalog() {
  return (
    <AuthenticatedContainer section="catalog">
      <Routes>
        <Route path={ROUTES.CATALOG.LISTS.ALL()} element={<ShowAllLists />}>
          <Route path={ROUTES.CATALOG.LISTS.NEW()} element={<CreateAList />} />
        </Route>
        <Route path={ROUTES.CATALOG.LISTS.EDIT()} element={<EditAList />} />
        <Route path={ROUTES.CATALOG.LISTS.DETAILS()} element={<ShowListDetails />} />
        <Route path={ROUTES.CATALOG.INDEX()} element={<SearchRecords />} />
        <Route
          path={ROUTES.CATALOG.RECORDS.SEARCH_RESULTS()}
          element={<CatalogSearchResults />}
        />
        <Route path={ROUTES.CATALOG.AUTHORS.DETAILS()} element={<ShowAuthorDetails />} />
        <Route path={ROUTES.CATALOG.SUBJECTS.DETAILS()} element={<ShowSubjectDetails />} />
        <Route path={ROUTES.CATALOG.GENRES.DETAILS()} element={<ShowGenreDetails />} />
        <Route path={ROUTES.CATALOG.TAGS.DETAILS()} element={<ShowTagDetails />} />
        <Route path={ROUTES.CATALOG.PUBLISHERS.DETAILS()} element={<ShowPublisherDetails />} />
        <Route path={ROUTES.CATALOG.RECORDS.NEW()} element={<CreateARecord />} />
        <Route path={ROUTES.CATALOG.RECORDS.EDIT()} element={<EditARecord />} />
        <Route path={ROUTES.CATALOG.RECORDS.IMPORT()} element={<ImportRecords />} />
        <Route path={ROUTES.CATALOG.RECORDS.DETAILS()} element={<ShowRecordDetails />} />
        <Route path={ROUTES.CATALOG.RECORDS.ALL()} element={<ShowAllRecords />} />
      </Routes>
    </AuthenticatedContainer>
  );
}

export default Catalog;
