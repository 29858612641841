import { ROUTES } from '@routes';
import { destroyPatronGroups, fetchPatronGroups } from '@store/patrons/groups/actions';
import { PatronGroup } from '@store/patrons/groups/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import Button from '@components/base/buttons-and-links/Button';
import { RootState } from '@store/root';
import MultiActionButton from '@components/base/buttons-and-links/MultiActionButton';
import MultiActionButtonItem from '@components/base/buttons-and-links/MultiActionButtonItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from '@components/compound/modals/Modal';
import TextHeader from '@components/base/text/TextHeader';
import ModalFooter from '@components/compound/modals/ModalFooter';
import PatronGroupList from './shared/PatronGroupList';

interface StateProps { groups: PatronGroup[] }
interface DispatchProps {
  dispatchFetchPatronGroups:()=>void
  dispatchDestroyPatronGroups: (groups:PatronGroup[])=>void
}
type ShowAllPatronGroupsProps = StateProps & DispatchProps

function ShowAllPatronGroups(props:ShowAllPatronGroupsProps) {
  const [selected, setSelected] = React.useState([] as PatronGroup[]);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const { dispatchFetchPatronGroups, dispatchDestroyPatronGroups, groups } = props;
  React.useEffect(() => {
    dispatchFetchPatronGroups();
  }, []);

  // React.useEffect(()=>{}, [groups]);

  const linkSelected = (selectedPatrons:PatronGroup[]):void => {
    setSelected(selectedPatrons);
  };

  const actions = [<Button key={2} color="blue-green" fill="solid" to={ROUTES.PATRONS.PATRON_GROUPS.NEW(true)}>Add a Patron Group</Button>,
    (
      <MultiActionButton key={3} tabIndex={0} label="Bulk Actions" disabled={selected.length === 0} style={{ display: 'inline-block' }}>
        <MultiActionButtonItem onClick={() => setShowDeleteModal(true)}>
          <FontAwesomeIcon icon={faTrash} />
          Delete
        </MultiActionButtonItem>
      </MultiActionButton>
    )];

  return (
    <PageContainer>
      <PatronGroupList
        groups={groups}
        currentPage={undefined}
        totalPages={undefined}
        numOfRecords={undefined}
        actions={actions}
        getSelected={linkSelected}
        showHeader
        selectable
        sortable
      />
      <Modal exitClick={() => setShowDeleteModal(false)} style={showDeleteModal ? {} : { display: 'none' }}>
        <TextHeader size="h3">Delete Records?</TextHeader>
        <p>Are you sure you want to delete these records?</p>
        <ModalFooter>
          <Button fill="solid" color="blue-green" onClick={() => { dispatchDestroyPatronGroups(selected); setShowDeleteModal(false); }}>Yes</Button>
        </ModalFooter>
      </Modal>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  groups: state.groups.groups,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchPatronGroups: ():void => {
    dispatch(fetchPatronGroups());
  },
  dispatchDestroyPatronGroups: (groups:PatronGroup[]):void => {
    dispatch(destroyPatronGroups(groups));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllPatronGroups);
