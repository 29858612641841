import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  FetchContractsAction,
  FetchContractAction,
  CreateContractAction,
  UpdateContractAction,
  DestroyContractAction,
} from '@store/procurement/contracts/types';
import {
  contractsFetched,
  contractFetched,
  contractCreated,
  contractUpdated,
  contractDestroyed,
} from '@store/procurement/contracts/actions';
import { Observable } from 'rxjs';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchContractsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.CONTRACTS.FETCH),
  switchMap((action:FetchContractsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/contracts/`,
    method: 'GET',
    success: contractsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchContractEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.CONTRACT.FETCH),
  switchMap((action:FetchContractAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/contracts/${action.id}/`,
    method: 'GET',
    success: contractFetched,
    auth: true,
    state$,
    action,
  })),
);

const createContractEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.CONTRACT.CREATE),
  switchMap((action:CreateContractAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/contracts/`,
    method: 'POST',
    success: contractCreated,
    auth: true,
    state$,
    action,
    body: action.contract,
  })),
);

const updateContractEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.CONTRACT.UPDATE),
  switchMap((action:UpdateContractAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/contracts/${action.id}/`,
    method: 'PUT',
    success: contractUpdated,
    auth: true,
    state$,
    action,
    body: action.contract,
  })),
);

const destroyContractEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.CONTRACT.DESTROY),
  switchMap((action:DestroyContractAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/contracts/${action.id}/`,
    method: 'PUT',
    success: contractDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchContractsEpic,
  fetchContractEpic,
  createContractEpic,
  updateContractEpic,
  destroyContractEpic,
];
