import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import { destroySupplier, fetchSupplier, updateSupplier } from '@store/procurement/suppliers/actions';
import { Supplier } from '@store/procurement/suppliers/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '@store/root';
import { Dispatch } from 'redux';
import { useParams } from 'react-router-dom';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import Label from '@components/base/form-fields/Label';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';

interface DispatchProps {
  dispatchFetchSupplier:(id:number|string)=>void,
  dispatchUpdateSupplier:(e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchDestroySupplier:(e:React.MouseEvent<HTMLFormElement>)=>void
}
interface StateProps { supplier:Supplier }

type EditASupplierProps = StateProps & DispatchProps;
function EditASupplier(props:EditASupplierProps) {
  const {
    dispatchFetchSupplier, supplier, dispatchUpdateSupplier, dispatchDestroySupplier,
  } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchSupplier(id);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Edit A Supplier</TextHeader>
      <APIForm
        id="di-edit-a-supplier"
        onSubmit={dispatchUpdateSupplier}
        action={PROCUREMENT_ACTION_TYPES.SUPPLIER.UPDATE}
        onSuccessRoute={ROUTES.PROCUREMENT.SUPPLIERS.DETAILS}
        onSuccessAction={PROCUREMENT_ACTION_TYPES.SUPPLIER.UPDATED}
        entityID={supplier.id}
      >

        <input type="hidden" name="id" defaultValue={supplier.id} />
        <Label htmlFor="name">Name:</Label>
        <InputText id="di-supplier-name" type="text" name="name" defaultValue={supplier.name} />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>

      <APIForm
        id="di-destroy-a-supplier"
        onSubmit={dispatchDestroySupplier}
        action={PROCUREMENT_ACTION_TYPES.SUPPLIER.DESTROY}
        onSuccessRoute={ROUTES.PROCUREMENT.SUPPLIERS.ALL(true)}
        onSuccessAction={PROCUREMENT_ACTION_TYPES.SUPPLIER.DESTROYED}
        entityID={supplier.id}
      >
        <input type="hidden" name="id" defaultValue={supplier.id} />
        <SubmitButton color="blue-green" fill="outline" value="Delete" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState) => ({
  supplier: state.suppliers.currentSupplier,
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  dispatchFetchSupplier: (id:number|string) => {
    dispatch(fetchSupplier(id));
  },
  dispatchUpdateSupplier: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updateSupplier(data));
  },
  dispatchDestroySupplier: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroySupplier(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditASupplier);
