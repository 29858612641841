import SubmitButton from '@components/base/form-fields/SubmitButton';
import Modal from '@components/compound/modals/Modal';
import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import { destroyContract, fetchContract } from '@store/procurement/contracts/actions';
import { Contract } from '@store/procurement/contracts/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

interface StateProps {
  contract: Contract
}

interface DispatchProps {
  dispatchFetchContract: (id:number|string)=>void
  dispatchDestroyContract: (e:React.MouseEvent<HTMLFormElement>)=>void
}

type DestroyContractProps = StateProps & DispatchProps;

function DestroyContract(props:DestroyContractProps) {
  const { dispatchFetchContract, dispatchDestroyContract, contract } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);
  const supplierId = parseInt(params.supplierId, 10);
  React.useEffect(() => {
    dispatchFetchContract(id);
  }, []);
  return (
    <Modal exitPath={ROUTES.PROCUREMENT.SUPPLIERS.DETAILS(supplierId)}>
      <APIForm
        id="di-destroy-a-purchase"
        onSubmit={dispatchDestroyContract}
        action={PROCUREMENT_ACTION_TYPES.CONTRACT.DESTROY}
        onSuccessRoute={ROUTES.PROCUREMENT.SUPPLIERS.DETAILS(supplierId)}
        onSuccessAction={PROCUREMENT_ACTION_TYPES.CONTRACT.DESTROYED}
        entityID={contract.id}
      >
        <input type="hidden" name="id" defaultValue={contract.id} />
        <SubmitButton color="blue-green" fill="outline" value="Delete" />
      </APIForm>
    </Modal>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  contract: state.contracts.currentContract || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchContract: (id:number|string) => {
    dispatch(fetchContract(id));
  },
  dispatchDestroyContract: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyContract(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DestroyContract);
