import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';

import {
  CreateReviewAction,
  DestroyReviewAction,
  FetchReviewAction,
  FetchReviewsAction,
  FetchReviewsOptions,
  Review,
  ReviewCreatedAction,
  ReviewDestroyedAction,
  ReviewFetchedAction,
  ReviewsFetchedAction,
  ReviewUpdatedAction,
  UpdateReviewAction,
} from '@store/catalog/reviews/types';

export const fetchReviews = (options:FetchReviewsOptions = undefined):FetchReviewsAction => ({
  type: CATALOG_ACTION_TYPES.REVIEWS.FETCH,
  options,
});

export const reviewsFetched = (response:Review[]):ReviewsFetchedAction => ({
  type: CATALOG_ACTION_TYPES.REVIEWS.FETCHED,
  reviews: response,
});

export const fetchReview = (id:number|string):FetchReviewAction => ({
  type: CATALOG_ACTION_TYPES.REVIEW.FETCH,
  id,
});

export const reviewFetched = (review:Review):ReviewFetchedAction => ({
  type: CATALOG_ACTION_TYPES.REVIEW.FETCHED,
  review,
});

export const createReview = (review:FormData):CreateReviewAction => ({
  type: CATALOG_ACTION_TYPES.REVIEW.CREATE,
  review,
});

export const reviewCreated = (review:Review):ReviewCreatedAction => ({
  type: CATALOG_ACTION_TYPES.REVIEW.CREATED,
  review,
  id: review.id,
});

export const updateReview = (review:FormData):UpdateReviewAction => ({
  type: CATALOG_ACTION_TYPES.REVIEW.UPDATE,
  review,
  id: review.get('id') as string|number,
});

export const reviewUpdated = (review:Review):ReviewUpdatedAction => ({
  type: CATALOG_ACTION_TYPES.REVIEW.UPDATED,
  review,
  id: review.id,
});

export const destroyReview = (review:FormData):DestroyReviewAction => ({
  type: CATALOG_ACTION_TYPES.REVIEW.DESTROY,
  id: review.get('id') as string|number,
});

export const reviewDestroyed = (id:number|string):ReviewDestroyedAction => ({
  type: CATALOG_ACTION_TYPES.REVIEW.DESTROYED,
  id,
});
