import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import {
  FetchRecordImagesAction,
  FetchRecordImageAction,
  CreateRecordImageAction,
  UpdateRecordImageAction,
  DestroyRecordImageAction,
} from '@store/catalog/recordImages/types';
import {
  recordImagesFetched,
  recordImageFetched,
  recordImageCreated,
  recordImageUpdated,
  recordImageDestroyed,
} from '@store/catalog/recordImages/actions';
import { Observable } from 'rxjs';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchRecordImagesEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD_IMAGES.FETCH),
  switchMap((action:FetchRecordImagesAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/record-images/`,
    method: 'GET',
    success: recordImagesFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchRecordImageEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD_IMAGE.FETCH),
  switchMap((action:FetchRecordImageAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/record-images/${action.id}/`,
    method: 'GET',
    success: recordImageFetched,
    auth: true,
    state$,
    action,
  })),
);

const createRecordImageEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD_IMAGE.CREATE),
  switchMap((action:CreateRecordImageAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/record-images/`,
    method: 'POST',
    success: recordImageCreated,
    auth: true,
    state$,
    action,
    body: action.recordImage,
  })),
);

const updateRecordImageEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD_IMAGE.UPDATE),
  switchMap((action:UpdateRecordImageAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/record-images/${action.id}/`,
    method: 'PUT',
    success: recordImageUpdated,
    auth: true,
    state$,
    action,
    body: action.recordImage,
  })),
);

const destroyRecordImageEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD_IMAGE.DESTROY),
  switchMap((action:DestroyRecordImageAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/record-images/${action.id}/`,
    method: 'DELETE',
    success: recordImageDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchRecordImagesEpic,
  fetchRecordImageEpic,
  createRecordImageEpic,
  updateRecordImageEpic,
  destroyRecordImageEpic,
];
