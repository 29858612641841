import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import { ROUTES } from '@routes';
import { Genre } from '@store/catalog/genres/types';
import { Record } from '@store/catalog/records/types';
import { Subject } from '@store/catalog/subjects/types';
import * as React from 'react';

interface RecordDetailsProps {
  record: Record
}

function RecordDetails(props: RecordDetailsProps) {
  const { record } = props;

  return (
    <PaginatedTable>
      <TableHeader>
        <TableRow>
          <TableHeaderColumn sortable={false} resizable>Property</TableHeaderColumn>
          <TableHeaderColumn sortable={false} resizable>Value</TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableColumn tabIndex={0} resizable>Publisher</TableColumn>
          <TableColumn tabIndex={0} resizable><HTMLLink color="blue-green" to={ROUTES.CATALOG.PUBLISHERS.DETAILS(record.publisher?.id)}>{record.publisher?.name}</HTMLLink></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn tabIndex={0} resizable>Year Published</TableColumn>
          <TableColumn tabIndex={0} resizable>{record.yearPublished}</TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn tabIndex={0} resizable>Original Publication Year</TableColumn>
          <TableColumn
            tabIndex={0}
            resizable
          >
            {record.originalPublicationYear}

          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn tabIndex={0} resizable>Subjects</TableColumn>
          <TableColumn tabIndex={0} resizable>
            {record.subjects?.map((subject:Subject, index:number) => (index > 0 ? (
              <span key={record.id}>
                ,
                <HTMLLink color="blue-green" to={ROUTES.CATALOG.SUBJECTS.DETAILS(subject.id)}>{subject.title}</HTMLLink>
              </span>
            ) : <span key={record.id}><HTMLLink color="blue-green" to={ROUTES.CATALOG.SUBJECTS.DETAILS(subject.id)}>{subject.title}</HTMLLink></span>))}
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn tabIndex={0} resizable>Genres</TableColumn>
          <TableColumn tabIndex={0} resizable>
            {record.genres?.map((genre:Genre, index:number) => (index > 0 ? (
              <span key={record.id}>
                ,
                <HTMLLink color="blue-green" to={ROUTES.CATALOG.GENRES.DETAILS(genre.id)}>{genre.title}</HTMLLink>
              </span>
            ) : <span key={record.id}><HTMLLink color="blue-green" to={ROUTES.CATALOG.GENRES.DETAILS(genre.id)}>{genre.title}</HTMLLink></span>))}
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn tabIndex={0} resizable>ISBN</TableColumn>
          <TableColumn tabIndex={0} resizable>{record.isbn}</TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn tabIndex={0} resizable>ISBN13</TableColumn>
          <TableColumn tabIndex={0} resizable>{record.isbn13}</TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn tabIndex={0} resizable>Number of Pages</TableColumn>
          <TableColumn tabIndex={0} resizable>{record.numberOfPages}</TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn tabIndex={0} resizable>Edition</TableColumn>
          <TableColumn tabIndex={0} resizable>{record.edition}</TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn tabIndex={0} resizable>Format</TableColumn>
          <TableColumn tabIndex={0} resizable>{record.format}</TableColumn>
        </TableRow>
      </TableBody>
    </PaginatedTable>
  );
}

export default RecordDetails;
