import SecondaryMenuItem from '@components/base/menu-items/SecondaryMenuItem';
import SecondaryMenu from '@components/compound/menus/SecondaryMenu';
import { ROUTES } from '@routes';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

function PatronsMenu() {
  const { t } = useTranslation();
  return (
    <SecondaryMenu>
      <SecondaryMenuItem to={ROUTES.PATRONS.INDEX(true)} label="Home" />
      <SecondaryMenuItem to={ROUTES.PATRONS.PATRONS.ALL(true)} label="Patrons" />
      <SecondaryMenuItem to={ROUTES.PATRONS.PATRON_GROUPS.ALL(true)} label={t('menu.patrons.groups')} />
    </SecondaryMenu>
  );
}

export default PatronsMenu;
