import SecondaryMenuItem from '@components/base/menu-items/SecondaryMenuItem';
import SecondaryMenu from '@components/compound/menus/SecondaryMenu';
import { ROUTES } from '@routes';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

function ProcurementsMenu() {
  const { t } = useTranslation();
  return (
    <SecondaryMenu>
      <SecondaryMenuItem to={ROUTES.PROCUREMENT.INDEX(true)} label={t('menu.procurement.home')} />
      <SecondaryMenuItem to={ROUTES.PROCUREMENT.SUPPLIERS.ALL(true)} label={t('menu.procurement.suppliers')} />
      <SecondaryMenuItem to={ROUTES.PROCUREMENT.PURCHASES.ALL(true)} label={t('menu.procurement.purchases')} />
      <SecondaryMenuItem to={ROUTES.PROCUREMENT.BUDGETS.ALL(true)} label={t('menu.procurement.budgets')} />
    </SecondaryMenu>
  );
}

export default ProcurementsMenu;
