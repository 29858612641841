import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '@store/root';
import AuthenticatedMenuOptions from '@containers/menu/AuthenticatedMenuOptions';
import { ROUTES } from '@routes';

interface StateProps {
  loggedIn:boolean
}
interface OwnProps {
  section?: 'catalog' | 'circulation' | 'patrons' | 'procurement',
  children:React.ReactNode
 }
type AuthenticatedContainerProps = StateProps & OwnProps;

function AuthenticatedContainer(props:AuthenticatedContainerProps) {
  const {
    loggedIn, section, children,
  } = props;
  return (
    <div>
      { !loggedIn && (<Navigate replace to={ROUTES.AUTHENTICATION.LOGIN} />) }
      <AuthenticatedMenuOptions section={section} />
      { children }
    </div>
  );
}

AuthenticatedContainer.defaultProps = {
  section: 'catalog',
};

const mapStateToProps = (state:RootState) => ({
  loggedIn: state.auth.apiKey !== undefined,
});

export default connect(mapStateToProps, null)(AuthenticatedContainer);
