import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { createHold } from '@store/circulation/holds/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import { Record } from '@store/catalog/records/types';
import { Patron } from '@store/patrons/patrons/types';
import { Branch } from '@store/organizations/branches/types';
import { RootState } from '@store/root';
import { useLocation } from 'react-router-dom';
import { fetchRecord, searchRecords } from '@store/catalog/records/actions';
import { fetchBranches } from '@store/organizations/branches/actions';
import InputSelect from '@components/base/form-fields/InputSelect';
import StepAccordion from '@components/compound/accordions/StepAccordion';
import StepAccordionStep from '@components/base/accordions/StepAccordionStep';
import StepAccordionStepOpen from '@components/base/accordions/StepAccordionStepOpen';
import StepAccordionStepClosed from '@components/base/accordions/StepAccordionStepClosed';
import { fetchPatron, searchPatrons } from '@store/patrons/patrons/actions';
import InlineSearch from '@components/base/search/InlineSearch';
import InlineSearchResult from '@components/base/search/InlineSearchResult';
import RecordSummary from '@containers/catalog/records/shared/RecordSummary';
import PatronSummary from '@containers/patrons/patrons/shared/PatronSummary';
import BranchSummary from '@containers/organizations/organizations/branches/shared/BranchSummary';
import Section from '@components/layout/generic/Section';
import Label from '@components/base/form-fields/Label';
import GenericHR from '@components/base/dividers/GenericHR';

interface StateProps {
  record: Record
  recordSearchResults: Record[]
  patron: Patron
  patronSearchResults: Patron[]
  branches: Branch[]
}
interface DispatchProps {
  dispatchCreateHold:(e:React.MouseEvent<HTMLFormElement>) => void
  dispatchFetchRecordDetails: (recordId:number|string)=>void
  dispatchSearchRecords: (term:string) => void
  dispatchFetchBranches: ()=> void
  dispatchFetchPatron: (patronId:string) => void
  dispatchSearchPatrons: (term:string) => void
}
type CreateAHoldProps = DispatchProps & StateProps;

function CreateAHold(props:CreateAHoldProps) {
  const {
    dispatchFetchRecordDetails, dispatchFetchBranches, dispatchFetchPatron,
    dispatchCreateHold, record, branches, patron,
    dispatchSearchRecords, dispatchSearchPatrons, recordSearchResults, patronSearchResults,
  } = props;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [currentBranch, setCurrentBranch] = React.useState(branches[0]);

  React.useEffect(() => {
    if (params.get('rid') !== null) {
      dispatchFetchRecordDetails(params.get('rid'));
    }
    if (params.get('pid') !== null) {
      dispatchFetchPatron(params.get('pid'));
    }
    dispatchFetchBranches();
  }, []);

  const handleBranchChange = (e:React.MouseEvent<HTMLSelectElement>) => {
    const selectedBranch = branches.find(
      (branch) => branch.id === parseInt(e.currentTarget.value, 10),
    );

    setCurrentBranch(selectedBranch);
  };

  let step = 1;
  if (params.get('rid') !== null) {
    step = 2;
  }
  if (params.get('pid') !== null) {
    step = 3;
  }
  return (
    <PageContainer>
      <StepAccordion initialStep={step}>
        <StepAccordionStep
          step={1}
          title="Choose a record"
        >
          <StepAccordionStepOpen
            nextButtonText="Next: Find a patron"
            disabled={record === undefined}
          >
            { record !== undefined && (
              <Section>
                <RecordSummary record={record} />
                <GenericHR />
              </Section>
            )}
            <Section>
              <Label htmlFor="title">{record !== undefined ? 'Search for a different record:' : 'Please choose a record:'}</Label>
              <InlineSearch
                searchFunction={dispatchSearchRecords}
                id="di-record-search"
                name="title"
              >
                {recordSearchResults.map((result:Record) => (
                  <InlineSearchResult
                    onSelect={() => dispatchFetchRecordDetails(result.id)}
                    key={result.id}
                  >
                    {result.title}
                  </InlineSearchResult>
                ))}
              </InlineSearch>
            </Section>
          </StepAccordionStepOpen>
          <StepAccordionStepClosed>
            <RecordSummary record={record} />
          </StepAccordionStepClosed>
        </StepAccordionStep>
        <StepAccordionStep
          step={2}
          title="Find a patron"
        >
          <StepAccordionStepOpen
            nextButtonText="Next: Choose a pickup time and date"
            disabled={patron === undefined}
          >
            { patron !== undefined && (
              <Section>
                <PatronSummary patron={patron} />
                <GenericHR />
              </Section>
            )}
            <Section>
              <Label htmlFor="name">{patron !== undefined ? 'Search for a different patron:' : 'Please choose a patron:'}</Label>
              <InlineSearch
                searchFunction={dispatchSearchPatrons}
                id="di-patron-search"
                name="name"
              >
                {patronSearchResults.map((result:Patron) => (
                  <InlineSearchResult
                    onSelect={() => dispatchFetchPatron(result.id)}
                    key={result.id}
                  >
                    {result.name}
                  </InlineSearchResult>
                ))}
              </InlineSearch>
            </Section>
          </StepAccordionStepOpen>
          <StepAccordionStepClosed>
            { patron !== undefined && <PatronSummary patron={patron} />}
          </StepAccordionStepClosed>
        </StepAccordionStep>
        <StepAccordionStep
          step={3}
          title="Choose a pickup time and location"
        >
          <StepAccordionStepOpen>
            { currentBranch !== undefined && (
              <Section>
                <BranchSummary branch={currentBranch} />
                <GenericHR />
              </Section>
            )}
            <Section>
              <Label htmlFor="pickUpBranch">Select the branch you would like to pick up the record from:</Label>
              <InputSelect
                onChange={handleBranchChange}
                name="pickupBranch"
                id="di-pickup-branch"
              >
                <option>--------</option>
                {branches.map((branch:Branch) => (
                  <option
                    key={branch.id}
                    value={branch.id}
                  >
                    {branch.title}
                  </option>
                ))}
              </InputSelect>
              <Label htmlFor="time">Select a time:</Label>
              <input type="time" name="time" id="di-time-input" />
            </Section>
          </StepAccordionStepOpen>
          <StepAccordionStepClosed>
            { currentBranch !== undefined && <BranchSummary branch={currentBranch} /> }
          </StepAccordionStepClosed>
        </StepAccordionStep>
      </StepAccordion>
      <div>
        <APIForm
          onSubmit={dispatchCreateHold}
          action={CIRCULATION_ACTION_TYPES.HOLD.CREATE}
          id="di-create-a-hold"
          onSuccessRoute={ROUTES.CIRCULATION.HOLDS.DETAILS}
          onSuccessAction={CIRCULATION_ACTION_TYPES.HOLD.CREATED}
        >
          <input type="hidden" id="di-hold-record" name="currentBranchId" defaultValue={currentBranch?.id} />
          <input type="hidden" id="di-hold-record" name="recordId" defaultValue={record?.id} />
          <input type="hidden" id="di-hold-patron" name="patronId" defaultValue={patron?.id} />
          <SubmitButton color="blue-green" fill="solid" value="Submit" />
        </APIForm>
      </div>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  record: state.records.currentRecord || {},
  recordSearchResults: state.records.searchResults,
  patron: state.patrons.currentPatron,
  patronSearchResults: state.patrons.searchResults,
  branches: state.branches.branches,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchBranches: () => {
    dispatch(fetchBranches());
  },
  dispatchFetchRecordDetails: (recordId:number|string) => {
    dispatch(fetchRecord(recordId));
  },
  dispatchSearchRecords: (term:string) => {
    dispatch(searchRecords(term));
  },
  dispatchFetchPatron: (patronId:string) => {
    dispatch(fetchPatron(patronId));
  },
  dispatchSearchPatrons: (term:string) => {
    dispatch(searchPatrons(term));
  },
  dispatchCreateHold: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createHold(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAHold);
