import LogoMenuItem from '@components/base/menu-items/LogoMenuItem';
import { ROUTES } from '@routes';
import * as React from 'react';

interface PreAuthMenuProps {
  children: React.ReactNode
}

function PreAuthMenu(props:PreAuthMenuProps) {
  const { children } = props;
  return (
    <nav className="di-pre-auth-menu">
      <LogoMenuItem to={ROUTES.AUTHENTICATION.LOGIN} />
      <ul>
        { children }
      </ul>
    </nav>
  );
}

export default PreAuthMenu;
