import StepAccordionStep from '@components/base/accordions/StepAccordionStep';
import StepAccordionStepClosed from '@components/base/accordions/StepAccordionStepClosed';
import StepAccordionStepOpen from '@components/base/accordions/StepAccordionStepOpen';
import InputSelect from '@components/base/form-fields/InputSelect';
import InputText from '@components/base/form-fields/InputText';
import InputTextWithSearch from '@components/base/form-fields/InputTextWithSearch';
import Label from '@components/base/form-fields/Label';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import TextArea from '@components/base/form-fields/TextArea';
import Section from '@components/layout/generic/Section';
import APIForm from '@containers/application/APIForm';
import RecordSummary from '@containers/catalog/records/shared/RecordSummary';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { searchAuthors } from '@store/catalog/authors/actions';
import { Author } from '@store/catalog/authors/types';
import { searchPublishers } from '@store/catalog/publishers/actions';
import { Publisher } from '@store/catalog/publishers/types';
import { createRecord } from '@store/catalog/records/actions';
import { Record } from '@store/catalog/records/types';
import { fetchOrganizations } from '@store/organizations/organizations/actions';
import { Organization } from '@store/organizations/organizations/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

interface OwnProps {
  step: number
  template: Record
  currentStep?: number
  nextStep?: ()=>void
  goToStep?: (step:number)=>void
  totalSteps?: number
}

const parseRecord = (data:FormData):Record => {
  const authors = new Map();
  for (const [key, value] of data.entries()) {
    if (key.startsWith('author')) {
      const index = parseInt(key.substring(key.indexOf('[') + 1, key.indexOf(']')), 10);
      authors.set(index, {
        [key.substring(key.indexOf('.') + 1)]: value,
      });
    }
  }
  return {
    authors: Array.from(authors.values()),
    title: data.get('title') as string,
    description: data.get('description') as string,
    serpText: data.get('serpText') as string,
    numberOfPages: parseInt(data.get('numberOfPages') as string, 10),
    publisher: {
      id: data.get('publisher.id') as string,
      name: data.get('publisher.name') as string,
    },
    originalPublicationYear: parseInt(data.get('originalPublicationYear') as string, 10),
    yearPublished: parseInt(data.get('yearPublished') as string, 10),
    edition: data.get('edition') as string,
    isbn: data.get('isbn') as string,
    isbn13: data.get('isbn13') as string,
    issn: data.get('issn') as string,
  };
};

interface StateProps {
  organizations: Organization[]
  publishers: Publisher[]
  authors: Author[]
  record: Record
}

interface DispatchProps {
  dispatchFetchOrganizations:()=>void
  dispatchCreateRecord: (e:React.MouseEvent<HTMLFormElement>)=> void
  dispatchSearchPublishers: (term:string)=>void
  dispatchSearchAuthors: (term:string)=>void
}
type CreateRecordStepProps = OwnProps & StateProps & DispatchProps;

function CreateRecordStep(props:CreateRecordStepProps) {
  const {
    step, template,
    organizations, record, publishers, authors,
    dispatchCreateRecord, dispatchFetchOrganizations, dispatchSearchPublishers,
    dispatchSearchAuthors,
    currentStep, nextStep, goToStep, totalSteps,
  } = props;

  const titleRef = React.useRef<HTMLInputElement>(null);
  const descriptionRef = React.useRef<HTMLTextAreaElement>(null);
  const serpTextRef = React.useRef<HTMLTextAreaElement>(null);
  const numberOfPagesRef = React.useRef<HTMLInputElement>(null);
  const yearPublishedRef = React.useRef<HTMLInputElement>(null);
  const isbnRef = React.useRef<HTMLInputElement>(null);
  const isbn13Ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    dispatchFetchOrganizations();
  }, []);

  React.useEffect(() => {
    titleRef.current.value = template?.title;
    descriptionRef.current.value = template?.description;
    numberOfPagesRef.current.value = template?.numberOfPages.toString();
    yearPublishedRef.current.value = template?.yearPublished.toString();
    isbnRef.current.value = template?.isbn;
    isbn13Ref.current.value = template?.isbn13;
    serpTextRef.current.value = template?.serpText;

    if (template?.publisher !== undefined) {
      dispatchSearchPublishers(template.publisher.name);
    }

    if (template?.authors?.length > 0) {
      dispatchSearchAuthors(template.authors[0].name);
    }
  }, [template]);

  if (record.id !== undefined) {
    nextStep();
  }

  return (
    <StepAccordionStep
      currentStep={currentStep}
      nextStep={nextStep}
      goToStep={goToStep}
      totalSteps={totalSteps}
      step={step}
      title="Provide basic information"
    >
      <StepAccordionStepOpen disabled={false}>

        <APIForm
          onSubmit={dispatchCreateRecord}
          action={CATALOG_ACTION_TYPES.RECORD.CREATE}
          id="di-create-a-record"
        >
          { template !== undefined && (
            <img src={template?.thumbnail} alt="" />
          )}
          { organizations.length > 1 ? (
            <Section>
              <Label htmlFor="organizationId">Organziation</Label>
              <InputSelect name="organizationId" id="di-organization" required>
                <option value="">Select an Organization</option>
                { organizations.map(
                  (organization) => (
                    <option key={organization.id} value={organization.id}>
                      {organization.title}
                    </option>
                  ),
                )}
              </InputSelect>
            </Section>
          ) : (
            <input name="organizationId" type="hidden" value={organizations[0]?.id || ''} />
          )}
          <input type="hidden" name="thumbnail" value={template?.thumbnail || ''} />
          <input type="hidden" name="smallThumbnail" value={template?.smallThumbnail || ''} />
          <Section>
            <Label htmlFor="name">Name: </Label>
            <InputText ref={titleRef} type="text" name="title" id="name" />
            <Label htmlFor="description">Long Description:</Label>
            <TextArea ref={descriptionRef} name="description" id="description" />
            <Label htmlFor="serpText">Short Description:</Label>
            <TextArea ref={serpTextRef} name="serpText" id="serpText" />

            { template?.authors.map((author:Author, index) => (
              <InputTextWithSearch
                key={author.name}
                collection={authors.map((a:Author) => a.name)}
                searchFunction={dispatchSearchAuthors}
                defaultValue={author.name}
                id={`di-authors-${index}`}
                name={`authors[${index}].name`}
              />
            ))}
            <Label htmlFor="numberOfPages">Number of Pages</Label>
            <InputText type="text" name="numberOfPages" ref={numberOfPagesRef} id="di-record-num-of-pages" />
          </Section>
          <Section>
            <Label htmlFor="publisher.name">Publisher</Label>
            <InputTextWithSearch
              collection={publishers.map((p:Publisher) => p.name)}
              searchFunction={dispatchSearchPublishers}
              defaultValue={template?.publisher.name}
              id="di-publisher"
              name="publisher.name"
            />
            <Label htmlFor="originalPublicationYear">Original Publication Year</Label>
            <InputText type="text" name="originalPublicationYear" id="originalPublicationYear" />
            <Label htmlFor="yearPublished">Publication Year</Label>
            <InputText ref={yearPublishedRef} type="text" name="yearPublished" id="yearPublished" />
            <Label htmlFor="edition">Edition</Label>
            <InputText type="text" name="edition" id="edition" />
          </Section>
          <Section>
            <Label htmlFor="isbn">ISBN:</Label>
            <InputText ref={isbnRef} type="text" name="isbn" id="isbn" />
            <Label htmlFor="isbn13">ISBN13:</Label>
            <InputText ref={isbn13Ref} type="text" name="isbn13" id="isbn13" />
            <Label htmlFor="issn">ISSN:</Label>
            <InputText type="text" name="issn" id="issn" />
          </Section>
          <SubmitButton fill="solid" color="blue-green" value="Submit" />
        </APIForm>
      </StepAccordionStepOpen>
      <StepAccordionStepClosed>
        <RecordSummary record={record} />
      </StepAccordionStepClosed>
    </StepAccordionStep>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  organizations: state.organizations.organizations,
  publishers: state.publishers.searchResults,
  authors: state.authors.searchResults,
  record: state.records.currentRecord || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreateRecord: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const record:Record = parseRecord(data);
    dispatch(createRecord(record));
  },
  dispatchFetchOrganizations: ():void => {
    dispatch(fetchOrganizations());
  },
  dispatchSearchPublishers: (term:string):void => {
    dispatch(searchPublishers(term));
  },
  dispatchSearchAuthors: (term:string):void => {
    dispatch(searchAuthors(term));
  },
});

CreateRecordStep.defaultProps = {
  currentStep: undefined,
  nextStep: undefined,
  goToStep: undefined,
  totalSteps: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRecordStep);
