import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';

import {
  ClearCurrentNoteAction,
  ClearNotesSearchResultsAction,
  CreateNoteAction,
  DestroyNoteAction,
  FetchNoteAction,
  FetchNotesAction,
  FetchNotesOptions,
  Note,
  NoteCreatedAction,
  NoteDestroyedAction,
  NoteFetchedAction,
  NotesFetchedAction,
  NotesSearchedAction,
  NoteUpdatedAction,
  SearchNotesAction,
  UpdateNoteAction,
} from '@store/catalog/notes/types';

export const fetchNotes = (options:FetchNotesOptions = undefined):FetchNotesAction => ({
  type: CATALOG_ACTION_TYPES.NOTES.FETCH,
  options,
});

export const notesFetched = (response:Note[]):NotesFetchedAction => ({
  type: CATALOG_ACTION_TYPES.NOTES.FETCHED,
  notes: response,
});

export const fetchNote = (id:number|string):FetchNoteAction => ({
  type: CATALOG_ACTION_TYPES.NOTE.FETCH,
  id,
});

export const noteFetched = (note:Note):NoteFetchedAction => ({
  type: CATALOG_ACTION_TYPES.NOTE.FETCHED,
  note,
  id: note.id,
});

export const createNote = (note:FormData):CreateNoteAction => ({
  type: CATALOG_ACTION_TYPES.NOTE.CREATE,
  note,
});

export const noteCreated = (note:Note):NoteCreatedAction => ({
  type: CATALOG_ACTION_TYPES.NOTE.CREATED,
  note,
  id: note.id,
});

export const updateNote = (note:FormData):UpdateNoteAction => ({
  type: CATALOG_ACTION_TYPES.NOTE.UPDATE,
  note,
  id: note.get('id') as string|number,
});

export const noteUpdated = (note:Note):NoteUpdatedAction => ({
  type: CATALOG_ACTION_TYPES.NOTE.UPDATED,
  note,
  id: note.id,
});

export const destroyNote = (note:FormData):DestroyNoteAction => ({
  type: CATALOG_ACTION_TYPES.NOTE.DESTROY,
  id: note.get('id') as string|number,
});

export const noteDestroyed = (id:number|string):NoteDestroyedAction => ({
  type: CATALOG_ACTION_TYPES.NOTE.DESTROYED,
  id,
});

export const clearCurrentNoteAction = ():ClearCurrentNoteAction => ({
  type: CATALOG_ACTION_TYPES.NOTE.CLEAR,
});

export const clearNotesSearchResults = ():ClearNotesSearchResultsAction => ({
  type: CATALOG_ACTION_TYPES.NOTES.CLEAR_SEARCH,
});

export const searchNotes = (term:string):SearchNotesAction => ({
  type: CATALOG_ACTION_TYPES.NOTES.SEARCH,
  term,
});

export const notesSearched = (results:Note[]):NotesSearchedAction => ({
  type: CATALOG_ACTION_TYPES.NOTES.SEARCHED,
  results,
});
