import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  CreateInvoiceAction,
  DestroyInvoiceAction,
  FetchInvoiceAction,
  FetchInvoicesAction,
  Invoice,
  InvoiceCreatedAction,
  InvoiceDestroyedAction,
  InvoiceFetchedAction,
  InvoicesFetchedAction,
  InvoiceUpdatedAction,
  UpdateInvoiceAction,
  FetchInvoiceOptions,
} from '@store/procurement/invoices/types';

export const fetchInvoices = (options:FetchInvoiceOptions = undefined):FetchInvoicesAction => ({
  type: PROCUREMENT_ACTION_TYPES.INVOICES.FETCH,
  options,
});

export const invoicesFetched = (response: Invoice[]):InvoicesFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.INVOICES.FETCHED,
  invoices: response,
});

export const fetchInvoice = (id:number|string):FetchInvoiceAction => ({
  type: PROCUREMENT_ACTION_TYPES.INVOICE.FETCH,
  id,
});

export const invoiceFetched = (invoice:Invoice):InvoiceFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.INVOICE.FETCHED,
  invoice,
});

export const createInvoice = (invoice:FormData):CreateInvoiceAction => ({
  type: PROCUREMENT_ACTION_TYPES.INVOICE.CREATE,
  invoice,
});

export const invoiceCreated = (invoice:Invoice):InvoiceCreatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.INVOICE.CREATED,
  invoice,
  id: invoice.id,
});

export const updateInvoice = (invoice:FormData):UpdateInvoiceAction => ({
  type: PROCUREMENT_ACTION_TYPES.INVOICE.UPDATE,
  invoice,
  id: invoice.get('id') as string|number,
});

export const invoiceUpdated = (invoice:Invoice):InvoiceUpdatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.INVOICE.UPDATED,
  invoice,
  id: invoice.id,
});

export const destroyInvoice = (invoice:FormData):DestroyInvoiceAction => ({
  type: PROCUREMENT_ACTION_TYPES.INVOICE.DESTROY,
  id: invoice.get('id') as string|number,
});

export const invoiceDestroyed = (id:number|string):InvoiceDestroyedAction => ({
  type: PROCUREMENT_ACTION_TYPES.INVOICE.DESTROYED,
  id,
});
