import * as React from 'react';
import { BaseEditor } from 'slate';
import { ReactEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';

type CustomText = { text: string; bold?: true }
type CustomElement = { type: 'paragraph'; children: CustomText[] }

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor
    Element: CustomElement
    Text: CustomText
  }
}
interface TextAreaProps {
  name: string
  id: string
  defaultValue?: string
}

const TextArea = React
  .forwardRef<HTMLTextAreaElement, TextAreaProps>((props:TextAreaProps, ref) => {
    const { defaultValue, name, id } = props;
    // const content:Descendant[] = [
    //   {
    //     type: 'paragraph',
    //     children: [{ text: defaultValue }],
    //   },
    // ];
    // const { name, id, defaultValue, onEditorStateChange } = props;
    // const content = ContentState.createFromBlockArray(defaultValue);
    // const default:EditorState = EditorState.createWithContent(content);
    // const [editor] = React.useState(() => withReact(createEditor()));
    return (
    // <Slate editor={editor} value={content}>
    //   <Editable />
    // </Slate>
      <textarea ref={ref} name={name} id={id} defaultValue={defaultValue} />
    );
  });

TextArea.defaultProps = {
  defaultValue: undefined,
};

export default TextArea;
