import * as React from 'react';

interface ThumbnailProps {
  src: string
  alt: string
  size: 'small' | 'medium' | 'large'
}

function Thumbnail(props:ThumbnailProps) {
  const { src, alt, size } = props;
  return (
    <img src={src} alt={alt} className={`di-thumbnail di-thumbnail-${size}`} />
  );
}

export default Thumbnail;
