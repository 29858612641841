import { ROUTES } from '@routes';
import { destroyPatrons, fetchPatrons } from '@store/patrons/patrons/actions';
import { Patron } from '@store/patrons/patrons/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import Button from '@components/base/buttons-and-links/Button';
import { RootState } from '@store/root';
import Modal from '@components/compound/modals/Modal';
import TextHeader from '@components/base/text/TextHeader';
import MultiActionButton from '@components/base/buttons-and-links/MultiActionButton';
import MultiActionButtonItem from '@components/base/buttons-and-links/MultiActionButtonItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import ModalFooter from '@components/compound/modals/ModalFooter';
import { createGroupMemberships, searchPatronGroups } from '@store/patrons/groups/actions';
import { PatronGroup } from '@store/patrons/groups/types';
import InlineSearch from '@components/base/search/InlineSearch';
import InlineSearchResult from '@components/base/search/InlineSearchResult';
import PatronList from './shared/PatronList';

interface StateProps {
  patrons: Patron[]
  groups: PatronGroup[]
  currentPage: number
  totalPages: number
  numOfRecords: number
}
interface DispatchProps {
  dispatchFetchPatrons:()=>void
  dispatchDestroyPatrons:(patrons:Patron[])=>void
  dispatchSearchPatronGroups: (term:string)=>void
  dispatchCreateGroupMembership: (patron:Patron, group:PatronGroup)=>void
}

type ShowAllPatronsProps = StateProps & DispatchProps;

function ShowAllPatrons(props:ShowAllPatronsProps) {
  const [selected, setSelected] = React.useState([] as Patron[]);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showAddTooGroupModal, setShowAddToGroupModal] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState(undefined);
  const {
    dispatchFetchPatrons, dispatchDestroyPatrons,
    dispatchSearchPatronGroups, dispatchCreateGroupMembership,
    patrons, groups, currentPage, totalPages, numOfRecords,
  } = props;

  React.useEffect(() => {
    dispatchFetchPatrons();
  }, []);

  const linkSelected = (selectedPatrons:Patron[]):void => {
    setSelected(selectedPatrons);
  };

  const actions = [<Button key={1} color="blue-green" fill="solid" to={ROUTES.PATRONS.PATRONS.NEW(true)}>Add a Patron</Button>,
    (
      <MultiActionButton key={3} tabIndex={0} label="Bulk Actions" disabled={selected.length === 0} style={{ display: 'inline-block' }}>
        <MultiActionButtonItem onClick={() => setShowDeleteModal(true)}>
          <FontAwesomeIcon icon={faTrash} />
          Delete
        </MultiActionButtonItem>
        <MultiActionButtonItem onClick={() => setShowAddToGroupModal(true)}>
          <FontAwesomeIcon icon={faUserGroup} />
          Add to Group
        </MultiActionButtonItem>
      </MultiActionButton>
    )];

  return (
    <PageContainer>
      <PatronList
        patrons={patrons}
        currentPage={currentPage}
        totalPages={totalPages}
        numOfRecords={numOfRecords}
        actions={actions}
        getSelected={linkSelected}
        showHeader
        selectable
        sortable
      />
      <Modal exitClick={() => setShowDeleteModal(false)} style={showDeleteModal ? {} : { display: 'none' }}>
        <TextHeader size="h3">Delete Records?</TextHeader>
        <p>Are you sure you want to delete these records?</p>
        <ModalFooter>
          <Button fill="solid" color="blue-green" onClick={() => { dispatchDestroyPatrons(selected); setShowDeleteModal(false); }}>Yes</Button>
        </ModalFooter>
      </Modal>
      <Modal
        exitClick={() => setShowAddToGroupModal(false)}
        title="Add to group?"
        style={showAddTooGroupModal ? {} : { display: 'none' }}
      >
        <div>
          <InlineSearch
            id="di-search-groups"
            name="di-search-groups"
            searchFunction={dispatchSearchPatronGroups}
          >
            { groups.map((group:PatronGroup) => (
              <InlineSearchResult onSelect={() => { setSelectedGroup(group); }}>
                {group.title}
              </InlineSearchResult>
            ))}
          </InlineSearch>

        </div>
        <ModalFooter>
          <Button
            fill="solid"
            color="blue-green"
            onClick={() => {
              dispatchCreateGroupMembership(selected[0], selectedGroup);
              setShowDeleteModal(false);
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  patrons: state.patrons.patrons,
  currentPage: state.patrons.currentPage,
  totalPages: state.patrons.totalPages,
  numOfRecords: state.patrons.results,
  groups: state.groups.searchResults,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchPatrons: ():void => {
    dispatch(fetchPatrons());
  },
  dispatchDestroyPatrons: (patrons:Patron[]):void => {
    dispatch(destroyPatrons(patrons));
  },
  dispatchSearchPatronGroups: (term:string):void => {
    dispatch(searchPatronGroups(term));
  },
  dispatchCreateGroupMembership: (patron:Patron, group:PatronGroup):void => {
    dispatch(createGroupMemberships(group.id, [patron]));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllPatrons);
