import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';

import {
  ClearCurrentBookmarkAction,
  ClearBookmarksSearchResultsAction,
  CreateBookmarkAction,
  DestroyBookmarkAction,
  FetchBookmarkAction,
  FetchBookmarksAction,
  Bookmark,
  BookmarkCreatedAction,
  BookmarkDestroyedAction,
  BookmarkFetchedAction,
  BookmarksFetchedAction,
  BookmarksSearchedAction,
  BookmarkUpdatedAction,
  SearchBookmarksAction,
  UpdateBookmarkAction,
  FetchBookmarksOptions,
} from '@store/catalog/bookmarks/types';

export const fetchBookmarks = (
  options: FetchBookmarksOptions = undefined,
):FetchBookmarksAction => ({
  type: CATALOG_ACTION_TYPES.BOOKMARKS.FETCH,
  options,
});

export const bookmarksFetched = (response:Bookmark[]):BookmarksFetchedAction => ({
  type: CATALOG_ACTION_TYPES.BOOKMARKS.FETCHED,
  bookmarks: response,
});

export const fetchBookmark = (id:number|string):FetchBookmarkAction => ({
  type: CATALOG_ACTION_TYPES.BOOKMARK.FETCH,
  id,
});

export const bookmarkFetched = (bookmark:Bookmark):BookmarkFetchedAction => ({
  type: CATALOG_ACTION_TYPES.BOOKMARK.FETCHED,
  bookmark,
  id: bookmark.id,
});

export const createBookmark = (bookmark:FormData):CreateBookmarkAction => ({
  type: CATALOG_ACTION_TYPES.BOOKMARK.CREATE,
  bookmark,
});

export const bookmarkCreated = (bookmark:Bookmark):BookmarkCreatedAction => ({
  type: CATALOG_ACTION_TYPES.BOOKMARK.CREATED,
  bookmark,
  id: bookmark.id,
});

export const updateBookmark = (bookmark:FormData):UpdateBookmarkAction => ({
  type: CATALOG_ACTION_TYPES.BOOKMARK.UPDATE,
  bookmark,
  id: bookmark.get('id') as string|number,
});

export const bookmarkUpdated = (bookmark:Bookmark):BookmarkUpdatedAction => ({
  type: CATALOG_ACTION_TYPES.BOOKMARK.UPDATED,
  bookmark,
  id: bookmark.id,
});

export const destroyBookmark = (bookmark:FormData):DestroyBookmarkAction => ({
  type: CATALOG_ACTION_TYPES.BOOKMARK.DESTROY,
  id: bookmark.get('id') as string|number,
});

export const bookmarkDestroyed = (id:number|string):BookmarkDestroyedAction => ({
  type: CATALOG_ACTION_TYPES.BOOKMARK.DESTROYED,
  id,
});

export const clearCurrentBookmarkAction = ():ClearCurrentBookmarkAction => ({
  type: CATALOG_ACTION_TYPES.BOOKMARK.CLEAR,
});

export const clearBookmarksSearchResults = ():ClearBookmarksSearchResultsAction => ({
  type: CATALOG_ACTION_TYPES.BOOKMARKS.CLEAR_SEARCH,
});

export const searchBookmarks = (term:string):SearchBookmarksAction => ({
  type: CATALOG_ACTION_TYPES.BOOKMARKS.SEARCH,
  term,
});

export const bookmarksSearched = (results:Bookmark[]):BookmarksSearchedAction => ({
  type: CATALOG_ACTION_TYPES.BOOKMARKS.SEARCHED,
  results,
});
