import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import {
  lists,
  reviews,
  notes,
  records,
  recordImages,
  recordInstances,
  authors,
  bookmarks,
  categories,
  publishers,
  tags,
  subjects,
  genres,
} from '@store/catalog/reducer';
import { auth } from '@store/authentication/reducer';
import {
  profile,
  familyMembers,
  associations,
} from '@store/profile/reducer';
import { notifications } from '@store/notifications/reducer';
import {
  organizations,
  branches,
  admins,
} from '@store/organizations/reducer';
import application from '@store/application/reducer';
import {
  patrons,
  groups,
} from '@store/patrons/reducer';
import {
  suppliers,
  purchases,
  invoices,
  lineItems,
  funds,
  budgets,
  contacts,
  contracts,
} from '@store/procurement/reducer';
import {
  fees,
  holds,
  transfers,
  recordLeases,
  locations,
  sections,
} from '@store/circulation/reducer';

import AuthenticationEpics from '@store/authentication/epics';
import RecordEpics from '@store/catalog/records/epics';
import ListEpics from '@store/catalog/lists/epics';
import SupplierEpics from '@store/procurement/suppliers/epics';
import PurchaseEpics from '@store/procurement/purchases/epics';
import ProfileEpics from '@store/profile/profile/epics';
import FundEpics from '@store/procurement/funds/epics';
import FamilyMemberEpics from '@store/profile/family/epics';
import AssociationEpics from '@store/profile/associations/epics';
import NotificationEpics from '@store/notifications/epics';
import PatronEpics from '@store/patrons/patrons/epics';
import PatronGroupEpics from '@store/patrons/groups/epics';
import OrganizationEpics from '@store/organizations/organizations/epics';
import BranchEpics from '@store/organizations/branches/epics';
import AdminEpics from '@store/organizations/admins/epics';
import ReviewEpics from '@store/catalog/reviews/epics';
import NoteEpics from '@store/catalog/notes/epics';
import InvoiceEpics from '@store/procurement/invoices/epics';
import LineItemEpics from '@store/procurement/lineItems/epics';
import BudgetEpics from '@store/procurement/budgets/epics';
import FeeEpics from '@store/circulation/fees/epics';
import HoldEpics from '@store/circulation/holds/epics';
import TransferEpics from '@store/circulation/transfers/epics';
import RecordImageEpics from '@store/catalog/recordImages/epics';
import RecordLeaseEpics from '@store/circulation/recordLeases/epics';
import RecordInstanceEpics from '@store/catalog/recordInstances/epics';
import AuthorEpics from '@store/catalog/authors/epics';
import BookmarkEpics from '@store/catalog/bookmarks/epics';
import CategoryEpics from '@store/catalog/categories/epics';
import PublisherEpics from '@store/catalog/publishers/epics';
import LocationEpics from '@store/circulation/locations/epics';
import GenreEpics from '@store/catalog/genres/epics';
import SubjectEpics from '@store/catalog/subjects/epics';
import TagEpics from '@store/catalog/tags/epics';
import SectionEpics from '@store/circulation/sections/epics';
import ContactEpics from './procurement/contacts/epics';
import ContractEpics from './procurement/contracts/epics';

export const rootEpic = combineEpics(
  // Application
  ...AuthenticationEpics,
  ...ProfileEpics,
  ...FamilyMemberEpics,
  ...AssociationEpics,
  // Catalog
  ...AuthorEpics,
  ...PublisherEpics,
  ...RecordEpics,
  ...ListEpics,
  ...NoteEpics,
  ...ReviewEpics,
  ...RecordImageEpics,
  ...RecordInstanceEpics,
  ...BookmarkEpics,
  ...CategoryEpics,
  ...GenreEpics,
  ...SubjectEpics,
  ...TagEpics,
  // Circulation
  ...FeeEpics,
  ...HoldEpics,
  ...TransferEpics,
  ...RecordLeaseEpics,
  ...LocationEpics,
  ...SectionEpics,
  // Procurement
  ...SupplierEpics,
  ...PurchaseEpics,
  ...InvoiceEpics,
  ...LineItemEpics,
  ...FundEpics,
  ...BudgetEpics,
  ...ContactEpics,
  ...ContractEpics,
  // Patron
  ...PatronEpics,
  ...PatronGroupEpics,
  // Notification
  ...NotificationEpics,
  // Organization
  ...OrganizationEpics,
  ...BranchEpics,
  ...AdminEpics,
);

export const rootReducer = combineReducers({
  admins,
  application,
  associations,
  auth,
  authors,
  bookmarks,
  branches,
  budgets,
  categories,
  contacts,
  contracts,
  familyMembers,
  fees,
  funds,
  genres,
  groups,
  holds,
  invoices,
  lineItems,
  lists,
  locations,
  notes,
  notifications,
  organizations,
  patrons,
  profile,
  publishers,
  purchases,
  records,
  recordLeases,
  recordImages,
  recordInstances,
  reviews,
  sections,
  subjects,
  suppliers,
  tags,
  transfers,
});

export type RootState = ReturnType<typeof rootReducer>;
