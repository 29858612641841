import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';

import {
  ClearCurrentCategoryAction,
  CreateCategoryAction,
  DestroyCategoryAction,
  FetchCategoryAction,
  FetchCategoriesAction,
  Category,
  CategoryCreatedAction,
  CategoryDestroyedAction,
  CategoryFetchedAction,
  CategoriesFetchedAction,
  CategoryUpdatedAction,
  UpdateCategoryAction,
  FetchCategoriesOptions,
} from '@store/catalog/categories/types';

export const fetchCategories = (
  options:FetchCategoriesOptions = undefined,
):FetchCategoriesAction => ({
  type: CATALOG_ACTION_TYPES.CATEGORIES.FETCH,
  options,
});

export const categoriesFetched = (response:Category[]):CategoriesFetchedAction => ({
  type: CATALOG_ACTION_TYPES.CATEGORIES.FETCHED,

  categories: response,
});

export const fetchCategory = (id:number|string):FetchCategoryAction => ({
  type: CATALOG_ACTION_TYPES.CATEGORY.FETCH,
  id,
});

export const categoryFetched = (category:Category):CategoryFetchedAction => ({
  type: CATALOG_ACTION_TYPES.CATEGORY.FETCHED,
  category,
  id: category.id,
});

export const createCategory = (category:FormData):CreateCategoryAction => ({
  type: CATALOG_ACTION_TYPES.CATEGORY.CREATE,
  category,
});

export const categoryCreated = (category:Category):CategoryCreatedAction => ({
  type: CATALOG_ACTION_TYPES.CATEGORY.CREATED,
  category,
  id: category.id,
});

export const updateCategory = (category:FormData):UpdateCategoryAction => ({
  type: CATALOG_ACTION_TYPES.CATEGORY.UPDATE,
  category,
  id: category.get('id') as string|number,
});

export const categoryUpdated = (category:Category):CategoryUpdatedAction => ({
  type: CATALOG_ACTION_TYPES.CATEGORY.UPDATED,
  category,
  id: category.id,
});

export const destroyCategory = (category:FormData):DestroyCategoryAction => ({
  type: CATALOG_ACTION_TYPES.CATEGORY.DESTROY,
  id: category.get('id') as string|number,
});

export const categoryDestroyed = (id:number|string):CategoryDestroyedAction => ({
  type: CATALOG_ACTION_TYPES.CATEGORY.DESTROYED,
  id,
});

export const clearCurrentCategoryAction = ():ClearCurrentCategoryAction => ({
  type: CATALOG_ACTION_TYPES.CATEGORY.CLEAR,
});
