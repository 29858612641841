import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface PageNumberProps {
  page: number
  current:boolean
}

function PageNumber(props:PageNumberProps) {
  const { page, current } = props;
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  urlParams.set('p', page.toString());
  return (
    <Link to={{ pathname: location.pathname, search: urlParams.toString() }} className={`di-page-number ${current ? 'di-page-number-current-page' : ''}`}>{ page }</Link>
  );
}

export default PageNumber;
