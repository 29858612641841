import * as React from 'react';

interface InputTextProps {
  id: string
  name: string
  type: 'email' | 'password' | 'text' | 'tel' | 'search'
  placeholder?: string
  defaultValue?:string
  value?:string
  disabled?:boolean
  required?:boolean
  readonly?:boolean
  onChange?:(e:React.ChangeEvent<HTMLInputElement>)=>void
}

const PLACEHOLDERS = {
  password: '•••••••••••••',
  email: 'user@example.org',
  text: '',
  tel: '(234) 233-3333',
  search: '',
};

const InputText = React.forwardRef<HTMLInputElement, InputTextProps>(
  (props:InputTextProps, ref) => {
    const {
      type, name, id, placeholder, defaultValue, disabled, onChange, value, required, readonly,
    } = props;
    return (
      <input
        type={type}
        className="di-input-text"
        name={name}
        id={id}
        placeholder={placeholder !== undefined ? placeholder : PLACEHOLDERS[type]}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={onChange}
        value={value}
        ref={ref}
        required={required}
        readOnly={readonly}
      />
    );
  },
);

InputText.defaultProps = {
  placeholder: undefined,
  defaultValue: undefined,
  value: undefined,
  disabled: false,
  onChange: undefined,
  required: false,
  readonly: false,
};

export default InputText;
