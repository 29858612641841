import Button from '@components/base/buttons-and-links/Button';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import { fetchFund } from '@store/procurement/funds/actions';
import { Fund } from '@store/procurement/funds/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

interface StateProps {
  fund:Fund
}
interface DispatchProps {
  dispatchFetchFund:(id:number|string)=>void
}
type ShowFundDetailsProps = StateProps & DispatchProps;

function ShowFundDetails(props:ShowFundDetailsProps) {
  const { dispatchFetchFund, fund } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);
  React.useEffect(() => {
    dispatchFetchFund(id);
  }, []);
  return (
    <PageContainer>
      <TextHeader size="h1">{fund.name}</TextHeader>
      <Button color="blue-green" fill="solid" to={ROUTES.PROCUREMENT.FUNDS.EDIT(fund.id)}>Edit</Button>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  fund: state.funds.currentFund || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchFund: (id:number|string):void => {
    dispatch(fetchFund(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowFundDetails);
