import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { destroyList, fetchList, updateList } from '@store/catalog/lists/actions';
import { List } from '@store/catalog/lists/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import PageContainer from '@components/layout/generic/PageContainer';
import InputText from '@components/base/form-fields/InputText';

interface StateProps { list:List, loading:boolean }
interface DispatchProps {
  dispatchFetchList:(id:number|string)=>void,
  dispatchUpdateList:(e: React.MouseEvent<HTMLFormElement, MouseEvent>) => void,
  dispatchDestroyList:(e: React.MouseEvent<HTMLFormElement, MouseEvent>) => void
}
type EditAListProps = StateProps & DispatchProps;

function EditAList(props: EditAListProps) {
  const {
    dispatchFetchList, loading, dispatchUpdateList, list, dispatchDestroyList,
  } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchList(id);
  }, []);

  return (
    <PageContainer loading={loading}>
      <APIForm
        id="di-edit-a-list"
        onSubmit={dispatchUpdateList}
        action={CATALOG_ACTION_TYPES.LIST.UPDATE}
        onSuccessRoute={ROUTES.CATALOG.LISTS.DETAILS(list.id)}
        onSuccessAction={CATALOG_ACTION_TYPES.LIST.UPDATED}
        entityID={list.id}
      >

        <input type="hidden" name="id" defaultValue={list.id} />
        <InputText id="di-list-name" type="text" name="name" defaultValue={list.name} />
        <SubmitButton fill="solid" color="blue-green" value="Submit" />
      </APIForm>

      <APIForm
        id="di-destroy-a-list"
        onSubmit={dispatchDestroyList}
        action={CATALOG_ACTION_TYPES.LIST.DESTROY}
        onSuccessRoute={ROUTES.CATALOG.LISTS.ALL(true)}
        onSuccessAction={CATALOG_ACTION_TYPES.LIST.DESTROYED}
        entityID={list.id}
      >
        <input type="hidden" name="id" defaultValue={list.id} />
        <SubmitButton fill="outline" color="blue-green" value="Delete" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  list: state.lists.currentList || {},
  loading: state.lists.currentList === undefined,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchList: (id:number|string):void => {
    dispatch(fetchList(id));
  },
  dispatchUpdateList: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updateList(data));
  },
  dispatchDestroyList: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyList(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAList);
