import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { createReview } from '@store/catalog/reviews/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import TextHeader from '@components/base/text/TextHeader';
import InputText from '@components/base/form-fields/InputText';
import Label from '@components/base/form-fields/Label';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import Modal from '@components/compound/modals/Modal';

interface DispatchProps { dispatchCreateReview:(e:React.MouseEvent<HTMLFormElement>) => void }
type CreateAReviewProps = DispatchProps;

function CreateAReview(props:CreateAReviewProps) {
  const { dispatchCreateReview } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);
  return (
    <Modal exitPath={ROUTES.CATALOG.RECORDS.DETAILS(id)}>
      <TextHeader size="h2">Add a Review</TextHeader>
      <APIForm
        onSubmit={dispatchCreateReview}
        action={CATALOG_ACTION_TYPES.REVIEW.CREATE}
        id="di-create-password-form"
        onSuccessRoute={ROUTES.CATALOG.RECORDS.DETAILS(id)}
        onSuccessAction={CATALOG_ACTION_TYPES.REVIEW.CREATED}
      >
        <Label htmlFor="name">Name:</Label>
        <InputText id="di-review-name" type="text" name="name" />
        <input type="hidden" name="recordId" value={id} />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreateReview: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createReview(data));
  },
});

export default connect(null, mapDispatchToProps)(CreateAReview);
