import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { PATRON_ACTION_TYPES } from '@store/patrons/actionTypes';
import {
  FetchPatronGroupsAction,
  FetchPatronGroupAction,
  CreatePatronGroupAction,
  UpdatePatronGroupAction,
  DestroyPatronGroupAction,
  SearchPatronGroupsAction,
  CreateGroupMembershipsAction,
  DestroyPatronGroupsAction,
} from '@store/patrons/groups/types';
import {
  patronGroupsFetched,
  patronGroupFetched,
  patronGroupCreated,
  patronGroupUpdated,
  patronGroupDestroyed,
  patronGroupsSearched,
  groupMembershipsCreated,
  patronGroupsDestroyed,
} from '@store/patrons/groups/actions';
import { Observable } from 'rxjs';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchPatronGroupsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PATRON_ACTION_TYPES.PATRON_GROUPS.FETCH),
  switchMap((action:FetchPatronGroupsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/groups/`,
    method: 'GET',
    success: patronGroupsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchPatronGroupEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PATRON_ACTION_TYPES.PATRON_GROUP.FETCH),
  switchMap((action:FetchPatronGroupAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/groups/`,
    method: 'GET',
    success: patronGroupFetched,
    auth: true,
    state$,
    action,
  })),
);

const createPatronGroupEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PATRON_ACTION_TYPES.PATRON_GROUP.CREATE),
  switchMap((action:CreatePatronGroupAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/groups/`,
    method: 'POST',
    success: patronGroupCreated,
    auth: true,
    state$,
    action,
    body: action.group,
  })),
);

const updatePatronGroupEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PATRON_ACTION_TYPES.PATRON_GROUP.UPDATE),
  switchMap((action:UpdatePatronGroupAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/groups/${action.id}/`,
    method: 'PUT',
    success: patronGroupUpdated,
    auth: true,
    state$,
    action,
    body: action.group,
  })),
);

const destroyPatronGroupEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PATRON_ACTION_TYPES.PATRON_GROUP.DESTROY),
  switchMap((action:DestroyPatronGroupAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/groups/${action.id}/`,
    method: 'DELETE',
    success: patronGroupDestroyed,
    auth: true,
    state$,
    action,
  })),
);

const destroyPatronGroupsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PATRON_ACTION_TYPES.PATRON_GROUPS.DESTROY),
  switchMap((action:DestroyPatronGroupsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/groups/`,
    method: 'DELETE',
    success: patronGroupsDestroyed,
    auth: true,
    state$,
    action,
    body: action.groups,
  })),
);

const searchPatronGroupsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PATRON_ACTION_TYPES.PATRON_GROUPS.SEARCH),
  switchMap((action:SearchPatronGroupsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/search-groups/`,
    method: 'POST',
    success: patronGroupsSearched,
    auth: true,
    state$,
    action,
  })),
);

const createGroupMemebershipEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PATRON_ACTION_TYPES.PATRON_GROUP.CREATE_MEMBERSHIPS),
  switchMap((action:CreateGroupMembershipsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/groups/${action.groupId}/members/`,
    method: 'POST',
    success: groupMembershipsCreated,
    auth: true,
    state$,
    action,
    body: action.patrons,
  })),
);

export default [
  fetchPatronGroupsEpic,
  fetchPatronGroupEpic,
  createPatronGroupEpic,
  updatePatronGroupEpic,
  destroyPatronGroupEpic,
  destroyPatronGroupsEpic,
  searchPatronGroupsEpic,
  createGroupMemebershipEpic,
];
