import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import { Supplier } from '@store/procurement/suppliers/types';
import APIList from '@containers/application/APIList';
import * as React from 'react';
import { ROUTES } from '@routes';
import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import { formatAddress } from '@containers/application/Helpers';

interface RequiredSupplierListProps {
  suppliers: Supplier[]
  currentPage: number
  totalPages: number
  sortable: boolean
  selectable: boolean
}
interface OptionalSupplierListProps {
  getSelected?: (suppliers:Supplier[])=>void
  filterable?: boolean
}
type SupplierListProps = OptionalSupplierListProps & RequiredSupplierListProps;

function SupplierList(props:SupplierListProps) {
  const [selected, setSelected] = React.useState([]);
  const {
    suppliers, getSelected, filterable, currentPage, totalPages, sortable, selectable,
  } = props;

  const toggleAll = (e:React.MouseEvent) => {
    e.stopPropagation();
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected([...suppliers]);
    }
    getSelected(selected);
  };

  const toggleSelected = (selectedSupplier:Supplier) => {
    if (selected.filter((record:Supplier) => record.id === selectedSupplier.id).length > 0) {
      setSelected(selected.filter((record:Supplier) => record.id !== selectedSupplier.id));
    } else {
      setSelected([...selected, selectedSupplier]);
    }
    getSelected(selected);
  };

  return (
    <APIList action={PROCUREMENT_ACTION_TYPES.PURCHASES.FETCH}>
      <PaginatedTable
        showHeader={filterable}
        empty={suppliers.length === 0}
        currentPage={currentPage}
        totalPages={totalPages}
      >
        <TableHeader>
          <TableRow>
            { selectable && <TableHeaderColumn resizable={false} onClick={toggleAll} sortable={false}><input readOnly type="checkbox" checked={selected.length === suppliers.length && suppliers.length > 0} /></TableHeaderColumn> }
            <TableHeaderColumn resizable attribute="name" sortable={sortable}>Name</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="mailingAddress" sortable={sortable}>Address</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="phone" sortable={sortable}>Phone</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="email" sortable={sortable}>Email</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="website" sortable={sortable}>Website</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          { suppliers.map((supplier:Supplier) => (
            <TableRow key={supplier.id} to={ROUTES.PROCUREMENT.SUPPLIERS.DETAILS(supplier.id)}>
              { selectable && (
                <TableColumn resizable={false} tabIndex={0}>
                  <input
                    readOnly
                    type="checkbox"
                    checked={selected.filter(
                      (selectedSupplier:Supplier) => selectedSupplier.id === supplier.id,
                    ).length > 0}
                    onClick={(e:React.MouseEvent) => {
                      e.stopPropagation(); toggleSelected(supplier);
                    }}
                  />
                </TableColumn>
              )}
              <TableColumn tabIndex={0} resizable>
                {supplier.name}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {formatAddress(supplier.mailingAddress)}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {supplier.phone}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" href={`mailto:${supplier.email}`}>{supplier.email}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" href={supplier.website} target="_blank">
                  {supplier.website}
                </HTMLLink>
              </TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
    </APIList>
  );
}

SupplierList.defaultProps = {
  getSelected: undefined,
  filterable: false,
};
export default SupplierList;
