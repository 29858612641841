import * as React from 'react';
import { Link } from 'react-router-dom';

interface MultiActionButtonItemProps {
  children: React.ReactNode
  to?: string
  onClick?: ()=>void
}

function MultiActionButtonItem(props:MultiActionButtonItemProps) {
  const { to, children, onClick } = props;
  let link;
  if (onClick !== undefined) {
    link = <button type="button" onClick={onClick}>{children}</button>;
  } else {
    link = <Link to={to}>{children}</Link>;
  }
  return (
    <li className="di-multi-action-button-item">{link}</li>
  );
}

MultiActionButtonItem.defaultProps = {
  onClick: undefined,
  to: undefined,
};

export default MultiActionButtonItem;
