import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import { Budget } from '@store/procurement/budgets/types';
import APIList from '@containers/application/APIList';
import * as React from 'react';
import { ROUTES } from '@routes';
import { Fund } from '@store/procurement/funds/types';

interface RequiredBudgetListProps {
  budgets: Budget[]
  currentPage: number
  totalPages: number
  sortable: boolean
  selectable: boolean
}
interface OptionalBudgetListProps {
  getSelected?: (budgets:Budget[])=>void
  filterable?: boolean
}
type BudgetListProps = OptionalBudgetListProps & RequiredBudgetListProps;

function BudgetList(props:BudgetListProps) {
  const [selected, setSelected] = React.useState([]);
  const {
    budgets, getSelected, filterable, currentPage, totalPages, sortable, selectable,
  } = props;

  const toggleAll = (e:React.MouseEvent) => {
    e.stopPropagation();
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected([...budgets]);
    }
    getSelected(selected);
  };

  const toggleSelected = (selectedBudget:Budget) => {
    if (selected.filter((record:Budget) => record.id === selectedBudget.id).length > 0) {
      setSelected(selected.filter((record:Budget) => record.id !== selectedBudget.id));
    } else {
      setSelected([...selected, selectedBudget]);
    }
    getSelected(selected);
  };

  return (
    <APIList action={PROCUREMENT_ACTION_TYPES.BUDGETS.FETCH}>
      <PaginatedTable
        showHeader={filterable}
        empty={budgets.length === 0}
        currentPage={currentPage}
        totalPages={totalPages}
      >
        <TableHeader>
          <TableRow>
            { selectable && <TableHeaderColumn resizable={false} onClick={toggleAll} sortable={false}><input readOnly type="checkbox" checked={selected.length === budgets.length && budgets.length > 0} /></TableHeaderColumn> }
            <TableHeaderColumn resizable attribute="name" sortable={sortable}>Name</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="startDate" sortable={sortable}>Start Date</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="endDate" sortable={sortable}>End Date</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="amount" sortable={sortable}>Amount Budgeted</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="funded" sortable={sortable}>Amount Funded</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          { budgets.map((budget:Budget) => (
            <TableRow key={budget.id} to={ROUTES.PROCUREMENT.BUDGETS.DETAILS(budget.id)}>
              { selectable && (
                <TableColumn resizable={false} tabIndex={0}>
                  <input
                    readOnly
                    type="checkbox"
                    checked={selected.filter(
                      (selectedBudget:Budget) => selectedBudget.id === budget.id,
                    ).length > 0}
                    onClick={(e:React.MouseEvent) => {
                      e.stopPropagation(); toggleSelected(budget);
                    }}
                  />
                </TableColumn>
              )}
              <TableColumn tabIndex={0} resizable>
                {budget.name}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {budget.startDate}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {budget.endDate}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {`$${budget.amount}`}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {`$${budget.funds?.reduce((accumulator:number, fund:Fund) => accumulator + fund.amount, 0)}`}
              </TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
    </APIList>
  );
}

BudgetList.defaultProps = {
  getSelected: undefined,
  filterable: false,
};
export default BudgetList;
