import Button from '@components/base/buttons-and-links/Button';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import { fetchFamilyMember } from '@store/profile/family/actions';
import { FamilyMember } from '@store/profile/family/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

interface StateProps { familyMember:FamilyMember }
interface DispatchProps {
  dispatchFetchFamilyMember:(id:number|string)=>void
}
type ShowFamilyMemberDetailsProps = StateProps & DispatchProps;

function ShowFamilyMemberDetails(props:ShowFamilyMemberDetailsProps) {
  const { dispatchFetchFamilyMember, familyMember } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchFamilyMember(id);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">{familyMember.name}</TextHeader>
      <Button color="blue-green" fill="solid" to={ROUTES.PROFILE.FAMILY_MEMBERS.EDIT(familyMember.id)}>Edit</Button>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  familyMember: state.familyMembers.currentFamilyMember,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchFamilyMember: (id:number|string):void => {
    dispatch(fetchFamilyMember(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowFamilyMemberDetails);
