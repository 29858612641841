import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { createBudget } from '@store/procurement/budgets/actions';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import Label from '@components/base/form-fields/Label';

interface DispatchProps { dispatchCreateBudget:(e:React.MouseEvent<HTMLFormElement>)=>void }
type CreateABudgetProps = DispatchProps

function CreateABudget(props:CreateABudgetProps) {
  const { dispatchCreateBudget } = props;
  return (
    <PageContainer>
      <TextHeader size="h1">Add An Budget</TextHeader>
      <APIForm
        id="di-add-an-budget--form"
        action={PROCUREMENT_ACTION_TYPES.BUDGET.CREATE}
        onSubmit={dispatchCreateBudget}
        onSuccessRoute={ROUTES.PROCUREMENT.BUDGETS.DETAILS}
        onSuccessAction={PROCUREMENT_ACTION_TYPES.BUDGET.CREATED}
      >

        <Label htmlFor="name">Name:</Label>
        <InputText type="text" name="name" id="di-budget-name" />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>
    </PageContainer>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreateBudget: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createBudget(data));
  },
});

export default connect(null, mapDispatchToProps)(CreateABudget);
