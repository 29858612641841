// import { PaginatedAPIResponse } from '@store/application/types';
import { PATRON_ACTION_TYPES } from '@store/patrons/actionTypes';
import {
  ClearCurrentPatronAction,
  ClearPatronSearchAction,
  CreatePatronAction,
  DestroyPatronAction,
  DestroyPatronsAction,
  FetchPatronAction,
  PatronAPIOptions,
  FetchPatronsAction,
  Patron,
  PatronCreatedAction,
  PatronDestroyedAction,
  PatronFetchedAction,
  PatronsDestroyedAction,
  PatronsFetchedAction,
  PatronsSearchedAction,
  PatronUpdatedAction,
  SearchPatronsAction,
  UpdatePatronAction,
} from '@store/patrons/patrons/types';

type FetchPatrons = (options?:PatronAPIOptions)=> FetchPatronsAction;
export const fetchPatrons:FetchPatrons = (
  options:PatronAPIOptions = undefined,
):FetchPatronsAction => ({
  type: PATRON_ACTION_TYPES.PATRONS.FETCH,
  options,
});
// interface PatronsFetchedResponse extends PaginatedAPIResponse {
//   data: Patron[]
// }

export const patronsFetched = (response:any):PatronsFetchedAction => ({
  type: PATRON_ACTION_TYPES.PATRONS.FETCHED,
  pageSize: response.pageSize,
  currentPage: response.currentPage,
  totalPages: response.totalPages,
  results: response.length,
  patrons: response,
});

export const destroyPatrons = (
  patrons:Patron[],
  options:PatronAPIOptions = undefined,
):DestroyPatronsAction => ({
  type: PATRON_ACTION_TYPES.PATRONS.DESTROY,
  patrons,
  options,
});

export const patronsDestroyed = ():PatronsDestroyedAction => ({
  type: PATRON_ACTION_TYPES.PATRONS.DESTROYED,
});

export const fetchPatron = (
  id:string,
  options:PatronAPIOptions = undefined,
):FetchPatronAction => ({
  type: PATRON_ACTION_TYPES.PATRON.FETCH,
  id,
  options,
});

export const patronFetched = (patron:Patron):PatronFetchedAction => ({
  type: PATRON_ACTION_TYPES.PATRON.FETCHED,
  patron,
});

export const createPatron = (
  patron:FormData,
  options:PatronAPIOptions = undefined,
):CreatePatronAction => ({
  type: PATRON_ACTION_TYPES.PATRON.CREATE,
  patron,
  options,
});

export const patronCreated = (patron:Patron):PatronCreatedAction => ({
  type: PATRON_ACTION_TYPES.PATRON.CREATED,
  patron,
  id: patron.id,
});

export const updatePatron = (patron:FormData):UpdatePatronAction => ({
  type: PATRON_ACTION_TYPES.PATRON.UPDATE,
  patron,
  id: patron.get('id') as string|number,
  organizationId: patron.get('organizationId') as string|number,
});

export const patronUpdated = (patron:Patron):PatronUpdatedAction => ({
  type: PATRON_ACTION_TYPES.PATRON.UPDATED,
  patron,
  id: patron.id,
});

export const destroyPatron = (patron:FormData):DestroyPatronAction => ({
  type: PATRON_ACTION_TYPES.PATRON.DESTROY,
  id: patron.get('id') as string|number,
  organizationId: patron.get('organizationId') as string|number,
});

export const patronDestroyed = (id:string):PatronDestroyedAction => ({
  type: PATRON_ACTION_TYPES.PATRON.DESTROYED,
  id,
});

export const clearCurrentPatron = ():ClearCurrentPatronAction => ({
  type: PATRON_ACTION_TYPES.PATRON.CLEAR,
});

export const searchPatrons = (term:string):SearchPatronsAction => ({
  type: PATRON_ACTION_TYPES.PATRONS.SEARCH,
  term,
});

export const patronsSearched = (results:Patron[]):PatronsSearchedAction => ({
  type: PATRON_ACTION_TYPES.PATRONS.SEARCHED,
  results,
});

export const clearPatronSearch = ():ClearPatronSearchAction => ({
  type: PATRON_ACTION_TYPES.PATRONS.CLEAR_SEARCH,
});
