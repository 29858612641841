import MainSearch from '@components/base/search/MainSearch';
import SearchResult from '@components/base/search/SearchResult';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import SingleColumnFlex from '@components/layout/generic/SingleColumnFlex';
import { ROUTES } from '@routes';
import { clearPatronSearch, searchPatrons } from '@store/patrons/patrons/actions';
import { Patron } from '@store/patrons/patrons/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

interface StateProps {
  results:Patron[]
}
interface DispatchProps {
  dispatchSearchPatrons:(term:string)=>void
  dispatchClearPatronSearch:()=>void
}
type SearchPatronsProps = StateProps & DispatchProps;

function SearchPatrons(props:SearchPatronsProps) {
  const { dispatchClearPatronSearch, results, dispatchSearchPatrons } = props;

  React.useEffect(() => {
    dispatchClearPatronSearch();
  }, []);

  return (
    <PageContainer>
      <SingleColumnFlex>
        <TextHeader size="h1">Search our patrons.</TextHeader>
        <MainSearch
          id="di-search-patrons"
          name="di-search-patrons"
          searchFunction={dispatchSearchPatrons}
          searchResultRoute={ROUTES.PATRONS.PATRONS.SEARCH_RESULTS(true)}
        >
          { results.map(
            (result:Patron) => (
              <SearchResult key={result.id} to={ROUTES.PATRONS.PATRONS.DETAILS(result.id)}>
                {result.name}
              </SearchResult>
            ),
          )}
        </MainSearch>
      </SingleColumnFlex>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState) => ({
  results: state.patrons.searchResults,
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  dispatchSearchPatrons: (term:string) => {
    dispatch(searchPatrons(term));
  },
  dispatchClearPatronSearch: () => {
    dispatch(clearPatronSearch());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPatrons);
