import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface TertiaryMenuItemProps {
  children: React.ReactNode
  to: string
  icon: IconDefinition
}

function TertiaryMenuItem(props:TertiaryMenuItemProps) {
  const { children, to, icon } = props;
  return (
    <li className="di-tertiary-menu-item">
      <Link to={to}>
        <FontAwesomeIcon icon={icon} />
        {children}
      </Link>
    </li>
  );
}

export default TertiaryMenuItem;
