import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';

import {
  ClearCurrentTagAction,
  CreateTagAction,
  DestroyTagAction,
  FetchTagAction,
  FetchTagsAction,
  FetchTagsOptions,
  Tag,
  TagCreatedAction,
  TagDestroyedAction,
  TagFetchedAction,
  TagsFetchedAction,
  TagUpdatedAction,
  UpdateTagAction,
} from '@store/catalog/tags/types';

export const fetchTags = (options:FetchTagsOptions = undefined):FetchTagsAction => ({
  type: CATALOG_ACTION_TYPES.TAGS.FETCH,
  options,
});

export const tagsFetched = (response:Tag[]):TagsFetchedAction => ({
  type: CATALOG_ACTION_TYPES.TAGS.FETCHED,
  tags: response,
});

export const fetchTag = (id:number|string):FetchTagAction => ({
  type: CATALOG_ACTION_TYPES.TAG.FETCH,
  id,
});

export const tagFetched = (tag:Tag):TagFetchedAction => ({
  type: CATALOG_ACTION_TYPES.TAG.FETCHED,
  tag,
  id: tag.id,
});

export const createTag = (tag:FormData):CreateTagAction => ({
  type: CATALOG_ACTION_TYPES.TAG.CREATE,
  tag,
});

export const tagCreated = (tag:Tag):TagCreatedAction => ({
  type: CATALOG_ACTION_TYPES.TAG.CREATED,
  tag,
  id: tag.id,
});

export const updateTag = (tag:FormData):UpdateTagAction => ({
  type: CATALOG_ACTION_TYPES.TAG.UPDATE,
  tag,
  id: tag.get('id') as string|number,
});

export const tagUpdated = (tag:Tag):TagUpdatedAction => ({
  type: CATALOG_ACTION_TYPES.TAG.UPDATED,
  tag,
  id: tag.id,
});

export const destroyTag = (tag:FormData):DestroyTagAction => ({
  type: CATALOG_ACTION_TYPES.TAG.DESTROY,
  id: tag.get('id') as string|number,
});

export const tagDestroyed = (id:number|string):TagDestroyedAction => ({
  type: CATALOG_ACTION_TYPES.TAG.DESTROYED,
  id,
});

export const clearCurrentTagAction = ():ClearCurrentTagAction => ({
  type: CATALOG_ACTION_TYPES.TAG.CLEAR,
});
