import * as React from 'react';

interface LabelProps {
  htmlFor:string
  children: React.ReactNode
}
function Label(props:LabelProps) {
  const { htmlFor, children } = props;
  return (
    <div>
      <label className="di-label" htmlFor={htmlFor}>
        { children }
      </label>
    </div>
  );
}

export default Label;
