import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { resetPassword } from '@store/authentication/actions';
import { connect } from 'react-redux';
import * as queryString from 'query-string';
import AnonymousContainer from '@containers/application/AnonymousContainer';
import APIForm from '@containers/application/APIForm';
import { AUTHENTICATION_ACTION_TYPES } from '@store/authentication/actionTypes';
import { Dispatch } from 'redux';
import Label from '@components/base/form-fields/Label';

interface DispatchProps { dispatchResetPassword:(e:React.MouseEvent<HTMLFormElement>) => void }
type ResetPasswordProps = DispatchProps;

function ResetPassword(props:ResetPasswordProps) {
  const { dispatchResetPassword } = props;
  const location = useLocation();
  return (
    <AnonymousContainer>
      <h1>Reset your Password</h1>
      <APIForm onSubmit={dispatchResetPassword} action={AUTHENTICATION_ACTION_TYPES.RESET_PASSWORD} id="di-reset-password-form">
        <Label htmlFor="password">New Password:</Label>
        <input name="password" type="password" />
        <input type="password" name="password_confirmation" id="password_confirmation" />
        <input type="hidden" name="reset_password_token" value={queryString.parse(location.search).reset_password_token} />
        <input type="submit" value="Let's Go" />
      </APIForm>
    </AnonymousContainer>
  );
}

const mapDispatchToProps = (dispatch:Dispatch): DispatchProps => ({
  dispatchResetPassword: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(resetPassword(data));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(ResetPassword);
