import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Bookmark } from '@store/catalog/bookmarks/types';
import * as React from 'react';
import TextHeader from '@components/base/text/TextHeader';

interface BookmarkListItemProps {
  bookmark: Bookmark
}

function BookmarkListItem(props:BookmarkListItemProps) {
  const { bookmark } = props;
  return (
    <li className="di-bookmark-list-item">
      <FontAwesomeIcon icon={faBookmark} />
      <div className="di-bookmark-list-item-content">
        <TextHeader size="h6">{bookmark.name}</TextHeader>
        <p>{bookmark.name}</p>
      </div>
    </li>
  );
}

export default BookmarkListItem;
