import { ROUTES } from '@routes';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthenticatedContainer from '@containers/application/AuthenticatedContainer';
import RecordLeaseRoutes from '@containers/circulation/leases/RecordLeaseRoutes';
import SearchRecordLeases from '@containers/circulation/instances/SearchRecordInstances';
import ShowAllRecordLeases from '@containers/circulation/leases/ShowAllRecordLeases';
import RecordInstanceSearchResults from '@containers/circulation/instances/RecordInstanceSearchResults';
import ShowRecordInstanceDetails from '@containers/circulation/instances/ShowRecordInstanceDetails';
import CreateAHold from './holds/CreateAHold';
import EditAHold from './holds/EditAHold';
import ShowHoldDetails from './holds/ShowHoldDetails';
import ShowAllHolds from './holds/ShowAllHolds';
import CreateAFee from './fees/CreateAFee';
import EditAFee from './fees/EditAFee';
import ShowFeeDetails from './fees/ShowFeeDetails';
import ShowAllFees from './fees/ShowAllFees';
import CreateATransfer from './transfers/CreateATransfer';
import EditATransfer from './transfers/EditATransfer';
import ShowTransferDetails from './transfers/ShowTransferDetails';
import ShowAllTransfers from './transfers/ShowAllTransfers';

function Circulation() {
  return (
    <AuthenticatedContainer section="circulation">
      <Routes>
        <Route path={ROUTES.CIRCULATION.LEASES.ALL()} element={<ShowAllRecordLeases />} />
        <Route
          path={ROUTES.CIRCULATION.RECORD_INSTANCES.SEARCH()}
          element={<RecordInstanceSearchResults />}
        />
        <Route
          path={ROUTES.CIRCULATION.RECORD_INSTANCES.DETAILS()}
          element={<ShowRecordInstanceDetails />}
        />
        <Route path={ROUTES.CIRCULATION.FEES.NEW()} element={<CreateAFee />} />
        <Route path={ROUTES.CIRCULATION.FEES.EDIT()} element={<EditAFee />} />
        <Route path={ROUTES.CIRCULATION.FEES.DETAILS()} element={<ShowFeeDetails />} />
        <Route path={ROUTES.CIRCULATION.FEES.ALL()} element={<ShowAllFees />} />
        <Route path={ROUTES.CIRCULATION.TRANSFERS.NEW()} element={<CreateATransfer />} />
        <Route path={ROUTES.CIRCULATION.TRANSFERS.EDIT()} element={<EditATransfer />} />
        <Route path={ROUTES.CIRCULATION.TRANSFERS.DETAILS()} element={<ShowTransferDetails />} />
        <Route path={ROUTES.CIRCULATION.TRANSFERS.ALL()} element={<ShowAllTransfers />} />
        <Route path={ROUTES.CIRCULATION.HOLDS.NEW()} element={<CreateAHold />} />
        <Route path={ROUTES.CIRCULATION.HOLDS.EDIT()} element={<EditAHold />} />
        <Route path={ROUTES.CIRCULATION.HOLDS.DETAILS()} element={<ShowHoldDetails />} />
        <Route path={ROUTES.CIRCULATION.HOLDS.ALL()} element={<ShowAllHolds />} />
        <Route path={ROUTES.CIRCULATION.INDEX()} element={<RecordLeaseRoutes />} />
        <Route path="/" element={<SearchRecordLeases />} />
      </Routes>
    </AuthenticatedContainer>
  );
}

export default Circulation;
