import * as React from 'react';

interface InputSelectProps {
  children: React.ReactNode
  name: string
  id: string
  required?: boolean
  disabled?: boolean
  onChange?:(e:React.FormEvent<HTMLSelectElement>)=>void
}

function InputSelect(props:InputSelectProps) {
  const {
    name, id, children, required, onChange, disabled,
  } = props;
  return (
    <select
      onChange={onChange}
      className="di-input-select"
      name={name}
      id={id}
      required={required}
      disabled={disabled}
    >
      {children}
    </select>
  );
}

InputSelect.defaultProps = {
  required: false,
  onChange: undefined,
  disabled: false,
};

export default InputSelect;
