import * as React from 'react';
import Button from '../buttons-and-links/Button';

interface StepAccordionStepOpenProps {
  children: React.ReactNode
  nextStep?: ()=>void
  totalSteps?: number
  step?: number
  nextButtonText?: string
  disabled?: boolean
}

function StepAccordionStepOpen(props:StepAccordionStepOpenProps) {
  const {
    children, step, totalSteps, nextButtonText, nextStep, disabled,
  } = props;
  return (
    <div className="di-step-accordion-step-open-container">
      <div className="di-step-accordion-step-open-body">
        { children }
      </div>

      { step < totalSteps
          && (
            <div className="di-step-accordion-step-open-actions">
              <Button color="blue-green" fill="solid" disabled={disabled} onClick={nextStep}>{nextButtonText}</Button>
            </div>
          )}
    </div>
  );
}
StepAccordionStepOpen.defaultProps = {
  nextStep: undefined,
  totalSteps: undefined,
  nextButtonText: 'change',
  step: undefined,
  disabled: true,
};

export default StepAccordionStepOpen;
