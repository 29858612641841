import FilterContainer from '@components/base/list-filters/FilterContainer';
import FilterOption from '@components/base/list-filters/FilterOption';
import MainSearch from '@components/base/search/MainSearch';
import SearchResult from '@components/base/search/SearchResult';
import SERP from '@components/base/serp/SERP';
import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { fetchSearchResults, searchRecords } from '@store/catalog/records/actions';
import { Record } from '@store/catalog/records/types';
import { RootState } from '@store/root';
import APIList from '@containers/application/APIList';
import * as React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';

interface DispatchProps {
  dispatchSearchRecords: (searchTerm:string)=>void,
  dispatchFetchSearchResults: (query:string)=>void
}
interface StateProps {
  results:Record[],
  records: Record[]
}

type RecordLeaseSearchResultsProps =
  DispatchProps & StateProps;

function RecordLeaseSearchResults(props:RecordLeaseSearchResultsProps) {
  const {
    dispatchFetchSearchResults, dispatchSearchRecords, records, results,
  } = props;
  const location = useLocation();
  const [queryParameters] = React.useState(new URLSearchParams(location.search));
  const query = queryParameters.get('q');

  React.useEffect(() => {
    dispatchFetchSearchResults(query);
  }, [location]);

  return (
    <PageContainer>
      <MainSearch
        id="di-search-records"
        name="search"
        searchFunction={dispatchSearchRecords}
        searchResultRoute={ROUTES.CATALOG.RECORDS.SEARCH_RESULTS()}
        defaultValue={queryParameters.get('q')}
      >
        { results.map(
          (result:Record) => (
            <SearchResult
              key={result.id}
              to={ROUTES.CATALOG.RECORDS.DETAILS(result.id)}
            >
              {result.title}
            </SearchResult>
          ),
        )}
      </MainSearch>
      <FilterContainer>
        <FilterOption tabIndex={0} filter="all" icon="all">All</FilterOption>
        <FilterOption tabIndex={0} filter="available" icon="gear">Available</FilterOption>
        <FilterOption tabIndex={0} filter="overdue" icon="gear">Overdue</FilterOption>
      </FilterContainer>
      <APIList action={CATALOG_ACTION_TYPES.RECORDS.FETCH_SEARCH_RESULTS}>
        { records.map(
          (record) => (
            <SERP
              key={record.id}
              record={record}
              to={ROUTES.CATALOG.RECORDS.DETAILS(record.id)}
            />
          ),
        )}
      </APIList>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  results: state.records.searchResults,
  records: state.records.records,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchSearchRecords: (searchTerm:string):void => {
    dispatch(searchRecords(searchTerm));
  },
  dispatchFetchSearchResults: (query:string):void => {
    dispatch(fetchSearchResults(query));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RecordLeaseSearchResults);
