import APIList from '@containers/application/APIList';
import { ROUTES } from '@routes';
import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';
import { fetchAdmins } from '@store/organizations/admins/actions';
import { Admin } from '@store/organizations/admins/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import TableRow from '@components/base/tables/TableRow';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableColumn from '@components/base/tables/TableColumn';
import TableBody from '@components/base/tables/TableBody';
import Button from '@components/base/buttons-and-links/Button';
import { RootState } from '@store/root';

interface StateProps { admins: Admin[] }
interface DispatchProps { dispatchFetchAdmins:(organizationId:number|string)=>void }
type ShowAllAdminsProps = StateProps & DispatchProps;

function ShowAllAdmins(props:ShowAllAdminsProps) {
  const { dispatchFetchAdmins, admins } = props;
  const params = useParams();
  const { organizationId } = params;

  React.useEffect(() => {
    dispatchFetchAdmins(organizationId);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Admins</TextHeader>
      <APIList action={ORGANIZATION_ACTION_TYPES.STAFF_ADMINS.FETCH}>
        <PaginatedTable>
          <TableHeader>
            <TableRow>
              <TableHeaderColumn sortable>
                Name
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            { admins.map((admin:Admin) => (
              <TableRow
                key={admin.id}
                to={
                  ROUTES.ORGANIZATION_MANAGEMENT
                    .ORGANIZATIONS.STAFF_ADMINS.DETAILS(admin.id, organizationId)
                }
              >
                <TableColumn tabIndex={0}>
                  {admin.name}
                </TableColumn>
              </TableRow>
            ))}
          </TableBody>
        </PaginatedTable>
      </APIList>
      <Button color="blue-green" fill="solid" to={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.STAFF_ADMINS.NEW(organizationId)}>Add an Admin</Button>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  admins: state.admins.admins,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchAdmins: (organizationId:number|string):void => {
    dispatch(fetchAdmins({ organizationId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllAdmins);
