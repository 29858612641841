import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import APIList from '@containers/application/APIList';
import { ROUTES } from '@routes';
import { PATRON_ACTION_TYPES } from '@store/patrons/actionTypes';
import { PatronGroup } from '@store/patrons/groups/types';
import * as React from 'react';

interface RequiredOwnProps {
  groups: PatronGroup[]
  currentPage:number
  totalPages:number
  numOfRecords:number
  showHeader: boolean
  selectable: boolean
  sortable: boolean
  actions?: React.ReactElement[]
}

interface OptionalOwnProps {
  getSelected?:(patrons: PatronGroup[])=>void
}

type PatronGroupListProps = OptionalOwnProps & RequiredOwnProps;

function PatronGroupList(props:PatronGroupListProps) {
  const [selected, setSelected] = React.useState([]);
  const {
    groups, getSelected, currentPage, totalPages,
    showHeader, selectable, sortable, actions, numOfRecords,
  } = props;

  React.useEffect(() => {
    getSelected(selected);
  }, [selected]);

  const toggleAll = (e:React.MouseEvent):void => {
    e.stopPropagation();
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected([...groups]);
    }
  };

  const toggleSelected = (selectedPatronGroup:PatronGroup):void => {
    if (selected
      .filter((patronGroup:PatronGroup) => patronGroup.id === selectedPatronGroup.id).length > 0) {
      setSelected(selected.filter(
        (patronGroup:PatronGroup) => patronGroup.id !== selectedPatronGroup.id,
      ));
    } else {
      setSelected([...selected, selectedPatronGroup]);
    }
  };

  return (
    <APIList action={PATRON_ACTION_TYPES.PATRON_GROUPS.FETCH}>
      <PaginatedTable
        showHeader={showHeader}
        empty={groups.length === 0}
        currentPage={currentPage}
        totalPages={totalPages}
        title="Groups"
        numOfRecordsString={`${numOfRecords?.toLocaleString()} Groups`}
        actions={actions}
      >
        <TableHeader>
          <TableRow>
            { selectable && (
              <TableHeaderColumn
                onClick={toggleAll}
                sortable={false}
                resizable={false}
              >
                <input
                  readOnly
                  type="checkbox"
                  checked={selected.length === groups.length && groups.length > 0}
                />
              </TableHeaderColumn>
            ) }
            <TableHeaderColumn sortable={sortable}>
              ID
            </TableHeaderColumn>
            <TableHeaderColumn sortable={sortable}>
              Title
            </TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          { groups.map((group:PatronGroup) => (
            <TableRow key={group.id} to={ROUTES.PATRONS.PATRON_GROUPS.DETAILS(group.id)}>
              { selectable && (
              <TableColumn
                tabIndex={0}
                resizable={false}
              >
                <input
                  readOnly
                  onClick={(e:React.MouseEvent) => {
                    e.stopPropagation();
                    toggleSelected(group);
                  }}
                  type="checkbox"
                  checked={selected.filter(
                    (selectedPatron:PatronGroup) => selectedPatron.id === group.id,
                  ).length > 0}
                />
              </TableColumn>
              ) }
              <TableColumn tabIndex={0}>
                {group.id}
              </TableColumn>
              <TableColumn tabIndex={0}>
                {group.title}
              </TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
    </APIList>
  );
}

PatronGroupList.defaultProps = {
  getSelected: undefined,
  actions: [],
};

export default PatronGroupList;
