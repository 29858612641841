import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import {
  Author,
  AuthorCreatedAction,
  AuthorFetchedAction,
  AuthorsSearchedAction,
  CreateAuthorAction,
  FetchAuthorAction,
  SearchAuthorsAction,
} from '@store/catalog/authors/types';

export function fetchAuthor(id:number|string):FetchAuthorAction {
  return {
    type: CATALOG_ACTION_TYPES.AUTHOR.FETCH,
    id,
  };
}

export function authorFetched(author:Author):AuthorFetchedAction {
  return {
    type: CATALOG_ACTION_TYPES.AUTHOR.FETCHED,
    author,
    id: author.id,
  };
}

export function createAuthor(author:Author):CreateAuthorAction {
  return {
    type: CATALOG_ACTION_TYPES.AUTHOR.CREATE,
    author,
  };
}

export function authorCreated(author:Author):AuthorCreatedAction {
  return {
    type: CATALOG_ACTION_TYPES.AUTHOR.CREATED,
    author,
  };
}

export function searchAuthors(term:string):SearchAuthorsAction {
  return {
    type: CATALOG_ACTION_TYPES.AUTHORS.SEARCH,
    term,
  };
}

export function authorsSearched(results:Author[]):AuthorsSearchedAction {
  return {
    type: CATALOG_ACTION_TYPES.AUTHORS.SEARCHED,
    results,
  };
}
