import { faStar, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Rating from 'react-rating';

interface StarRatingProps {
  rating: number
  onRate: (value:number)=>void
}

function StarRating(props:StarRatingProps) {
  const { rating, onRate } = props;
  return (
    <Rating
      className="di-star-rating"
      initialRating={rating}
      onChange={onRate}
      readonly={onRate === undefined}
      emptySymbol={<FontAwesomeIcon icon={emptyStar as IconDefinition} />}
      fullSymbol={<FontAwesomeIcon icon={faStar} />}
    />
  );
}

export default StarRating;
