import * as React from 'react';

interface TertiaryMenuProps {
  children: React.ReactNode
}
function TertiaryMenu(props:TertiaryMenuProps) {
  const { children } = props;
  return (
    <nav className="di-tertiary-menu">
      <ul>
        { children }
      </ul>
    </nav>
  );
}

export default TertiaryMenu;
