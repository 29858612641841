import { ROUTES } from '@routes';
import { fetchSupplier } from '@store/procurement/suppliers/actions';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams, Routes, Route } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Supplier } from '@store/procurement/suppliers/types';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import Button from '@components/base/buttons-and-links/Button';
import { Purchase } from '@store/procurement/purchases/types';
import { Contact } from '@store/procurement/contacts/types';
import PageAccordion from '@components/compound/accordions/PageAccordion';
import { fetchContacts } from '@store/procurement/contacts/actions';
import { fetchPurchases } from '@store/procurement/purchases/actions';
import { Contract } from '@store/procurement/contracts/types';
import { fetchContracts } from '@store/procurement/contracts/actions';
import PurchaseList from '../purchases/shared/PurchaseList';
import ContactList from '../contacts/shared/ContactList';
import ContractList from '../contracts/shared/ContractList';
import DestroyContract from '../contracts/DestroyContract';

interface StateProps {
  supplier:Supplier
  purchases:Purchase[]
  contacts:Contact[]
  contracts:Contract[]
}
interface DispatchProps {
  dispatchFetchSupplier:(id:number|string)=>void
  dispatchFetchPurchases:(id:number|string)=>void
  dispatchFetchContacts: (id:number|string)=>void
  dispatchFetchContracts: (id:number|string)=>void
}
type ShowSupplierDetailsProps = StateProps & DispatchProps;
function ShowSupplierDetails(props:ShowSupplierDetailsProps) {
  const {
    dispatchFetchSupplier, dispatchFetchPurchases,
    dispatchFetchContacts, supplier, purchases, contacts,
    contracts, dispatchFetchContracts,
  } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);
  React.useEffect(() => {
    dispatchFetchSupplier(id);
    dispatchFetchPurchases(id);
    dispatchFetchContacts(id);
    dispatchFetchContracts(id);
  }, []);
  return (
    <PageContainer>
      <TextHeader size="h1">{supplier.name}</TextHeader>
      <Button color="blue-green" fill="solid" to={ROUTES.PROCUREMENT.SUPPLIERS.EDIT(supplier.id)}>Edit</Button>
      <PageAccordion defaultOpen title="Purchases" tabIndex={0}>
        <PurchaseList
          purchases={purchases}
          sortable={false}
          selectable={false}
          currentPage={undefined}
          totalPages={undefined}
          getSelected={undefined}
          filterable={false}
        />
      </PageAccordion>
      <PageAccordion defaultOpen title="Contacts" tabIndex={0}>
        <ContactList
          contacts={contacts}
          sortable={false}
          selectable={false}
          currentPage={undefined}
          totalPages={undefined}
          getSelected={undefined}
          filterable={false}
        />
      </PageAccordion>
      <PageAccordion defaultOpen={false} title="Contracts" tabIndex={0}>
        <ContractList
          contracts={contracts}
          sortable={false}
          selectable={false}
          currentPage={undefined}
          totalPages={undefined}
          getSelected={undefined}
          filterable={false}
        />
      </PageAccordion>
      <Routes>
        <Route
          path={ROUTES.PROCUREMENT.SUPPLIERS.CONTRACTS.DESTROY()}
          element={<DestroyContract />}
        />
      </Routes>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState) => ({
  supplier: state.suppliers.currentSupplier || {},
  purchases: state.purchases.purchases,
  contacts: state.contacts.contacts,
  contracts: state.contracts.contracts,
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  dispatchFetchSupplier: (id:number|string) => {
    dispatch(fetchSupplier(id));
  },
  dispatchFetchPurchases: (id:number|string) => {
    dispatch(fetchPurchases({ supplierId: id }));
  },
  dispatchFetchContacts: (id:number|string) => {
    dispatch(fetchContacts({ supplierId: id }));
  },
  dispatchFetchContracts: (id:number|string) => {
    dispatch(fetchContracts({ supplierId: id }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowSupplierDetails);
