import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { createRecordImage } from '@store/catalog/recordImages/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import Modal from '@components/compound/modals/Modal';

interface DispatchProps {
  dispatchCreateRecordImage: (e:React.MouseEvent<HTMLFormElement>) => void
}
type CreateARecordImageProps = DispatchProps;

function CreateARecordImage(props:CreateARecordImageProps) {
  const { dispatchCreateRecordImage } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);
  return (
    <Modal exitPath={ROUTES.CATALOG.RECORDS.DETAILS(id)}>
      <HTMLLink color="blue-green" to={ROUTES.CATALOG.RECORDS.DETAILS(id)}>Cancel</HTMLLink>
      Add a RecordImage
      <APIForm
        onSubmit={dispatchCreateRecordImage}
        action={CATALOG_ACTION_TYPES.RECORD_IMAGE.CREATE}
        id="di-create-password-form"
        onSuccessRoute={ROUTES.CATALOG.RECORDS.DETAILS(id)}
        onSuccessAction={CATALOG_ACTION_TYPES.RECORD_IMAGE.CREATED}
      >
        <input type="hidden" name="recordId" value={id} />
        <input type="text" />
        <input type="submit" value="Submit" />
      </APIForm>
      <HTMLLink color="blue-green" to={ROUTES.CATALOG.RECORDS.DETAILS(id)}>Cancel</HTMLLink>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreateRecordImage: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createRecordImage(data));
  },
});

export default connect(null, mapDispatchToProps)(CreateARecordImage);
