import * as React from 'react';
import { Link } from 'react-router-dom';

interface SecondaryMenuItemProps {
  to: string
  selected?: boolean
  label: string
}

function SecondaryMenuItem(props:SecondaryMenuItemProps) {
  const { selected, to, label } = props;
  return (
    <li className="di-admin-menu-item">
      <Link className={selected ? 'selected' : ''} to={to}>{label}</Link>
    </li>
  );
}

SecondaryMenuItem.defaultProps = {
  selected: false,
};

export default SecondaryMenuItem;
