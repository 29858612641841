import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';

import {
  CreateAdminAction,
  DestroyAdminAction,
  FetchAdminAction,
  FetchAdminsAction,
  Admin,
  AdminCreatedAction,
  AdminDestroyedAction,
  AdminFetchedAction,
  AdminsFetchedAction,
  AdminUpdatedAction,
  UpdateAdminAction,
  FetchAdminsOptions,
} from '@store/organizations/admins/types';

export const fetchAdmins = (options:FetchAdminsOptions):FetchAdminsAction => ({
  type: ORGANIZATION_ACTION_TYPES.STAFF_ADMINS.FETCH,
  options,
});

export const adminsFetched = (response:Admin[]):AdminsFetchedAction => ({
  type: ORGANIZATION_ACTION_TYPES.STAFF_ADMINS.FETCHED,
  admins: response,
});

export const fetchAdmin = (id:number|string, options:FetchAdminsOptions):FetchAdminAction => ({
  type: ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.FETCH,
  options,
  id,
});

export const adminFetched = (admin:Admin):AdminFetchedAction => ({
  type: ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.FETCHED,
  admin,
});

export const createAdmin = (admin:FormData):CreateAdminAction => ({
  type: ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.CREATE,
  admin,
});

export const adminCreated = (admin:Admin):AdminCreatedAction => ({
  type: ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.CREATED,
  admin,
  id: admin.id,
});

export const updateAdmin = (admin:FormData):UpdateAdminAction => ({
  type: ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.UPDATE,
  admin,
  id: admin.get('id') as string|number,
});

export const adminUpdated = (admin:Admin):AdminUpdatedAction => ({
  type: ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.UPDATED,
  admin,
  id: admin.id,
});

export const destroyAdmin = (admin:FormData):DestroyAdminAction => ({
  type: ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.DESTROY,
  id: admin.get('id') as string|number,
  organizationId: admin.get('organizationId') as string|number,
});

export const adminDestroyed = (id:number|string):AdminDestroyedAction => ({
  type: ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.DESTROYED,
  id,
});
