import { ROUTES } from '@routes';
import * as React from 'react';
import SecondaryMenu from '@components/compound/menus/SecondaryMenu';
import SecondaryMenuItem from '@components/base/menu-items/SecondaryMenuItem';
import { t } from 'i18next';

function CatalogMenu() {
  return (
    <SecondaryMenu>
      <SecondaryMenuItem to={ROUTES.CATALOG.INDEX(true)} label={t('menu.catalog.home')} />
      <SecondaryMenuItem to={ROUTES.CATALOG.RECORDS.ALL(true)} label={t('menu.catalog.records')} />
      <SecondaryMenuItem to={ROUTES.CATALOG.LISTS.ALL(true)} label={t('menu.catalog.lists')} />
    </SecondaryMenu>
  );
}

export default CatalogMenu;
