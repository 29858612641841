import Button from '@components/base/buttons-and-links/Button';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import { fetchAdmin } from '@store/organizations/admins/actions';
import { Admin } from '@store/organizations/admins/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

interface StateProps { admin:Admin }
interface DispatchProps {
  dispatchFetchAdmin:(id:number|string, organizationId:number|string)=>void
}
type ShowAdminDetailsProps = StateProps & DispatchProps;

function ShowAdminDetails(props: ShowAdminDetailsProps) {
  const { dispatchFetchAdmin, admin } = props;

  const params = useParams();
  const { id, organizationId } = params;

  React.useEffect(() => {
    dispatchFetchAdmin(id, organizationId);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">{admin.name}</TextHeader>
      <Button color="blue-green" fill="solid" to={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.STAFF_ADMINS.EDIT(id, organizationId)}>Edit</Button>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  admin: state.admins.currentAdmin || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchAdmin: (id:string|number, organizationId:number|string):void => {
    dispatch(fetchAdmin(id, { organizationId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAdminDetails);
