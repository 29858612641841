import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Bookmark } from '@store/catalog/bookmarks/types';
import * as React from 'react';
import BookmarkListItem from '@components/base/list-items/BookmarkListItem';

interface NotificatiosMenuItemProps {
  bookmarks: Bookmark[]
  fetchBookmarks: ()=>void
}

function BookmarksMenuItem(props:NotificatiosMenuItemProps) {
  const { bookmarks, fetchBookmarks } = props;
  return (
    <div onMouseEnter={fetchBookmarks} className="di-bookmarks-menu-item">
      <span>
        <FontAwesomeIcon icon={faBookmark} />
      </span>
      <div className="di-bookmarks-list">
        <ul>
          {bookmarks.map(
            (bookmark:Bookmark) => <BookmarkListItem key={bookmark.id} bookmark={bookmark} />,
          )}
        </ul>
      </div>
    </div>
  );
}

export default BookmarksMenuItem;
