import { ROUTES } from '@routes';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface OrganizationMenuProps { organizationId:number }

function OrganizationMenu(props:OrganizationMenuProps) {
  const { organizationId } = props;
  return (
    <div>
      <ul>
        <li>
          <Link to={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.INDEX(true)}>
            General Information
          </Link>
        </li>
        <li>
          <Link to={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.BRANCHES.ALL(organizationId)}>
            Branches
          </Link>
        </li>
        <li>
          <Link to={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.STAFF_ADMINS.ALL(organizationId)}>
            Staff
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default OrganizationMenu;
