import InputText from '@components/base/form-fields/InputText';
import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import TextHeader from '@components/base/text/TextHeader';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import { fetchRecordInstance } from '@store/catalog/recordInstances/actions';
import { RecordInstance } from '@store/catalog/recordInstances/types';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { RecordLease } from '@store/circulation/recordLeases/types';
import { fetchPurchaseByRecordInstance } from '@store/procurement/purchases/actions';
import { Purchase } from '@store/procurement/purchases/types';
import { RootState } from '@store/root';
import APIList from '@containers/application/APIList';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { fetchRecordLeases } from '@store/circulation/recordLeases/actions';

interface StateProps {
  recordInstance:RecordInstance,
  leases: RecordLease[]
  loading: boolean
  purchase:Purchase
}
interface DispatchProps {
  dispatchFetchRecordInstance:(id:number|string)=>void
  fetchLeases:(id:number|string)=>void
  fetchPurchase:(id:number|string)=>void
}

type ShowRecordInstanceDetailsProps = StateProps & DispatchProps;

function ShowRecordInstanceDetails(props:ShowRecordInstanceDetailsProps) {
  const {
    dispatchFetchRecordInstance, fetchLeases, fetchPurchase,
    loading, recordInstance, leases, purchase,
  } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchRecordInstance(id);
    fetchLeases(id);
    fetchPurchase(id);
  }, []);

  return (
    <PageContainer loading={loading}>
      <TextHeader size="h1">{recordInstance.record.title}</TextHeader>
      <div>
        <InputText type="text" name="patron-search" id="patron-search" />
      </div>

      <TextHeader size="h2">Lease History</TextHeader>

      <APIList action={CIRCULATION_ACTION_TYPES.LEASES.FETCH}>
        <PaginatedTable empty={leases.length === 0}>
          <TableHeader>
            <TableRow>
              <TableHeaderColumn attribute="leaseStartDate" sortable={false}>
                From
              </TableHeaderColumn>
              <TableHeaderColumn attribute="leaseEndDate" sortable={false}>
                To
              </TableHeaderColumn>
              <TableHeaderColumn attribute="title" sortable={false}>
                Item
              </TableHeaderColumn>
              <TableHeaderColumn attribute="patron" sortable={false}>
                Patron
              </TableHeaderColumn>
              <TableHeaderColumn attribute="status" sortable={false}>
                Status
              </TableHeaderColumn>
              <TableHeaderColumn attribute="amountDue" sortable={false}>
                Amount Due
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            { leases.map((recordLease:RecordLease) => (
              <TableRow
                key={recordLease.id}
                to={ROUTES.CIRCULATION.RECORD_INSTANCES.DETAILS(recordLease.recordInstance.id)}
              >
                <TableColumn tabIndex={0}>
                  {new Date(recordLease.leaseStartDate).toDateString() }
                </TableColumn>
                <TableColumn tabIndex={0}>
                  { new Date(recordLease.leaseEndDate).toDateString()}
                </TableColumn>
                <TableColumn tabIndex={0}>
                  {recordLease.recordInstance?.record.title}
                </TableColumn>
                <TableColumn tabIndex={0}>
                  {recordLease.patron.name}
                </TableColumn>
                <TableColumn tabIndex={0}>
                  {recordLease.status}
                </TableColumn>
                <TableColumn tabIndex={0}>
                  $
                  {recordLease.amountDue}
                </TableColumn>
              </TableRow>
            ))}
          </TableBody>
        </PaginatedTable>
      </APIList>
      <TextHeader size="h2">Purchase Information</TextHeader>
      {purchase.supplier?.name}
      {purchase.purchaseDate}
      <Link
        to={ROUTES.CATALOG.RECORDS.RECORD_INSTANCES.EDIT(
          parseInt(params.recordId, 10),
          recordInstance.id,
        )}
      >
        Edit
      </Link>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  recordInstance: state.recordInstances.currentRecordInstance || {},
  leases: state.recordLeases.recordLeases,
  loading: state.recordInstances.currentRecordInstance === undefined,
  purchase: state.purchases.currentPurchase || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchRecordInstance: (id:number|string) => {
    dispatch(fetchRecordInstance(id));
  },
  fetchLeases: (id:number|string):void => {
    dispatch(fetchRecordLeases({ recordInstanceId: id }));
  },
  fetchPurchase: (id:number|string):void => {
    dispatch(fetchPurchaseByRecordInstance(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowRecordInstanceDetails);
