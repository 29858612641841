import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import * as React from 'react';

function Billing() {
  return (
    <PageContainer>
      <TextHeader size="h1">Billing</TextHeader>
    </PageContainer>
  );
}

export default Billing;
