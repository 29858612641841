import Button from '@components/base/buttons-and-links/Button';
import InlineSearch from '@components/base/search/InlineSearch';
import InlineSearchResult from '@components/base/search/InlineSearchResult';
import Modal from '@components/compound/modals/Modal';
import ModalFooter from '@components/compound/modals/ModalFooter';
import PageContainer from '@components/layout/generic/PageContainer';
import Section from '@components/layout/generic/Section';
import ItemDetailsHeader from '@components/layout/ItemDetailsHeader';
import { ROUTES } from '@routes';
import { createGroupMemberships, fetchPatronGroup } from '@store/patrons/groups/actions';
import { PatronGroup } from '@store/patrons/groups/types';
import { fetchPatrons, searchPatrons } from '@store/patrons/patrons/actions';
import { Patron } from '@store/patrons/patrons/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import PatronList from '../patrons/shared/PatronList';

interface StateProps {
  group:PatronGroup
  patrons:Patron[]
  searchResults:Patron[]
}
interface DispatchProps {
  dispatchFetchPatronGroup:(id:number|string)=>void
  dispatchFetchPatrons:(groupId:number|string)=>void
  dispatchSearchPatrons: (term:string)=>void
  dispatchCreateGroupMemberships: (groupId:number|string, patrons:Patron[])=>void
}
type ShowPatronGroupDetailsProps = StateProps & DispatchProps;
function ShowPatronGroupDetails(props:ShowPatronGroupDetailsProps) {
  const {
    dispatchFetchPatronGroup, dispatchFetchPatrons,
    dispatchSearchPatrons, dispatchCreateGroupMemberships,
    group, patrons, searchResults,
  } = props;
  const params = useParams();

  const [showAddUsersModal, setShowAddUsersModal] = React.useState(false);
  const [patronsToAdd, setUsersToAdd] = React.useState([] as Patron[]);

  React.useEffect(() => {
    dispatchFetchPatronGroup(params.id);
    dispatchFetchPatrons(params.id);
  }, []);

  const actions = [
    <Button key={0} color="blue-green" fill="solid" to={ROUTES.PATRONS.PATRON_GROUPS.EDIT(group.id)}>Edit</Button>,
    <Button key={1} color="blue-green" fill="solid" onClick={() => setShowAddUsersModal(true)}>Add Users</Button>,
  ];

  return (
    <PageContainer>
      <ItemDetailsHeader
        title={group.title}
        actions={actions}
      />
      <PatronList
        patrons={patrons}
        currentPage={undefined}
        totalPages={undefined}
        numOfRecords={undefined}
        showHeader={false}
        selectable={false}
        sortable={false}
      />
      <Modal
        exitClick={() => setShowAddUsersModal(false)}
        title="Add to group?"
        style={showAddUsersModal ? {} : { display: 'none' }}
      >
        <Section>
          <InlineSearch
            id="di-search-patrons"
            name="di-search-patrons"
            searchFunction={dispatchSearchPatrons}
          >
            { searchResults.map((patron:Patron) => (
              <InlineSearchResult
                key={patron.id}
                onSelect={() => { setUsersToAdd([...patronsToAdd, patron]); }}
              >
                {patron.name}
              </InlineSearchResult>
            ))}
          </InlineSearch>
        </Section>
        <Section>
          <ul>
            { patronsToAdd.map((patron:Patron) => (
              <li key={patron.id}>
                {patron.name}
              </li>
            ))}
          </ul>
        </Section>
        <ModalFooter>
          <Button
            fill="solid"
            color="blue-green"
            onClick={() => {
              dispatchCreateGroupMemberships(params.id, patronsToAdd);
              setShowAddUsersModal(false);
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  group: state.groups.currentPatronGroup || {},
  patrons: state.patrons.patrons,
  searchResults: state.patrons.searchResults,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchPatronGroup: (id:number|string):void => {
    dispatch(fetchPatronGroup(id));
  },
  dispatchFetchPatrons: (groupId:number|string):void => {
    dispatch(fetchPatrons({ groupId }));
  },
  dispatchSearchPatrons: (term:string):void => {
    dispatch(searchPatrons(term));
  },
  dispatchCreateGroupMemberships: (groupId:number|string, patrons:Patron[]):void => {
    dispatch(createGroupMemberships(groupId, patrons));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowPatronGroupDetails);
