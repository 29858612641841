import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { Observable } from 'rxjs';
import {
  CreateLocationAction,
  DestroyLocationAction,
  FetchLocationAction,
  FetchLocationsAction,
  UpdateLocationAction,
} from '@store/circulation/locations/types';
import {
  locationCreated,
  locationDestroyed,
  locationFetched,
  locationsFetched,
  locationUpdated,
} from '@store/circulation/locations/actions';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchLocationsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.LOCATIONS.FETCH),
  switchMap((action:FetchLocationsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/locations/`,
    method: 'GET',
    success: locationsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchLocationEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.LOCATION.FETCH),
  switchMap((action:FetchLocationAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/locations/${action.id}/`,
    method: 'GET',
    success: locationFetched,
    auth: true,
    state$,
    action,
  })),
);

const createLocationEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.LOCATION.CREATE),
  switchMap((action:CreateLocationAction):any => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/locations/`,
    method: 'POST',
    success: locationCreated,
    auth: true,
    state$,
    action,
    body: action.location,
  })),
);

const updateLocationEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.LOCATION.UPDATE),
  switchMap((action:UpdateLocationAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/locations/${action.id}/`,
    method: 'PUT',
    success: locationUpdated,
    auth: true,
    state$,
    action,
    body: action.location,
  })),
);

const destroyLocationEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.LOCATION.DESTROY),
  switchMap((action:DestroyLocationAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/locations/${action.id}/`,
    method: 'DELETE',
    success: locationDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchLocationsEpic,
  fetchLocationEpic,
  createLocationEpic,
  updateLocationEpic,
  destroyLocationEpic,
];
