import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { createRecordInstance } from '@store/catalog/recordInstances/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import Modal from '@components/compound/modals/Modal';

interface DispatchProps {
  dispatchCreateRecordInstance:(e:React.MouseEvent<HTMLFormElement>) => void
}
type CreateARecordInstanceProps = DispatchProps;

function CreateARecordInstance(props:CreateARecordInstanceProps) {
  const { dispatchCreateRecordInstance } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);
  return (
    <Modal exitPath={ROUTES.CATALOG.RECORDS.DETAILS(id)}>
      Add a Record Instance
      <APIForm
        onSubmit={dispatchCreateRecordInstance}
        action={CATALOG_ACTION_TYPES.RECORD_INSTANCE.CREATE}
        id="di-create-password-form"
        onSuccessRoute={ROUTES.CATALOG.RECORDS.DETAILS(id)}
        onSuccessAction={CATALOG_ACTION_TYPES.RECORD_INSTANCE.CREATED}
      >
        <input type="hidden" name="recordId" value={id} />
        <input type="text" />
        <input type="submit" value="Submit" />
      </APIForm>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreateRecordInstance: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createRecordInstance(data));
  },
});

export default connect(null, mapDispatchToProps)(CreateARecordInstance);
