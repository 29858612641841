import AuthenticatedContainer from 'containers/application/AuthenticatedContainer';
import { ROUTES } from '@routes';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import AddAPurchase from '@containers/procurement/purchases/CreateAPurchase';
import AddASupplier from '@containers/procurement/suppliers/AddASupplier';
import ProcurementsOverview from '@containers/procurement/ProcurementsOverview';
import Purchases from '@containers/procurement/purchases/ShowAllPurchases';
import Suppliers from '@containers/procurement/suppliers/ShowAllSuppliers';
import ShowPurchaseDetails from '@containers/procurement/purchases/ShowPurchaseDetails';
import Supplier from '@containers/procurement/suppliers/ShowSupplierDetails';
import EditASupplier from '@containers/procurement/suppliers/EditASupplier';
import EditAPurchase from '@containers/procurement/purchases/EditAPurchase';
import ShowFundDetails from '@containers/procurement/funds/ShowFundDetails';
import CreateAFund from '@containers/procurement/funds/CreateAFund';
import EditAFund from '@containers/procurement/funds/EditAFund';
import ShowAllFunds from '@containers/procurement/funds/ShowAllFunds';
import ShowBudgetDetails from './budgets/ShowBudgetDetails';
import EditABudget from './budgets/EditABudget';
import ShowAllBudgets from './budgets/ShowAllBudgets';
import CreateABudget from './budgets/CreateABudget';

function Procurements() {
  return (
    <AuthenticatedContainer section="procurement">
      <Routes>
        <Route path={ROUTES.PROCUREMENT.PURCHASES.ALL()} element={<Purchases />} />
        <Route path={ROUTES.PROCUREMENT.PURCHASES.NEW()} element={<AddAPurchase />} />
        <Route path={ROUTES.PROCUREMENT.PURCHASES.DETAILS()} element={<ShowPurchaseDetails />} />
        <Route path={ROUTES.PROCUREMENT.PURCHASES.EDIT()} element={<EditAPurchase />} />
        <Route path={ROUTES.PROCUREMENT.SUPPLIERS.NEW()} element={<AddASupplier />} />
        <Route path={ROUTES.PROCUREMENT.SUPPLIERS.DETAILS()} element={<Supplier />} />
        <Route path={ROUTES.PROCUREMENT.SUPPLIERS.EDIT()} element={<EditASupplier />} />
        <Route path={ROUTES.PROCUREMENT.SUPPLIERS.ALL()} element={<Suppliers />} />
        <Route path={ROUTES.PROCUREMENT.FUNDS.NEW()} element={<CreateAFund />} />
        <Route path={ROUTES.PROCUREMENT.FUNDS.DETAILS()} element={<ShowFundDetails />} />
        <Route path={ROUTES.PROCUREMENT.FUNDS.EDIT()} element={<EditAFund />} />
        <Route path={ROUTES.PROCUREMENT.FUNDS.ALL()} element={<ShowAllFunds />} />
        <Route path={ROUTES.PROCUREMENT.BUDGETS.DETAILS()} element={<ShowBudgetDetails />} />
        <Route path={ROUTES.PROCUREMENT.BUDGETS.EDIT()} element={<EditABudget />} />
        <Route path={ROUTES.PROCUREMENT.BUDGETS.ALL()} element={<ShowAllBudgets />} />
        <Route path={ROUTES.PROCUREMENT.BUDGETS.NEW()} element={<CreateABudget />} />
        <Route path={ROUTES.PROCUREMENT.INDEX()} element={<ProcurementsOverview />} />
      </Routes>
    </AuthenticatedContainer>
  );
}

export default Procurements;
