import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';
import { destroyBranch, fetchBranch, updateBranch } from '@store/organizations/branches/actions';
import { Branch } from '@store/organizations/branches/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import { RootState } from '@store/root';

interface StateProps {
  branch:Branch
}
interface DispatchProps {
  dispatchFetchBranch:(id:number|string, organizationId:number|string)=>void
  dispatchUpdateBranch:(e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchDestroyBranch:(e:React.MouseEvent<HTMLFormElement>)=>void
}

type EditAnBranchProps = StateProps & DispatchProps;

function EditAnBranch(props:EditAnBranchProps) {
  const {
    dispatchFetchBranch, dispatchUpdateBranch, branch, dispatchDestroyBranch,
  } = props;

  const params = useParams();
  const { id, organizationId } = params;

  React.useEffect(() => {
    dispatchFetchBranch(id, organizationId);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Edit A Branch</TextHeader>
      <APIForm
        id="di-edit-a-branch"
        onSubmit={dispatchUpdateBranch}
        action={ORGANIZATION_ACTION_TYPES.BRANCH.UPDATE}
        onSuccessRoute={
          ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.BRANCHES.DETAILS(branch.id, organizationId)
        }
        onSuccessAction={ORGANIZATION_ACTION_TYPES.BRANCH.UPDATED}
        entityID={branch.id}
      >
        <input type="hidden" name="id" defaultValue={branch.id} />
        <input type="hidden" name="organizationId" defaultValue={organizationId} />
        <InputText id="di-branch-title" type="text" name="title" defaultValue={branch.title} />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>

      <APIForm
        id="di-destroy-a-branch"
        onSubmit={dispatchDestroyBranch}
        action={ORGANIZATION_ACTION_TYPES.BRANCH.DESTROY}
        onSuccessRoute={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.BRANCHES.ALL(organizationId)}
        onSuccessAction={ORGANIZATION_ACTION_TYPES.BRANCH.DESTROYED}
        entityID={branch.id}
      >
        <input type="hidden" name="id" defaultValue={branch.id} />
        <input type="hidden" name="organizationId" defaultValue={organizationId} />
        <SubmitButton color="blue-green" fill="outline" value="Delete" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  branch: state.branches.currentBranch || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchBranch: (id:number|string, organizationId:number|string):void => {
    dispatch(fetchBranch(id, { organizationId }));
  },
  dispatchUpdateBranch: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updateBranch(data));
  },
  dispatchDestroyBranch: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyBranch(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAnBranch);
