import MainSearch from '@components/base/search/MainSearch';
import SearchResult from '@components/base/search/SearchResult';
import PageContainer from '@components/layout/generic/PageContainer';
import MainSearchContainer from '@components/layout/search/MainSearchContainer';
import { ROUTES } from '@routes';
import { clearRecordSearch, searchRecords } from '@store/catalog/records/actions';
import { Record } from '@store/catalog/records/types';
import { Organization } from '@store/organizations/organizations/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Dispatch } from 'redux';

interface StateProps {
  results:Record[]
  loading:boolean
  organizations: Organization[]
}
interface DispatchProps {
  dispatchSearchRecords:(term: string)=>void
  dispatchClearRecordSearch:()=>void
}
type SearchRecordsProps = StateProps & DispatchProps;

function SearchRecords(props:SearchRecordsProps) {
  const {
    dispatchClearRecordSearch, dispatchSearchRecords, results, loading, organizations,
  } = props;
  const { t } = useTranslation();
  React.useEffect(() => {
    dispatchClearRecordSearch();
  }, []);

  if (organizations.length === 0 && !loading) {
    return <Navigate replace to={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.NEW(true)} />;
  }

  return (
    <PageContainer>
      <MainSearchContainer header={t('catalog.records.search.title')} subheader="Search all records across all branches and organizations.">
        <MainSearch
          id="di-search-records"
          name="search"
          searchFunction={dispatchSearchRecords}
          searchResultRoute={ROUTES.CATALOG.RECORDS.SEARCH_RESULTS(true)}
        >
          { results.map(
            (result:Record) => (
              <SearchResult key={result.id} to={ROUTES.CATALOG.RECORDS.DETAILS(result.id)}>
                {result.title}
              </SearchResult>
            ),
          )}
        </MainSearch>
      </MainSearchContainer>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState) => ({
  results: state.records.searchResults,
  organizations: state.organizations.organizations,
  loading: state.organizations.loading,
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  dispatchSearchRecords: (searchTerm:string) => {
    dispatch(searchRecords(searchTerm));
  },
  dispatchClearRecordSearch: () => {
    dispatch(clearRecordSearch());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchRecords);
