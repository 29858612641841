import * as React from 'react';
import {
  BrowserRouter, Route, Routes, Navigate,
} from 'react-router-dom';
import { connect } from 'react-redux';

import Patrons from '@containers/patrons/Patrons';
import Catalog from '@containers/catalog/Catalog';
import Procurements from '@containers/procurement/Procurements';
import Circulation from '@containers/circulation/Circulation';
import OrganizationManagement from '@containers/organizations/OrganizationManagement';
import { Dispatch } from 'redux';
import Login from '@containers/pre-authentication/Login';
import Signup from '@containers/pre-authentication/Signup';
import { loadSession } from '@store/authentication/actions';
import ForgotPassword from '@containers/pre-authentication/forgot-password/ForgotPassword';
import ResetPassword from '@containers/pre-authentication/ResetPassword';
import { ROUTES } from '@routes';
import Profile from '@containers/profile/Profile';
import NoRoute from '@containers/errors/404';
import CreateAnOrganization from '@containers/organizations/organizations/CreateAnOrganization';
import ConfirmEmail from '@containers/pre-authentication/confirm-email/ConfirmEmail';
import { RootState } from '@store/root';

interface DispatchProps { dispatchLoadSession:()=>void }
interface StateProps {
  loading: boolean
}
type ApplicationProps = DispatchProps & StateProps;

function Disembark(props:ApplicationProps) {
  const { dispatchLoadSession, loading } = props;

  React.useEffect(() => {
    dispatchLoadSession();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        { !loading
        && (
          <>

            <Route path={ROUTES.AUTHENTICATION.SIGNUP} element={<Signup />} />
            <Route path={ROUTES.AUTHENTICATION.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={ROUTES.AUTHENTICATION.RESET_PASSWORD} element={<ResetPassword />} />
            <Route
              path={ROUTES.DASHBOARD}
              element={<Navigate replace to={ROUTES.CATALOG.INDEX()} />}
            />
            <Route path={ROUTES.PROFILE.INDEX()} element={<Profile />} />
            <Route path={ROUTES.CATALOG.BASE} element={<Catalog />} />
            <Route path={ROUTES.PATRONS.BASE} element={<Patrons />} />
            <Route path={ROUTES.PROCUREMENT.BASE} element={<Procurements />} />
            <Route path={ROUTES.CIRCULATION.BASE} element={<Circulation />} />
            <Route
              path={ROUTES.ORGANIZATION_MANAGEMENT.BASE}
              element={<OrganizationManagement />}
            />
            <Route
              path={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.NEW()}
              element={<CreateAnOrganization />}
            />
            <Route path={ROUTES.AUTHENTICATION.LOGIN} element={<Login />} />
            <Route path={ROUTES.AUTHENTICATION.CONFIRM_EMAIL()} element={<ConfirmEmail />} />
            <Route
              path={ROUTES.ROOT}
              element={<Navigate replace to={ROUTES.AUTHENTICATION.LOGIN} />}
            />
            <Route path="*" element={<NoRoute />} />
          </>
        )}
        <Route path="*" element={<div />} />
      </Routes>
    </BrowserRouter>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchLoadSession: () => {
    dispatch(loadSession());
  },
});

const mapStateToProps = (state:RootState):StateProps => ({
  loading: state.auth.loading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Disembark);
