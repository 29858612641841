import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import {
  FetchCategoriesAction,
  FetchCategoryAction,
  CreateCategoryAction,
  UpdateCategoryAction,
  DestroyCategoryAction,
} from '@store/catalog/categories/types';
import {
  categoriesFetched,
  categoryFetched,
  categoryCreated,
  categoryUpdated,
  categoryDestroyed,
} from '@store/catalog/categories/actions';
import { Observable } from 'rxjs';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { Action } from 'redux';
import CONFIG from '@config/environments/base';
import { RootStateOrAny } from 'react-redux';

const fetchCategoriesEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.CATEGORIES.FETCH),
  switchMap((action:FetchCategoriesAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/categories/`,
    method: 'GET',
    success: categoriesFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchCategoryEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.CATEGORY.FETCH),
  switchMap((action:FetchCategoryAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/categories/${action.id}`,
    method: 'GET',
    success: categoryFetched,
    auth: true,
    state$,
    action,
  })),
);

const createCategoryEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.CATEGORY.CREATE),
  switchMap((action:CreateCategoryAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/categories/`,
    method: 'POST',
    success: categoryCreated,
    auth: true,
    state$,
    action,
    body: action.category,
  })),
);

const updateCategoryEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.CATEGORY.UPDATE),
  switchMap((action:UpdateCategoryAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/categories/${action.id}`,
    method: 'PUT',
    success: categoryUpdated,
    auth: true,
    state$,
    action,
    body: action.category,
  })),
);

const destroyCategoryEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.CATEGORY.DESTROY),
  switchMap((action:DestroyCategoryAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/categories/${action.id}`,
    method: 'DELETE',
    success: categoryDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchCategoriesEpic,
  fetchCategoryEpic,
  createCategoryEpic,
  updateCategoryEpic,
  destroyCategoryEpic,
];
