import * as React from 'react';

interface TabsProps {
  children: React.ReactNode
}

function handleTabClick(e:React.MouseEvent<HTMLElement>) {
  const target = e.target as HTMLElement;

  if (target.className !== 'di-text-header') { return; }

  // Hide all tabs and remove highlights
  const allPanes = e.currentTarget.getElementsByClassName('di-tab-pane-body');
  Array.from(allPanes).forEach((element:HTMLElement) => {
    const copy = element;
    copy.style.display = 'none';
  });
  const allTabs = e.currentTarget.getElementsByClassName('di-tab-option');
  Array.from(allTabs).forEach((element:HTMLElement) => {
    element.classList.remove('di-tab-option-selected');
  });

  // get tab index of selected tab
  const parent = target.parentElement as HTMLDivElement;
  const index = Array.prototype.indexOf.call(e.currentTarget.getElementsByClassName('di-tab-option'), parent);

  // find the tab body that corresponse to that index and show it
  const body = e.currentTarget.getElementsByClassName('di-tab-pane-body')[index] as HTMLDivElement;
  if (body !== undefined) {
    body.style.display = 'initial';
    parent.classList.add('di-tab-option-selected');
  }
}
class Tabs extends React.Component<TabsProps, Record<string, never>> {
  private tabs = React.createRef<HTMLDivElement>();

  componentDidMount(): void {
    const firstTab = this.tabs.current.getElementsByClassName('di-tab-option')[0] as HTMLDivElement;
    if (firstTab !== undefined) {
      firstTab.classList.add('di-tab-option-selected');
    }

    const firstTabContent = this.tabs.current.getElementsByClassName('di-tab-pane-body')[0] as HTMLDivElement;
    if (firstTabContent !== undefined) {
      firstTabContent.style.display = 'initial';
    }
  }

  render(): React.ReactNode {
    const { children } = this.props;
    return (
      <div role="none" className="di-tabs" onClick={handleTabClick} ref={this.tabs}>
        {children}
      </div>
    );
  }
}

export default Tabs;
