import FlexContainer from '@components/layout/generic/FlexContainer';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROUTES } from '@routes';
import { Record } from '@store/catalog/records/types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import TextHeader from '@components/base/text/TextHeader';
import { Author } from '@store/catalog/authors/types';

interface SERPProps {
  to: string
  record: Record
}

function SERP(props:SERPProps) {
  const { to, record } = props;
  return (
    <div className="di-serp">
      <MultiColumnFlex>
        <FlexContainer>
          <MultiColumnFlex>
            <Link to={to}>
              <TextHeader size="h3">{record.title}</TextHeader>
            </Link>
            <div className="di-serp-actions">
              <button type="button">
                <FontAwesomeIcon icon={faBookmark} />
                Add
              </button>
              <Link to={`${ROUTES.CIRCULATION.HOLDS.NEW}?rid=${record.id}`}>Hold</Link>
            </div>
          </MultiColumnFlex>
          <div className="di-serp-metadata-top">
            { record.authors.map((author:Author, index:number) => (
              <span key={author.id}>
                {index > 0 ? ', ' : ''}
                {author.name}
              </span>
            ))}
            <span> •&nbsp;</span>
            <span>
              {record.numberOfPages}
              {' '}
              pages
            </span>
            <span> •&nbsp;</span>
            <span>
              {record.copiesAvailable}
              /
              {record.totalCopies}
              {' '}
              available
            </span>
            <span> •&nbsp;</span>
            <span>{record.originalPublicationYear}</span>
            <span> • </span>
            <span>{record.publisher?.name}</span>
          </div>
          <p>{record.serpText}</p>
          <div className="di-serp-metadata-bottom" />
        </FlexContainer>
        <div className="di-serp-thumbnail">
          <img alt="" src={record.smallThumbnail} />
        </div>
      </MultiColumnFlex>
    </div>
  );
}

export default SERP;
