import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { Author, AuthorAction } from '@store/catalog/authors/types';
import { Bookmark, BookmarkAction } from '@store/catalog/bookmarks/types';
import { Category, CategoryAction } from '@store/catalog/categories/types';
import { List, ListAction } from '@store/catalog/lists/types';
import { Note, NoteAction } from '@store/catalog/notes/types';
import { Publisher, PublisherAction } from '@store/catalog/publishers/types';
import { RecordImage, RecordImageAction } from '@store/catalog/recordImages/types';
import { RecordInstance, RecordInstanceAction } from '@store/catalog/recordInstances/types';
import { Record, RecordAction } from '@store/catalog/records/types';
import { Review, ReviewAction } from '@store/catalog/reviews/types';
import { Genre, GenreAction } from '@store/catalog/genres/types';
import { Subject, SubjectAction } from '@store/catalog/subjects/types';
import { Tag, TagAction } from '@store/catalog/tags/types';
import { PaginatedAPIResponse } from '@store/application/types';

interface RecordsState extends PaginatedAPIResponse {
  externalSearchResults: Record[] // Search results for records from Google API
  searchResults: Record[] // Internal search results
  records: Record[]
  currentRecord: Record
  loading: boolean
}

const initialRecordsState:RecordsState = {
  externalSearchResults: [],
  searchResults: [],
  records: [],
  currentRecord: undefined,
  loading: false,
};

export const records = (
  state:RecordsState = initialRecordsState,
  action:RecordAction = undefined,
):RecordsState => {
  switch (action.type) {
    case CATALOG_ACTION_TYPES.RECORDS.FETCH:
    case CATALOG_ACTION_TYPES.RECORDS.CLEAR:
    case CATALOG_ACTION_TYPES.RECORDS.FETCH_SEARCH_RESULTS:
      return {
        ...state,
        records: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CATALOG_ACTION_TYPES.RECORDS.FETCHED:
      return {
        ...state,
        records: action.records,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CATALOG_ACTION_TYPES.RECORD.ISBN_SEARCH_RESULTS:
      return { ...state, externalSearchResults: action.records };
    case CATALOG_ACTION_TYPES.RECORD.FETCHED:
    case CATALOG_ACTION_TYPES.RECORD.UPDATED:
      return { ...state, currentRecord: action.record };
    case CATALOG_ACTION_TYPES.RECORD.CREATED:
    case CATALOG_ACTION_TYPES.LIST.RECORD_ADDED:
      return { ...state, records: [...state.records, action.record] };
    // case CATALOG_ACTION_TYPES.RECORDS.SEARCH:
    case CATALOG_ACTION_TYPES.RECORDS.CLEAR_SEARCH:
      return { ...state, searchResults: [] };
    case CATALOG_ACTION_TYPES.RECORDS.SEARCHED:
      return { ...state, searchResults: action.results };
    case CATALOG_ACTION_TYPES.RECORD.DESTROYED:
    case CATALOG_ACTION_TYPES.RECORD.UPDATE:
    case CATALOG_ACTION_TYPES.RECORD.CREATE:
    case CATALOG_ACTION_TYPES.RECORD.FETCH:
    case CATALOG_ACTION_TYPES.RECORD.CLEAR:
      return { ...state, currentRecord: undefined };
    default:
      return state;
  }
};

interface ListState extends PaginatedAPIResponse {
  searchResults: List[]
  all: List[]
  currentList: List
  loading: boolean
}

const initialListsState:ListState = {
  searchResults: [],
  all: [],
  currentList: undefined,
  loading: false,
};

export const lists = (state:ListState = initialListsState, action:ListAction = undefined) => {
  switch (action.type) {
    case CATALOG_ACTION_TYPES.LISTS.FETCH:
      return {
        ...state,
        all: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CATALOG_ACTION_TYPES.LISTS.FETCHED:
      return {
        ...state,
        all: action.lists,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CATALOG_ACTION_TYPES.LISTS.CLEAR_SEARCH:
    case CATALOG_ACTION_TYPES.LISTS.SEARCH:
      return { ...state, searchResults: [] };
    case CATALOG_ACTION_TYPES.LISTS.SEARCHED:
      return { ...state, searchResults: action.results };
    case CATALOG_ACTION_TYPES.LIST.FETCHED:
    case CATALOG_ACTION_TYPES.LIST.UPDATED:
      return { ...state, currentList: action.list };
    case CATALOG_ACTION_TYPES.LIST.DESTROYED:
    case CATALOG_ACTION_TYPES.LIST.CREATE:
    case CATALOG_ACTION_TYPES.LIST.CLEAR:
    case CATALOG_ACTION_TYPES.LIST.FETCH:
      return { ...state, currentList: undefined };
    case CATALOG_ACTION_TYPES.LIST.CREATED:
      return {
        ...state,
        all: [...state.all, action.list],
        currentList: action.list,
      };
    default:
      return state;
  }
};

interface NotesState extends PaginatedAPIResponse {
  searchResults: Note[]
  notes: Note[]
  currentNote:Note
  loading: boolean
}

const initialNotesState:NotesState = {
  searchResults: [],
  notes: [],
  currentNote: undefined,
  loading: false,
};

export const notes = (state:NotesState = initialNotesState, action:NoteAction = undefined) => {
  switch (action.type) {
    case CATALOG_ACTION_TYPES.NOTES.FETCH:
      return {
        ...state,
        notes: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CATALOG_ACTION_TYPES.NOTES.FETCHED:
      return {
        ...state,
        notes: action.notes,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CATALOG_ACTION_TYPES.NOTES.CLEAR_SEARCH:
    case CATALOG_ACTION_TYPES.NOTES.SEARCH:
      return { ...state, searchResults: [] };
    case CATALOG_ACTION_TYPES.NOTES.SEARCHED:
      return { ...state, searchResults: action.results };
    case CATALOG_ACTION_TYPES.NOTE.FETCHED:
    case CATALOG_ACTION_TYPES.NOTE.UPDATED:
      return { ...state, currentNote: action.note };
    case CATALOG_ACTION_TYPES.NOTE.CREATED:
      return { ...state, notes: [...state.notes, action.note] };
    case CATALOG_ACTION_TYPES.NOTE.DESTROYED:
    case CATALOG_ACTION_TYPES.NOTE.UPDATE:
    case CATALOG_ACTION_TYPES.NOTE.CREATE:
    case CATALOG_ACTION_TYPES.NOTE.FETCH:
    case CATALOG_ACTION_TYPES.NOTE.CLEAR:
      return { ...state, currentNote: undefined };
    default:
      return state;
  }
};

interface ReviewsState extends PaginatedAPIResponse {
  searchResults: Review[]
  reviews: Review[]
  currentReview:Review
  loading: boolean
}

const initialReviewsState:ReviewsState = {
  searchResults: [],
  reviews: [],
  currentReview: undefined,
  loading: false,
};

export const reviews = (
  state:ReviewsState = initialReviewsState,
  action:ReviewAction = undefined,
) => {
  switch (action.type) {
    case CATALOG_ACTION_TYPES.REVIEWS.FETCH:
      return {
        ...state,
        reviews: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CATALOG_ACTION_TYPES.REVIEWS.FETCHED:
      return {
        ...state,
        reviews: action.reviews,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CATALOG_ACTION_TYPES.REVIEW.FETCHED:
    case CATALOG_ACTION_TYPES.REVIEW.UPDATED:
      return { ...state, currentReview: action.review };
    case CATALOG_ACTION_TYPES.REVIEW.CREATED:
      return { ...state, reviews: [...state.reviews, action.review] };
    case CATALOG_ACTION_TYPES.REVIEW.DESTROYED:
    case CATALOG_ACTION_TYPES.REVIEW.UPDATE:
    case CATALOG_ACTION_TYPES.REVIEW.CREATE:
      return { ...state, currentReview: undefined };
    default:
      return state;
  }
};

interface RecordImagesState extends PaginatedAPIResponse {
  searchResults: RecordImage[]
  recordImages: RecordImage[]
  currentRecordImage:RecordImage
  loading: boolean
}

const initialRecordImagesState:RecordImagesState = {
  searchResults: [],
  recordImages: [],
  currentRecordImage: undefined,
  loading: false,
};

export const recordImages = (
  state:RecordImagesState = initialRecordImagesState,
  action:RecordImageAction = undefined,
) => {
  switch (action.type) {
    case CATALOG_ACTION_TYPES.RECORD_IMAGES.FETCH:
      return {
        ...state,
        recordImages: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CATALOG_ACTION_TYPES.RECORD_IMAGES.FETCHED:
      return {
        ...state,
        recordImages: action.recordImages,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CATALOG_ACTION_TYPES.RECORD_IMAGE.FETCHED:
    case CATALOG_ACTION_TYPES.RECORD_IMAGE.UPDATED:
      return { ...state, currentRecordImage: action.recordImage };
    case CATALOG_ACTION_TYPES.RECORD_IMAGE.CREATED:
      return { ...state, recordImages: [...state.recordImages, action.recordImage] };
    case CATALOG_ACTION_TYPES.RECORD_IMAGE.DESTROYED:
    case CATALOG_ACTION_TYPES.RECORD_IMAGE.UPDATE:
    case CATALOG_ACTION_TYPES.RECORD_IMAGE.CREATE:
      return { ...state, currentRecordImage: undefined };
    default:
      return state;
  }
};

interface RecordInstancesState extends PaginatedAPIResponse {
  searchResults: RecordInstance[]
  recordInstances: RecordInstance[]
  currentRecordInstance:RecordInstance
  loading: boolean
}

const initialRecordInstancesState:RecordInstancesState = {
  searchResults: [],
  recordInstances: [],
  currentRecordInstance: undefined,
  loading: false,
};

export const recordInstances = (
  state:RecordInstancesState = initialRecordInstancesState,
  action:RecordInstanceAction = undefined,
) => {
  switch (action.type) {
    case CATALOG_ACTION_TYPES.RECORD_INSTANCES.FETCH:
      return {
        ...state,
        recordInstances: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CATALOG_ACTION_TYPES.RECORD_INSTANCES.FETCHED:
      return {
        ...state,
        recordInstances: action.recordInstances,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CATALOG_ACTION_TYPES.RECORD_INSTANCES.SEARCHED:
      return { ...state, searchResults: action.recordInstances };
    case CATALOG_ACTION_TYPES.RECORD_INSTANCES.CLEAR_SEARCH:
      return { ...state, searchResults: [] };
    case CATALOG_ACTION_TYPES.RECORD_INSTANCE.FETCHED:
    case CATALOG_ACTION_TYPES.RECORD_INSTANCE.UPDATED:
      return { ...state, currentRecordInstance: action.recordInstance };
    case CATALOG_ACTION_TYPES.RECORD_INSTANCE.CREATED:
      return { ...state, recordInstances: [...state.recordInstances, action.recordInstance] };
    case CATALOG_ACTION_TYPES.RECORD_INSTANCE.DESTROYED:
    case CATALOG_ACTION_TYPES.RECORD_INSTANCE.UPDATE:
    case CATALOG_ACTION_TYPES.RECORD_INSTANCE.CREATE:
      return { ...state, currentRecordInstance: undefined };
    default:
      return state;
  }
};

interface AuthorsState extends PaginatedAPIResponse {
  searchResults: Author[]
  authors: Author[]
  currentAuthor:Author
  loading: boolean
}

const initialAuthorsState:AuthorsState = {
  searchResults: [],
  authors: [],
  currentAuthor: undefined,
  loading: false,
};

export const authors = (
  state:AuthorsState = initialAuthorsState,
  action:AuthorAction = undefined,
) => {
  switch (action.type) {
    case CATALOG_ACTION_TYPES.AUTHOR.CREATE:
    case CATALOG_ACTION_TYPES.AUTHOR.FETCH:
      return {
        ...state,
        currentAuthor: undefined,
        loading: true,
      };
    case CATALOG_ACTION_TYPES.AUTHOR.CREATED:
    case CATALOG_ACTION_TYPES.AUTHOR.FETCHED:
      return {
        ...state,
        currentAuthor: action.author,
        loading: false,
      };
    case CATALOG_ACTION_TYPES.AUTHORS.SEARCH:
      return { ...state, searchResults: [] };
    case CATALOG_ACTION_TYPES.AUTHORS.SEARCHED:
      return { ...state, searchResults: action.results };
    default:
      return state;
  }
};

interface PublishersState extends PaginatedAPIResponse {
  searchResults: Publisher[]
  publishers: Publisher[]
  currentPublisher:Publisher
  loading: boolean
}

const initialPublishersState:PublishersState = {
  searchResults: [],
  publishers: [],
  currentPublisher: undefined,
  loading: false,
};

export const publishers = (
  state:PublishersState = initialPublishersState,
  action:PublisherAction = undefined,
) => {
  switch (action.type) {
    case CATALOG_ACTION_TYPES.PUBLISHER.CREATE:
    case CATALOG_ACTION_TYPES.PUBLISHER.FETCH:
      return {
        ...state,
        currentPublisher: undefined,
        loading: true,
      };
    case CATALOG_ACTION_TYPES.PUBLISHER.CREATED:
    case CATALOG_ACTION_TYPES.PUBLISHER.FETCHED:
      return {
        ...state,
        currentPublisher: action.publisher,
        loading: false,
      };
    case CATALOG_ACTION_TYPES.PUBLISHERS.SEARCH:
      return { ...state, searchResults: [] };
    case CATALOG_ACTION_TYPES.PUBLISHERS.SEARCHED:
      return { ...state, searchResults: action.results };
    default:
      return state;
  }
};

interface BookmarksState extends PaginatedAPIResponse {
  searchResults: Bookmark[]
  bookmarks: Bookmark[]
  currentBookmark:Bookmark
  loading: boolean
}

const initialBookmarksState:BookmarksState = {
  searchResults: [],
  bookmarks: [],
  currentBookmark: undefined,
  loading: false,
};

export const bookmarks = (
  state:BookmarksState = initialBookmarksState,
  action:BookmarkAction = undefined,
) => {
  switch (action.type) {
    case CATALOG_ACTION_TYPES.BOOKMARKS.FETCH:
      return {
        ...state,
        bookmarks: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CATALOG_ACTION_TYPES.BOOKMARKS.FETCHED:
      return {
        ...state,
        bookmarks: action.bookmarks,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CATALOG_ACTION_TYPES.BOOKMARKS.CLEAR_SEARCH:
    case CATALOG_ACTION_TYPES.BOOKMARKS.SEARCH:
      return { ...state, searchResults: [] };
    case CATALOG_ACTION_TYPES.BOOKMARKS.SEARCHED:
      return { ...state, searchResults: action.results };
    case CATALOG_ACTION_TYPES.BOOKMARK.FETCHED:
    case CATALOG_ACTION_TYPES.BOOKMARK.UPDATED:
      return { ...state, currentBookmark: action.bookmark };
    case CATALOG_ACTION_TYPES.BOOKMARK.CREATED:
      return { ...state, bookmarks: [...state.bookmarks, action.bookmark] };
    case CATALOG_ACTION_TYPES.BOOKMARK.DESTROYED:
    case CATALOG_ACTION_TYPES.BOOKMARK.UPDATE:
    case CATALOG_ACTION_TYPES.BOOKMARK.CREATE:
    case CATALOG_ACTION_TYPES.BOOKMARK.FETCH:
    case CATALOG_ACTION_TYPES.BOOKMARK.CLEAR:
      return { ...state, currentBookmark: undefined };
    default:
      return state;
  }
};

interface CategoriesState extends PaginatedAPIResponse {
  searchResults: Category[]
  categories: Category[]
  currentCategory:Category
  loading: boolean
}

const initialCategoriesState:CategoriesState = {
  searchResults: [],
  categories: [],
  currentCategory: undefined,
  loading: false,
};

export const categories = (
  state:CategoriesState = initialCategoriesState,
  action:CategoryAction = undefined,
) => {
  switch (action.type) {
    case CATALOG_ACTION_TYPES.CATEGORIES.FETCH:
      return {
        ...state,
        categories: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CATALOG_ACTION_TYPES.CATEGORIES.FETCHED:
      return {
        ...state,
        categories: action.categories,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CATALOG_ACTION_TYPES.CATEGORY.FETCHED:
    case CATALOG_ACTION_TYPES.CATEGORY.UPDATED:
      return { ...state, currentCategory: action.category };
    case CATALOG_ACTION_TYPES.CATEGORY.CREATED:
      return {
        ...state,
        currentCategory: action.category,
        categories: [...state.categories, action.category],
      };
    case CATALOG_ACTION_TYPES.CATEGORY.DESTROYED:
    case CATALOG_ACTION_TYPES.CATEGORY.UPDATE:
    case CATALOG_ACTION_TYPES.CATEGORY.CREATE:
    case CATALOG_ACTION_TYPES.CATEGORY.FETCH:
    case CATALOG_ACTION_TYPES.CATEGORY.CLEAR:
      return { ...state, currentCategory: undefined };
    default:
      return state;
  }
};

interface GenresState extends PaginatedAPIResponse {
  searchResults: Genre[]
  genres: Genre[]
  currentGenre:Genre
  loading: boolean
}

const initialGenresState:GenresState = {
  searchResults: [],
  genres: [],
  currentGenre: undefined,
  loading: false,
};

export const genres = (state:GenresState = initialGenresState, action:GenreAction = undefined) => {
  switch (action.type) {
    case CATALOG_ACTION_TYPES.GENRES.FETCH:
      return {
        ...state,
        genres: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CATALOG_ACTION_TYPES.GENRES.FETCHED:
      return {
        ...state,
        genres: action.genres,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CATALOG_ACTION_TYPES.GENRE.FETCHED:
    case CATALOG_ACTION_TYPES.GENRE.UPDATED:
      return { ...state, currentGenre: action.genre };
    case CATALOG_ACTION_TYPES.GENRE.CREATED:
      return { ...state, genres: [...state.genres, action.genre] };
    case CATALOG_ACTION_TYPES.GENRE.DESTROYED:
    case CATALOG_ACTION_TYPES.GENRE.UPDATE:
    case CATALOG_ACTION_TYPES.GENRE.CREATE:
    case CATALOG_ACTION_TYPES.GENRE.FETCH:
    case CATALOG_ACTION_TYPES.GENRE.CLEAR:
      return { ...state, currentGenre: undefined };
    default:
      return state;
  }
};

interface SubjectsState extends PaginatedAPIResponse {
  searchResults: Subject[]
  subjects: Subject[]
  currentSubject:Subject
  loading: boolean
}

const initialSubjectsState:SubjectsState = {
  searchResults: [],
  subjects: [],
  currentSubject: undefined,
  loading: false,
};

export const subjects = (
  state:SubjectsState = initialSubjectsState,
  action:SubjectAction = undefined,
) => {
  switch (action.type) {
    case CATALOG_ACTION_TYPES.SUBJECTS.FETCH:
      return {
        ...state,
        subjects: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CATALOG_ACTION_TYPES.SUBJECTS.FETCHED:
      return {
        ...state,
        subjects: action.subjects,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CATALOG_ACTION_TYPES.SUBJECT.FETCHED:
    case CATALOG_ACTION_TYPES.SUBJECT.UPDATED:
      return { ...state, currentSubject: action.subject };
    case CATALOG_ACTION_TYPES.SUBJECT.CREATED:
      return {
        ...state,
        currentSubject: action.subject,
        subjects: [...state.subjects, action.subject],
      };
    case CATALOG_ACTION_TYPES.SUBJECT.DESTROYED:
    case CATALOG_ACTION_TYPES.SUBJECT.UPDATE:
    case CATALOG_ACTION_TYPES.SUBJECT.CREATE:
    case CATALOG_ACTION_TYPES.SUBJECT.FETCH:
    case CATALOG_ACTION_TYPES.SUBJECT.CLEAR:
      return { ...state, currentSubject: undefined };
    default:
      return state;
  }
};

interface TagsState extends PaginatedAPIResponse {
  searchResults: Tag[]
  tags: Tag[]
  currentTag:Tag
  loading: boolean
}

const initialTagsState:TagsState = {
  searchResults: [],
  tags: [],
  currentTag: undefined,
  loading: false,
};

export const tags = (state:TagsState = initialTagsState, action:TagAction = undefined) => {
  switch (action.type) {
    case CATALOG_ACTION_TYPES.TAGS.FETCH:
      return {
        ...state,
        tags: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CATALOG_ACTION_TYPES.TAGS.FETCHED:
      return {
        ...state,
        tags: action.tags,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CATALOG_ACTION_TYPES.TAG.FETCHED:
    case CATALOG_ACTION_TYPES.TAG.UPDATED:
      return { ...state, currentTag: action.tag };
    case CATALOG_ACTION_TYPES.TAG.CREATED:
      return { ...state, tags: [...state.tags, action.tag] };
    case CATALOG_ACTION_TYPES.TAG.DESTROYED:
    case CATALOG_ACTION_TYPES.TAG.UPDATE:
    case CATALOG_ACTION_TYPES.TAG.CREATE:
    case CATALOG_ACTION_TYPES.TAG.FETCH:
    case CATALOG_ACTION_TYPES.TAG.CLEAR:
      return { ...state, currentTag: undefined };
    default:
      return state;
  }
};
