import Thumbnail from '@components/base/images/Thumbnail';
import SubHeader from '@components/base/text/SubHeader';
import TextHeader from '@components/base/text/TextHeader';
import FlexContainer from '@components/layout/generic/FlexContainer';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import { Author } from '@store/catalog/authors/types';
import { Record } from '@store/catalog/records/types';
import * as React from 'react';

interface RecordSummaryProps {
  record: Record
}

function RecordSummary(props:RecordSummaryProps) {
  const { record } = props;
  return (
    <MultiColumnFlex>
      <FlexContainer>
        <Thumbnail size="medium" src={record.smallThumbnail} alt="" />
      </FlexContainer>
      <FlexContainer>
        <TextHeader size="h4">{record.title}</TextHeader>
        <SubHeader>
          { record.authors?.map((author:Author, index:number) => (
            <span key={`${author.id}-${index + 1}`}>
              {index > 0 ? ', ' : ''}
              {author.name}
            </span>
          ))}
          <span> •&nbsp;</span>
          <span>
            {record.numberOfPages}
            {' '}
            pages
          </span>
          <span> •&nbsp;</span>
          <span>
            {record.copiesAvailable}
            /
            {record.totalCopies}
            {' '}
            available
          </span>
        </SubHeader>
        <p>{record.serpText}</p>
      </FlexContainer>
    </MultiColumnFlex>
  );
}

export default RecordSummary;
