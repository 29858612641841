import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import * as React from 'react';
import { Link } from 'react-router-dom';

function ProcurementsOverview() {
  return (
    <PageContainer>
      <TextHeader size="h1">Overview</TextHeader>
      <Link to={ROUTES.PROCUREMENT.PURCHASES.NEW(true)}>New Purchase</Link>
    </PageContainer>
  );
}

export default ProcurementsOverview;
