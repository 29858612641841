import * as React from 'react';
import { useNavigate } from 'react-router-dom';

interface TableRowProps {
  children: React.ReactNode
  to?: string
}

function TableRow(props:TableRowProps) {
  const { children, to } = props;
  const navigate = useNavigate();
  return (
    <tr className={`di-table-row ${to !== undefined ? 'di-table-row-clickable' : ''}`} onClick={() => { if (to !== undefined) { navigate(to); } }}>
      { children }
    </tr>
  );
}

TableRow.defaultProps = {
  to: undefined,
};

export default TableRow;
