import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';

import {
  ClearCurrentGenreAction,
  CreateGenreAction,
  DestroyGenreAction,
  FetchGenreAction,
  FetchGenresAction,
  FetchGenresOptions,
  Genre,
  GenreCreatedAction,
  GenreDestroyedAction,
  GenreFetchedAction,
  GenresFetchedAction,
  GenreUpdatedAction,
  UpdateGenreAction,
} from '@store/catalog/genres/types';

export const fetchGenres = (options:FetchGenresOptions = undefined):FetchGenresAction => ({
  type: CATALOG_ACTION_TYPES.GENRES.FETCH,
  options,
});

export const genresFetched = (response:Genre[]):GenresFetchedAction => ({
  type: CATALOG_ACTION_TYPES.GENRES.FETCHED,

  genres: response,
});

export const fetchGenre = (id:number|string):FetchGenreAction => ({
  type: CATALOG_ACTION_TYPES.GENRE.FETCH,
  id,
});

export const genreFetched = (genre:Genre):GenreFetchedAction => ({
  type: CATALOG_ACTION_TYPES.GENRE.FETCHED,
  genre,
  id: genre.id,
});

export const createGenre = (genre:FormData):CreateGenreAction => ({
  type: CATALOG_ACTION_TYPES.GENRE.CREATE,
  genre,
});

export const genreCreated = (genre:Genre):GenreCreatedAction => ({
  type: CATALOG_ACTION_TYPES.GENRE.CREATED,
  genre,
  id: genre.id,
});

export const updateGenre = (genre:FormData):UpdateGenreAction => ({
  type: CATALOG_ACTION_TYPES.GENRE.UPDATE,
  genre,
  id: genre.get('id') as string|number,
});

export const genreUpdated = (genre:Genre):GenreUpdatedAction => ({
  type: CATALOG_ACTION_TYPES.GENRE.UPDATED,
  genre,
  id: genre.id,
});

export const destroyGenre = (genre:FormData):DestroyGenreAction => ({
  type: CATALOG_ACTION_TYPES.GENRE.DESTROY,
  id: genre.get('id') as string|number,
});

export const genreDestroyed = (id:number|string):GenreDestroyedAction => ({
  type: CATALOG_ACTION_TYPES.GENRE.DESTROYED,
  id,
});

export const clearCurrentGenreAction = ():ClearCurrentGenreAction => ({
  type: CATALOG_ACTION_TYPES.GENRE.CLEAR,
});
