import * as React from 'react';
import { Link } from 'react-router-dom';

interface SquareButtonProps {
  to: string
  children: React.ReactNode
}

function SquareButton(props:SquareButtonProps) {
  const { to, children } = props;

  return (
    <Link className="di-square-button" to={to}>
      {children}
    </Link>
  );
}

export default SquareButton;
