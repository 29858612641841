import { Note } from '@store/catalog/notes/types';
import * as moment from 'moment';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import TextHeader from '@components/base/text/TextHeader';

interface NoteListItemProps {
  note: Note
}

interface NoteListItemState {
  overflow: boolean
}

class NoteListItem extends React.Component<NoteListItemProps, NoteListItemState> {
  constructor(props:NoteListItemProps) {
    super(props);
    this.state = {
      overflow: false,
    };
  }

  componentDidMount(): void {
    const element = document.getElementsByClassName('di-note-list-item-content')[0];
    if (element !== undefined) {
      this.setState({
        overflow: element.scrollHeight > element.clientHeight,
      });
    }
  }

  render(): React.ReactNode {
    const { note } = this.props;
    const { overflow } = this.state;
    return (
      <li className="di-note-list-item">
        <TextHeader size="h5">{note.title}</TextHeader>
        <div className="di-note-list-item-date">
          { note.updatedAt > note.createdAt ? `Updated on ${moment(note.updatedAt).format('MMM Do YY')}.` : `Created on ${moment(note.createdAt).format('MMM Do YY')}.` }
        </div>
        <ReactMarkdown className="di-note-list-item-content" remarkPlugins={[remarkGfm]}>{ note.content}</ReactMarkdown>
        { overflow ? <button type="button">More</button> : undefined }
      </li>
    );
  }
}

export default NoteListItem;
