import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import * as React from 'react';

const containerStyle = {
  width: '64px',
  height: '64px',
};

interface OwnProps {
  lat: number
  lng: number
}
const mapOptions = {
  fullscreenControl: false,
};

function LocationMap(props:OwnProps) {
  const { lat, lng } = props;
  return (

    <LoadScript
      googleMapsApiKey="AIzaSyAMuHGLLQFKFFUlKTAXqghJQaECfjJKcMs"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat, lng }}
        zoom={10}
        options={mapOptions}
      >
        <Marker position={{ lat, lng }} />
      </GoogleMap>
    </LoadScript>
  );
}

export default LocationMap;
