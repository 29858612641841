import * as React from 'react';
import { Link } from 'react-router-dom';
import TextHeader from '@components/base/text/TextHeader';

export interface FormatOptionProps {
  children: React.ReactNode
  to?: string
  selected?: boolean
  copiesAvailable: number
  numberOfHolds: number
}

function FormatOption(props:FormatOptionProps) {
  const {
    selected, copiesAvailable, numberOfHolds, children, to,
  } = props;
  if (selected) {
    return (
      <div className="di-format-option di-format-option-selected">
        <TextHeader size="h5">{children}</TextHeader>
        <div className="di-format-option-subtext">
          {copiesAvailable}
          {' '}
          copies available
        </div>
        <div className="di-format-option-subtext">
          {numberOfHolds}
          {' '}
          holds
        </div>
      </div>
    );
  }
  return (
    <Link className="di-format-option" to={to}>
      <TextHeader size="h5">{children}</TextHeader>
      <div className="di-format-option-subtext">
        {copiesAvailable}
        {' '}
        copies available
      </div>
      <div className="di-format-option-subtext">
        {numberOfHolds}
        {' '}
        holds
      </div>
    </Link>
  );
}

FormatOption.defaultProps = {
  to: undefined,
  selected: undefined,
};

export default FormatOption;
