import * as React from 'react';

interface TextHeaderProps {
  size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children: React.ReactNode
}

function TextHeader(props:TextHeaderProps) {
  const { size, children } = props;
  switch (size) {
    case 'h1': return <h1 className="di-text-header">{ children }</h1>;
    case 'h2': return <h2 className="di-text-header">{ children }</h2>;
    case 'h3': return <h3 className="di-text-header">{ children }</h3>;
    case 'h4': return <h4 className="di-text-header">{ children }</h4>;
    case 'h5': return <h5 className="di-text-header">{ children }</h5>;
    case 'h6': return <h6 className="di-text-header">{ children }</h6>;
    default: return <h6 className="di-text-header">{ children }</h6>;
  }
}

export default TextHeader;
