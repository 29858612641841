interface IPROFILE_ACTION_TYPES {
  ASSOCIATIONS: {
    FETCH: 'disembark/profile/fetch_associations',
    FETCHED: 'disembark/profile/associations_fetched',
  },
  ASSOCIATION: {
    FETCH: 'disembark/profile/fetch_association',
    FETCHED: 'disembark/profile/association_fetched',
    CREATE: 'disembark/profile/create_association',
    CREATED: 'disembark/profile/association_created',
    UPDATE: 'disembark/profile/update_association',
    UPDATED: 'disembark/profile/association_updated',
    DESTROY: 'disembark/profile/destroy_association',
    DESTROYED: 'disembark/profile/association_destroyed'
  },
  PROFILE: {
    CREATE: 'disembark/profile/create_profile',
    CREATED: 'disembark/profile/profile_created',
    FETCH: 'disembark/profile/fetch_profile',
    FETCHED: 'disembark/profile/profile_fetched',
    UPDATE: 'disembark/profile/update_profile',
    UPDATED: 'disembark/profile/profile_updated',
    DESTROY: 'disembark/profile/destroy_profile',
    DESTROYED: 'disembark/profile/profile_destroyed',
  },
  FAMILY_MEMBERS: {
    FETCH: 'disembark/profile/fetch_family_members',
    FETCHED: 'disembark/profile/family_members_fetched',
  },
  FAMILY_MEMBER: {
    FETCH: 'disembark/profile/fetch_family_member',
    FETCHED: 'disembark/profile/family_member_fetched',
    CREATE: 'disembark/profile/create_family_member',
    CREATED: 'disembark/profile/family_member_created',
    UPDATE: 'disembark/profile/update_family_member',
    UPDATED: 'disembark/profile/family_member_updated',
    DESTROY: 'disembark/profile/destroy_family_member',
    DESTROYED: 'disembark/profile/family_member_destroyed'
  }
}

export const PROFILE_ACTION_TYPES:IPROFILE_ACTION_TYPES = {
  ASSOCIATIONS: {
    FETCH: 'disembark/profile/fetch_associations',
    FETCHED: 'disembark/profile/associations_fetched',
  },
  ASSOCIATION: {
    FETCH: 'disembark/profile/fetch_association',
    FETCHED: 'disembark/profile/association_fetched',
    CREATE: 'disembark/profile/create_association',
    CREATED: 'disembark/profile/association_created',
    UPDATE: 'disembark/profile/update_association',
    UPDATED: 'disembark/profile/association_updated',
    DESTROY: 'disembark/profile/destroy_association',
    DESTROYED: 'disembark/profile/association_destroyed',
  },
  PROFILE: {
    CREATE: 'disembark/profile/create_profile',
    CREATED: 'disembark/profile/profile_created',
    FETCH: 'disembark/profile/fetch_profile',
    FETCHED: 'disembark/profile/profile_fetched',
    UPDATE: 'disembark/profile/update_profile',
    UPDATED: 'disembark/profile/profile_updated',
    DESTROY: 'disembark/profile/destroy_profile',
    DESTROYED: 'disembark/profile/profile_destroyed',
  },
  FAMILY_MEMBERS: {
    FETCH: 'disembark/profile/fetch_family_members',
    FETCHED: 'disembark/profile/family_members_fetched',
  },
  FAMILY_MEMBER: {
    FETCH: 'disembark/profile/fetch_family_member',
    FETCHED: 'disembark/profile/family_member_fetched',
    CREATE: 'disembark/profile/create_family_member',
    CREATED: 'disembark/profile/family_member_created',
    UPDATE: 'disembark/profile/update_family_member',
    UPDATED: 'disembark/profile/family_member_updated',
    DESTROY: 'disembark/profile/destroy_family_member',
    DESTROYED: 'disembark/profile/family_member_destroyed',
  },
};

export default PROFILE_ACTION_TYPES;
