import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface ProfileMenuProps {
  children: React.ReactNode
}

function ProfileMenu(props:ProfileMenuProps) {
  const { children } = props;
  return (
    <nav className="di-profile-menu-item">
      <span className="di-profile-icon"><FontAwesomeIcon icon={faUserCircle} /></span>
      <div className="di-profile-list">
        <ul>
          { children }
        </ul>
      </div>
    </nav>
  );
}

export default ProfileMenu;
