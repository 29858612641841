import Button from '@components/base/buttons-and-links/Button';
import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import TextHeader from '@components/base/text/TextHeader';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import { fetchTransfers } from '@store/circulation/transfers/actions';
import { Transfer } from '@store/circulation/transfers/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

interface StateProps { transfers:Transfer[] }
interface DispatchProps { dispatchFetchTransfers:()=>void }
type ShowAllTransfersProps = StateProps & DispatchProps;

function ShowAllTransfers(props:ShowAllTransfersProps) {
  const { dispatchFetchTransfers, transfers } = props;
  React.useEffect(() => {
    dispatchFetchTransfers();
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Transfers</TextHeader>
      <PaginatedTable>
        <TableHeader>
          <TableRow>
            <TableHeaderColumn sortable>Name</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          { transfers.map((transfer) => (
            <TableRow key={transfer.id} to={ROUTES.CIRCULATION.TRANSFERS.DETAILS(transfer.id)}>
              <TableColumn tabIndex={0}>{transfer.name}</TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
      <Button color="blue-green" fill="solid" to={ROUTES.CIRCULATION.TRANSFERS.NEW(true)}>Add a Transfer</Button>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  transfers: state.transfers.transfers,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchTransfers: ():void => {
    dispatch(fetchTransfers());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllTransfers);
