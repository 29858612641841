import Button from '@components/base/buttons-and-links/Button';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import { fetchBranch } from '@store/organizations/branches/actions';
import { Branch } from '@store/organizations/branches/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

interface StateProps {
  branch:Branch
}
interface DispatchProps {
  dispatchFetchBranch:(id:number|string, organizationId:number|string)=>void
}
type ShowBranchDetailsProps = StateProps & DispatchProps;

function ShowBranchDetails(props:ShowBranchDetailsProps) {
  const { dispatchFetchBranch, branch } = props;
  const params = useParams();
  const { id, organizationId } = params;
  React.useEffect(() => {
    dispatchFetchBranch(id, organizationId);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">{branch.title}</TextHeader>
      <Button color="blue-green" fill="solid" to={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.BRANCHES.EDIT(id, organizationId)}>Edit</Button>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  branch: state.branches.currentBranch || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchBranch: (id:number|string, organizationId:number|string):void => {
    dispatch(fetchBranch(id, { organizationId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowBranchDetails);
