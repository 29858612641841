import * as React from 'react';
import { ROUTES } from '@routes';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Organization } from '@store/organizations/organizations/types';
import FlexContainer from '@components/layout/generic/FlexContainer';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import PageContainer from '@components/layout/generic/PageContainer';
import TertiaryMenu from '@components/compound/menus/TertiaryMenu';
import OrganizationMenu from '@containers/organizations/organizations/shared/OrganizationMenu';
import ShowOrganizationDetails from '@containers/organizations/organizations/ShowOrganizationDetails';
import EditAnOrganization from '@containers/organizations/organizations/EditAnOrganization';
import { RootState } from '@store/root';
import { Dispatch } from 'redux';
import { fetchOrganizations } from '@store/organizations/organizations/actions';
import APIList from '@containers/application/APIList';
import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';
import Button from '@components/base/buttons-and-links/Button';
import CreateABranch from '@containers/organizations/organizations/branches/CreateABranch';
import EditABranch from '@containers/organizations/organizations/branches/EditABranch';
import ShowBranchDetails from '@containers/organizations/organizations/branches/ShowBranchDetails';
import ShowAllBranches from '@containers/organizations/organizations/branches/ShowAllBranches';
import CreateAnAdmin from '@containers/organizations/organizations/admins/CreateAnAdmin';
import EditAnAdmin from '@containers/organizations/organizations/admins/EditAnAdmin';
import ShowAdminDetails from '@containers/organizations/organizations/admins/ShowAdminDetails';
import ShowAllAdmins from '@containers/organizations/organizations/admins/ShowAllAdmins';

interface StateProps {
  organizations: Organization[],
  currentOrganization: Organization
}
interface DispatchProps { dispatchFetchOrganizations:()=>void }
type ShowDefaultOrganizationProps = StateProps & DispatchProps;

function ShowDefaultOrganization(props:ShowDefaultOrganizationProps) {
  const { dispatchFetchOrganizations, organizations, currentOrganization } = props;

  React.useEffect(() => {
    dispatchFetchOrganizations();
  }, []);

  return (
    <PageContainer>
      <MultiColumnFlex alignItems="start">
        <TertiaryMenu>
          <APIList action={ORGANIZATION_ACTION_TYPES.ORGANIZATIONS.FETCH}>
            <ul>
              { organizations.map((organization:Organization) => (
                <li key={organization.id}>{organization.title}</li>
              ))}
            </ul>
          </APIList>
          <Button color="blue-green" fill="solid" to={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.NEW(true)}>Add an Organization</Button>
          <OrganizationMenu organizationId={currentOrganization?.id} />
        </TertiaryMenu>
        <FlexContainer>
          <Routes>
            <Route
              path={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.BRANCHES.NEW()}
              element={<CreateABranch />}
            />
            <Route
              path={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.BRANCHES.EDIT()}
              element={<EditABranch />}
            />
            <Route
              path={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.BRANCHES.DETAILS()}
              element={<ShowBranchDetails />}
            />
            <Route
              path={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.BRANCHES.ALL()}
              element={<ShowAllBranches />}
            />
            <Route
              path={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.STAFF_ADMINS.NEW()}
              element={<CreateAnAdmin />}
            />
            <Route
              path={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.STAFF_ADMINS.EDIT()}
              element={<EditAnAdmin />}
            />
            <Route
              path={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.STAFF_ADMINS.DETAILS()}
              element={<ShowAdminDetails />}
            />
            <Route
              path={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.STAFF_ADMINS.ALL()}
              element={<ShowAllAdmins />}
            />
            <Route
              path={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.DETAILS()}
              element={<ShowOrganizationDetails />}
            />
            <Route
              path={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.EDIT()}
              element={<EditAnOrganization />}
            />
            <Route
              path={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.INDEX()}
              element={<ShowOrganizationDetails />}
            />
          </Routes>
        </FlexContainer>
      </MultiColumnFlex>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  organizations: state.organizations.organizations,
  currentOrganization: state.organizations.currentOrganization,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchOrganizations: ():void => {
    dispatch(fetchOrganizations());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowDefaultOrganization);
