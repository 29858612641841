import PageContainer from '@components/layout/generic/PageContainer';
import { fetchRecordLease } from '@store/circulation/recordLeases/actions';
import { RecordLease } from '@store/circulation/recordLeases/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

interface StateProps { recordLease:RecordLease }
interface DispatchProps { dispatchFetchRecordLease:()=>void }
type ShowRecordLeaseDetailsProps = StateProps & DispatchProps;

class ShowRecordLeaseDetails extends React.Component<ShowRecordLeaseDetailsProps, null> {
  componentDidMount() {
    const { dispatchFetchRecordLease } = this.props;
    dispatchFetchRecordLease();
  }

  render() {
    const { recordLease } = this.props;
    return (
      <PageContainer>
        { recordLease.patron.name }
        { recordLease.recordInstance?.record.title }
      </PageContainer>
    );
  }
}

const mapStateToProps = (state:RootState):StateProps => ({
  recordLease: state.recordLeases.currentRecordLease || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => {
  const params = useParams();
  return {
    dispatchFetchRecordLease: () => {
      dispatch(fetchRecordLease(parseInt(params.id, 10)));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowRecordLeaseDetails);
