import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchProfile, updateProfile } from '@store/profile/profile/actions';
import APIForm from '@containers/application/APIForm';
import { PROFILE_ACTION_TYPES } from '@store/profile/actionTypes';
import { Dispatch } from 'redux';
import { RootState } from '@store/root';
import { Profile } from '@store/profile/profile/types';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import PageContainer from '@components/layout/generic/PageContainer';
import Label from '@components/base/form-fields/Label';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@routes';

interface StateProps { profile: Profile }
interface DispatchProps {
  dispatchUpdateProfile: (e:React.MouseEvent<HTMLFormElement>)=>void,
  dispatchFetchProfile:()=>void
}
type ProfileFormProps = StateProps & DispatchProps;

function EditMyProfile(props:ProfileFormProps) {
  const { dispatchFetchProfile, profile, dispatchUpdateProfile } = props;
  const { t } = useTranslation();
  React.useEffect(() => {
    dispatchFetchProfile();
  }, []);
  return (
    <PageContainer>
      <APIForm
        id="di-edit-a-profile"
        onSubmit={dispatchUpdateProfile}
        action={PROFILE_ACTION_TYPES.PROFILE.UPDATE}
        onSuccessRoute={ROUTES.PROFILE.INDEX(true)}
        onSuccessAction={PROFILE_ACTION_TYPES.PROFILE.UPDATED}
        entityID={profile.id}
      >
        <Label htmlFor="name">{ t('generic.label.name') }</Label>
        <InputText type="text" name="name" id="di-profile-name" defaultValue={profile.name} />
        <Label htmlFor="email">{ t('generic.label.email')}</Label>
        <InputText type="email" name="email" id="di-profile-email" defaultValue={profile.email} />
        <SubmitButton color="blue-green" fill="solid" value={t('generic.form.submit')} />
      </APIForm>
      <Link to={ROUTES.PROFILE.CHANGE_PASSWORD(true)}>{ t('profile.links.changePassword') }</Link>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  profile: state.profile,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchProfile: () => {
    dispatch(fetchProfile());
  },
  dispatchUpdateProfile: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updateProfile(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMyProfile);
