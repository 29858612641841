import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { RecordInstance } from '@store/catalog/recordInstances/types';
import APIList from '@containers/application/APIList';
import * as React from 'react';
import { ROUTES } from '@routes';

interface RecordInstanceListRequiredProps {
  recordInstances: RecordInstance[]
  currentPage: number
  totalPages: number
  sortable: boolean
  selectable: boolean
}

interface RecordInstanceListOptionalProps {
  filterable?: boolean
  clickable?: boolean
  getSelected?: (recordInstances:RecordInstance[])=>void
}

type RecordInstanceListProps = RecordInstanceListOptionalProps & RecordInstanceListRequiredProps;

function RecordInstanceList(props:RecordInstanceListProps) {
  const {
    selectable, filterable, currentPage, totalPages,
    recordInstances, sortable, clickable, getSelected,
  } = props;
  const [selected, setSelected] = React.useState([]);

  const toggleAll = (e:React.MouseEvent) => {
    e.stopPropagation();
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected([...recordInstances]);
    }
    getSelected(selected);
  };

  const toggleSelected = (selectedRecordInstance:RecordInstance) => {
    if (selected.filter(
      (record:RecordInstance) => record.id === selectedRecordInstance.id,
    ).length > 0) {
      setSelected(selected.filter(
        (record:RecordInstance) => record.id !== selectedRecordInstance.id,
      ));
    } else {
      setSelected([...selected, selectedRecordInstance]);
    }
    getSelected(selected);
  };

  return (
    <APIList action={CIRCULATION_ACTION_TYPES.HOLDS.FETCH}>
      <PaginatedTable
        showHeader={filterable}
        empty={recordInstances.length === 0}
        currentPage={currentPage}
        totalPages={totalPages}
      >
        <TableHeader>
          <TableRow>
            { selectable && <TableHeaderColumn onClick={toggleAll} sortable={false}><input readOnly type="checkbox" checked={selected.length === recordInstances.length && recordInstances.length > 0} /></TableHeaderColumn> }
            <TableHeaderColumn sortable={sortable} resizable>Name</TableHeaderColumn>
            <TableHeaderColumn sortable={sortable} resizable>Status</TableHeaderColumn>
            <TableHeaderColumn sortable={sortable} resizable>Branch</TableHeaderColumn>
            <TableHeaderColumn sortable={sortable} resizable>Location</TableHeaderColumn>
            <TableHeaderColumn sortable={sortable} resizable>Section</TableHeaderColumn>
            <TableHeaderColumn sortable={sortable} resizable>Condition</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          { recordInstances.map((recordInstance:RecordInstance) => (
            <TableRow
              key={recordInstance.id}
              to={clickable
                ? ROUTES.CIRCULATION.RECORD_INSTANCES.DETAILS(recordInstance.id) : undefined}
            >
              { selectable && (
                <TableColumn tabIndex={0} resizable={false}>
                  <input
                    readOnly
                    type="checkbox"
                    checked={selected.filter(
                      (selectedRecordInstance:
                        RecordInstance) => selectedRecordInstance.id === recordInstance.id,
                    ).length > 0}
                    onClick={(e:React.MouseEvent) => {
                      e.stopPropagation(); toggleSelected(recordInstance);
                    }}
                  />
                </TableColumn>
              )}
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" to={ROUTES.CATALOG.RECORDS.DETAILS(recordInstance.record.id)}>{recordInstance.record.title}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>{recordInstance.status}</TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" to={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.BRANCHES.DETAILS(recordInstance.branch?.id)}>{recordInstance.branch?.title}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" to="">{recordInstance.location?.name}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" to="">{recordInstance.section?.label}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>{recordInstance.condition}</TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
    </APIList>
  );
}

RecordInstanceList.defaultProps = {
  filterable: false,
  clickable: false,
  getSelected: undefined,
};

export default RecordInstanceList;
