import { fetchAuthHeaders, handleAJAXRequest, handleAPIErrors } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  FetchBranchesAction,
  FetchBranchAction,
  CreateBranchAction,
  UpdateBranchAction,
  DestroyBranchAction,
  SearchBranchesAction,
} from '@store/organizations/branches/types';
import {
  branchesFetched,
  branchFetched,
  branchCreated,
  branchUpdated,
  branchDestroyed,
  branchesSearched,
} from '@store/organizations/branches/actions';
import { Observable, ObservableInput } from 'rxjs';
import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';
import { Action } from 'redux';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import CONFIG from '@config/environments/base';
import { RootStateOrAny } from 'react-redux';

const fetchBranchesEpic = (action$:any, state$:any) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.BRANCHES.FETCH),
  switchMap((action:FetchBranchesAction):Observable<Action> => ajax({
    url: `${CONFIG.AUTH_URL}/organizations/${action.options.organizationId}/branches/`,
    method: 'GET',
    headers: fetchAuthHeaders(state$),
  })
    .pipe(
      map(
        (response:AjaxResponse) => branchesFetched(response.response),
      ),
      catchError(
        (error):ObservableInput<Action> => handleAPIErrors(action, error),
      ),
    )),
);

const fetchBranchEpic = (action$:any, state$:any) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.BRANCH.FETCH),
  switchMap((action:FetchBranchAction):Observable<Action> => ajax({
    url: `${CONFIG.AUTH_URL}/organizations/${action.options.organizationId}/branches/${action.id}/`,
    method: 'GET',
    headers: fetchAuthHeaders(state$),
  })
    .pipe(
      map(
        (response:AjaxResponse) => branchFetched(response.response),
      ),
      catchError(
        (error):ObservableInput<Action> => handleAPIErrors(action, error),
      ),
    )),
);

const createBranchEpic = (action$:any, state$:any) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.BRANCH.CREATE),
  switchMap((action:CreateBranchAction):Observable<Action> => ajax({
    url: `${CONFIG.AUTH_URL}/organizations/${action.branch.get('organizationId')}/branches/`,
    method: 'POST',
    headers: fetchAuthHeaders(state$),
    body: action.branch,
  })
    .pipe(
      map(
        (response:AjaxResponse) => branchCreated(response.response),
      ),
      catchError(
        (error):ObservableInput<Action> => handleAPIErrors(action, error),
      ),
    )),
);

const updateBranchEpic = (action$:any, state$:any) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.BRANCH.UPDATE),
  switchMap((action:UpdateBranchAction):Observable<Action> => ajax({
    url: `${CONFIG.AUTH_URL}/organizations/${action.branch.get('organizationId')}/branches/${action.branch.get('id')}/`,
    method: 'PUT',
    headers: fetchAuthHeaders(state$),
    body: action.branch,
  })
    .pipe(
      map(
        (response:AjaxResponse) => branchUpdated(response.response),
      ),
      catchError(
        (error):ObservableInput<Action> => handleAPIErrors(action, error),
      ),
    )),
);

const destroyBranchEpic = (action$:any, state$:any) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.BRANCH.DESTROY),
  switchMap((action:DestroyBranchAction):Observable<Action> => ajax({
    url: `${CONFIG.AUTH_URL}/organizations/${action.data.get('organizationId')}/branches/${action.id}/`,
    method: 'DELETE',
    headers: fetchAuthHeaders(state$),
  })
    .pipe(
      map(
        () => branchDestroyed(action.data.get('id') as string),
      ),
      catchError(
        (error):ObservableInput<Action> => handleAPIErrors(action, error),
      ),
    )),
);

const searchBranchesEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.BRANCHES.SEARCH),
  switchMap((action:SearchBranchesAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/organizations/${action.term}/admins/`,
    method: 'POST',
    success: branchesSearched,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchBranchesEpic,
  fetchBranchEpic,
  createBranchEpic,
  updateBranchEpic,
  destroyBranchEpic,
  searchBranchesEpic,
];
