import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { Fee } from '@store/circulation/fees/types';
import APIList from '@containers/application/APIList';
import * as React from 'react';
import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import { ROUTES } from '@routes';

interface RequiredOwnProps {
  fees: Fee[]
  currentPage:number
  totalPages:number
  numOfRecords:number
  showHeader: boolean
  selectable: boolean
  sortable: boolean
  actions?: React.ReactElement[]
}

interface OptionalOwnProps {
  getSelected?:(fees: Fee[])=>void
}

type FeeListProps = OptionalOwnProps & RequiredOwnProps;

function FeeList(props:FeeListProps) {
  const [selected, setSelected] = React.useState([]);
  const {
    fees, getSelected, currentPage, totalPages,
    showHeader, selectable, sortable, actions, numOfRecords,
  } = props;

  React.useEffect(() => {
    if (getSelected !== undefined) {
      getSelected(selected);
    }
  }, [selected]);

  const toggleAll = (e:React.MouseEvent):void => {
    e.stopPropagation();
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected([...fees]);
    }
  };

  const toggleSelected = (selectedFee:Fee):void => {
    if (selected.filter((fee:Fee) => fee.id === selectedFee.id).length > 0) {
      setSelected(selected.filter((fee:Fee) => fee.id !== selectedFee.id));
    } else {
      setSelected([...selected, selectedFee]);
    }
  };

  return (
    <APIList action={CATALOG_ACTION_TYPES.LISTS.FETCH}>
      <PaginatedTable
        showHeader={showHeader}
        empty={fees.length === 0}
        currentPage={currentPage}
        totalPages={totalPages}
        title="Fees"
        numOfRecordsString={numOfRecords !== undefined ? `${numOfRecords.toLocaleString()} Fees` : ''}
        actions={actions}
      >
        <TableHeader>
          <TableRow>
            { selectable && (
              <TableHeaderColumn
                onClick={toggleAll}
                sortable={false}
                resizable={false}
              >
                <input
                  readOnly
                  type="checkbox"
                  checked={selected.length === fees.length && fees.length > 0}
                />
              </TableHeaderColumn>
            ) }
            <TableHeaderColumn resizable attribute="branch" sortable={sortable}>Branch</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="recordInstance" sortable={sortable}>Record</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="patron" sortable={sortable}>Patron</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="amountDue" sortable={sortable}>Amount Due</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          { fees.map((fee:Fee) => (
            <TableRow key={fee.id}>
              { selectable && (
                <TableColumn
                  tabIndex={0}
                  resizable={false}
                >
                  <input
                    readOnly
                    onClick={(e:React.MouseEvent) => {
                      e.stopPropagation();
                      toggleSelected(fee);
                    }}
                    type="checkbox"
                    checked={selected.filter(
                      (selectedFee:Fee) => selectedFee.id === fee.id,
                    ).length > 0}
                  />
                </TableColumn>
              ) }
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" to={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.BRANCHES.DETAILS(fee.branch?.id)}>{ fee.branch?.title }</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" to={ROUTES.CIRCULATION.RECORD_INSTANCES.DETAILS(fee.recordInstance.id)}>{fee.recordInstance?.record.title}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" to={ROUTES.PATRONS.PATRONS.DETAILS(fee.patron?.id)}>{fee.patron.name}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>{fee.amountDue}</TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
    </APIList>
  );
}

FeeList.defaultProps = {
  getSelected: undefined,
  actions: [],
};

export default FeeList;
