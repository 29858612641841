import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import {
  FetchSectionsAction,
  FetchSectionAction,
  CreateSectionAction,
  UpdateSectionAction,
  DestroySectionAction,
} from '@store/circulation/sections/types';
import {
  sectionsFetched,
  sectionFetched,
  sectionCreated,
  sectionUpdated,
  sectionDestroyed,
} from '@store/circulation/sections/actions';
import { Observable } from 'rxjs';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchSectionsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.SECTIONS.FETCH),
  switchMap((action:FetchSectionsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/sections/`,
    method: 'GET',
    success: sectionsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchSectionEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.SECTION.FETCH),
  switchMap((action:FetchSectionAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/sections/${action.id}/`,
    method: 'GET',
    success: sectionFetched,
    auth: true,
    state$,
    action,
  })),
);

const createSectionEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.SECTION.CREATE),
  switchMap((action:CreateSectionAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/sections/`,
    method: 'POST',
    success: sectionCreated,
    auth: true,
    state$,
    action,
    body: action.section,
  })),
);

const updateSectionEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.SECTION.UPDATE),
  switchMap((action:UpdateSectionAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/sections/${action.id}/`,
    method: 'PUT',
    success: sectionUpdated,
    auth: true,
    state$,
    action,
    body: action.section,
  })),
);

const destroySectionEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.SECTION.DESTROY),
  switchMap((action:DestroySectionAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/sections/${action.id}/`,
    method: 'DELETE',
    success: sectionDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchSectionsEpic,
  fetchSectionEpic,
  createSectionEpic,
  updateSectionEpic,
  destroySectionEpic,
];
