import * as React from 'react';

interface MainMenuProps {
  children: React.ReactNode
}

function MainMenu(props:MainMenuProps) {
  const { children } = props;
  return (
    <ul className="di-main-menu">
      { children }
    </ul>
  );
}

export default MainMenu;
