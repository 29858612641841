import StepAccordionStep from '@components/base/accordions/StepAccordionStep';
import StepAccordionStepClosed from '@components/base/accordions/StepAccordionStepClosed';
import StepAccordionStepOpen from '@components/base/accordions/StepAccordionStepOpen';
import Label from '@components/base/form-fields/Label';
import InlineSearch from '@components/base/search/InlineSearch';
import InlineSearchResult from '@components/base/search/InlineSearchResult';
import { searchByISBN } from '@store/catalog/records/actions';
import { Record } from '@store/catalog/records/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

interface OwnProps {
  step: number
  setRecordTemplate: (record:Record)=>void
  currentStep?: number
  nextStep?: ()=>void
  goToStep?: (step:number)=>void
  totalSteps?: number
}

interface DispatchProps {
  dispatchSearchRecordsByISBN: (isbn:string)=>void
}

interface StateProps {
  searchResults: Record[]
}

type SearchByISBNStepProps = OwnProps & DispatchProps & StateProps;

function SearchByISBN(props:SearchByISBNStepProps) {
  const {
    step, searchResults, dispatchSearchRecordsByISBN,
    currentStep, nextStep, goToStep, totalSteps,
    setRecordTemplate,
  } = props;
  return (
    <StepAccordionStep
      currentStep={currentStep}
      nextStep={nextStep}
      goToStep={goToStep}
      totalSteps={totalSteps}
      step={step}
      title="Search by ISBN"
    >
      <StepAccordionStepOpen disabled={false}>
        <Label htmlFor="isbn">ISBN:</Label>
        <InlineSearch
          id="di-seach-by-isbn"
          name="isbn"
          searchFunction={dispatchSearchRecordsByISBN}
          dynamic={false}
        >
          { searchResults.map((record:Record) => (
            <InlineSearchResult
              onSelect={() => { setRecordTemplate(record); }}
              key={record.id}
            >
              {record.title}
            </InlineSearchResult>
          ))}
        </InlineSearch>
      </StepAccordionStepOpen>
      <StepAccordionStepClosed>
        asdf
      </StepAccordionStepClosed>
    </StepAccordionStep>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  searchResults: state.records.externalSearchResults,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchSearchRecordsByISBN: (isbn:string):void => {
    dispatch(searchByISBN(isbn));
  },
});

SearchByISBN.defaultProps = {
  currentStep: undefined,
  nextStep: undefined,
  goToStep: undefined,
  totalSteps: undefined,
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchByISBN);
