import * as React from 'react';
import * as ReactDOM from 'react-dom';

import './index.scss';
import 'i18n';

import { Provider } from 'react-redux';
import configureStore from '@store/store';
import Disembark from 'Disembark';

ReactDOM.render(
  <Provider store={configureStore()}>
    <React.Suspense fallback={<div />}>
      <Disembark />
    </React.Suspense>
  </Provider>,
  document.getElementById('app'),
);
