import Button from '@components/base/buttons-and-links/Button';
import PreAuthMenu from '@components/compound/menus/PreAuthMenu';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@routes';

function AnonymousMenuOptions() {
  const { t } = useTranslation();
  return (
    <PreAuthMenu>
      <Button fill="solid" color="blue-green" to={ROUTES.AUTHENTICATION.LOGIN}>{ t('menu.anonymous.login') }</Button>
      <Button fill="outline" color="blue-green" to={ROUTES.AUTHENTICATION.SIGNUP}>{ t('menu.anonymous.signup') }</Button>
    </PreAuthMenu>
  );
}

export default AnonymousMenuOptions;
