// TODO: Update function to process ROUTES variable and convert string to functions
export const ROUTES = {
  AUTHENTICATION: {
    SIGNUP: '/signup/',
    LOGIN: '/login/',
    FORGOT_PASSWORD: '/forgot-password/',
    RESET_PASSWORD: '/reset-password/',
    CONFIRM_EMAIL: (email:string = undefined) => ((email === undefined) ? '/confirm-email/:email/' : `/confirm-email/${encodeURIComponent(email)}/`),
  },

  PROFILE: {
    INDEX: (absolute = false) => (absolute ? '/profile/' : '/profile/*'),
    CHANGE_PASSWORD: (absolute = false) => (absolute ? '/profile/change-password/' : '/change-password/'),
    BILLING: (absolute = false) => (absolute ? '/profile/billing/' : '/billing/'),
    FAMILY_MEMBERS: {
      ALL: (absolute = false) => (absolute ? '/profile/family/' : '/family/'),
      NEW: (absolute = false) => (absolute ? '/profile/family/new/' : '/family/new/'),
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/profile/family/:id/' : `/profile/family/${id}/`),
      EDIT: (id:number|string = undefined) => (id === undefined ? '/profile/family/:id/edit/' : `/profile/family/${id}/edit/`),
    },
    NOTIFICATION_SETTINGS: (absolute = false) => (absolute ? '/profile/notifications/' : '/notifications/'),
  },

  DASHBOARD: '/dashboard/',

  CATALOG: {
    BASE: '/catalog/*',
    INDEX: (absolute = false) => (absolute ? '/catalog/' : '/*'),
    LISTS: {
      ALL: (absolute = false) => (absolute ? '/catalog/lists/' : '/lists/*'),
      NEW: (absolute = false) => (absolute ? '/catalog/lists/new/' : 'new/'),
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/lists/:id/*' : `/catalog/lists/${id}/`),
      EDIT: (id:number|string = undefined) => (id === undefined ? '/lists/:id/edit/' : `/catalog/lists/${id}/edit/`),
      ADD_RECORD: (id:number|string = undefined) => (id === undefined ? '/add-record/' : `/catalog/lists/${id}/add-record/`),
    },
    RECORDS: {
      IMPORT: (absolute = false) => (absolute ? '/catalog/records/import/' : '/records/import/*'),
      IMPORT_GOODREADS: (absolute = false) => (absolute ? '/catalog/records/import/goodreads/' : '/goodreads/'),
      IMPORT_CSV: (absolute = false) => (absolute ? '/catalog/records/import/csv/' : '/csv/'),
      SEARCH_RESULTS: (absolute = false) => (absolute ? '/catalog/search/' : '/search/'),
      ALL: (absolute = false) => (absolute ? '/catalog/records/' : '/records/'),
      NEW: (absolute = false) => (absolute ? '/catalog/records/new/' : '/records/new/'),
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/records/:id/*' : `/catalog/records/${id}/`),
      ADD_TO_LIST: (id:number|string = undefined) => (id === undefined ? '/catalog/records/:id/add-to-list/' : `/catalog/records/${id}/add-to-list/`),
      EDIT: (id:number|string = undefined) => (id === undefined ? '/catalog/records/:id/edit/' : `/catalog/records/${id}/edit/`),
      NOTES: {
        NEW: (id:number|string = undefined) => (id === undefined ? '/notes/new/' : `/catalog/records/${id}/notes/new/`),
        DETAILS: (recordId:number, id:number = undefined) => (id === undefined ? '/notes/:id/' : `/catalog/records/${recordId}/notes/${id}/`),
        EDIT: (recordId:number, id:number = undefined) => (id === undefined ? '/notes/:id/edit/' : `/catalog/records/${recordId}/notes/${id}/edit/`),
      },
      REVIEWS: {
        NEW: (id:number|string = undefined) => (id === undefined ? '/reviews/new/' : `/catalog/records/${id}/reviews/new/`),
        EDIT: (recordId:number, id:number = undefined) => (id === undefined ? '/reviews/:id/edit/' : `/catalog/records/${recordId}/reviews/${id}/edit/`),
      },
      RECORD_IMAGES: {
        NEW: (id:number|string = undefined) => (id === undefined ? '/record-images/new/' : `/catalog/records/${id}/record-images/new/`),
        DETAILS: (recordId:number, id:number = undefined) => (id === undefined ? '/record-images/:id/' : `/catalog/records/${recordId}/record-images/${id}/`),
        EDIT: (recordId:number, id:number = undefined) => (id === undefined ? '/record-images/:id/edit/' : `/catalog/records/${recordId}/record-images/${id}/edit/`),
      },
      RECORD_INSTANCES: {
        NEW: (id:number|string = undefined) => (id === undefined ? '/record-instances/new/' : `/catalog/records/${id}/record-instances/new/`),
        DETAILS: (recordId:number, id:number = undefined) => (id === undefined ? `/catalog/records/${recordId}/record-instances/:id/` : `/catalog/records/${recordId}/record-instances/${id}/`),
        EDIT: (recordId:number, id:number = undefined) => (id === undefined ? `/catalog/records/${recordId}/record-instances/:id/edit/` : `/catalog/records/${recordId}/record-instances/${id}/edit/`),
      },
    },
    AUTHORS: {
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/authors/:id/' : `/catalog/authors/${id}/`),
    },
    TAGS: {
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/tags/:id/' : `/catalog/tags/${id}/`),
    },
    GENRES: {
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/genres/:id/' : `/catalog/genres/${id}/`),
    },
    SUBJECTS: {
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/subjects/:id/' : `/catalog/subjects/${id}/`),
    },
    PUBLISHERS: {
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/publishers/:id/' : `/catalog/publishers/${id}/`),
    },
  },

  CIRCULATION: {
    BASE: '/circulation/*',
    INDEX: (absolute = false) => (absolute ? '/circulation/' : '/*'),
    RECORD_INSTANCES: {
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/instances/:id/' : `/circulation/instances/${id}/`),
      SEARCH: (absolute = false) => (absolute ? '/circulation/search/' : '/search/'),
    },
    LEASES: {
      CHECK_IN: (absolute = false) => (absolute ? '/circulation/check-in/' : '/check-in/'),
      CHECK_OUT: (absolute = false) => (absolute ? '/circulation/check-out/' : '/check-out/'),
      RENEW: (absolute = false) => (absolute ? '/circulation/renew/' : '/renew/'),
      ALL: (absolute = false) => (absolute ? '/circulation/all/' : '/all/'),
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/circulation/:id/' : `/circulation/${id}/`),
    },
    HOLDS: {
      ALL: (absolute = false) => (absolute ? '/circulation/holds/' : '/holds/*'),
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/holds/:id/' : `/circulation/holds/${id}/`),
      EDIT: (id:number|string = undefined) => (id === undefined ? '/holds/:id/edit/' : `/circulation/holds/${id}/edit/`),
      NEW: (absolute = false) => (absolute ? '/circulation/holds/new/' : '/holds/new/'),
    },
    FEES: {
      ALL: (absolute = false) => (absolute ? '/circulation/fees/' : '/fees/*'),
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/fees/:id/' : `/circulation/fees/${id}/`),
      EDIT: (id:number|string = undefined) => (id === undefined ? '/fees/:id/edit/' : `/circulation/fees/${id}/edit/`),
      NEW: (absolute = false) => (absolute ? '/circulation/fees/new/' : '/fees/new/'),
    },
    TRANSFERS: {
      ALL: (absolute = false) => (absolute ? '/circulation/transfers/' : '/transfers/*'),
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/transfers/:id/' : `/circulation/transfers/${id}/`),
      EDIT: (id:number|string = undefined) => (id === undefined ? '/transfers/:id/edit/' : `/circulation/transfers/${id}/edit/`),
      NEW: (absolute = false) => (absolute ? '/circulation/transfers/new/' : '/transfers/new/'),
    },
  },

  PROCUREMENT: {
    BASE: '/procurements/*',
    INDEX: (absolute = false) => (absolute ? '/procurements/' : '/*'),
    PURCHASES: {
      ALL: (absolute = false) => (absolute ? '/procurements/purchases/' : '/purchases/*'),
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/purchases/:id/' : `/procurements/purchases/${id}/`),
      EDIT: (id:number|string = undefined) => (id === undefined ? '/purchases/:id/edit/' : `/procurements/purchases/${id}/edit/`),
      NEW: (absolute = false) => (absolute ? '/procurements/purchases/new/' : '/purchases/new/'),
      INVOICES: {
        ALL: (purchaseId:number = undefined) => (purchaseId === undefined ? '/purchases/:purchaseId/invoices/' : `/procurements/purchases/${purchaseId}/invoices/`),
        NEW: (purchaseId:number = undefined) => (purchaseId === undefined ? '/purchases/:purchaseId/invoices/new/' : `/procurements/purchases/${purchaseId}/invoices/new/`),
        DETAILS: (id:number|string = undefined, purchaseId:number = undefined) => (id === undefined ? '/purchases/:purchaseId/invoices/:id/' : `/procurements/purchases/${purchaseId}/invoices/${id}/`),
        EDIT: (id:number|string = undefined, purchaseId:number = undefined) => (id === undefined ? '/purchases/:purchaseId/invoices/:id/edit/' : `/procurements/purchases/${purchaseId}/invoices/${id}/edit/`),
      },
      LINE_ITEMS: {
        ALL: (purchaseId:number = undefined) => (purchaseId === undefined ? '/purchases/:purchaseId/line-items/' : `/procurements/purchases/${purchaseId}/line-items/`),
        NEW: (purchaseId:number = undefined) => (purchaseId === undefined ? '/purchases/:purchaseId/line-items/new/' : `/procurements/purchases/${purchaseId}/line-items/new/`),
        DETAILS: (id:number|string = undefined, purchaseId:number = undefined) => (id === undefined ? '/purchases/:purchaseId/line-items/:id/' : `/procurements/purchases/${purchaseId}/line-items/${id}/`),
        EDIT: (id:number|string = undefined, purchaseId:number = undefined) => (id === undefined ? '/purchases/:purchaseId/line-items/:id/edit/' : `/procurements/purchases/${purchaseId}/line-items/${id}/edit/`),
      },
    },
    BUDGETS: {
      ALL: (absolute = false) => (absolute ? '/procurements/budgets/' : '/budgets/*'),
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/budgets/:id/' : `/procurements/budgets/${id}/`),
      EDIT: (id:number|string = undefined) => (id === undefined ? '/budgets/:id/edit/' : `/procurements/budgets/${id}/edit/`),
      NEW: (absolute = false) => (absolute ? '/procurements/budgets/new/' : '/budgets/new/'),
    },
    SUPPLIERS: {
      ALL: (absolute = false) => (absolute ? '/procurements/suppliers/' : '/suppliers/*'),
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/suppliers/:id/*' : `/procurements/suppliers/${id}/`),
      EDIT: (id:number|string = undefined) => (id === undefined ? '/suppliers/:id/edit/' : `/procurements/suppliers/${id}/edit/`),
      NEW: (absolute = false) => (absolute ? '/procurements/suppliers/new/' : '/suppliers/new/'),
      CONTRACTS: {
        DESTROY: (supplierId:number = undefined, id:number = undefined) => (id === undefined ? '/contracts/:id/destroy/' : `/procurements/suppliers/${supplierId}/contracts/${id}/destroy/`),
      },
    },
    FUNDS: {
      ALL: (absolute = false) => (absolute ? '/procurements/funds/' : '/funds/*'),
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/funds/:id/' : `/procurements/funds/${id}/`),
      EDIT: (id:number|string = undefined) => (id === undefined ? '/funds/:id/edit/' : `/procurements/funds/${id}/edit/`),
      NEW: (absolute = false) => (absolute ? '/procurements/funds/new/' : '/funds/new/'),
    },
  },

  PATRONS: {
    BASE: '/membership/*',
    INDEX: (absolute = false) => (absolute ? '/membership/' : '/*'),
    PATRONS: {
      ALL: (absolute = false) => (absolute ? '/membership/patrons/' : '/patrons/*'),
      SEARCH_RESULTS: (absolute = false) => (absolute ? '/membership/search/' : '/search/*'),
      DETAILS: (id:string = undefined) => (id === undefined ? '/patrons/:id/' : `/membership/patrons/${id}/`),
      EDIT: (id:string = undefined) => (id === undefined ? '/patrons/:id/edit/' : `/membership/patrons/${id}/edit/`),
      NEW: (absolute = false) => (absolute ? '/membership/patrons/new/' : '/patrons/new/'),
      IMPORT: '/patrons/import',
    },
    PATRON_GROUPS: {
      ALL: (absolute = false) => (absolute ? '/membership/groups/' : '/groups/*'),
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/groups/:id/' : `/membership/groups/${id}/`),
      EDIT: (id:number|string = undefined) => (id === undefined ? '/groups/:id/edit/' : `/membership/groups/${id}/edit/`),
      NEW: (absolute = false) => (absolute ? '/membership/groups/new/' : '/groups/new/'),
    },
  },

  REPORTS: '/reports/',

  ORGANIZATION_MANAGEMENT: {
    BASE: '/organizations/*',
    ORGANIZATIONS: {
      INDEX: (absolute = false) => (absolute ? '/organizations/' : '/*'),
      NEW: (absolute = false) => (absolute ? '/organizations/new/' : '/organizations/new/'),
      DEFAULT: (absolute = false) => (absolute ? '/organizations/default/' : '/default/'),
      DETAILS: (id:number|string = undefined) => (id === undefined ? '/:id/' : `/organizations/${id}/`),
      EDIT: (id:number|string = undefined) => (id === undefined ? '/:id/edit/' : `/organizations/${id}/edit/`),
      STAFF_ADMINS: {
        ALL: (organizationId:number|string = undefined) => (organizationId === undefined ? '/:organizationId/staff/*' : `/organizations/${organizationId}/staff/`),
        NEW: (organizationId:number|string = undefined) => (organizationId === undefined ? '/:organizationId/staff/new/' : `/organizations/${organizationId}/staff/new/`),
        DETAILS: (id:number|string = undefined, organizationId:number|string = undefined) => (id === undefined ? '/:organizationId/staff/:id/' : `/organizations/${organizationId}/staff/${id}/`),
        EDIT: (id:number|string = undefined, organizationId:number|string = undefined) => (id === undefined ? '/:organizationId/staff/:id/edit/' : `/organizations/${organizationId}/staff/${id}/edit/`),
      },
      BRANCHES: {
        ALL: (organizationId:number|string = undefined) => (organizationId === undefined ? '/:organizationId/branches/*' : `/organizations/${organizationId}/branches/`),
        NEW: (organizationId:number|string = undefined) => (organizationId === undefined ? '/:organizationId/branches/new/' : `/organizations/${organizationId}/branches/new/`),
        DETAILS: (id:number|string = undefined, organizationId:number|string = undefined) => (id === undefined ? '/:organizationId/branches/:id/' : `/organizations/${organizationId}/branches/${id}/`),
        EDIT: (id:number|string = undefined, organizationId:number|string = undefined) => (id === undefined ? '/:organizationId/branches/:id/edit/' : `/organizations/${organizationId}/branches/${id}/edit/`),
      },
    },
  },

  ROOT: '/',
};

export default ROUTES;
