import { ROUTES } from '@routes';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { clearCurrentRecordLease, updateRecordLease } from '@store/circulation/recordLeases/actions';
import { RecordLease } from '@store/circulation/recordLeases/types';
import { RootState } from '@store/root';
import APIForm from '@containers/application/APIForm';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import SearchRecordLeases from '@containers/circulation/instances/SearchRecordInstances';

interface StateProps { lease:RecordLease }
interface DispatchProps {
  dispatchClearCurrentRecordLease:()=>void
  dispatchUpdateRecordLease:(e:React.MouseEvent<HTMLFormElement>)=>void
}
type RenewARecordLeaseProps = StateProps & DispatchProps;

function RenewARecordLease(props:RenewARecordLeaseProps) {
  const { dispatchClearCurrentRecordLease, lease, dispatchUpdateRecordLease } = props;

  React.useEffect(() => {
    dispatchClearCurrentRecordLease();
  }, []);

  if (lease === undefined) { return <SearchRecordLeases />; }
  return (
    <div>
      { lease.recordInstance?.record.title }
      <APIForm
        onSubmit={dispatchUpdateRecordLease}
        action={CIRCULATION_ACTION_TYPES.LEASE.CREATE}
        id="di-create-password-form"
        onSuccessRoute={ROUTES.CIRCULATION.LEASES.CHECK_OUT(true)}
        onSuccessAction={CIRCULATION_ACTION_TYPES.LEASE.CREATED}
      >
        <input type="hidden" name="patronId" value={lease.patron?.id} />
        <input type="hidden" name="recordId" value={lease.recordInstance?.id} />
        <input type="submit" value="Checkout" />
      </APIForm>
    </div>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  lease: state.recordLeases.currentRecordLease,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchUpdateRecordLease: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updateRecordLease(data));
  },
  dispatchClearCurrentRecordLease: () => {
    dispatch(clearCurrentRecordLease());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RenewARecordLease);
