import {
  faBook, faCircleNotch, faComputerMouse, faSearch, faTv,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface FilterOptionProps {
  children: React.ReactNode
  icon: string
  filter: string
  tabIndex: number
}

const getCorrectIcon = (icon:string) => {
  switch (icon) {
    case 'book':
      return faBook;
    case 'all':
      return faSearch;
    case 'tv':
      return faTv;
    case 'computer':
      return faComputerMouse;
    default:
      return faCircleNotch;
  }
};

function FilterOption(props:FilterOptionProps) {
  const {
    filter, children, icon, tabIndex,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const dropDown = React.useRef(null);
  params.set('f', filter);
  return (
    <div
      ref={dropDown}
      tabIndex={tabIndex}
      role="button"
      className="di-filter-option"
      onClick={() => navigate({ pathname: location.pathname, search: params.toString() })}
      onKeyDown={(e:React.KeyboardEvent<HTMLDivElement>) => {
        console.log(e.key);
        if (document.activeElement === dropDown.current && e.key === 'space') {
          navigate({ pathname: location.pathname, search: params.toString() });
        }
      }}
    >
      <FontAwesomeIcon icon={getCorrectIcon(icon)} />
      {children}
    </div>
  );
}

export default FilterOption;
