import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { Hold } from '@store/circulation/holds/types';
import APIList from '@containers/application/APIList';
import * as React from 'react';
import { ROUTES } from '@routes';

interface HoldListRequiredProps {
  holds: Hold[]
  currentPage: number
  totalPages: number
  sortable: boolean
  selectable: boolean
}

interface HoldListOptionalProps {
  filterable?: boolean
  clickable?: boolean
  getSelected?: (holds:Hold[])=>void
}

type HoldListProps = HoldListOptionalProps & HoldListRequiredProps;

function HoldList(props:HoldListProps) {
  const [selected, setSelected] = React.useState([]);
  const {
    selectable, filterable, currentPage, totalPages, holds, sortable, clickable, getSelected,
  } = props;

  const toggleAll = (e:React.MouseEvent) => {
    e.stopPropagation();
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected([...holds]);
    }
    getSelected(selected);
  };

  const toggleSelected = (selectedHold:Hold) => {
    if (selected.filter((record:Hold) => record.id === selectedHold.id).length > 0) {
      setSelected(selected.filter((record:Hold) => record.id !== selectedHold.id));
    } else {
      setSelected([...selected, selectedHold]);
    }
    getSelected(selected);
  };

  return (
    <APIList action={CIRCULATION_ACTION_TYPES.HOLDS.FETCH}>
      <PaginatedTable
        showHeader={filterable}
        empty={holds.length === 0}
        currentPage={currentPage}
        totalPages={totalPages}
      >
        <TableHeader>
          <TableRow>
            { selectable && <TableHeaderColumn onClick={toggleAll} sortable={false}><input readOnly type="checkbox" checked={selected.length === holds.length && holds.length > 0} /></TableHeaderColumn> }
            <TableHeaderColumn resizable attribute="record" sortable={sortable}>Record</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="patron" sortable={sortable}>Patron</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="status" sortable={sortable}>Status</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="activationDate" sortable={sortable}> Activation Date </TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          { holds.map((hold:Hold) => (
            <TableRow
              key={hold.id}
              to={clickable ? ROUTES.CIRCULATION.HOLDS.DETAILS(hold.id) : undefined}
            >
              { selectable && (
                <TableColumn tabIndex={0} resizable={false}>
                  <input
                    readOnly
                    type="checkbox"
                    checked={selected.filter(
                      (selectedHold:Hold) => selectedHold.id === hold.id,
                    ).length > 0}
                    onClick={(e:React.MouseEvent) => {
                      e.stopPropagation(); toggleSelected(hold);
                    }}
                  />
                </TableColumn>
              )}
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" to={ROUTES.CATALOG.RECORDS.DETAILS(hold.record.id)}>{hold.record.title}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" to={ROUTES.PATRONS.PATRONS.DETAILS(hold.patron?.id)}>{hold.patron?.name}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {hold.status}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {hold.activationDate}
              </TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
    </APIList>
  );
}

HoldList.defaultProps = {
  filterable: false,
  clickable: false,
  getSelected: undefined,
};

export default HoldList;
