import Button from '@components/base/buttons-and-links/Button';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import { fetchOrganization } from '@store/organizations/organizations/actions';
import { Organization } from '@store/organizations/organizations/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

interface StateProps {
  organization:Organization
  organizations:Organization[]
}
interface DispatchProps {
  dispatchFetchOrganization:(id:number|string)=>void
}
type ShowOrganizationDetailsProps = StateProps & DispatchProps;

function ShowOrganizationDetails(props:ShowOrganizationDetailsProps) {
  const { dispatchFetchOrganization, organization, organizations } = props;
  const params = useParams();
  const { id } = params;

  // Used to show a default org
  React.useEffect(() => {
    if (id === undefined && organization?.id !== undefined) {
      dispatchFetchOrganization(organization.id);
    } else if (id !== undefined) {
      dispatchFetchOrganization(id);
    }
  }, [organizations]);

  return organization === undefined ? <div>Loading</div> : (
    <PageContainer>
      <TextHeader size="h1">{organization.title}</TextHeader>
      <Button color="blue-green" fill="solid" to={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.EDIT(organization.id)}>Edit</Button>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  organization: state.organizations.currentOrganization || {},
  organizations: state.organizations.organizations,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchOrganization: (id:number|string):void => {
    dispatch(fetchOrganization(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowOrganizationDetails);
