import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  FetchBudgetsAction,
  FetchBudgetAction,
  CreateBudgetAction,
  UpdateBudgetAction,
  DestroyBudgetAction,
} from '@store/procurement/budgets/types';
import {
  budgetsFetched,
  budgetFetched,
  budgetCreated,
  budgetUpdated,
  budgetDestroyed,
} from '@store/procurement/budgets/actions';
import { Observable } from 'rxjs';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchBudgetsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.BUDGETS.FETCH),
  switchMap((action:FetchBudgetsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/budgets/`,
    method: 'GET',
    success: budgetsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchBudgetEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.BUDGET.FETCH),
  switchMap((action:FetchBudgetAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/budgets/${action.id}/`,
    method: 'GET',
    success: budgetFetched,
    auth: true,
    state$,
    action,
  })),
);

const createBudgetEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.BUDGET.CREATE),
  switchMap((action:CreateBudgetAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/budgets/`,
    method: 'POST',
    success: budgetCreated,
    auth: true,
    state$,
    action,
    body: action.budget,
  })),
);

const updateBudgetEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.BUDGET.UPDATE),
  switchMap((action:UpdateBudgetAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/budgets/${action.id}/`,
    method: 'PUT',
    success: budgetUpdated,
    auth: true,
    state$,
    action,
    body: action.budget,
  })),
);

const destroyBudgetEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.BUDGET.DESTROY),
  switchMap((action:DestroyBudgetAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/budgets/${action.id}/`,
    method: 'DELETE',
    success: budgetDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchBudgetsEpic,
  fetchBudgetEpic,
  createBudgetEpic,
  updateBudgetEpic,
  destroyBudgetEpic,
];
