import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import { fetchGenre } from '@store/catalog/genres/actions';
import { Genre } from '@store/catalog/genres/types';
import { fetchRecords } from '@store/catalog/records/actions';
import { Record } from '@store/catalog/records/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import RecordList from '@containers/catalog/records/shared/RecordList';

interface StateProps {
  records: Record[]
  genre:Genre,
  loading:boolean
  currentPage: number
  totalPages: number
  numOfRecords: number
}
interface DispatchProps {
  dispatchFetchRecords: (id:number|string)=>void
  dispatchFetchGenre: (id:number|string)=>void
}

type ShowGenreDetailsProps =
StateProps & DispatchProps & WithTranslation;

function ShowGenreDetails(props:ShowGenreDetailsProps) {
  const {
    dispatchFetchGenre, dispatchFetchRecords, loading, genre,
    records, currentPage, totalPages, numOfRecords,
  } = props;
  const [selected, setSelected] = React.useState([]);
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchGenre(id);
    dispatchFetchRecords(id);
  }, []);

  const linkSelected = (selectedRecords:Record[]):void => {
    setSelected(selectedRecords);
    console.log(selected);
  };

  return (
    <PageContainer loading={loading}>
      <TextHeader size="h1">{genre.title}</TextHeader>
      <RecordList
        records={records}
        currentPage={currentPage}
        totalPages={totalPages}
        getSelected={linkSelected}
        selectable
        sortable
        showHeader
        numOfRecords={numOfRecords}
      />
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  records: state.records.records,
  currentPage: state.records.currentPage,
  totalPages: state.records.totalPages,
  genre: state.genres.currentGenre || {},
  loading: state.genres.currentGenre === undefined,
  numOfRecords: state.genres.results,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchRecords: (id:number|string) => {
    dispatch(fetchRecords({ genreId: id }));
  },
  dispatchFetchGenre: (id) => {
    dispatch(fetchGenre(id));
  },
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ShowGenreDetails),
);
