import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import * as React from 'react';

interface FilterContainerProps {
  children: React.ReactNode
}

function FilterContainer(props:FilterContainerProps) {
  const { children } = props;
  return (
    <div className="di-filter-container">
      <MultiColumnFlex>
        { children }
        <button type="button" className="di-filter-tool-toggle">Tools</button>
      </MultiColumnFlex>
    </div>
  );
}

export default FilterContainer;
