import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import { createSupplier } from '@store/procurement/suppliers/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import InputText from '@components/base/form-fields/InputText';
import Label from '@components/base/form-fields/Label';

interface DispatchProps {
  dispatchCreateSupplier:(e:React.MouseEvent<HTMLFormElement>)=>void
}
type AddASupplierProps = DispatchProps;

function AddASupplier(props:AddASupplierProps) {
  const { dispatchCreateSupplier } = props;
  return (
    <PageContainer>
      <TextHeader size="h1">Add A Supplier</TextHeader>
      <APIForm
        id="di-add-a-supplier-form"
        action={PROCUREMENT_ACTION_TYPES.SUPPLIER.CREATE}
        onSubmit={dispatchCreateSupplier}
        onSuccessRoute={ROUTES.PROCUREMENT.SUPPLIERS.DETAILS}
        onSuccessAction={PROCUREMENT_ACTION_TYPES.SUPPLIER.CREATED}
      >

        <Label htmlFor="name">Name:</Label>
        <InputText type="text" name="name" id="di-supplier-name" />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>
    </PageContainer>
  );
}

const mapDispatchToProps = (dispatch:Dispatch) => ({
  dispatchCreateSupplier: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createSupplier(data));
  },
});

export default connect(null, mapDispatchToProps)(AddASupplier);
