import * as React from 'react';

interface SubmitButtonProps {
  value: string
  disabled?: boolean
  color: 'blue-green' | 'black' | 'light-brown'
  fill: 'solid' | 'outline'
}

function SubmitButton(props:SubmitButtonProps) {
  const {
    value, disabled, color, fill,
  } = props;
  return <input type="submit" className={`di-submit-${fill} ${color}-background`} value={value} disabled={disabled === undefined ? false : disabled} />;
}

SubmitButton.defaultProps = {
  disabled: false,
};

export default SubmitButton;
