import SubHeader from '@components/base/text/SubHeader';
import TextHeader from '@components/base/text/TextHeader';
import FlexContainer from '@components/layout/generic/FlexContainer';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import { faCircleDollarToSlot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Budget } from '@store/procurement/budgets/types';
import * as React from 'react';

interface BudgetSummaryProps {
  budget: Budget
}

function BudgetSummary(props:BudgetSummaryProps) {
  const { budget } = props;
  return (
    <MultiColumnFlex>
      <FlexContainer style={{ width: 84, textAlign: 'center' }}>
        <FontAwesomeIcon style={{ fontSize: 50, margin: 10, color: 'rgb(195, 200, 210)' }} icon={faCircleDollarToSlot} />
      </FlexContainer>
      <FlexContainer>
        <TextHeader size="h4">{budget?.name}</TextHeader>
        <SubHeader>
          { budget.name }
          <span> •&nbsp;</span>
          <span>
            {budget?.amount}
          </span>
        </SubHeader>
      </FlexContainer>
    </MultiColumnFlex>
  );
}

export default BudgetSummary;
