import NoteListItem from '@components/base/list-items/NoteListItem';
import { faPencilSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROUTES } from '@routes';
import { Note } from '@store/catalog/notes/types';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';

interface NotesListProps {
  notes: Note[]
}

function NotesList(props:NotesListProps) {
  const params = useParams();
  const { notes } = props;
  const { id } = params;

  return (
    <div className="di-notes-list">
      <div>
        <FontAwesomeIcon icon={faPencilSquare} />
        { notes.length }
        {' '}
        Notes
      </div>
      <ul>
        { notes.map((note:Note) => <NoteListItem key={note.id} note={note} />)}
        <li>
          <Link to={ROUTES.CATALOG.RECORDS.NOTES.NEW(id)}>Add Note</Link>
        </li>
      </ul>
    </div>
  );
}

export default NotesList;
