import * as React from 'react';

interface RequiredStepAccordionProps {
  children: React.ReactElement[]
}
interface OptionalStepAccordionProps {
  initialStep?: number
}
type StepAccordionProps = RequiredStepAccordionProps & OptionalStepAccordionProps;

function StepAccordion(props:StepAccordionProps) {
  const { initialStep, children } = props;
  const [currentStep, setCurrentStep] = React.useState(initialStep);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const goToStep = (step:number) => {
    setCurrentStep(step);
  };

  const cloned = children.map(
    (
      element:React.ReactElement,
      index:number,
    ) => React.cloneElement(element, {
      currentStep,
      key: `i-${1 + index}`,
      nextStep,
      goToStep,
      totalSteps: children.length,
    }),
  );
  return (
    <div className="di-step-accordion">
      { cloned }
    </div>
  );
}

StepAccordion.defaultProps = {
  initialStep: 1,
};

export default StepAccordion;
