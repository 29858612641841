import { ROUTES } from '@routes';
import { fetchBudgets } from '@store/procurement/budgets/actions';
import { Budget } from '@store/procurement/budgets/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '@store/root';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import Button from '@components/base/buttons-and-links/Button';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import FlexContainer from '@components/layout/generic/FlexContainer';
import RightAlignedFlexContainer from '@components/layout/generic/RightAlignedFlexContainer';
import SubHeader from '@components/base/text/SubHeader';
import BudgetList from './shared/BudgetList';

interface StateProps {
  budgets: Budget[]
  currentPage: number
  totalPages: number
  numOfRecords: number
}
interface DispatchProps {
  dispatchFetchBudgets:()=>void
}
type ShowAllBudgetsProps = StateProps & DispatchProps;

function ShowAllBudgets(props:ShowAllBudgetsProps) {
  const {
    dispatchFetchBudgets, budgets, currentPage, totalPages, numOfRecords,
  } = props;
  React.useEffect(() => {
    dispatchFetchBudgets();
  }, []);
  const [selected, setSelected] = React.useState([]);

  const linkSelected = (selectedSuppliers:Budget[]) => {
    setSelected(selectedSuppliers);
    console.log(selected);
  };
  return (
    <PageContainer>
      <MultiColumnFlex>
        <FlexContainer flexValue={1}>
          <TextHeader size="h1">Budgets</TextHeader>
          <SubHeader>
            {numOfRecords}
            {' '}
            budgets
          </SubHeader>
        </FlexContainer>
        <RightAlignedFlexContainer>
          <Button color="blue-green" fill="solid" to={ROUTES.PROCUREMENT.BUDGETS.NEW(true)}>Add a Budget</Button>
        </RightAlignedFlexContainer>
      </MultiColumnFlex>
      <BudgetList
        sortable
        selectable
        getSelected={linkSelected}
        budgets={budgets}
        currentPage={currentPage}
        totalPages={totalPages}
        filterable
      />
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  budgets: state.budgets.budgets,
  currentPage: state.budgets.currentPage,
  totalPages: state.budgets.totalPages,
  numOfRecords: state.budgets.results,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchBudgets: ():void => {
    dispatch(fetchBudgets());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllBudgets);
