import { PROFILE_ACTION_TYPES } from '@store/profile/actionTypes';
import {
  CreateProfileAction,
  DestroyProfileAction,
  FetchProfileAction,
  Profile,
  ProfileCreatedAction,
  ProfileDestroyedAction,
  ProfileFetchedAction,
  ProfileUpdatedAction,
  UpdateProfileAction,
} from '@store/profile/profile/types';

export function fetchProfile():FetchProfileAction {
  return {
    type: PROFILE_ACTION_TYPES.PROFILE.FETCH,
  };
}

export function profileFetched(response:Profile):ProfileFetchedAction {
  return {
    type: PROFILE_ACTION_TYPES.PROFILE.FETCHED,
    response,
  };
}

export function createProfile(credentials:FormData):CreateProfileAction {
  return {
    type: PROFILE_ACTION_TYPES.PROFILE.CREATE,
    credentials,
  };
}

export function profileCreated():ProfileCreatedAction {
  return {
    type: PROFILE_ACTION_TYPES.PROFILE.CREATED,
  };
}
export function updateProfile(data:FormData):UpdateProfileAction {
  return {
    type: PROFILE_ACTION_TYPES.PROFILE.UPDATE,
    data,
  };
}

export function profileUpdated(profile:Profile):ProfileUpdatedAction {
  return {
    type: PROFILE_ACTION_TYPES.PROFILE.UPDATED,
    profile,
  };
}

export function destroyProfile(data:FormData):DestroyProfileAction {
  return {
    type: PROFILE_ACTION_TYPES.PROFILE.DESTROY,
    data,
  };
}

export function profileDestroyed():ProfileDestroyedAction {
  return {
    type: PROFILE_ACTION_TYPES.PROFILE.DESTROYED,
  };
}
