import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import {
  FetchNotificationsAction,
  FetchNotificationAction,
  CreateNotificationAction,
  UpdateNotificationAction,
  DestroyNotificationAction,
} from '@store/notifications/types';
import {
  notificationsFetched,
  notificationFetched,
  notificationCreated,
  notificationUpdated,
  notificationDestroyed,
} from '@store/notifications/actions';
import { Observable } from 'rxjs';
import { NOTIFICATION_ACTION_TYPES } from '@store/notifications/actionTypes';
import { Action } from 'redux';
import CONFIG from '@config/environments/base';
import { RootStateOrAny } from 'react-redux';

const fetchNotificationsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(NOTIFICATION_ACTION_TYPES.NOTIFICATIONS.FETCH),
  switchMap((action:FetchNotificationsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.NOTIFICATIONS_URL}/notifications/`,
    method: 'GET',
    success: notificationsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchNotificationEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(NOTIFICATION_ACTION_TYPES.NOTIFICATION.FETCH),
  switchMap((action:FetchNotificationAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.NOTIFICATIONS_URL}/notifications/${action.id}/`,
    method: 'GET',
    success: notificationFetched,
    auth: true,
    state$,
    action,
  })),
);

const createNotificationEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(NOTIFICATION_ACTION_TYPES.NOTIFICATION.CREATE),
  switchMap((action:CreateNotificationAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.NOTIFICATIONS_URL}/notifications/`,
    method: 'POST',
    success: notificationCreated,
    auth: true,
    state$,
    action,
    body: action.notification,
  })),
);

const updateNotificationEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(NOTIFICATION_ACTION_TYPES.NOTIFICATION.UPDATE),
  switchMap((action:UpdateNotificationAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.NOTIFICATIONS_URL}/notifications/${action.id}/`,
    method: 'PUT',
    success: notificationUpdated,
    auth: true,
    state$,
    action,
    body: action.notification,
  })),
);

const destroyNotificationEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(NOTIFICATION_ACTION_TYPES.NOTIFICATION.DESTROY),
  switchMap((action:DestroyNotificationAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.NOTIFICATIONS_URL}/notifications/${action.id}/`,
    method: 'DELETE',
    success: notificationDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchNotificationsEpic,
  fetchNotificationEpic,
  createNotificationEpic,
  updateNotificationEpic,
  destroyNotificationEpic,
];
