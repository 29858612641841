import * as React from 'react';

interface RightAlignedFlexContainerProps {
  children: React.ReactNode
}

function RightAlignedFlexContainer(props:RightAlignedFlexContainerProps) {
  const { children } = props;
  return (
    <div className="di-right-aligned-flex-container">
      {children}
    </div>
  );
}

export default RightAlignedFlexContainer;
