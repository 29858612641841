import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import { destroyFund, fetchFund, updateFund } from '@store/procurement/funds/actions';
import { Fund } from '@store/procurement/funds/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import InputText from '@components/base/form-fields/InputText';
import Label from '@components/base/form-fields/Label';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import { RootState } from '@store/root';

interface StateProps {
  fund:Fund
}
interface DispatchProps {
  dispatchFetchFund:(id:number|string)=>void
  dispatchUpdateFund:(e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchDestroyFund:(e:React.MouseEvent<HTMLFormElement>)=>void
}
type EditAFundProps = StateProps & DispatchProps;

function EditAFund(props:EditAFundProps) {
  const {
    dispatchFetchFund, fund, dispatchUpdateFund, dispatchDestroyFund,
  } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchFund(id);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Edit A Fund</TextHeader>
      <APIForm
        id="di-edit-a-fund"
        onSubmit={dispatchUpdateFund}
        action={PROCUREMENT_ACTION_TYPES.FUND.UPDATE}
        onSuccessRoute={ROUTES.PROCUREMENT.FUNDS.DETAILS}
        onSuccessAction={PROCUREMENT_ACTION_TYPES.FUND.UPDATED}
        entityID={fund.id}
      >

        <input type="hidden" name="id" defaultValue={fund.id} />
        <Label htmlFor="name">Name:</Label>
        <InputText id="di-fund-name" type="text" name="name" defaultValue={fund.name} />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>

      <APIForm
        id="di-destroy-a-fund"
        onSubmit={dispatchDestroyFund}
        action={PROCUREMENT_ACTION_TYPES.FUND.DESTROY}
        onSuccessRoute={ROUTES.PROCUREMENT.FUNDS.ALL(true)}
        onSuccessAction={PROCUREMENT_ACTION_TYPES.FUND.DESTROYED}
        entityID={fund.id}
      >
        <input type="hidden" name="id" defaultValue={fund.id} />
        <SubmitButton color="blue-green" fill="outline" value="Delete" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  fund: state.funds.currentFund,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchFund: (id:number|string):void => {
    dispatch(fetchFund(id));
  },
  dispatchUpdateFund: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updateFund(data));
  },
  dispatchDestroyFund: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyFund(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAFund);
