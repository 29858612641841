import { ROUTES } from '@routes';
import { addRecordsToList, destroyRecords, fetchRecords } from '@store/catalog/records/actions';
import { FetchRecordsOptions, Record } from '@store/catalog/records/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import Button from '@components/base/buttons-and-links/Button';
import RecordList from '@containers/catalog/records/shared/RecordList';
import { useLocation } from 'react-router-dom';
import MultiActionButton from '@components/base/buttons-and-links/MultiActionButton';
import { faList, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MultiActionButtonItem from '@components/base/buttons-and-links/MultiActionButtonItem';
import Modal from '@components/compound/modals/Modal';
import { List } from '@store/catalog/lists/types';
import { fetchLists } from '@store/catalog/lists/actions';
import TextHeader from '@components/base/text/TextHeader';
import InputSelect from '@components/base/form-fields/InputSelect';
import SubmitButton from '@components/base/form-fields/SubmitButton';

interface StateProps {
  records: Record[]
  lists: List[]
  totalPages: number
  currentPage: number
  numOfRecords: number
}
interface DispatchProps {
  dispatchFetchRecords:()=>void
  dispatchFetchLists:()=>void
  dispatchDestroyRecords: (records:Record[])=>void
  dispatchAddRecordsToList: (records:Record[], id:number)=>void
}

type ShowAllRecordsProps = StateProps & DispatchProps;

function ShowAllRecords(props: ShowAllRecordsProps) {
  const [selected, setSelected] = React.useState([]);
  const [showListModal, setShowListModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const {
    dispatchFetchRecords, dispatchFetchLists, dispatchDestroyRecords, dispatchAddRecordsToList,
    records, currentPage, totalPages, numOfRecords, lists,
  } = props;
  const location = useLocation();

  React.useEffect(() => {
    dispatchFetchRecords();
    dispatchFetchLists();
  }, [location]);

  const linkSelected = (selectedRecords:Record[]):void => {
    setSelected(selectedRecords);
  };

  const submitAddRecordsToList = (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const form = Object.fromEntries(data.entries());
    dispatchAddRecordsToList(selected, parseInt(form.id as string, 10));
    setShowListModal(false);
  };

  const actions = [
    <Button key={1} color="blue-green" fill="solid" to={ROUTES.CATALOG.RECORDS.NEW(true)}>Add a Record</Button>,
    <Button key={2} color="blue-green" fill="outline" to={ROUTES.CATALOG.RECORDS.IMPORT(true)}>Import Records</Button>,
    (
      <MultiActionButton key={3} tabIndex={0} label="Bulk Actions" disabled={selected.length === 0} style={{ display: 'inline-block' }}>
        <MultiActionButtonItem onClick={() => setShowListModal(true)}>
          <FontAwesomeIcon icon={faList} />
          Add To List
        </MultiActionButtonItem>
        <MultiActionButtonItem onClick={() => setShowDeleteModal(true)}>
          <FontAwesomeIcon icon={faTrash} />
          Delete
        </MultiActionButtonItem>
      </MultiActionButton>
    ),
  ];

  return (
    <PageContainer>
      <RecordList
        records={records}
        currentPage={currentPage}
        totalPages={totalPages}
        numOfRecords={numOfRecords}
        getSelected={linkSelected}
        selectable
        sortable
        showHeader
        actions={actions}
      />
      <Modal exitClick={() => setShowDeleteModal(false)} style={showDeleteModal ? {} : { display: 'none' }}>
        <TextHeader size="h3">Delete Records?</TextHeader>
        <p>Are you sure you want to delete these records?</p>
        <Button fill="solid" color="blue-green" onClick={() => { dispatchDestroyRecords(selected); setShowDeleteModal(false); }}>Yes</Button>
      </Modal>
      <Modal exitClick={() => setShowListModal(false)} style={showListModal ? {} : { display: 'none' }}>
        <TextHeader size="h3">Select a List</TextHeader>
        <form onSubmit={submitAddRecordsToList}>
          <InputSelect name="id" id="di-lists">
            {lists.map((list:List) => <option key={list.id} value={list.id}>{list.name}</option>)}
          </InputSelect>
          <SubmitButton fill="solid" color="blue-green" value="Add" />
        </form>
      </Modal>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  records: state.records.records,
  lists: state.lists.all,
  currentPage: state.records.currentPage,
  totalPages: state.records.totalPages,
  numOfRecords: state.records.results,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchRecords: (options:FetchRecordsOptions = undefined):void => {
    dispatch(fetchRecords(options));
  },
  dispatchFetchLists: ():void => {
    dispatch(fetchLists());
  },
  dispatchDestroyRecords: (records:Record[]):void => {
    dispatch(destroyRecords(records));
  },
  dispatchAddRecordsToList: (records:Record[], listId:number):void => {
    dispatch(addRecordsToList(records, listId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllRecords);
