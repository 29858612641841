import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import { fetchAuthor } from '@store/catalog/authors/actions';
import { Author } from '@store/catalog/authors/types';
import { fetchRecords } from '@store/catalog/records/actions';
import { Record } from '@store/catalog/records/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import RecordList from '@containers/catalog/records/shared/RecordList';

interface StateProps {
  records: Record[]
  author:Author,
  loading:boolean
  currentPage: number
  totalPages: number
  numOfRecords: number
}
interface DispatchProps {
  dispatchFetchRecords: (id:number|string)=>void
  dispatchFetchAuthor: (id:number|string)=>void
}
type ShowAuthorDetailsProps =
  StateProps & DispatchProps & WithTranslation;

function ShowAuthorDetails(props:ShowAuthorDetailsProps) {
  const {
    dispatchFetchAuthor, dispatchFetchRecords,
    loading, author, records, currentPage, totalPages, numOfRecords,
  } = props;
  const [selected, setSelected] = React.useState([]);
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchAuthor(id);
    dispatchFetchRecords(id);
  }, []);

  const linkSelected = (selectedRecords:Record[]):void => {
    setSelected(selectedRecords);
    console.log(selected);
  };

  return (
    <PageContainer loading={loading}>
      <TextHeader size="h1">{author.name}</TextHeader>
      <RecordList
        records={records}
        currentPage={currentPage}
        totalPages={totalPages}
        numOfRecords={numOfRecords}
        getSelected={linkSelected}
        selectable
        sortable
        showHeader
      />
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  records: state.records.records,
  currentPage: state.records.currentPage,
  totalPages: state.records.totalPages,
  numOfRecords: state.records.results,
  author: state.authors.currentAuthor || {},
  loading: state.authors.currentAuthor === undefined,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchRecords: (id:number|string) => {
    dispatch(fetchRecords({ authorId: id }));
  },
  dispatchFetchAuthor: (id:number|string) => {
    dispatch(fetchAuthor(id));
  },
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ShowAuthorDetails),
);
