// import { PaginatedAPIResponse } from '@store/application/types';
import { PATRON_ACTION_TYPES } from '@store/patrons/actionTypes';
import {
  CreateGroupMembershipsAction,
  CreatePatronGroupAction,
  DestroyPatronGroupAction,
  DestroyPatronGroupsAction,
  FetchGroupsOptions,
  FetchPatronGroupAction,
  FetchPatronGroupsAction,
  GroupMembershipsCreatedAction,
  PatronGroup,
  PatronGroupCreatedAction,
  PatronGroupDestroyedAction,
  PatronGroupFetchedAction,
  PatronGroupsDestroyedAction,
  PatronGroupsFetchedAction,
  PatronGroupsSearchedAction,
  PatronGroupUpdatedAction,
  SearchPatronGroupsAction,
  UpdatePatronGroupAction,
} from '@store/patrons/groups/types';
import { Patron } from '../patrons/types';

// TODO: Clean up options use

type FetchPatronGroups = (options?:FetchGroupsOptions) => FetchPatronGroupsAction;
export const fetchPatronGroups:FetchPatronGroups = (
  options:FetchGroupsOptions = undefined,
):FetchPatronGroupsAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUPS.FETCH,
  options,
});
// interface PatronGroupsFetchedResponse extends PaginatedAPIResponse {
//   data: PatronGroup[]
// }
type PatronGroupsFetched = (response:PatronGroup[]) => PatronGroupsFetchedAction;

export const patronGroupsFetched:PatronGroupsFetched = (
  response:PatronGroup[],
):PatronGroupsFetchedAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUPS.FETCHED,
  groups: response,
});

export const fetchPatronGroup = (id:number|string):FetchPatronGroupAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUP.FETCH,
  id,
});

export const patronGroupFetched = (group:PatronGroup):PatronGroupFetchedAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUP.FETCHED,
  group,
});

export const createPatronGroup = (group:FormData):CreatePatronGroupAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUP.CREATE,
  organizationId: group.get('organizationId') as string|number,
  group,
});

export const patronGroupCreated = (group:PatronGroup):PatronGroupCreatedAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUP.CREATED,
  group,
  id: group.id,
});

export const updatePatronGroup = (group:FormData):UpdatePatronGroupAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUP.UPDATE,
  id: group.get('id') as string|number,
  organizationId: group.get('organizationId') as string|number,
  group,
});

export const patronGroupUpdated = (group:PatronGroup):PatronGroupUpdatedAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUP.UPDATED,
  id: group.id,
  group,
});

export const destroyPatronGroup = (group:FormData):DestroyPatronGroupAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUP.DESTROY,
  id: group.get('id') as string|number,
});

export const patronGroupDestroyed = (id:number|string):PatronGroupDestroyedAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUP.DESTROYED,
  id,
});

export const destroyPatronGroups = (
  groups:PatronGroup[],
):DestroyPatronGroupsAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUPS.DESTROY,
  groups,
});

export const patronGroupsDestroyed = ():PatronGroupsDestroyedAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUPS.DESTROYED,
});

export const searchPatronGroups = (term:string):SearchPatronGroupsAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUPS.SEARCH,
  term,
});

export const patronGroupsSearched = (groups:PatronGroup[]):PatronGroupsSearchedAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUPS.SEARCHED,
  groups,
});

export const createGroupMemberships = (
  groupId:number|string,
  patrons:Patron[],
):CreateGroupMembershipsAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUP.CREATE_MEMBERSHIPS,
  groupId,
  patrons,
});

export const groupMembershipsCreated = (patrons:Patron[]):GroupMembershipsCreatedAction => ({
  type: PATRON_ACTION_TYPES.PATRON_GROUP.MEMBERSHIPS_CREATED,
  patrons,
});
