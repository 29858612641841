import { ROUTES } from '@routes';
import { fetchSuppliers } from '@store/procurement/suppliers/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { Supplier } from '@store/procurement/suppliers/types';
import { RootState } from '@store/root';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import Button from '@components/base/buttons-and-links/Button';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import FlexContainer from '@components/layout/generic/FlexContainer';
import SubHeader from '@components/base/text/SubHeader';
import RightAlignedFlexContainer from '@components/layout/generic/RightAlignedFlexContainer';
import SupplierList from './shared/SupplierList';

interface StateProps {
  suppliers:Supplier[]
  currentPage: number
  totalPages: number
  numOfRecords: number
}
interface DispatchProps {
  dispatchFetchSuppliers:()=>void
}
type ShowAllSuppliersProps = StateProps & DispatchProps;

function ShowAllSuppliers(props:ShowAllSuppliersProps) {
  const {
    dispatchFetchSuppliers, suppliers, currentPage, totalPages, numOfRecords,
  } = props;
  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    dispatchFetchSuppliers();
  }, []);

  const linkSelected = (selectedSuppliers:Supplier[]) => {
    setSelected(selectedSuppliers);
    console.log(selected);
  };
  return (
    <PageContainer>
      <MultiColumnFlex>
        <FlexContainer flexValue={1}>
          <TextHeader size="h1">Suppliers</TextHeader>
          <SubHeader>
            {numOfRecords}
            {' '}
            suppliers
          </SubHeader>
        </FlexContainer>
        <RightAlignedFlexContainer>
          <Button color="blue-green" fill="solid" to={ROUTES.PROCUREMENT.SUPPLIERS.NEW(true)}>Add a Supplier</Button>
        </RightAlignedFlexContainer>
      </MultiColumnFlex>
      <SupplierList
        sortable
        selectable
        getSelected={linkSelected}
        suppliers={suppliers}
        currentPage={currentPage}
        totalPages={totalPages}
        filterable
      />
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState) => ({
  suppliers: state.suppliers.suppliers,
  currentPage: state.suppliers.currentPage,
  totalPages: state.suppliers.totalPages,
  numOfRecords: state.suppliers.results,
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  dispatchFetchSuppliers: () => {
    dispatch(fetchSuppliers());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllSuppliers);
