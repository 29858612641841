import { ApplicationMessage } from '@store/application/reducer';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';

export interface BreadCrumb {
  name: string
  to: string
}
interface RequiredOwnProps {
  children: React.ReactNode
}

interface OptionalOwnProps {
  breadcrumbs?: BreadCrumb[]
  loading?: boolean
  pageActions?: string[]
  redirectAction?: string
  redirectRoute?: string
}

interface StateProps {
  completed: ApplicationMessage[]
  errors: ApplicationMessage[]
}

type PageContainerProps = OptionalOwnProps & RequiredOwnProps & StateProps;

function PageContainer(props:PageContainerProps) {
  const {
    loading, children, breadcrumbs, pageActions, redirectAction, redirectRoute, completed, errors,
  } = props;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let redirect;
  if (
    redirectAction !== undefined
    && completed.findIndex((message) => message.action === redirectAction) > -1
  ) {
    redirect = <Navigate replace to={redirectRoute} />;
  }

  const completedActions = completed.filter(
    (message:ApplicationMessage) => pageActions.includes(message.action),
  );
  const errorsToDisplay = errors.filter(
    (message:ApplicationMessage) => pageActions.includes(message.target),
  );

  return (
    <div className={`di-page-container ${loading ? 'di-page-container-loading' : ''}`}>
      <div className="di-page-breadcrumbss">
        { breadcrumbs.map((breadcrumb:BreadCrumb, index:number) => ((index === 0)
          ? <span key={breadcrumb.name}><Link to={breadcrumb.to}>{breadcrumb.name}</Link></span>
          : (
            <span key={breadcrumb.name}>
              {' '}
              •
              {' '}
              <Link to={breadcrumb.to}>
                {breadcrumb.name}
              </Link>
            </span>
          )))}
      </div>
      <div>
        <ul>
          { completedActions.map((action) => <li key={action.action}>{action.action}</li>)}
          { errorsToDisplay.map((action) => (
            <li key={action.action}>
              ERROR:
              {' '}
              {action.action}
            </li>
          ))}
        </ul>
      </div>
      { children }
      { redirect }
    </div>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  completed: state.application.filter((message:ApplicationMessage) => message.state === 'completed'),
  errors: state.application.filter((message:ApplicationMessage) => message.state === 'error'),
});

PageContainer.defaultProps = {
  loading: false,
  breadcrumbs: [],
  pageActions: [],
  redirectAction: undefined,
  redirectRoute: undefined,
};

export default connect(mapStateToProps, null)(PageContainer);
