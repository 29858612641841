import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';
import { createBranch } from '@store/organizations/branches/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import InputText from '@components/base/form-fields/InputText';
import Label from '@components/base/form-fields/Label';
import { useParams } from 'react-router-dom';

interface DispatchProps { dispatchCreateBranch:(e:React.MouseEvent<HTMLFormElement>)=>void }
type CreateABranchProps = DispatchProps;

function CreateABranch(props:CreateABranchProps) {
  const { dispatchCreateBranch } = props;
  const params = useParams();
  const { organizationId } = params;
  return (
    <PageContainer>
      <TextHeader size="h1">Add An Branch</TextHeader>
      <APIForm
        id="di-add-a-branch-form"
        action={ORGANIZATION_ACTION_TYPES.BRANCH.CREATE}
        onSubmit={dispatchCreateBranch}
        onSuccessRoute={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.BRANCHES.DETAILS}
        onSuccessAction={ORGANIZATION_ACTION_TYPES.BRANCH.CREATED}
        parentRouteParams={[{ key: ':organizationId', value: organizationId, prefix: '/organizations' }]}
      >
        <Label htmlFor="name">Name:</Label>
        <InputText type="text" name="title" id="di-branch-name" />
        <input type="hidden" name="organizationId" value={organizationId} />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>
    </PageContainer>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreateBranch: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createBranch(data));
  },
});

export default connect(null, mapDispatchToProps)(CreateABranch);
