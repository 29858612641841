import { fetchAuthHeaders, handleAPIErrors } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  FetchOrganizationsAction,
  FetchOrganizationAction,
  CreateOrganizationAction,
  UpdateOrganizationAction,
  DestroyOrganizationAction,
  // CheckOrganizationShortNameAction,
} from '@store/organizations/organizations/types';
import {
  organizationsFetched,
  organizationFetched,
  organizationCreated,
  organizationUpdated,
  organizationDestroyed,
  // organizationShortNameChecked,
} from '@store/organizations/organizations/actions';
import { Observable, ObservableInput } from 'rxjs';
import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';
import { Action } from 'redux';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import CONFIG from '@config/environments/base';
import { RootStateOrAny } from 'react-redux';

const fetchOrganizationsEpic = (
  action$:any,
  state$:any,
) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.ORGANIZATIONS.FETCH),
  switchMap((action:FetchOrganizationsAction):Observable<Action> => ajax({
    url: `${CONFIG.AUTH_URL}/organizations/`,
    method: 'GET',
    headers: fetchAuthHeaders(state$),
  }).pipe(
    map(
      (response:AjaxResponse) => organizationsFetched(response.response),
    ),
    catchError(
      (error):ObservableInput<Action> => handleAPIErrors(action, error),
    ),
  )),
);

const fetchOrganizationEpic = (
  action$:any,
  state$:any,
) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.ORGANIZATION.FETCH),
  switchMap((action:FetchOrganizationAction):Observable<Action> => ajax({
    url: `${CONFIG.AUTH_URL}/organizations/${action.id}/`,
    method: 'GET',
    headers: fetchAuthHeaders(state$),
  })
    .pipe(
      map(
        (response:AjaxResponse) => organizationFetched(response.response),
      ),
      catchError(
        (error):ObservableInput<Action> => handleAPIErrors(action, error),
      ),
    )),
);

const createOrganizationEpic = (
  action$:any,
  state$:RootStateOrAny,
) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.ORGANIZATION.CREATE),
  switchMap((action:CreateOrganizationAction):Observable<Action> => ajax({
    url: `${CONFIG.AUTH_URL}/organizations/`,
    method: 'POST',
    headers: fetchAuthHeaders(state$),
    body: action.organization,
  })
    .pipe(
      map((response:AjaxResponse) => organizationCreated(response.response)),
      catchError((error):ObservableInput<Action> => handleAPIErrors(action, error)),
    )),
);

const updateOrganizationEpic = (
  action$:any,
  state$:RootStateOrAny,
) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.ORGANIZATION.UPDATE),
  switchMap((action:UpdateOrganizationAction):Observable<Action> => ajax({
    url: `${CONFIG.AUTH_URL}/organizations/${action.organization.get('id')}/`,
    method: 'PUT',
    headers: fetchAuthHeaders(state$),
    body: action.organization,
  })
    .pipe(
      map(
        (response:AjaxResponse) => organizationUpdated(response.response),
      ),
      catchError(
        (error):ObservableInput<Action> => handleAPIErrors(action, error),
      ),
      catchError(
        (error):ObservableInput<Action> => handleAPIErrors(action, error),
      ),
    )),
);

const destroyOrganizationEpic = (
  action$:any,
  state$:RootStateOrAny,
) => action$.pipe(
  ofType(ORGANIZATION_ACTION_TYPES.ORGANIZATION.DESTROY),
  switchMap((action:DestroyOrganizationAction):Observable<Action> => ajax({
    url: `${CONFIG.AUTH_URL}/organizations/${action.data.get('id')}/`,
    method: 'DELETE',
    headers: fetchAuthHeaders(state$),
  })
    .pipe(
      map(
        () => organizationDestroyed(action.data.get('id') as string),
      ),
      catchError(
        (error):ObservableInput<Action> => handleAPIErrors(action, error),
      ),
    )),
);

// const checkOrganizationShortNameEpic = (
//   action$:any,
// ) => action$.pipe(
//   ofType(ORGANIZATION_ACTION_TYPES.ORGANIZATION.CHECK_SHORT_NAME),
//   switchMap((action:CheckOrganizationShortNameAction):Observable<Action> => ajax({
//     url: '/data/organizations/check-short-name.json',
//     method: 'GET',
//     headers: fetchAuthHeaders(state$),
//   }).pipe(
//     map(
//       (response:AjaxResponse) => organizationShortNameChecked(response.response.alternativeName),
//     ),
//     catchError(
//       (error):ObservableInput<Action> => handleAPIErrors(action, error),
//     ),
//   )),
// );

export default [
  fetchOrganizationsEpic,
  fetchOrganizationEpic,
  createOrganizationEpic,
  updateOrganizationEpic,
  destroyOrganizationEpic,
  // checkOrganizationShortNameEpic,
];
