import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RecordLease } from '@store/circulation/recordLeases/types';
import APIForm from '@containers/application/APIForm';
import {
  clearCurrentRecordLease, destroyRecordLease,
} from '@store/circulation/recordLeases/actions';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { ROUTES } from '@routes';
import { RootState } from '@store/root';
import SearchRecordLeases from '@containers/circulation/instances/SearchRecordInstances';

interface StateProps { lease:RecordLease }
interface DispatchProps {
  dispatchDestroyRecordLease:(e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchClearCurrentRecordLease:()=>void
}

type CheckInARecordLeaseProps = StateProps & DispatchProps;

function CheckInARecordLease(props:CheckInARecordLeaseProps) {
  const { dispatchClearCurrentRecordLease, lease, dispatchDestroyRecordLease } = props;

  React.useEffect(() => {
    dispatchClearCurrentRecordLease();
  });

  if (lease === undefined) { return <SearchRecordLeases />; }
  return (
    <div>
      <APIForm
        onSubmit={dispatchDestroyRecordLease}
        action={CIRCULATION_ACTION_TYPES.LEASE.DESTROY}
        id="di-check-in-a-record"
        onSuccessRoute={ROUTES.CIRCULATION.LEASES.CHECK_IN(true)}
        onSuccessAction={CIRCULATION_ACTION_TYPES.LEASE.DESTROYED}
      >
        <input type="hidden" name="id" value={lease.id} />
        <input type="submit" value="Check In" />
      </APIForm>
      <button type="button" onClick={() => { dispatchClearCurrentRecordLease(); }}>Cancel</button>
    </div>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  lease: state.recordLeases.currentRecordLease,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchClearCurrentRecordLease: () => {
    dispatch(clearCurrentRecordLease());
  },
  dispatchDestroyRecordLease: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyRecordLease(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckInARecordLease);
