import StepAccordionStep from '@components/base/accordions/StepAccordionStep';
import StepAccordionStepClosed from '@components/base/accordions/StepAccordionStepClosed';
import StepAccordionStepOpen from '@components/base/accordions/StepAccordionStepOpen';
import Label from '@components/base/form-fields/Label';
import Section from '@components/layout/generic/Section';
import { createCategory, fetchCategories } from '@store/catalog/categories/actions';
import { Category } from '@store/catalog/categories/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

interface OwnProps {
  step: number
  currentStep?: number
  nextStep?: ()=>void
  goToStep?: (step:number)=>void
  totalSteps?: number
}

interface StateProps {
  categories: Category[]
  category: Category
}

interface DispatchProps {
  dispatchFetchCategories: ()=>void
  dispatchCreateCategory: (e:React.MouseEvent<HTMLFormElement>)=>void
}

type AssociateMetadataStepProps = OwnProps & StateProps & DispatchProps;

function AssociateMetadataStep(props:AssociateMetadataStepProps) {
  const {
    step, categories, category, dispatchFetchCategories, dispatchCreateCategory,
    currentStep, nextStep, goToStep, totalSteps,
  } = props;

  React.useEffect(() => {
    dispatchFetchCategories();
    console.log(categories);
    console.log(category);
    console.log(dispatchCreateCategory);
  }, []);
  return (
    <StepAccordionStep
      currentStep={currentStep}
      nextStep={nextStep}
      goToStep={goToStep}
      totalSteps={totalSteps}
      step={step}
      title="Provide copy details"
    >
      <StepAccordionStepOpen>

        <Section>
          <Label htmlFor="categoryId">Category</Label>
          {/* <div style={showCategoryForm ? { display: 'none' } : {}}>
              <InputSelect name="categoryId" id="categoryId">
                { categories.map(
                  (category) => (
                    <option key={category.id} value={category.id}>
                      {category.label}
                    </option>
                  ),
                )}
              </InputSelect>
              <Button fill="solid" color="blue-green" onClick={() => setShowCategoryForm(true)}>
              Add Subject</Button>
            </div> */}
          {/* <div style={showCategoryForm ? {} : { display: 'none' }}>
              <APIForm
                onSubmit={dispatchCreateCategory}
                action={CATALOG_ACTION_TYPES.SUBJECT.CREATE}
                id="di-create-subject"
                onSuccessAction={CATALOG_ACTION_TYPES.SUBJECT.CREATED}
              >
                <Label htmlFor="title">Title</Label>
                <InputText id="di-subject-title" type="text" name="label" />
                <SubmitButton color="blue-green" fill="solid" value="Add" />
              </APIForm>
            </div> */}
        </Section>
      </StepAccordionStepOpen>
      <StepAccordionStepClosed>
        asd
      </StepAccordionStepClosed>
    </StepAccordionStep>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  categories: state.categories.categories,
  category: state.categories.currentCategory,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchCategories: ():void => {
    dispatch(fetchCategories());
  },
  dispatchCreateCategory: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createCategory(data));
  },
});

AssociateMetadataStep.defaultProps = {
  currentStep: undefined,
  nextStep: undefined,
  goToStep: undefined,
  totalSteps: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssociateMetadataStep);
