import Button from '@components/base/buttons-and-links/Button';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import { fetchFee } from '@store/circulation/fees/actions';
import { Fee } from '@store/circulation/fees/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

interface StateProps {
  fee:Fee
}
interface DispatchProps {
  dispatchFetchFee:(id:number|string)=>void
}
type ShowFeeDetailsProps = StateProps & DispatchProps;

function ShowFeeDetails(props:ShowFeeDetailsProps) {
  const { dispatchFetchFee, fee } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);
  React.useEffect(() => {
    dispatchFetchFee(id);
  }, []);
  return (
    <PageContainer>
      <TextHeader size="h1">{fee.name}</TextHeader>
      <Button color="blue-green" fill="solid" to={ROUTES.CIRCULATION.FEES.EDIT(parseInt(params.recordId, 10))}>Edit</Button>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  fee: state.fees.currentFee || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchFee: (id:number|string) => {
    dispatch(fetchFee(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowFeeDetails);
