import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface MultiActionButtonProps {
  children: React.ReactNode
  label: string
  tabIndex: number
  disabled?: boolean
  style?: React.CSSProperties
}

function MultiActionButton(props:MultiActionButtonProps) {
  const [open, setOpen] = React.useState(false);
  const {
    label, children, tabIndex, disabled, style,
  } = props;
  const dropDown = React.useRef(null);
  return (
    <div
      tabIndex={tabIndex}
      role="button"
      className={`di-multi-action-button ${disabled ? 'di-multi-action-button-disabled' : ''}`}
      style={style}
      onClick={() => !disabled && setOpen(!open)}
      onKeyDown={(e:React.KeyboardEvent<HTMLDivElement>) => { console.log(e.key); if (document.activeElement === dropDown.current && e.key === 'space' && !disabled) { setOpen(!open); } }}
    >
      <div
        ref={dropDown}
        className="di-multi-action-button-header"
      >
        <span>{label}</span>
        <FontAwesomeIcon icon={faCaretDown} />
      </div>
      <ul className="di-multi-action-button-body" style={open ? {} : { display: 'none' }}>
        {children}
      </ul>
    </div>
  );
}
MultiActionButton.defaultProps = {
  disabled: false,
  style: {},
};

export default MultiActionButton;
