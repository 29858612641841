import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import {
  FetchTransfersAction,
  FetchTransferAction,
  CreateTransferAction,
  UpdateTransferAction,
  DestroyTransferAction,
} from '@store/circulation/transfers/types';
import {
  transfersFetched,
  transferFetched,
  transferCreated,
  transferUpdated,
  transferDestroyed,
} from '@store/circulation/transfers/actions';
import { Observable } from 'rxjs';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchTransfersEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.TRANSFERS.FETCH),
  switchMap((action:FetchTransfersAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/transfers/`,
    method: 'GET',
    success: transfersFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchTransferEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.TRANSFER.FETCH),
  switchMap((action:FetchTransferAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/transfers/${action.id}/`,
    method: 'GET',
    success: transferFetched,
    auth: true,
    state$,
    action,
  })),
);

const createTransferEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.TRANSFER.CREATE),
  switchMap((action:CreateTransferAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/transfers/`,
    method: 'POST',
    success: transferCreated,
    auth: true,
    state$,
    action,
    body: action.transfer,
  })),
);

const updateTransferEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.TRANSFER.UPDATE),
  switchMap((action:UpdateTransferAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/transfers/${action.id}/`,
    method: 'PUT',
    success: transferUpdated,
    auth: true,
    state$,
    action,
    body: action.transfer,
  })),
);

const destroyTransferEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.TRANSFER.DESTROY),
  switchMap((action:DestroyTransferAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/transfers/${action.id}/`,
    method: 'DELETE',
    success: transferDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchTransfersEpic,
  fetchTransferEpic,
  createTransferEpic,
  updateTransferEpic,
  destroyTransferEpic,
];
