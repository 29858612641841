import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import {
  FetchRecordInstancesAction,
  FetchRecordInstanceAction,
  CreateRecordInstanceAction,
  UpdateRecordInstanceAction,
  DestroyRecordInstanceAction,
  SearchRecordInstancesAction,
} from '@store/catalog/recordInstances/types';
import {
  recordInstancesFetched,
  recordInstanceFetched,
  recordInstanceCreated,
  recordInstanceUpdated,
  recordInstanceDestroyed,
  recordInstancesSearched,
} from '@store/catalog/recordInstances/actions';
import { Observable } from 'rxjs';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchRecordInstancesEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD_INSTANCES.FETCH),
  switchMap((action:FetchRecordInstancesAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/record-instances/`,
    method: 'GET',
    success: recordInstancesFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchRecordInstanceEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD_INSTANCE.FETCH),
  switchMap((action:FetchRecordInstanceAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/record-instances/${action.id}/`,
    method: 'GET',
    success: recordInstanceFetched,
    auth: true,
    state$,
    action,
  })),
);

const createRecordInstanceEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD_INSTANCE.CREATE),
  switchMap((action:CreateRecordInstanceAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/record-instances/`,
    method: 'POST',
    success: recordInstanceCreated,
    auth: true,
    state$,
    action,
    body: action.recordInstance,
  })),
);

const updateRecordInstanceEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD_INSTANCE.UPDATE),
  switchMap((action:UpdateRecordInstanceAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/record-instances/${action.id}/`,
    method: 'PUT',
    success: recordInstanceUpdated,
    auth: true,
    state$,
    action,
    body: action.recordInstance,
  })),
);

const destroyRecordInstanceEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD_INSTANCE.DESTROY),
  switchMap((action:DestroyRecordInstanceAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/record-instances/${action.id}/`,
    method: 'DELETE',
    success: recordInstanceDestroyed,
    auth: true,
    state$,
    action,
  })),
);

const searchRecordInstancesEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD_INSTANCES.SEARCH),
  switchMap((action:SearchRecordInstancesAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/record-instances/`,
    method: 'POST',
    success: recordInstancesSearched,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchRecordInstancesEpic,
  fetchRecordInstanceEpic,
  createRecordInstanceEpic,
  updateRecordInstanceEpic,
  destroyRecordInstanceEpic,
  searchRecordInstancesEpic,
];
