import PageContainer from '@components/layout/generic/PageContainer';
import { fetchFees } from '@store/circulation/fees/actions';
import { Fee } from '@store/circulation/fees/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import FeeList from './shared/FeeList';

interface StateProps {
  fees:Fee[],
  currentPage: number
  totalPages: number
  numOfRecords: number
  loading:boolean
}
interface DispatchProps { dispatchFetchFees:()=>void }
type ShowAllFeesProps = StateProps & DispatchProps;

function ShowAllFees(props:ShowAllFeesProps) {
  const {
    dispatchFetchFees, loading, fees, currentPage, totalPages, numOfRecords,
  } = props;

  React.useEffect(() => {
    dispatchFetchFees();
  }, []);

  return (
    <PageContainer loading={loading}>
      <FeeList
        fees={fees}
        currentPage={currentPage}
        totalPages={totalPages}
        numOfRecords={numOfRecords}
        selectable={false}
        sortable
        showHeader
      />
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  fees: state.fees.fees,
  currentPage: state.fees.currentPage,
  totalPages: state.fees.totalPages,
  numOfRecords: state.fees.results || 0,
  loading: state.fees.loading,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchFees: ():void => {
    dispatch(fetchFees());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllFees);
