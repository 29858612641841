import TextHeader from '@components/base/text/TextHeader';
import FlexContainer from '@components/layout/generic/FlexContainer';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import RightAlignedFlexContainer from '@components/layout/generic/RightAlignedFlexContainer';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

interface ModalProps {
  children: React.ReactNode
  title?: string
  exitPath?: string
  exitClick?: ()=>void
  style?: React.CSSProperties
}

function Modal(props:ModalProps) {
  const navigate = useNavigate();
  const {
    children, exitPath, exitClick, style, title,
  } = props;
  return (
    <div
      role="none"
      className="di-modal-background"
      onClick={exitClick !== undefined ? exitClick : () => { navigate(exitPath); }}
      style={style}
    >
      <div className="di-modal-container" role="none" onClick={(e:React.MouseEvent) => { e.stopPropagation(); }}>
        <MultiColumnFlex alignItems="start" className="di-modal-header">
          <FlexContainer flexValue={1}>
            <TextHeader size="h3">{title}</TextHeader>
          </FlexContainer>
          <RightAlignedFlexContainer>
            <button type="button" onClick={exitClick !== undefined ? exitClick : () => { navigate(exitPath); }}>
              <FontAwesomeIcon aria-label="Close Modal" icon={faClose} />
            </button>
          </RightAlignedFlexContainer>
        </MultiColumnFlex>
        {children}
      </div>
    </div>
  );
}

Modal.defaultProps = {
  style: {},
  exitClick: undefined,
  exitPath: undefined,
  title: undefined,
};

export default Modal;
