import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import SquareButton from '@components/base/buttons-and-links/SquareButton';
import GenericHR from '@components/base/dividers/GenericHR';
import SubHeader from '@components/base/text/SubHeader';
import TextHeader from '@components/base/text/TextHeader';
import CenterContainer from '@components/layout/generic/CenterContainer';
import Section from '@components/layout/generic/Section';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGoodreads } from '@fortawesome/free-brands-svg-icons';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROUTES } from '@routes';
import * as React from 'react';

function ImportRecordsHome() {
  return (
    <CenterContainer>
      <TextHeader size="h1">Let&apos;s add some records</TextHeader>
      <SubHeader>Populate your ILS with all of your records.</SubHeader>
      <Section>
        <TextHeader size="h4">Import via:</TextHeader>
      </Section>
      <SquareButton to={ROUTES.CATALOG.RECORDS.IMPORT_CSV(true)}>
        <FontAwesomeIcon icon={faFileCsv} />
      </SquareButton>
      <SquareButton to={ROUTES.CATALOG.RECORDS.IMPORT_GOODREADS(true)}>
        <FontAwesomeIcon icon={faGoodreads as IconProp} />
      </SquareButton>
      <GenericHR />
      <HTMLLink color="blue-green" to={ROUTES.CATALOG.RECORDS.NEW(true)}>Manually add your first record.</HTMLLink>
    </CenterContainer>
  );
}

export default ImportRecordsHome;
