import * as Cookie from 'js-cookie';

import { ProfileFetchedAction } from '@store/profile/profile/types';
import { SessionAction } from '@store/authentication/types';
import { AUTHENTICATION_ACTION_TYPES } from '@store/authentication/actionTypes';

interface SessionState {
  apiKey: string
  forgotPasswordSuccess: boolean
  loading: boolean
}

const initialAuthState: SessionState = {
  apiKey: undefined,
  forgotPasswordSuccess: false,
  loading: true,
};

type AuthReducerAction = SessionAction | ProfileFetchedAction;

export function auth(
  state:SessionState = initialAuthState,
  action:AuthReducerAction = undefined,
):SessionState {
  switch (action.type) {
    case AUTHENTICATION_ACTION_TYPES.SESSION.LOAD:
      return {
        ...state,
        loading: true,
      };
    case AUTHENTICATION_ACTION_TYPES.SESSION.LOADED:
      return {
        ...state,
        apiKey: action.apiKey,
        loading: false,
      };
    case AUTHENTICATION_ACTION_TYPES.FORGOT_PASSWORD_SUCCESS:
      return { ...state, forgotPasswordSuccess: true };
    case AUTHENTICATION_ACTION_TYPES.SESSION.DESTROY:
      Cookie.remove('apiKey');
      return {
        ...initialAuthState,
        loading: false,
      };
    case AUTHENTICATION_ACTION_TYPES.SESSION.CREATED:
      Cookie.set('apiKey', action.apiKey, { secure: true, sameSite: 'strict' });
      return {
        ...state,
        apiKey: action.apiKey,
      };
    default:
      return state;
  }
}

export default auth;
