import { createRecordInstance, fetchRecordInstances } from '@store/catalog/recordInstances/actions';
import { Patron } from '@store/patrons/patrons/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import APIForm from '@containers/application/APIForm';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { ROUTES } from '@routes';
import { RootState } from '@store/root';
import { RecordInstance } from '@store/catalog/recordInstances/types';
import { clearCurrentPatron, fetchPatron, searchPatrons } from '@store/patrons/patrons/actions';
import { clearCurrentRecord, searchRecords } from '@store/catalog/records/actions';
import PageContainer from '@components/layout/generic/PageContainer';
import { useLocation } from 'react-router-dom';
import StepAccordion from '@components/compound/accordions/StepAccordion';
import StepAccordionStep from '@components/base/accordions/StepAccordionStep';
import StepAccordionStepOpen from '@components/base/accordions/StepAccordionStepOpen';
import StepAccordionStepClosed from '@components/base/accordions/StepAccordionStepClosed';
import InlineSearch from '@components/base/search/InlineSearch';
import Label from '@components/base/form-fields/Label';
import { Record } from '@store/catalog/records/types';
import InlineSearchResult from '@components/base/search/InlineSearchResult';
import Section from '@components/layout/generic/Section';
import PatronSummary from '@containers/patrons/patrons/shared/PatronSummary';
import GenericHR from '@components/base/dividers/GenericHR';
import RecordInstanceSummary from '../instances/shared/RecordInstanceSummary';

interface StateProps {
  patron:Patron
  recordInstances:RecordInstance[]
  recordSearchResults: Record[]
  patronSearchResults: Patron[]
}
interface DispatchProps {
  dispatchCreateRecordLease: (e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchClearCurrentPatron: ()=>void
  dispatchClearCurrentRecord: ()=>void
  dispatchSearchPatrons: (term:string)=>void
  dispatchFetchPatron: (id:string)=>void
  dispatchFetchRecordInstances: (recordId:number)=>void
  dispatchSearchRecords: (term:string)=>void
}

type CheckOutARecordInstanceProps = StateProps & DispatchProps;
function CheckOutARecordInstance(props:CheckOutARecordInstanceProps) {
  const {
    dispatchClearCurrentPatron, dispatchClearCurrentRecord,
    dispatchCreateRecordLease, patron, recordInstances, dispatchFetchRecordInstances,
    recordSearchResults, patronSearchResults, dispatchSearchRecords, dispatchSearchPatrons,
    dispatchFetchPatron,
  } = props;
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [selectedRecordInstance, setSelectedRecordInstance] = React.useState(undefined);

  React.useEffect(() => {
    dispatchClearCurrentPatron();
    dispatchClearCurrentRecord();

    if (urlParams.get('rid') !== null) {
      const recordId = parseInt(urlParams.get('rid'), 10);
      dispatchFetchRecordInstances(recordId);
    }

    if (urlParams.get('pid') !== null) {
      const patronId = urlParams.get('pid');
      dispatchFetchPatron(patronId);
    }
  }, []);

  const chooseSelectedRecordInstance = (instance:RecordInstance) => {
    setSelectedRecordInstance(instance);
  };

  return (
    <PageContainer>
      <StepAccordion>
        <StepAccordionStep step={1} title="Select a copy of a record to check out:">
          <StepAccordionStepOpen
            nextButtonText="Next: Find a patron"
            disabled={selectedRecordInstance === undefined}
          >
            { recordInstances.length > 0 && (
              <Section>
                {/* <RecordSummary record={record} /> */}
                {recordInstances.map(
                  (instance:RecordInstance) => (
                    <RecordInstanceSummary
                      key={instance.id}
                      recordInstance={instance}
                      onClick={() => chooseSelectedRecordInstance(instance)}
                    />
                  ),
                )}
                <GenericHR />
              </Section>
            )}
            <Section>
              <Label htmlFor="title">{recordInstances.length > 0 ? 'Search for a different record:' : 'Please choose a record:'}</Label>
              <InlineSearch
                id="di-record-search"
                name="title"
                searchFunction={dispatchSearchRecords}
              >
                {recordSearchResults.map((result:Record) => (
                  <InlineSearchResult
                    onSelect={() => dispatchFetchRecordInstances(result.id)}
                    key={result.id}
                  >
                    {result.title}
                  </InlineSearchResult>
                ))}
              </InlineSearch>
            </Section>
          </StepAccordionStepOpen>
          <StepAccordionStepClosed>
            { selectedRecordInstance !== undefined && (
            <RecordInstanceSummary
              recordInstance={selectedRecordInstance}
            />
            )}
          </StepAccordionStepClosed>
        </StepAccordionStep>
        <StepAccordionStep step={2} title="Select a patron:">
          <StepAccordionStepOpen
            nextButtonText="Next: Finalize the checkout process"
            disabled={patron === undefined}
          >
            { patron !== undefined && (
              <Section>
                <PatronSummary patron={patron} />
                <GenericHR />
              </Section>
            )}
            <Section>
              <Label htmlFor="name">{patron !== undefined ? 'Search for a different patron:' : 'Please choose a patron:'}</Label>
              <InlineSearch
                searchFunction={dispatchSearchPatrons}
                id="di-patron-search"
                name="name"
              >
                {patronSearchResults.map((result:Patron) => (
                  <InlineSearchResult
                    onSelect={() => dispatchFetchPatron(result.id)}
                    key={result.id}
                  >
                    {result.name}
                  </InlineSearchResult>
                ))}
              </InlineSearch>
            </Section>
          </StepAccordionStepOpen>
          <StepAccordionStepClosed>
            { patron !== undefined && <PatronSummary patron={patron} />}
          </StepAccordionStepClosed>
        </StepAccordionStep>
        <StepAccordionStep step={3} title="Check out the resource">
          <StepAccordionStepOpen>
            asd
          </StepAccordionStepOpen>
          <StepAccordionStepClosed>
            asd
          </StepAccordionStepClosed>
        </StepAccordionStep>
      </StepAccordion>
      { recordInstances.map((lease:RecordInstance) => (
        <div key={lease.id}>
          {/* <span>{ lease.recordInstance?.record.title }</span> */}
        </div>
      ))}
      <APIForm
        onSubmit={dispatchCreateRecordLease}
        action={CIRCULATION_ACTION_TYPES.LEASE.CREATE}
        id="di-create-password-form"
        onSuccessRoute={ROUTES.CIRCULATION.LEASES.CHECK_OUT(true)}
        onSuccessAction={CIRCULATION_ACTION_TYPES.LEASE.CREATED}
      >
        <input type="hidden" name="patronId" defaultValue={patron?.id} />
        <input type="hidden" name="recordId" defaultValue={selectedRecordInstance?.id} />
        <input type="submit" value="Checkout" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  patron: state.patrons.currentPatron,
  recordInstances: state.recordInstances.recordInstances,
  recordSearchResults: state.records.searchResults,
  patronSearchResults: state.patrons.searchResults,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreateRecordLease: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createRecordInstance(data));
  },
  dispatchClearCurrentPatron: () => {
    dispatch(clearCurrentPatron());
  },
  dispatchClearCurrentRecord: () => {
    dispatch(clearCurrentRecord());
  },
  dispatchFetchRecordInstances: (recordId:number) => {
    dispatch(fetchRecordInstances({ recordId }));
  },
  dispatchSearchRecords: (term:string) => {
    dispatch(searchRecords(term));
  },
  dispatchSearchPatrons: (term:string) => {
    dispatch(searchPatrons(term));
  },
  dispatchFetchPatron: (id:string) => {
    dispatch(fetchPatron(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutARecordInstance);
