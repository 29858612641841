import * as React from 'react';

interface SubHeaderProps {
  children: React.ReactNode
}

function SubHeader(props:SubHeaderProps) {
  const { children } = props;
  return (
    <p className="di-subheader">
      {children}
    </p>
  );
}

export default SubHeader;
