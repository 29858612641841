import Button from '@components/base/buttons-and-links/Button';
import GenericHR from '@components/base/dividers/GenericHR';
import TextHeader from '@components/base/text/TextHeader';
import StarRating from '@components/compound/ratings/StarRating';
import FlexContainer from '@components/layout/generic/FlexContainer';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import PageContainer from '@components/layout/generic/PageContainer';
import SingleColumnFlex from '@components/layout/generic/SingleColumnFlex';
import { ROUTES } from '@routes';
import { Author } from '@store/catalog/authors/types';
import { fetchRecord, fetchRecords } from '@store/catalog/records/actions';
import { Record } from '@store/catalog/records/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, useParams, Routes } from 'react-router-dom';
import { Dispatch } from 'redux';
import CreateARecordInstance from '@containers/catalog/records/instances/CreateARecordInstance';
import CreateANote from '@containers/catalog/records/notes/CreateANote';
import ShowAllNotes from '@containers/catalog/records/notes/ShowAllNotes';
import ShowAllRecordImages from '@containers/catalog/records/recordImages/ShowAllRecordImages';
import CreateAReview from '@containers/catalog/records/reviews/CreateAReview';
import ShowAllReviews from '@containers/catalog/records/reviews/ShowAllReviews';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import DescriptionText from '@components/base/text/DescriptionText';
import FormatOption from '@components/base/buttons-and-links/FormatOption';
import RightAlignedFlexContainer from '@components/layout/generic/RightAlignedFlexContainer';
import Section from '@components/layout/generic/Section';
import PageAccordion from '@components/compound/accordions/PageAccordion';
import Tabs from '@components/compound/tabs/Tabs';
import TabPane from '@components/base/tabs/TabPane';
import TabHeader from '@components/base/tabs/TabHeader';
import TabBody from '@components/base/tabs/TabBody';
import TabOption from '@components/base/tabs/TabOption';
import { Tag } from '@store/catalog/tags/types';
import TagLink from '@components/base/tags/TagLink';
import MultiActionButton from '@components/base/buttons-and-links/MultiActionButton';
import MultiActionButtonItem from '@components/base/buttons-and-links/MultiActionButtonItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsLeftRight, faPlusCircle, faSignOut } from '@fortawesome/free-solid-svg-icons';
import PurchaseList from '@containers/procurement/purchases/shared/PurchaseList';
import { Purchase } from '@store/procurement/purchases/types';
import { fetchPurchases } from '@store/procurement/purchases/actions';
import ShowRecordListOptions from '@containers/catalog/records/shared/ShowRecordListOptions';
import AddRecordToList from '@containers/catalog/records/shared/AddRecordToList';
import CreateARecordImage from '@containers/catalog/records/recordImages/CreateARecordImage';
import HoldList from '@containers/circulation/holds/shared/HoldsList';
import { Hold } from '@store/circulation/holds/types';
import { fetchHolds } from '@store/circulation/holds/actions';
import RecordLeaseList from '@containers/circulation/leases/shared/RecordLeaseList';
import { fetchRecordLeases } from '@store/circulation/recordLeases/actions';
import { RecordLease } from '@store/circulation/recordLeases/types';
import RecordInstanceList from '@containers/circulation/instances/shared/RecordInstanceList';
import { RecordInstance } from '@store/catalog/recordInstances/types';
import { fetchRecordInstances } from '@store/catalog/recordInstances/actions';
import RecordDetails from './fragments/show-record-details/RecordDetails';

interface DispatchProps {
  dispatchFetchRecord:(id:number|string)=>void
  dispatchFetchPurchases:(id:number|string)=>void
  dispatchFetchSimilarRecords:(id:number|string)=>void
  dispatchFetchHolds:(id:number|string)=>void
  dispatchFetchRecordLeases:(id:number|string)=>void
  dispatchFetchRecordInstances:(id:number|string)=>void
}
interface StateProps {
  record:Record,
  similarRecords:Record[],
  loading:boolean
  purchases: Purchase[]
  holds:Hold[]
  recordLeases:RecordLease[]
  recordInstances:RecordInstance[]
}
type ShowRecordDetailsProps = DispatchProps & StateProps;

function ShowRecordDetails(props:ShowRecordDetailsProps) {
  const {
    dispatchFetchRecord, dispatchFetchSimilarRecords, dispatchFetchPurchases, dispatchFetchHolds,
    dispatchFetchRecordLeases, dispatchFetchRecordInstances, loading, record, purchases,
    similarRecords, holds, recordLeases, recordInstances,
  } = props;

  const params = useParams();
  const { id } = params;

  React.useEffect(() => {
    dispatchFetchRecord(id);
    dispatchFetchSimilarRecords(id);
    dispatchFetchPurchases(id);
    dispatchFetchHolds(id);
    dispatchFetchRecordLeases(id);
    dispatchFetchRecordInstances(id);
  }, []);

  console.log(record);

  const rateRecord = (value:number):void => {
    console.log(value);
  };

  return (
    <PageContainer loading={loading}>
      <SingleColumnFlex>
        <MultiColumnFlex alignItems="start">
          <FlexContainer>
            <ShowAllRecordImages record={record} />
          </FlexContainer>
          <FlexContainer flexValue={1}>
            <TextHeader size="h1">{ record.title }</TextHeader>
            <div>
              by
              {' '}
              { record.authors?.map((author:Author) => <HTMLLink color="blue-green" key={author.id} to={ROUTES.CATALOG.AUTHORS.DETAILS(author.id)}>{author.name}</HTMLLink>)}
            </div>

            <MultiColumnFlex>
              <FlexContainer flexValue={1}>
                <StarRating rating={record.averageRating} onRate={rateRecord} />
                <HTMLLink color="blue-green" href="#di-ratings">
                  { record.totalNumberOfRatings}
                  {' '}
                  reviews
                </HTMLLink>
              </FlexContainer>
              <RightAlignedFlexContainer>
                <ShowAllNotes />
              </RightAlignedFlexContainer>
            </MultiColumnFlex>
            <GenericHR />
            <MultiColumnFlex>
              <FormatOption
                copiesAvailable={record.copiesAvailable}
                numberOfHolds={record.numberOfHolds}
                selected
              >
                {record.format}

              </FormatOption>
              { record.otherFormats?.map(
                (format) => (
                  <FormatOption
                    copiesAvailable={format.copiesAvailable}
                    numberOfHolds={format.numberOfHolds}
                    key={format.format}
                    to={ROUTES.CATALOG.RECORDS.DETAILS(format.recordId)}
                  >
                    {format.format}
                  </FormatOption>
                ),
              )}
            </MultiColumnFlex>
            <DescriptionText>
              {record.description}
            </DescriptionText>
            <GenericHR />
            <MultiColumnFlex>
              <Button to={`${ROUTES.CIRCULATION.HOLDS.NEW(true)}?rid=${record.id}`} fill="solid" color="blue-green">Place Hold</Button>
              <MultiActionButton tabIndex={0} label="Admin Actions">
                <MultiActionButtonItem to={`${ROUTES.CIRCULATION.LEASES.CHECK_OUT(true)}?rid=${record.id}`}>
                  <FontAwesomeIcon icon={faSignOut} />
                  Check Out
                </MultiActionButtonItem>
                <MultiActionButtonItem to={`${ROUTES.CIRCULATION.TRANSFERS.NEW(true)}?rid=${record.id}`}>
                  <FontAwesomeIcon icon={faArrowsLeftRight} />
                  Transfer
                </MultiActionButtonItem>
                <MultiActionButtonItem
                  to={ROUTES.CATALOG.RECORDS.RECORD_INSTANCES.NEW(record.id)}
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                  Add a Copy
                </MultiActionButtonItem>
              </MultiActionButton>
            </MultiColumnFlex>
          </FlexContainer>
        </MultiColumnFlex>
      </SingleColumnFlex>
      <PageAccordion tabIndex={0} title="Administrative Information" defaultOpen={false}>
        <Tabs>
          <TabHeader>
            <TabOption title="Copies" />
            <TabOption title="Holds" />
            <TabOption title="Purchase Information" />
            <TabOption title="Leases" />
          </TabHeader>
          <TabBody>
            <TabPane>
              <RecordInstanceList
                recordInstances={recordInstances}
                sortable={false}
                selectable={false}
                currentPage={undefined}
                totalPages={undefined}
                getSelected={undefined}
                filterable={false}
              />
            </TabPane>
            <TabPane>
              <HoldList
                holds={holds}
                sortable={false}
                selectable={false}
                currentPage={undefined}
                totalPages={undefined}
                getSelected={undefined}
                filterable={false}
              />
            </TabPane>
            <TabPane>
              <PurchaseList
                purchases={purchases}
                sortable={false}
                selectable={false}
                currentPage={undefined}
                totalPages={undefined}
                getSelected={undefined}
                filterable={false}
              />
            </TabPane>
            <TabPane>
              <RecordLeaseList
                recordLeases={recordLeases}
                sortable={false}
                selectable={false}
                currentPage={undefined}
                totalPages={undefined}
                getSelected={undefined}
                numOfRecords={0}
                showHeader={false}
              />
            </TabPane>
          </TabBody>
        </Tabs>
      </PageAccordion>
      <PageAccordion tabIndex={0} title="Additional Details" defaultOpen>
        <Section>
          <RecordDetails record={record} />
        </Section>
      </PageAccordion>
      <PageAccordion tabIndex={0} title="Reviews" defaultOpen>
        <ShowAllReviews />
      </PageAccordion>
      <GenericHR />
      <Section>
        <TextHeader size="h3">Similar Records</TextHeader>
        {similarRecords.map(
          (similar:Record) => <div key={similar.id}>{similar.title}</div>,
        )}
      </Section>
      <Section>
        <TextHeader size="h3">Tags</TextHeader>
        {record.tags?.map(
          (tag:Tag) => (
            <TagLink key={tag.id} to={ROUTES.CATALOG.TAGS.DETAILS(tag.id)}>{tag.title}</TagLink>
          ),
        )}
      </Section>
      {/* Modals */}
      <Routes>
        <Route
          path={ROUTES.CATALOG.RECORDS.ADD_TO_LIST()}
          element={<AddRecordToList />}
        />
        <Route
          path={ROUTES.CATALOG.RECORDS.DETAILS()}
          element={<ShowRecordListOptions />}
        />
        <Route
          path={ROUTES.CATALOG.RECORDS.NOTES.NEW()}
          element={<CreateANote />}
        />
        <Route
          path={ROUTES.CATALOG.RECORDS.RECORD_INSTANCES.NEW()}
          element={<CreateARecordInstance />}
        />
        <Route
          path={ROUTES.CATALOG.RECORDS.RECORD_IMAGES.NEW()}
          element={<CreateARecordImage />}
        />
        <Route
          path={ROUTES.CATALOG.RECORDS.REVIEWS.NEW()}
          element={<CreateAReview />}
        />
      </Routes>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  record: state.records.currentRecord || {},
  similarRecords: state.records.records,
  loading: state.records.currentRecord === undefined,
  purchases: state.purchases.purchases,
  holds: state.holds.holds,
  recordLeases: state.recordLeases.recordLeases,
  recordInstances: state.recordInstances.recordInstances,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchSimilarRecords(id:number|string) {
    dispatch(fetchRecords({ recordId: id }));
  },
  dispatchFetchRecord: (id:number|string) => {
    dispatch(fetchRecord(id));
  },
  dispatchFetchPurchases: (id:number|string):void => {
    dispatch(fetchPurchases({ recordId: id }));
  },
  dispatchFetchHolds: (id:number|string):void => {
    dispatch(fetchHolds({ recordId: id }));
  },
  dispatchFetchRecordLeases: (id:number|string):void => {
    dispatch(fetchRecordLeases({ recordId: id }));
  },
  dispatchFetchRecordInstances: (id:number|string):void => {
    dispatch(fetchRecordInstances({ recordId: id }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowRecordDetails);
