import {
  CreateBranchAction,
  DestroyBranchAction,
  FetchBranchAction,
  FetchBranchesAction,
  Branch,
  BranchCreatedAction,
  BranchDestroyedAction,
  BranchFetchedAction,
  BranchesFetchedAction,
  BranchUpdatedAction,
  UpdateBranchAction,
  FetchBranchesOptions,
  SearchBranchesAction,
  BranchesSearchedAction,
  ClearCurrentBranchAction,
} from '@store/organizations/branches/types';
import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';

export const fetchBranches = (options:FetchBranchesOptions = undefined):FetchBranchesAction => ({
  type: ORGANIZATION_ACTION_TYPES.BRANCHES.FETCH,
  options,
});
export const branchesFetched = (response:Branch[]):BranchesFetchedAction => ({
  type: ORGANIZATION_ACTION_TYPES.BRANCHES.FETCHED,
  branches: response,
});

export const fetchBranch = (
  id:number|string,
  options:FetchBranchesOptions = undefined,
):FetchBranchAction => ({
  type: ORGANIZATION_ACTION_TYPES.BRANCH.FETCH,
  options,
  id,
});

export const branchFetched = (branch:Branch):BranchFetchedAction => ({
  type: ORGANIZATION_ACTION_TYPES.BRANCH.FETCHED,
  branch,
});

export const createBranch = (branch:FormData):CreateBranchAction => ({
  type: ORGANIZATION_ACTION_TYPES.BRANCH.CREATE,
  branch,
});

export const branchCreated = (branch:Branch):BranchCreatedAction => ({
  type: ORGANIZATION_ACTION_TYPES.BRANCH.CREATED,
  branch,
  id: branch.id,
});

export const updateBranch = (branch:FormData):UpdateBranchAction => ({
  type: ORGANIZATION_ACTION_TYPES.BRANCH.UPDATE,
  branch,
  id: branch.get('id') as string,
});

export const branchUpdated = (branch:Branch):BranchUpdatedAction => ({
  type: ORGANIZATION_ACTION_TYPES.BRANCH.UPDATED,
  branch,
  id: branch.id,
});

export const destroyBranch = (data:FormData):DestroyBranchAction => ({
  type: ORGANIZATION_ACTION_TYPES.BRANCH.DESTROY,
  data,
  id: data.get('id') as string,
});

export const branchDestroyed = (id:number|string):BranchDestroyedAction => ({
  type: ORGANIZATION_ACTION_TYPES.BRANCH.DESTROYED,
  id,
});

export const searchBranches = (term:string):SearchBranchesAction => ({
  type: ORGANIZATION_ACTION_TYPES.BRANCHES.SEARCH,
  term,
});

export const branchesSearched = (branches:Branch[]):BranchesSearchedAction => ({
  type: ORGANIZATION_ACTION_TYPES.BRANCHES.SEARCHED,
  branches,
});

export const clearCurrentBranch = ():ClearCurrentBranchAction => ({
  type: ORGANIZATION_ACTION_TYPES.BRANCH.CLEAR,
});
