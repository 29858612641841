import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  FetchBookmarksAction,
  FetchBookmarkAction,
  CreateBookmarkAction,
  UpdateBookmarkAction,
  DestroyBookmarkAction,
  SearchBookmarksAction,
} from '@store/catalog/bookmarks/types';
import {
  bookmarksFetched,
  bookmarkFetched,
  bookmarkCreated,
  bookmarkUpdated,
  bookmarkDestroyed,
  bookmarksSearched,
} from '@store/catalog/bookmarks/actions';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchBookmarksEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.BOOKMARKS.FETCH),
  switchMap((action:FetchBookmarksAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/bookmarks/`,
    method: 'GET',
    success: bookmarksFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchBookmarkEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.BOOKMARK.FETCH),
  switchMap((action:FetchBookmarkAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/bookmarks/${action.id}`,
    method: 'GET',
    success: bookmarkFetched,
    auth: true,
    state$,
    action,
  })),
);

const createBookmarkEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.BOOKMARK.CREATE),
  switchMap((action:CreateBookmarkAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/bookmarks/`,
    method: 'POST',
    success: bookmarkCreated,
    auth: true,
    state$,
    action,
    body: action.bookmark,
  })),
);

const updateBookmarkEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.BOOKMARK.UPDATE),
  switchMap((action:UpdateBookmarkAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/bookmarks/${action.id}`,
    method: 'PUT',
    success: bookmarkUpdated,
    auth: true,
    state$,
    action,
    body: action.bookmark,
  })),
);

const destroyBookmarkEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.BOOKMARK.DESTROY),
  switchMap((action:DestroyBookmarkAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/bookmarks/${action.id}`,
    method: 'DELETE',
    success: bookmarkDestroyed,
    auth: true,
    state$,
    action,
  })),
);

const searchBookmarksEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.BOOKMARKS.SEARCH),
  switchMap((action:SearchBookmarksAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/bookmarks/`,
    method: 'POST',
    success: bookmarksSearched,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchBookmarksEpic,
  fetchBookmarkEpic,
  createBookmarkEpic,
  updateBookmarkEpic,
  destroyBookmarkEpic,
  searchBookmarksEpic,
];
