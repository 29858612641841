import { ROUTES } from '@routes';
import { fetchPurchases } from '@store/procurement/purchases/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '@store/root';
import { Dispatch } from 'redux';
import { Purchase } from '@store/procurement/purchases/types';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import Button from '@components/base/buttons-and-links/Button';
import PurchaseList from '@containers/procurement/purchases/shared/PurchaseList';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import FlexContainer from '@components/layout/generic/FlexContainer';
import RightAlignedFlexContainer from '@components/layout/generic/RightAlignedFlexContainer';
import SubHeader from '@components/base/text/SubHeader';

interface DispatchProps {
  dispatchFetchPurchases: ()=>void
}
interface StateProps {
  purchases:Purchase[],
  currentPage: number
  totalPages: number
  numOfRecords: number
  loading: boolean
}
type ShowAllPurchasesProps = DispatchProps & StateProps;

function ShowAllPurchases(props:ShowAllPurchasesProps) {
  const {
    dispatchFetchPurchases, purchases, loading, totalPages, currentPage, numOfRecords,
  } = props;
  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    dispatchFetchPurchases();
  }, []);

  const linkSelected = (selectedPurchases:Purchase[]) => {
    setSelected(selectedPurchases);
    console.log(selected);
  };

  return (
    <PageContainer loading={loading}>
      <MultiColumnFlex>
        <FlexContainer flexValue={1}>
          <TextHeader size="h1">Purchases</TextHeader>
          <SubHeader>{`${numOfRecords} purchases`}</SubHeader>
        </FlexContainer>
        <RightAlignedFlexContainer>
          <Button color="blue-green" fill="solid" to={ROUTES.PROCUREMENT.PURCHASES.NEW(true)}>Add a Purchase</Button>
        </RightAlignedFlexContainer>
      </MultiColumnFlex>
      <PurchaseList
        sortable
        selectable
        getSelected={linkSelected}
        purchases={purchases}
        currentPage={currentPage}
        totalPages={totalPages}
        filterable
      />
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  purchases: state.purchases.purchases,
  currentPage: state.purchases.currentPage,
  totalPages: state.purchases.totalPages,
  loading: state.purchases.loading,
  numOfRecords: state.purchases.results,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchPurchases: () => {
    dispatch(fetchPurchases());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllPurchases);
