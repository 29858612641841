import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import {
  FetchReviewsAction,
  FetchReviewAction,
  CreateReviewAction,
  UpdateReviewAction,
  DestroyReviewAction,
} from '@store/catalog/reviews/types';
import {
  reviewsFetched,
  reviewFetched,
  reviewCreated,
  reviewUpdated,
  reviewDestroyed,
} from '@store/catalog/reviews/actions';
import { Observable } from 'rxjs';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchReviewsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.REVIEWS.FETCH),
  switchMap((action:FetchReviewsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/reviews/`,
    method: 'GET',
    success: reviewsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchReviewEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.REVIEW.FETCH),
  switchMap((action:FetchReviewAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/reviews/${action.id}/`,
    method: 'GET',
    success: reviewFetched,
    auth: true,
    state$,
    action,
  })),
);

const createReviewEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.REVIEW.CREATE),
  switchMap((action:CreateReviewAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/reviews/`,
    method: 'POST',
    success: reviewCreated,
    auth: true,
    state$,
    action,
    body: action.review,
  })),
);

const updateReviewEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.REVIEW.UPDATE),
  switchMap((action:UpdateReviewAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/reviews/${action.id}/`,
    method: 'PUT',
    success: reviewUpdated,
    auth: true,
    state$,
    action,
    body: action.review,
  })),
);

const destroyReviewEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.REVIEW.DESTROY),
  switchMap((action:DestroyReviewAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/reviews/${action.id}/`,
    method: 'DELETE',
    success: reviewDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchReviewsEpic,
  fetchReviewEpic,
  createReviewEpic,
  updateReviewEpic,
  destroyReviewEpic,
];
