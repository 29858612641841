import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export interface InlineSearchResultProps {
  children: React.ReactNode
  onSelect: ()=>void
  icon?: IconProp
}

function InlineSearchResult(props:InlineSearchResultProps) {
  const { children, onSelect, icon } = props;
  return (
    <li className="di-inline-search-result">
      <div className="di-inline-search-result-icon-container">
        <FontAwesomeIcon icon={icon === undefined ? faSearch : icon} />
      </div>
      <button type="button" onClick={onSelect}>{children}</button>
    </li>
  );
}

InlineSearchResult.defaultProps = {
  icon: undefined,
};

export default InlineSearchResult;
