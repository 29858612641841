import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import {
  FetchRecordLeasesAction,
  FetchRecordLeaseAction,
  CreateRecordLeaseAction,
  UpdateRecordLeaseAction,
  DestroyRecordLeaseAction,
  SearchRecordLeasesAction,
} from '@store/circulation/recordLeases/types';
import {
  recordLeasesFetched,
  recordLeaseFetched,
  recordLeaseCreated,
  recordLeaseUpdated,
  recordLeaseDestroyed,
  recordLeasesSearched,
} from '@store/circulation/recordLeases/actions';
import { Observable } from 'rxjs';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { Action } from 'redux';
import CONFIG from '@config/environments/base';
import { RootStateOrAny } from 'react-redux';

const fetchRecordLeasesEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.LEASES.FETCH),
  switchMap((action:FetchRecordLeasesAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/leases/`,
    method: 'GET',
    success: recordLeasesFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchRecordLeaseEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.LEASE.FETCH),
  switchMap((action:FetchRecordLeaseAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/leases/${action.id}/`,
    method: 'GET',
    success: recordLeaseFetched,
    auth: true,
    state$,
    action,
  })),
);

const createRecordLeaseEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.LEASE.CREATE),
  switchMap((action:CreateRecordLeaseAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/leases/`,
    method: 'POST',
    success: recordLeaseCreated,
    auth: true,
    state$,
    action,
    body: action.lease,
  })),
);

const updateRecordLeaseEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.LEASE.UPDATE),
  switchMap((action:UpdateRecordLeaseAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/leases/${action.id}/`,
    method: 'PUT',
    success: recordLeaseUpdated,
    auth: true,
    state$,
    action,
    body: action.lease,
  })),
);

const destroyRecordLeaseEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.LEASE.DESTROY),
  switchMap((action:DestroyRecordLeaseAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/leases/${action.id}/`,
    method: 'DELETE',
    success: recordLeaseDestroyed,
    auth: true,
    action,
    state$,
  })),
);

const searchRecordLeasesEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.LEASES.SEARCH),
  switchMap((action:SearchRecordLeasesAction) => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/leases/`,
    method: 'POST',
    success: recordLeasesSearched,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchRecordLeasesEpic,
  fetchRecordLeaseEpic,
  createRecordLeaseEpic,
  updateRecordLeaseEpic,
  destroyRecordLeaseEpic,
  searchRecordLeasesEpic,
];
