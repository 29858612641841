import * as React from 'react';

interface CenterContainerProps {
  children: React.ReactNode
  maxWidth?: number
}

function CenterContainer(props:CenterContainerProps) {
  const { children, maxWidth } = props;
  return (
    <div className="di-center-container" style={maxWidth !== undefined ? { maxWidth } : {}}>
      { children }
    </div>
  );
}

CenterContainer.defaultProps = {
  maxWidth: undefined,
};

export default CenterContainer;
