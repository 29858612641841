import APIList from '@containers/application/APIList';
import { ROUTES } from '@routes';
import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';
import { fetchBranches } from '@store/organizations/branches/actions';
import { Branch } from '@store/organizations/branches/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import Button from '@components/base/buttons-and-links/Button';
import { RootState } from '@store/root';
import { useParams } from 'react-router-dom';

interface StateProps { branches: Branch[] }
interface DispatchProps { dispatchFetchBranches:(organizationId: string|number)=>void }
type ShowAllBranchesProps = StateProps & DispatchProps;

function ShowAllBranches(props:ShowAllBranchesProps) {
  const { dispatchFetchBranches, branches } = props;

  const params = useParams();
  const { organizationId } = params;

  React.useEffect(() => {
    dispatchFetchBranches(organizationId);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Branches</TextHeader>
      <APIList action={ORGANIZATION_ACTION_TYPES.BRANCHES.FETCH}>
        <PaginatedTable>
          <TableHeader>
            <TableRow>
              <TableHeaderColumn sortable>Name</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            { branches.map((branch:Branch) => (
              <TableRow
                key={branch.id}
                to={
                  ROUTES.ORGANIZATION_MANAGEMENT
                    .ORGANIZATIONS.BRANCHES.DETAILS(branch.id, organizationId)
                }
              >
                <TableColumn tabIndex={0}>{branch.title}</TableColumn>
              </TableRow>
            ))}
          </TableBody>
        </PaginatedTable>
      </APIList>
      <Button color="blue-green" fill="solid" to={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.BRANCHES.NEW(organizationId)}>Add an Branch</Button>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  branches: state.branches.branches,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchBranches: (organizationId:string|number):void => {
    dispatch(fetchBranches({ organizationId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllBranches);
