import * as React from 'react';

interface SectionProps {
  children: React.ReactNode
}

function Section(props:SectionProps) {
  const { children } = props;
  return (
    <div className="di-section">
      { children }
    </div>
  );
}

export default Section;
