import * as React from 'react';
import { Link } from 'react-router-dom';

interface ButtonProps {
  color: 'blue-green' | 'black'
  fill: 'solid' | 'outline'
  to?: string
  children: React.ReactNode
  disabled?: boolean
  onClick?: ()=>void
}

function Button(props:ButtonProps) {
  const {
    color, fill, to, children, disabled, onClick,
  } = props;
  if (to !== undefined) {
    return (
      <Link className={`di-button di-button-${color} di-button-${fill}`} to={to} onClick={onClick}>{children}</Link>
    );
  }
  return (
    <button type="button" disabled={disabled} className={`di-button di-button-${color} di-button-${fill}`} onClick={onClick}>
      {children}
    </button>
  );
}

Button.defaultProps = {
  disabled: false,
  onClick: undefined,
  to: undefined,
};

export default Button;
