import Button from '@components/base/buttons-and-links/Button';
import TextHeader from '@components/base/text/TextHeader';
import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import { fetchTransfer } from '@store/circulation/transfers/actions';
import { Transfer } from '@store/circulation/transfers/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

interface StateProps {
  transfer:Transfer,
  loading:boolean
}
interface DispatchProps {
  dispatchFetchTransfer:(id:number|string)=>void
}
type ShowTransferDetailsProps = StateProps & DispatchProps;

function ShowTransferDetails(props:ShowTransferDetailsProps) {
  const { dispatchFetchTransfer, loading, transfer } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchTransfer(id);
  }, []);
  return (
    <PageContainer loading={loading}>
      <TextHeader size="h1">{transfer.name}</TextHeader>
      <Button color="blue-green" fill="solid" to={ROUTES.CIRCULATION.TRANSFERS.EDIT(parseInt(params.recordId, 10))}>Edit</Button>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  transfer: state.transfers.currentTransfer || {},
  loading: state.transfers.currentTransfer === undefined,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchTransfer: (id:number|string) => {
    dispatch(fetchTransfer(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowTransferDetails);
