import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import PageContainer from '@components/layout/generic/PageContainer';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import TertiaryMenu from '@components/compound/menus/TertiaryMenu';
import FlexContainer from '@components/layout/generic/FlexContainer';
import AuthenticatedContainer from '@containers/application/AuthenticatedContainer';
import { ROUTES } from '@routes';
import PasswordForm from '@containers/profile/PasswordForm';
import ProfileForm from '@containers/profile/ProfileForm';
import NotificationSettings from '@containers/profile/notifications/NotificationSettings';
import ShowAllFamilyMembers from '@containers/profile/family/ShowAllFamilyMembers';
import CreateAFamilyMember from '@containers/profile/family/CreateAFamilyMember';
import ShowFamilyMemberDetails from '@containers/profile/family/ShowFamilyMemberDetails';
import EditAFamilyMember from '@containers/profile/family/EditAFamilyMember';
import Billing from '@containers/profile/Billing';
import TertiaryMenuItem from '@components/base/menu-items/TertiaryMenuItem';
import { useTranslation } from 'react-i18next';
import {
  faDollarSign, faUser, faUserGroup, faQuoteLeft,
} from '@fortawesome/free-solid-svg-icons';

function Profile() {
  const { t } = useTranslation();
  return (
    <AuthenticatedContainer>
      <PageContainer>
        <MultiColumnFlex alignItems="start">
          <TertiaryMenu>
            <TertiaryMenuItem
              icon={faUser}
              to={ROUTES.PROFILE.INDEX(true)}
            >
              { t('menu.profile.profile') }
            </TertiaryMenuItem>
            <TertiaryMenuItem
              icon={faUserGroup}
              to={ROUTES.PROFILE.FAMILY_MEMBERS.ALL(true)}
            >
              { t('menu.profile.family') }
            </TertiaryMenuItem>
            <TertiaryMenuItem
              icon={faDollarSign}
              to={ROUTES.PROFILE.BILLING(true)}
            >
              { t('menu.profile.billing') }
            </TertiaryMenuItem>
            <TertiaryMenuItem
              icon={faQuoteLeft}
              to={ROUTES.PROFILE.NOTIFICATION_SETTINGS(true)}
            >
              { t('menu.profile.settings') }
            </TertiaryMenuItem>
          </TertiaryMenu>
          <FlexContainer flexValue={1}>
            <Routes>
              <Route
                path={ROUTES.PROFILE.FAMILY_MEMBERS.ALL()}
                element={<ShowAllFamilyMembers />}
              />
              <Route
                path={ROUTES.PROFILE.FAMILY_MEMBERS.NEW()}
                element={<CreateAFamilyMember />}
              />
              <Route
                path={ROUTES.PROFILE.FAMILY_MEMBERS.DETAILS()}
                element={<ShowFamilyMemberDetails />}
              />
              <Route
                path={ROUTES.PROFILE.FAMILY_MEMBERS.EDIT()}
                element={<EditAFamilyMember />}
              />
              <Route path={ROUTES.PROFILE.CHANGE_PASSWORD()} element={<PasswordForm />} />
              <Route path={ROUTES.PROFILE.BILLING()} element={<Billing />} />
              <Route
                path={ROUTES.PROFILE.NOTIFICATION_SETTINGS()}
                element={<NotificationSettings />}
              />
              <Route path={ROUTES.PROFILE.INDEX(true)} element={<ProfileForm />} />
            </Routes>
          </FlexContainer>
        </MultiColumnFlex>
      </PageContainer>
    </AuthenticatedContainer>
  );
}

export default Profile;
