import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import {
  CreateHoldAction,
  HoldCreatedAction,
  FetchHoldAction,
  FetchHoldsAction,
  HoldFetchedAction,
  HoldsFetchedAction,
  UpdateHoldAction,
  HoldUpdatedAction,
  Hold,
  DestroyHoldAction,
  HoldDestroyedAction,
  FetchHoldsOptions,
} from '@store/circulation/holds/types';

export function fetchHolds(options:FetchHoldsOptions = undefined):FetchHoldsAction {
  return {
    type: CIRCULATION_ACTION_TYPES.HOLDS.FETCH,
    options,
  };
}

export function holdsFetched(response:Hold[]):HoldsFetchedAction {
  return {
    type: CIRCULATION_ACTION_TYPES.HOLDS.FETCHED,
    holds: response,
  };
}

export function fetchHold(id:number|string):FetchHoldAction {
  return {
    type: CIRCULATION_ACTION_TYPES.HOLD.FETCH,
    id,
  };
}

export function holdFetched(hold:Hold):HoldFetchedAction {
  return {
    type: CIRCULATION_ACTION_TYPES.HOLD.FETCHED,
    hold,
  };
}

export const createHold = (hold:FormData):CreateHoldAction => ({
  type: CIRCULATION_ACTION_TYPES.HOLD.CREATE,
  hold,
});

export const holdCreated = (hold:Hold):HoldCreatedAction => ({
  type: CIRCULATION_ACTION_TYPES.HOLD.CREATED,
  hold,
  id: hold.id,
});

export const updateHold = (hold:FormData):UpdateHoldAction => ({
  type: CIRCULATION_ACTION_TYPES.HOLD.UPDATE,
  hold,
  id: hold.get('id') as string|number,
});

export const holdUpdated = (hold:Hold):HoldUpdatedAction => ({
  type: CIRCULATION_ACTION_TYPES.HOLD.UPDATED,
  hold,
  id: hold.id,
});

export const destroyHold = (hold:FormData):DestroyHoldAction => ({
  type: CIRCULATION_ACTION_TYPES.HOLD.DESTROY,
  id: hold.get('id') as string|number,
});

export const holdDestroyed = (id:number|string):HoldDestroyedAction => ({
  type: CIRCULATION_ACTION_TYPES.HOLD.DESTROYED,
  id,
});
