import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationMessage } from '@store/application/reducer';

interface OwnProps { children:React.ReactNode, action:any }
interface StateProps { errors:ApplicationMessage[] }
type APIListProps = OwnProps & StateProps;

function APIList(props:APIListProps) {
  const { errors, action, children } = props;
  return (
    <div>
      <ul>
        {
        errors.filter((error) => error.target === action)
        // Extract the errors from the objects
          .reduce((ownErrors, current) => [...ownErrors, current.errors], [])
          .map((error) => <li key={error}>{error}</li>)
}
      </ul>
      {children}
    </div>
  );
}

const mapStateToProps = (state:RootState) => ({
  errors: state.application.filter((message:ApplicationMessage) => message.state === 'error'),
});

export default connect(mapStateToProps, null)(APIList);
