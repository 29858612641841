import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';

import {
  CreateRecordImageAction,
  DestroyRecordImageAction,
  FetchRecordImageAction,
  FetchRecordImagesAction,
  FetchRecordImagesOptions,
  RecordImage,
  RecordImageCreatedAction,
  RecordImageDestroyedAction,
  RecordImageFetchedAction,
  RecordImagesFetchedAction,
  RecordImageUpdatedAction,
  UpdateRecordImageAction,
} from '@store/catalog/recordImages/types';

export const fetchRecordImages = (
  options:FetchRecordImagesOptions = undefined,
):FetchRecordImagesAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_IMAGES.FETCH,
  options,
});

export const recordImagesFetched = (
  response:RecordImage[],
):RecordImagesFetchedAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_IMAGES.FETCHED,
  recordImages: response,
});

export const fetchRecordImage = (id:number|string):FetchRecordImageAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_IMAGE.FETCH,
  id,
});

export const recordImageFetched = (recordImage:RecordImage):RecordImageFetchedAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_IMAGE.FETCHED,
  recordImage,
  id: recordImage.id,
});

export const createRecordImage = (recordImage:FormData):CreateRecordImageAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_IMAGE.CREATE,
  recordImage,
});

export const recordImageCreated = (recordImage:RecordImage):RecordImageCreatedAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_IMAGE.CREATED,
  recordImage,
  id: recordImage.id,
});

export const updateRecordImage = (recordImage:FormData):UpdateRecordImageAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_IMAGE.UPDATE,
  recordImage,
  id: recordImage.get('id') as string|number,
});

export const recordImageUpdated = (recordImage:RecordImage):RecordImageUpdatedAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_IMAGE.UPDATED,
  recordImage,
  id: recordImage.id,
});

export const destroyRecordImage = (recordImage:FormData):DestroyRecordImageAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_IMAGE.DESTROY,
  id: recordImage.get('id') as string|number,
});

export const recordImageDestroyed = (id:number|string):RecordImageDestroyedAction => ({
  type: CATALOG_ACTION_TYPES.RECORD_IMAGE.DESTROYED,
  id,
});
