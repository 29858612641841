import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import { Contact } from '@store/procurement/contacts/types';
import APIList from '@containers/application/APIList';
import * as React from 'react';

interface RequiredContactListProps {
  contacts: Contact[]
  currentPage: number
  totalPages: number
  sortable: boolean
  selectable: boolean
}
interface OptionalContactListProps {
  getSelected?: (contacts:Contact[])=>void
  filterable?: boolean
}
type ContactListProps = OptionalContactListProps & RequiredContactListProps;

function ContactList(props:ContactListProps) {
  const [selected, setSelected] = React.useState([]);
  const {
    contacts, getSelected, filterable, currentPage, totalPages, sortable, selectable,
  } = props;

  const toggleAll = (e:React.MouseEvent) => {
    e.stopPropagation();
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected([...contacts]);
    }
    getSelected(selected);
  };

  const toggleSelected = (selectedContact:Contact) => {
    if (selected.filter((record:Contact) => record.id === selectedContact.id).length > 0) {
      setSelected(selected.filter((record:Contact) => record.id !== selectedContact.id));
    } else {
      setSelected([...selected, selectedContact]);
    }
    getSelected(selected);
  };

  return (
    <APIList action={PROCUREMENT_ACTION_TYPES.CONTACTS.FETCH}>
      <PaginatedTable
        showHeader={filterable}
        empty={contacts.length === 0}
        currentPage={currentPage}
        totalPages={totalPages}
      >
        <TableHeader>
          <TableRow>
            { selectable && <TableHeaderColumn onClick={toggleAll} sortable={false}><input readOnly type="checkbox" checked={selected.length === contacts.length && contacts.length > 0} /></TableHeaderColumn> }
            <TableHeaderColumn resizable attribute="contactDate" sortable={sortable}>Contact Date</TableHeaderColumn>
            {/*  <TableHeaderColumn
            resizable attribute="title" sortable={sortable}> Title</TableHeaderColumn>
            <TableHeaderColumn
            resizable attribute="status" sortable={sortable}>Status</TableHeaderColumn>
            <TableHeaderColumn
            resizable attribute="supplier" sortable={sortable}> Supplier </TableHeaderColumn>
            <TableHeaderColumn
  resizable attribute="budget" sortable={sortable}> Budget </TableHeaderColumn> */}
          </TableRow>
        </TableHeader>
        <TableBody>
          { contacts.map((contact:Contact) => (
            <TableRow key={contact.id}>
              { selectable && (
                <TableColumn resizable={false} tabIndex={0}>
                  <input
                    readOnly
                    type="checkbox"
                    checked={selected.filter(
                      (selectedContact:Contact) => selectedContact.id === contact.id,
                    ).length > 0}
                    onClick={(e:React.MouseEvent) => {
                      e.stopPropagation(); toggleSelected(contact);
                    }}
                  />
                </TableColumn>
              )}
              {/* <TableColumn tabIndex={0} resizable>
                {contact.contactDate}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {contact.title}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {contact.status}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green"
                to={ROUTES.PROCUREMENT.SUPPLIERS.DETAILS(contact.supplier?.id)}>
                {contact.supplier?.name}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green"
                to={ROUTES.PROCUREMENT.BUDGETS.DETAILS(contact.budget?.id)}>
                {contact.budget?.name}</HTMLLink>
              </TableColumn> */}
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
    </APIList>
  );
}

ContactList.defaultProps = {
  getSelected: undefined,
  filterable: false,
};
export default ContactList;
