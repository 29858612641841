import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import MainSearch from '@components/base/search/MainSearch';
import { ROUTES } from '@routes';
import TextHeader from '@components/base/text/TextHeader';
import SearchResult from '@components/base/search/SearchResult';
import { clearRecordInstanceSearch, searchRecordInstances } from '@store/catalog/recordInstances/actions';
import { RootState } from '@store/root';
import { RecordInstance } from '@store/catalog/recordInstances/types';
import { faCheckCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

interface StateProps { searchResults:RecordInstance[] }
interface DispatchProps {
  dispatchSearchRecordInstances:(term:string)=>void,
  dispatchClearRecordInstanceSearch:()=>void
}

type SearchReacordLeasesProps = StateProps & DispatchProps;

function SearchRecordLeases(props:SearchReacordLeasesProps) {
  const { dispatchClearRecordInstanceSearch, dispatchSearchRecordInstances, searchResults } = props;
  React.useEffect(() => {
    dispatchClearRecordInstanceSearch();
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Check Out. Check In. Renew.</TextHeader>
      <MainSearch
        name="di-search-record-leases"
        id="di-search-record-leases"
        searchFunction={dispatchSearchRecordInstances}
        searchResultRoute={ROUTES.CIRCULATION.RECORD_INSTANCES.SEARCH(true)}
      >
        { searchResults.map((instance:RecordInstance) => (<SearchResult icon={instance.status === 'available' ? faCheckCircle : faXmarkCircle} key={instance.id} to={ROUTES.CIRCULATION.RECORD_INSTANCES.DETAILS(instance.id)}>{instance.record.title}</SearchResult>))}
      </MainSearch>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  searchResults: state.recordInstances.searchResults,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchSearchRecordInstances: (searchTerm:string) => {
    dispatch(searchRecordInstances(searchTerm));
  },
  dispatchClearRecordInstanceSearch: () => {
    dispatch(clearRecordInstanceSearch());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchRecordLeases);
