import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import { ROUTES } from '@routes';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { Record } from '@store/catalog/records/types';
import APIList from '@containers/application/APIList';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface RequiredOwnProps {
  records: Record[]
  currentPage:number
  totalPages:number
  numOfRecords:number
  showHeader: boolean
  selectable: boolean
  sortable: boolean
  actions?: React.ReactElement[]
}

interface OptionalOwnProps {
  getSelected?:(records: Record[])=>void
}

type RecordListProps = OptionalOwnProps & RequiredOwnProps;

function RecordList(props:RecordListProps) {
  const [selected, setSelected] = React.useState([]);
  const {
    records, getSelected, currentPage, totalPages,
    showHeader, selectable, sortable, actions, numOfRecords,
  } = props;
  const { t } = useTranslation();

  React.useEffect(() => {
    getSelected(selected);
  }, [selected]);

  const toggleAll = (e:React.MouseEvent):void => {
    e.stopPropagation();
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected([...records]);
    }
  };

  const toggleSelected = (selectedRecord:Record):void => {
    if (selected.filter((record:Record) => record.id === selectedRecord.id).length > 0) {
      setSelected(selected.filter((record:Record) => record.id !== selectedRecord.id));
    } else {
      setSelected([...selected, selectedRecord]);
    }
  };

  return (
    <APIList action={CATALOG_ACTION_TYPES.RECORDS.FETCH}>
      <PaginatedTable
        showHeader={showHeader}
        empty={records.length === 0}
        currentPage={currentPage}
        totalPages={totalPages}
        title="Records"
        numOfRecordsString={`${numOfRecords?.toLocaleString()} Records`}
        actions={actions}
      >
        <TableHeader>
          <TableRow>
            { selectable && (
              <TableHeaderColumn
                onClick={toggleAll}
                sortable={false}
                resizable={false}
              >
                <input
                  readOnly
                  type="checkbox"
                  checked={selected.length === records.length && records.length > 0}
                />
              </TableHeaderColumn>
            ) }
            <TableHeaderColumn defaultWidth={400} resizable attribute="name" sortable={sortable}>{ t('catalog.records.model.name') }</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="type" sortable={sortable}>{ t('catalog.records.model.type') }</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="authors" sortable={sortable}>{ t('catalog.records.model.authors') }</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="publisher" sortable={sortable}>{ t('catalog.records.model.publisher') }</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="copies" sortable={sortable}>{ t('catalog.records.model.numberOfCopies') }</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          { records.map((record:Record) => (
            <TableRow key={record.id}>
              { selectable && (
                <TableColumn
                  tabIndex={0}
                  resizable={false}
                >
                  <input
                    readOnly
                    onClick={(e:React.MouseEvent) => {
                      e.stopPropagation();
                      toggleSelected(record);
                    }}
                    type="checkbox"
                    checked={selected.filter(
                      (selectedRecord:Record) => selectedRecord.id === record.id,
                    ).length > 0}
                  />
                </TableColumn>
              ) }
              <TableColumn tabIndex={0} defaultWidth={400} resizable>
                <Link to={ROUTES.CATALOG.RECORDS.DETAILS(record.id)}>{record.title}</Link>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>Book</TableColumn>
              <TableColumn tabIndex={0} resizable>
                {record.authors?.map((author, index) => (
                  <span key={author.name}>
                    { index > 0 ? ', ' : ''}
                    <Link
                      onClick={(e:React.MouseEvent) => { e.stopPropagation(); }}
                      to={ROUTES.CATALOG.AUTHORS.DETAILS(author.id)}
                    >
                      {author.name}
                    </Link>
                  </span>
                ))}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <Link
                  onClick={(e:React.MouseEvent) => { e.stopPropagation(); }}
                  to={ROUTES.CATALOG.PUBLISHERS.DETAILS(record.publisher?.id)}
                >
                  {record.publisher?.name}
                </Link>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {record.copiesAvailable}
                {' '}
                /
                {' '}
                {record.totalCopies}
              </TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
    </APIList>
  );
}

RecordList.defaultProps = {
  getSelected: undefined,
  actions: [],
};

export default RecordList;
