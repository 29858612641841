import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { RecordLease } from '@store/circulation/recordLeases/types';
import APIList from '@containers/application/APIList';
import * as React from 'react';
import { ROUTES } from '@routes';

interface RecordLeaseListRequiredProps {
  recordLeases: RecordLease[]
  currentPage: number
  totalPages: number
  numOfRecords:number
  showHeader: boolean
  sortable: boolean
  selectable: boolean
  actions?: React.ReactElement[]
}

interface RecordLeaseListOptionalProps {
  clickable?: boolean
  getSelected?: (recordLeases:RecordLease[])=>void
}

type RecordLeaseListProps = RecordLeaseListOptionalProps & RecordLeaseListRequiredProps;

function RecordLeaseList(props:RecordLeaseListProps) {
  const [selected, setSelected] = React.useState([]);
  const {
    recordLeases, getSelected, selectable, showHeader,
    currentPage, totalPages, sortable, clickable, numOfRecords, actions,
  } = props;

  React.useEffect(() => {
    if (getSelected !== undefined) { getSelected(selected); }
  }, [selected]);

  const toggleAll = (e:React.MouseEvent) => {
    e.stopPropagation();
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected([...recordLeases]);
    }
  };

  const toggleSelected = (selectedRecordLease:RecordLease) => {
    if (selected.filter((record:RecordLease) => record.id === selectedRecordLease.id).length > 0) {
      setSelected(selected.filter((record:RecordLease) => record.id !== selectedRecordLease.id));
    } else {
      setSelected([...selected, selectedRecordLease]);
    }
  };

  return (
    <APIList action={CIRCULATION_ACTION_TYPES.HOLDS.FETCH}>
      <PaginatedTable
        showHeader={showHeader}
        empty={recordLeases.length === 0}
        currentPage={currentPage}
        totalPages={totalPages}
        title="Loans"
        numOfRecordsString={numOfRecords !== undefined ? `${numOfRecords.toLocaleString()} Loans` : ''}
        actions={actions}
      >
        <TableHeader>
          <TableRow>
            { selectable && <TableHeaderColumn resizable={false} onClick={toggleAll} sortable={false}><input readOnly type="checkbox" checked={selected.length === recordLeases.length && recordLeases.length > 0} /></TableHeaderColumn> }
            <TableHeaderColumn resizable attribute="leaseStartDate" sortable={sortable}>
              From
            </TableHeaderColumn>
            <TableHeaderColumn resizable attribute="leaseEndDate" sortable={sortable}>
              To
            </TableHeaderColumn>
            <TableHeaderColumn resizable attribute="title" sortable={sortable}>
              Item
            </TableHeaderColumn>
            <TableHeaderColumn resizable attribute="patron" sortable={sortable}>
              Patron
            </TableHeaderColumn>
            <TableHeaderColumn resizable attribute="status" sortable={sortable}>
              Status
            </TableHeaderColumn>
            <TableHeaderColumn resizable attribute="amountDue" sortable={sortable}>
              Amount Due
            </TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          { recordLeases.map((recordLease:RecordLease) => (
            <TableRow
              key={recordLease.id}
              to={clickable ? ROUTES.CIRCULATION.HOLDS.DETAILS(recordLease.id) : undefined}
            >
              { selectable && (
                <TableColumn tabIndex={0} resizable={false}>
                  <input
                    readOnly
                    type="checkbox"
                    onClick={(e:React.MouseEvent) => {
                      e.stopPropagation(); toggleSelected(recordLease);
                    }}
                    checked={selected.filter(
                      (selectedRecordLease:
                        RecordLease) => selectedRecordLease.id === recordLease.id,
                    ).length > 0}
                  />
                </TableColumn>
              )}
              <TableColumn tabIndex={0} resizable>
                {new Date(recordLease.leaseStartDate).toDateString() }
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                { new Date(recordLease.leaseEndDate).toDateString()}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" to={ROUTES.CATALOG.RECORDS.DETAILS(recordLease.recordInstance?.record.id)}>{recordLease.recordInstance?.record.title}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                <HTMLLink color="blue-green" to={ROUTES.PATRONS.PATRONS.DETAILS(recordLease.patron?.id)}>{recordLease.patron?.name}</HTMLLink>
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {recordLease.status}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                $
                {recordLease.amountDue}
              </TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
    </APIList>
  );
}

RecordLeaseList.defaultProps = {
  actions: [],
  clickable: false,
  getSelected: undefined,
};

export default RecordLeaseList;
