import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  FetchContactsAction,
  FetchContactAction,
  CreateContactAction,
  UpdateContactAction,
  DestroyContactAction,
} from '@store/procurement/contacts/types';
import {
  contactsFetched,
  contactFetched,
  contactCreated,
  contactUpdated,
  contactDestroyed,
} from '@store/procurement/contacts/actions';
import { Observable } from 'rxjs';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchContactsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.CONTACTS.FETCH),
  switchMap((action:FetchContactsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/contacts/`,
    method: 'GET',
    success: contactsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchContactEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.CONTACT.FETCH),
  switchMap((action:FetchContactAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/contacts/${action.id}/`,
    method: 'GET',
    success: contactFetched,
    auth: true,
    state$,
    action,
  })),
);

const createContactEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.CONTACT.CREATE),
  switchMap((action:CreateContactAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/contacts/`,
    method: 'POST',
    success: contactCreated,
    auth: true,
    state$,
    action,
    body: action.contact,
  })),
);

const updateContactEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.CONTACT.UPDATE),
  switchMap((action:UpdateContactAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/contacts/${action.id}/`,
    method: 'PUT',
    success: contactUpdated,
    auth: true,
    state$,
    action,
    body: action.contact,
  })),
);

const destroyContactEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.CONTACT.DESTROY),
  switchMap((action:DestroyContactAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/contacts/${action.id}/`,
    method: 'DELETE',
    success: contactDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchContactsEpic,
  fetchContactEpic,
  createContactEpic,
  updateContactEpic,
  destroyContactEpic,
];
