import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';
import TableHeader from '@components/base/tables/TableHeader';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableRow from '@components/base/tables/TableRow';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import { Fund } from '@store/procurement/funds/types';
import APIList from '@containers/application/APIList';
import * as React from 'react';
import { ROUTES } from '@routes';

interface RequiredFundListProps {
  funds: Fund[]
  currentPage: number
  totalPages: number
  sortable: boolean
  selectable: boolean
}
interface OptionalFundListProps {
  getSelected?: (funds:Fund[])=>void
  filterable?: boolean
}
type FundListProps = OptionalFundListProps & RequiredFundListProps;

function FundList(props:FundListProps) {
  const [selected, setSelected] = React.useState([]);
  const {
    funds, getSelected, filterable, currentPage, totalPages, sortable, selectable,
  } = props;

  const toggleAll = (e:React.MouseEvent) => {
    e.stopPropagation();
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected([...funds]);
    }
    getSelected(selected);
  };

  const toggleSelected = (selectedFund:Fund) => {
    if (selected.filter((record:Fund) => record.id === selectedFund.id).length > 0) {
      setSelected(selected.filter((record:Fund) => record.id !== selectedFund.id));
    } else {
      setSelected([...selected, selectedFund]);
    }
    getSelected(selected);
  };

  return (
    <APIList action={PROCUREMENT_ACTION_TYPES.FUNDS.FETCH}>
      <PaginatedTable
        showHeader={filterable}
        empty={funds.length === 0}
        currentPage={currentPage}
        totalPages={totalPages}
      >
        <TableHeader>
          <TableRow>
            { selectable && <TableHeaderColumn resizable={false} onClick={toggleAll} sortable={false}><input readOnly type="checkbox" checked={selected.length === funds.length && funds.length > 0} /></TableHeaderColumn> }
            <TableHeaderColumn resizable attribute="name" sortable={sortable}>Name</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="branch" sortable={sortable}>Branch</TableHeaderColumn>
            <TableHeaderColumn resizable attribute="amount" sortable={sortable}>Amount</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          { funds.map((fund:Fund) => (
            <TableRow key={fund.id} to={ROUTES.PROCUREMENT.FUNDS.DETAILS(fund.id)}>
              { selectable && (
                <TableColumn resizable={false} tabIndex={0}>
                  <input
                    readOnly
                    type="checkbox"
                    checked={selected.filter(
                      (selectedFund:Fund) => selectedFund.id === fund.id,
                    ).length > 0}
                    onClick={(e:React.MouseEvent) => {
                      e.stopPropagation(); toggleSelected(fund);
                    }}
                  />
                </TableColumn>
              ) }
              <TableColumn tabIndex={0} resizable>
                {fund.name}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {fund.branch?.title}
              </TableColumn>
              <TableColumn tabIndex={0} resizable>
                {fund.amount}
              </TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
    </APIList>
  );
}

FundList.defaultProps = {
  getSelected: undefined,
  filterable: false,
};
export default FundList;
