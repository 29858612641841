import { fetchNotes } from '@store/catalog/notes/actions';
import { Note } from '@store/catalog/notes/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import NotesList from '@components/compound/lists/NotesList';
import { RootState } from '@store/root';
import { useParams } from 'react-router-dom';

interface StateProps {
  notes: Note[]
}
interface DispatchProps {
  dispatchFetchNotes:(recordId:string|number)=>void
}

type ShowAllNotesProps = StateProps & DispatchProps;

function ShowAllNotes(props:ShowAllNotesProps) {
  const { dispatchFetchNotes, notes } = props;
  const params = useParams();
  const { id } = params;

  React.useEffect(() => {
    dispatchFetchNotes(id);
  }, []);

  return (
    <div>
      <NotesList notes={notes} />
    </div>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  notes: state.notes.notes,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchNotes: (recordId:string|number):void => {
    dispatch(fetchNotes({ recordId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllNotes);
