import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import SubmitButton from '../form-fields/SubmitButton';

export interface InlineSearchProps {
  searchFunction:(term:string)=>void
  id: string
  name: string
  children:React.ReactNode
}

interface OwnProps {
  defaultValue?: string
  dynamic?: boolean
}

type SearchProps = InlineSearchProps & OwnProps;

function InlineSearch(props:SearchProps) {
  const [searchInputLength, setSearchInputLength] = React.useState(0);
  const {
    searchFunction, id, name, children,
    dynamic, defaultValue,
  } = props;

  const internalSearchFunction = (e:any) => {
    e.preventDefault();
    setSearchInputLength(e.currentTarget.value.length);
    searchFunction(e.currentTarget.value);
  };
  const handleChildClick = () => {
    setSearchInputLength(0);
  };

  const handleFormSubmit = (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    if (!dynamic) {
      const data = new FormData(e.currentTarget);
      const term = data.get(name) as string;
      setSearchInputLength(term.length);
      searchFunction(term);
    }
  };

  return (
    <div className="di-inline-search">
      <div className="di-inline-search-container">
        <div className="di-inline-search-subcontainer">
          <div className="di-inline-search-icon-container">
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <div className="di-inline-search-input-container">
            <div className="di-input-buffer" />
            <form onSubmit={handleFormSubmit}>
              <input
                id={`${id}-search`}
                name={name}
                onChange={dynamic ? internalSearchFunction : undefined}
                type="search"
                defaultValue={defaultValue}
              />
              { !dynamic && (
                <SubmitButton color="blue-green" fill="solid" value="Go" />
              )}
            </form>
          </div>
        </div>
      </div>
      <ol
        onClick={handleChildClick}
        onKeyPress={handleChildClick}
        role="presentation"
        className={`di-inline-search-results ${searchInputLength > 0 ? 'di-inline-search-results-show' : ''}`}
      >
        { children }
      </ol>
    </div>
  );
}
InlineSearch.defaultProps = {
  defaultValue: undefined,
  dynamic: true,
};

export default InlineSearch;
