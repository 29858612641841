import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';

import {
  ClearCurrentSubjectAction,
  CreateSubjectAction,
  DestroySubjectAction,
  FetchSubjectAction,
  FetchSubjectsAction,
  FetchSubjectsOptions,
  Subject,
  SubjectCreatedAction,
  SubjectDestroyedAction,
  SubjectFetchedAction,
  SubjectsFetchedAction,
  SubjectUpdatedAction,
  UpdateSubjectAction,
} from '@store/catalog/subjects/types';

export const fetchSubjects = (options:FetchSubjectsOptions = undefined):FetchSubjectsAction => ({
  type: CATALOG_ACTION_TYPES.SUBJECTS.FETCH,
  options,
});

export const subjectsFetched = (response:Subject[]):SubjectsFetchedAction => ({
  type: CATALOG_ACTION_TYPES.SUBJECTS.FETCHED,
  subjects: response,
});

export const fetchSubject = (id:number|string):FetchSubjectAction => ({
  type: CATALOG_ACTION_TYPES.SUBJECT.FETCH,
  id,
});

export const subjectFetched = (subject:Subject):SubjectFetchedAction => ({
  type: CATALOG_ACTION_TYPES.SUBJECT.FETCHED,
  subject,
  id: subject.id,
});

export const createSubject = (subject:FormData):CreateSubjectAction => ({
  type: CATALOG_ACTION_TYPES.SUBJECT.CREATE,
  subject,
});

export const subjectCreated = (subject:Subject):SubjectCreatedAction => ({
  type: CATALOG_ACTION_TYPES.SUBJECT.CREATED,
  subject,
  id: subject.id,
});

export const updateSubject = (subject:FormData):UpdateSubjectAction => ({
  type: CATALOG_ACTION_TYPES.SUBJECT.UPDATE,
  subject,
  id: subject.get('id') as string|number,
});

export const subjectUpdated = (subject:Subject):SubjectUpdatedAction => ({
  type: CATALOG_ACTION_TYPES.SUBJECT.UPDATED,
  subject,
  id: subject.id,
});

export const destroySubject = (subject:FormData):DestroySubjectAction => ({
  type: CATALOG_ACTION_TYPES.SUBJECT.DESTROY,
  id: subject.get('id') as string|number,
});

export const subjectDestroyed = (id:number|string):SubjectDestroyedAction => ({
  type: CATALOG_ACTION_TYPES.SUBJECT.DESTROYED,
  id,
});

export const clearCurrentSubjectAction = ():ClearCurrentSubjectAction => ({
  type: CATALOG_ACTION_TYPES.SUBJECT.CLEAR,
});
