// import { PaginatedAPIResponse } from '@store/application/types';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { ListRecordMembership } from '@store/catalog/lists/types';
import {
  FetchRecordsAction,
  RecordsFetchedAction,
  SearchRecordsAction,
  FetchRecordAction,
  CreateRecordAction,
  RecordCreatedAction,
  RecordsSearchedAction,
  Record,
  RecordFetchedAction,
  UpdateRecordAction,
  RecordUpdatedAction,
  DestroyRecordAction,
  RecordDestroyedAction,
  ClearCurrentRecordAction,
  ClearRecordsAction,
  ClearRecordSearchAction,
  SearchByISBNAction,
  ISBNSearchCompletedAction,
  FetchSearchResultsAction,
  FetchRecordsOptions,
  AddRecordTolistAction,
  RecordAddedToListAction,
  RemoveRecordFromListAction,
  RecordRemovedFromListAction,
  AddRecordsTolistAction,
  RecordsAddedToListAction,
  DestroyRecordsAction,
  RecordsDestroyedAction,
  RecordsImportedAction,
  ImportRecordsAction,
} from '@store/catalog/records/types';
import { AjaxResponse } from 'rxjs/ajax';
import importGoogleBooksV1 from '../importers/GoogleBooksV1Importer';

export const fetchRecords = (options:FetchRecordsOptions = undefined):FetchRecordsAction => ({
  type: CATALOG_ACTION_TYPES.RECORDS.FETCH,
  options,
});
// interface RecordsFetchedResponse extends PaginatedAPIResponse {
//   data: Record[]
// }

export const recordsFetched = (response:Record[]):RecordsFetchedAction => ({
  type: CATALOG_ACTION_TYPES.RECORDS.FETCHED,
  records: response,
});

export const fetchRecord = (id:number|string):FetchRecordAction => ({
  type: CATALOG_ACTION_TYPES.RECORD.FETCH,
  id,
});

export const recordFetched = (record:Record):RecordFetchedAction => ({
  type: CATALOG_ACTION_TYPES.RECORD.FETCHED,
  record,
  id: record.id,
});

export const createRecord = (record:Record):CreateRecordAction => ({
  type: CATALOG_ACTION_TYPES.RECORD.CREATE,
  record,
});

export const recordCreated = (record:Record):RecordCreatedAction => ({
  type: CATALOG_ACTION_TYPES.RECORD.CREATED,
  record,
  id: record.id,
});

export const updateRecord = (record:FormData):UpdateRecordAction => ({
  type: CATALOG_ACTION_TYPES.RECORD.UPDATE,
  record,
  id: record.get('id') as string|number,
});

export const recordUpdated = (record:Record):RecordUpdatedAction => ({
  type: CATALOG_ACTION_TYPES.RECORD.UPDATED,
  record,
  id: record.id,
});

export const destroyRecord = (record:FormData):DestroyRecordAction => ({
  type: CATALOG_ACTION_TYPES.RECORD.DESTROY,
  id: record.get('id') as string|number,
});

export const recordDestroyed = (id:number|string):RecordDestroyedAction => ({
  type: CATALOG_ACTION_TYPES.RECORD.DESTROYED,
  id,
});

export const destroyRecords = (records:Record[]):DestroyRecordsAction => ({
  type: CATALOG_ACTION_TYPES.RECORDS.DESTROY,
  records,
});

export const recordsDestroyed = ():RecordsDestroyedAction => ({
  type: CATALOG_ACTION_TYPES.RECORDS.DESTROYED,
});

export const searchRecords = (term:string):SearchRecordsAction => ({
  type: CATALOG_ACTION_TYPES.RECORDS.SEARCH,
  term,
});

export const recordsSearched = (results:Record[]):RecordsSearchedAction => ({
  type: CATALOG_ACTION_TYPES.RECORDS.SEARCHED,
  results,
});

export function importRecords(records:Record[]):ImportRecordsAction {
  return {
    type: CATALOG_ACTION_TYPES.RECORDS.IMPORT,
    records,
  };
}

export const recordsImported = (records:Record[]):RecordsImportedAction => ({
  type: CATALOG_ACTION_TYPES.RECORDS.IMPORTED,
  records,
});

export const clearCurrentRecord = ():ClearCurrentRecordAction => ({
  type: CATALOG_ACTION_TYPES.RECORD.CLEAR,
});

export const clearRecords = ():ClearRecordsAction => ({
  type: CATALOG_ACTION_TYPES.RECORDS.CLEAR,
});

export const clearRecordSearch = ():ClearRecordSearchAction => ({
  type: CATALOG_ACTION_TYPES.RECORDS.CLEAR_SEARCH,
});

export const searchByISBN = (isbn:string):SearchByISBNAction => ({
  type: CATALOG_ACTION_TYPES.RECORD.SEARCH_BY_ISBN,
  isbn,
});

export const ISBNSearchCompleted = (results:AjaxResponse):ISBNSearchCompletedAction => {
  const records = importGoogleBooksV1(results.response.items);
  return {
    type: CATALOG_ACTION_TYPES.RECORD.ISBN_SEARCH_RESULTS,
    records,
  };
};

export const fetchSearchResults = (searchTerm:string):FetchSearchResultsAction => ({
  type: CATALOG_ACTION_TYPES.RECORDS.FETCH_SEARCH_RESULTS,
  searchTerm,
});

export const addRecordToList = (listMembership: FormData):AddRecordTolistAction => ({
  type: CATALOG_ACTION_TYPES.LIST.ADD_RECORD,
  listMembership,
});

export const recordAddedToList = (record:Record):RecordAddedToListAction => ({
  type: CATALOG_ACTION_TYPES.LIST.RECORD_ADDED,
  record,
  id: record.id,
});
export const addRecordsToList = (records:Record[], listId:number):AddRecordsTolistAction => ({
  type: CATALOG_ACTION_TYPES.LIST.ADD_RECORDS,
  records,
  listId,
});

export const recordsAddedToList = (records:Record[]):RecordsAddedToListAction => ({
  type: CATALOG_ACTION_TYPES.LIST.RECORDS_ADDED,
  records,
});

export const removeRecordFromList = (listMembership:ListRecordMembership):
RemoveRecordFromListAction => ({
  type: CATALOG_ACTION_TYPES.LIST.REMOVE_RECORD,
  listMembership,
});

export const recordRemovedFromList = (id:number|string):RecordRemovedFromListAction => ({
  type: CATALOG_ACTION_TYPES.LIST.RECORD_REMOVED,
  id,
});
