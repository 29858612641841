import * as React from 'react';

interface OwnProps {
  id: string
  readOnly?: boolean
  checked?: boolean
  name?: string
  onChange?: (e:React.ChangeEvent<HTMLInputElement>)=>void
  label?: string
}

function CheckBox(props:OwnProps) {
  const {
    readOnly, checked, name, id, onChange, label,
  } = props;
  return (
    <div className="di-checkbox-container">
      <input
        id={id}
        name={name}
        className="di-checkbox"
        readOnly={readOnly}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        value="true"
      />
      <label htmlFor="name">{label}</label>
    </div>
  );
}

CheckBox.defaultProps = {
  readOnly: false,
  checked: undefined,
  name: undefined,
  onChange: undefined,
  label: undefined,
};

export default CheckBox;
