interface IAUTHENTICATION_ACTION_TYPES {
  SESSION: {
    LOAD: 'disembark/auth/load_session',
    LOADED: 'disembark/auth/session_loaded',
    CREATED: 'disembark/auth/session_created',
    DESTROY: 'disembark/auth/session_destroyed',
    INVALID: 'disembark/auth/session_invalid',
  },
  VALIDATE_TOKEN: 'disembark/auth/validate_token',
  LOGIN: 'disembark/auth/create_session',
  LOGOUT: 'disembark/auth/destroy_session',
  FORGOT_PASSWORD: 'disembark/auth/forgot_password',
  FORGOT_PASSWORD_SUCCESS: 'disembark/auth/forgot_password_success',
  RESET_PASSWORD: 'disembark/auth/reset_password',
  CONFIRM_EMAIL: 'disembark/auth/confirm_email',
  EMAIL_CONFIRMED: 'disembark/auth/email_confirmed',
}

export const AUTHENTICATION_ACTION_TYPES:IAUTHENTICATION_ACTION_TYPES = {
  SESSION: {
    LOAD: 'disembark/auth/load_session',
    LOADED: 'disembark/auth/session_loaded',
    CREATED: 'disembark/auth/session_created',
    DESTROY: 'disembark/auth/session_destroyed',
    INVALID: 'disembark/auth/session_invalid',
  },
  VALIDATE_TOKEN: 'disembark/auth/validate_token',
  LOGIN: 'disembark/auth/create_session',
  LOGOUT: 'disembark/auth/destroy_session',
  FORGOT_PASSWORD: 'disembark/auth/forgot_password',
  FORGOT_PASSWORD_SUCCESS: 'disembark/auth/forgot_password_success',
  RESET_PASSWORD: 'disembark/auth/reset_password',
  CONFIRM_EMAIL: 'disembark/auth/confirm_email',
  EMAIL_CONFIRMED: 'disembark/auth/email_confirmed',
};

export default AUTHENTICATION_ACTION_TYPES;
