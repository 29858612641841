import * as React from 'react';

interface TableBodyProps {
  children: React.ReactNode
}

function TableBody(props:TableBodyProps) {
  const { children } = props;
  return (
    <tbody className="di-table-body">
      { children }
    </tbody>
  );
}

export default TableBody;
