import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import {
  CreateAuthorAction,
  FetchAuthorAction,
  SearchAuthorsAction,
} from '@store/catalog/authors/types';
import { authorCreated, authorFetched, authorsSearched } from '@store/catalog/authors/actions';
import { handleAJAXRequest } from '@store/application/APIHelper';
import { Action } from 'redux';
import CONFIG from '@config/environments/base';
import { RootStateOrAny } from 'react-redux';

const fetchAuthorEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.AUTHOR.FETCH),
  switchMap((action:FetchAuthorAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/authors/${action.id}`,
    method: 'GET',
    success: authorFetched,
    auth: true,
    state$,
    action,
  })),
);

const createAuthorEpic = (action$:any, state$:any) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.PUBLISHER.CREATE),
  switchMap((action:CreateAuthorAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/authors/`,
    method: 'POST',
    success: authorCreated,
    auth: true,
    state$,
    action,
    body: action.author,
  })),
);

const searchAuthorEpic = (action$:any, state$:any) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.PUBLISHERS.SEARCH),
  switchMap((action:SearchAuthorsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/authors/`,
    method: 'GET',
    success: authorsSearched,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchAuthorEpic,
  createAuthorEpic,
  searchAuthorEpic,
];
