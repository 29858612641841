import * as React from 'react';
import { ROUTES } from '@routes';
import { connect } from 'react-redux';
import APIForm from '@containers/application/APIForm';
import { AUTHENTICATION_ACTION_TYPES } from '@store/authentication/actionTypes';
import { Dispatch } from 'redux';
import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import Label from '@components/base/form-fields/Label';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import { forgotPassword } from '@store/authentication/actions';
import CenterContainer from '@components/layout/generic/CenterContainer';

interface ForgotPasswordFormProps {
  dispatchForgotPassword:(e:React.MouseEvent<HTMLFormElement>)=>void
}

function ForgotPasswordForm(props: ForgotPasswordFormProps) {
  const { dispatchForgotPassword } = props;
  return (
    <CenterContainer>
      <APIForm
        onSubmit={dispatchForgotPassword}
        action={AUTHENTICATION_ACTION_TYPES.FORGOT_PASSWORD}
        id="di-forgot-password-form"
      >
        <Label htmlFor="email">Email</Label>
        <InputText name="email" type="email" id="email" />
        <input type="hidden" name="redirect_url" value="http://localhost:8080/reset-password/" />
        <SubmitButton fill="solid" color="blue-green" value="Reset Password" />
      </APIForm>
      <p>
        Don&apos;t have an account?
        <br />
        <HTMLLink color="blue-green" to={ROUTES.AUTHENTICATION.SIGNUP}>Sign Up</HTMLLink>
      </p>
    </CenterContainer>
  );
}

const mapDispatchToProps = (dispatch:Dispatch) => ({
  dispatchForgotPassword: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(forgotPassword(data));
  },
});

export default connect(null, mapDispatchToProps)(ForgotPasswordForm);
