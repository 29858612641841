import * as React from 'react';

interface SingleColumnFlexProps {
  children: React.ReactNode
}

function SingleColumnFlex(props:SingleColumnFlexProps) {
  const { children } = props;
  return (
    <div className="di-single-columnn-flex">
      {children}
    </div>
  );
}

export default SingleColumnFlex;
