import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { destroyRecord, fetchRecord, updateRecord } from '@store/catalog/records/actions';
import { Record } from '@store/catalog/records/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { RootState } from '@store/root';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';

interface StateProps {
  record:Record
  loading: boolean
}
interface DispatchProps {
  dispatchFetchRecord:(id:number|string)=>void
  dispatchUpdateRecord: (e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchDestroyRecord: (e:React.MouseEvent<HTMLFormElement>)=>void
}

type EditAnRecordProps = StateProps & DispatchProps;

function EditAnRecord(props:EditAnRecordProps) {
  const {
    dispatchFetchRecord,
    loading, dispatchUpdateRecord, record, dispatchDestroyRecord,
  } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchRecord(id);
  }, []);

  return (
    <PageContainer loading={loading}>
      <TextHeader size="h1">Edit A Record</TextHeader>
      <APIForm
        id="di-edit-a-record"
        onSubmit={dispatchUpdateRecord}
        action={CATALOG_ACTION_TYPES.RECORD.UPDATE}
        onSuccessRoute={ROUTES.CATALOG.RECORDS.DETAILS}
        onSuccessAction={CATALOG_ACTION_TYPES.RECORD.UPDATED}
        entityID={record.id}
      >

        <input type="hidden" name="id" defaultValue={record.id} />
        <InputText id="di-record=name" type="text" name="name" defaultValue={record.title} />
        <SubmitButton fill="solid" color="blue-green" value="Submit" />
      </APIForm>

      <APIForm
        id="di-destroy-a-record"
        onSubmit={dispatchDestroyRecord}
        action={CATALOG_ACTION_TYPES.RECORD.DESTROY}
        onSuccessRoute={ROUTES.CATALOG.RECORDS.ALL(true)}
        onSuccessAction={CATALOG_ACTION_TYPES.RECORD.DESTROYED}
        entityID={record.id}
      >
        <input type="hidden" name="id" defaultValue={record.id} />
        <SubmitButton fill="outline" color="blue-green" value="Delete" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  record: state.records.currentRecord || {},
  loading: state.records.currentRecord === undefined,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchRecord: (id:number|string):void => {
    dispatch(fetchRecord(id));
  },
  dispatchUpdateRecord: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updateRecord(data));
  },
  dispatchDestroyRecord: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyRecord(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAnRecord);
