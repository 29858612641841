import * as React from 'react';
import { Link } from 'react-router-dom';

interface TagLinkProps {
  children: React.ReactNode
  to: string
}

function TagLink(props:TagLinkProps) {
  const { children, to } = props;
  return (
    <Link className="di-tag-link" to={to}>{children}</Link>
  );
}

export default TagLink;
