import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { PATRON_ACTION_TYPES } from '@store/patrons/actionTypes';
import {
  FetchPatronsAction,
  FetchPatronAction,
  CreatePatronAction,
  UpdatePatronAction,
  DestroyPatronAction,
  SearchPatronsAction,
  DestroyPatronsAction,
} from '@store/patrons/patrons/types';
import {
  patronsFetched,
  patronFetched,
  patronCreated,
  patronUpdated,
  patronDestroyed,
  patronsSearched,
} from '@store/patrons/patrons/actions';
import { Observable } from 'rxjs';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';
// import { searchPatrons } from '@queries';

const fetchPatronsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PATRON_ACTION_TYPES.PATRONS.FETCH),
  switchMap((action:FetchPatronsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/patrons/`,
    method: 'GET',
    success: patronsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchPatronEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PATRON_ACTION_TYPES.PATRON.FETCH),
  switchMap((action:FetchPatronAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/patrons/${action.id}/`,
    method: 'GET',
    success: patronFetched,
    auth: true,
    state$,
    action,
  })),
);

const createPatronEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PATRON_ACTION_TYPES.PATRON.CREATE),
  switchMap((action:CreatePatronAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/patrons/`,
    method: 'POST',
    success: patronCreated,
    auth: true,
    state$,
    action,
    body: action.patron,
  })),
);

const updatePatronEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PATRON_ACTION_TYPES.PATRON.UPDATE),
  switchMap((action:UpdatePatronAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}atrons/${action.id}/`,
    method: 'PUT',
    success: patronUpdated,
    auth: true,
    state$,
    action,
    body: action.patron,
  })),
);

const destroyPatronEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PATRON_ACTION_TYPES.PATRON.DESTROY),
  switchMap((action:DestroyPatronAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}atrons/${action.id}/`,
    method: 'DELETE',
    success: patronDestroyed,
    auth: true,
    state$,
    action,
  })),
);

const destroyPatronsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PATRON_ACTION_TYPES.PATRONS.DESTROY),
  switchMap((action:DestroyPatronsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/patrons/`,
    method: 'DELETE',
    success: patronFetched,
    auth: true,
    state$,
    action,
  })),
);

const searchPatronsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PATRON_ACTION_TYPES.PATRONS.SEARCH),
  switchMap((action:SearchPatronsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/search-patrons/`,
    method: 'POST',
    success: patronsSearched,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchPatronsEpic,
  destroyPatronsEpic,
  searchPatronsEpic,
  fetchPatronEpic,
  createPatronEpic,
  updatePatronEpic,
  destroyPatronEpic,
];
