import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  CreateContractAction,
  DestroyContractAction,
  FetchContractAction,
  FetchContractsAction,
  FetchContractsOptions,
  Contract,
  ContractCreatedAction,
  ContractDestroyedAction,
  ContractFetchedAction,
  ContractsFetchedAction,
  ContractUpdatedAction,
  UpdateContractAction,
} from '@store/procurement/contracts/types';

export const fetchContracts = (options:FetchContractsOptions = undefined):FetchContractsAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTRACTS.FETCH,
  options,
});

export const contractsFetched = (response:Contract[]):ContractsFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTRACTS.FETCHED,
  contracts: response,
});

export const fetchContract = (id:number|string):FetchContractAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTRACT.FETCH,
  id,
});

export const contractFetched = (contract:Contract):ContractFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTRACT.FETCHED,
  contract,
});

export const createContract = (contract:FormData):CreateContractAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTRACT.CREATE,
  contract,
});

export const contractCreated = (contract:Contract):ContractCreatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTRACT.CREATED,
  contract,
  id: contract.id,
});

export const updateContract = (contract:FormData):UpdateContractAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTRACT.UPDATE,
  contract,
  id: contract.get('id') as string|number,
});

export const contractUpdated = (contract:Contract):ContractUpdatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTRACT.UPDATED,
  contract,
  id: contract.id,
});

export const destroyContract = (contract:FormData):DestroyContractAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTRACT.DESTROY,
  id: contract.get('id') as string|number,
});

export const contractDestroyed = (id:number|string):ContractDestroyedAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTRACT.DESTROYED,
  id,
});
