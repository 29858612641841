import APIList from '@containers/application/APIList';
import { ROUTES } from '@routes';
import { fetchFamilyMembers } from '@store/profile/family/actions';
import { FamilyMember } from '@store/profile/family/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { PROFILE_ACTION_TYPES } from '@store/profile/actionTypes';
import { RootState } from '@store/root';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import Button from '@components/base/buttons-and-links/Button';
import PaginatedTable from '@components/compound/tables/PaginatedTable';
import TableHeader from '@components/base/tables/TableHeader';
import TableRow from '@components/base/tables/TableRow';
import TableHeaderColumn from '@components/base/tables/TableHeaderColumn';
import TableBody from '@components/base/tables/TableBody';
import TableColumn from '@components/base/tables/TableColumn';

interface StateProps { familyMembers: FamilyMember[]}
interface DispatchProps { dispatchFetchFamilyMembers:()=>void }
type ShowAllFamilyMembersProps = StateProps & DispatchProps;

function ShowAllFamilyMembers(props:ShowAllFamilyMembersProps) {
  const { dispatchFetchFamilyMembers, familyMembers } = props;
  React.useEffect(() => {
    dispatchFetchFamilyMembers();
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">FamilyMembers</TextHeader>
      <APIList action={PROFILE_ACTION_TYPES.FAMILY_MEMBERS.FETCH}>
        <PaginatedTable>
          <TableHeader>
            <TableRow>
              <TableHeaderColumn sortable>
                Name
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            { familyMembers.map((familyMember:FamilyMember) => (
              <TableRow
                key={familyMember.id}
                to={ROUTES.PROFILE.FAMILY_MEMBERS.DETAILS(familyMember.id)}
              >
                <TableColumn tabIndex={0}>
                  {familyMember.name}
                </TableColumn>
              </TableRow>
            ))}
          </TableBody>
        </PaginatedTable>
      </APIList>
      <Button color="blue-green" fill="solid" to={ROUTES.PROFILE.FAMILY_MEMBERS.NEW(true)}>Add a FamilyMember</Button>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  familyMembers: state.familyMembers.familyMembers,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchFamilyMembers: ():void => {
    dispatch(fetchFamilyMembers());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllFamilyMembers);
