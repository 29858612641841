import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { destroyTransfer, fetchTransfer, updateTransfer } from '@store/circulation/transfers/actions';
import { Transfer } from '@store/circulation/transfers/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import Label from '@components/base/form-fields/Label';
import InputText from '@components/base/form-fields/InputText';

interface StateProps { transfer:Transfer }
interface DispatchProps {
  dispatchFetchTransfer:(id:number|string)=>void
  dispatchUpdateTransfer:(e:React.MouseEvent<HTMLFormElement>)=>void,
  dispatchDestroyTransfer:(e:React.MouseEvent<HTMLFormElement>)=>void
}
type EditATransferProps = StateProps & DispatchProps;

function EditATransfer(props:EditATransferProps) {
  const {
    dispatchFetchTransfer, transfer, dispatchUpdateTransfer, dispatchDestroyTransfer,
  } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchTransfer(id);
  });

  return (
    <div>
      Edit A Transfer
      <APIForm
        id="di-edit-a-transfer"
        onSubmit={dispatchUpdateTransfer}
        action={CIRCULATION_ACTION_TYPES.TRANSFER.UPDATE}
        onSuccessRoute={ROUTES.CIRCULATION.TRANSFERS.DETAILS(transfer.id)}
        onSuccessAction={CIRCULATION_ACTION_TYPES.TRANSFER.UPDATED}
        entityID={transfer.id}
      >

        <input type="hidden" name="id" defaultValue={transfer.id} />
        <Label htmlFor="name">Name:</Label>
        <InputText id="di-transfer-name" type="text" name="name" defaultValue={transfer.name} />
        <SubmitButton color="blue-green" fill="solid" value="Save" />
      </APIForm>

      <APIForm
        id="di-destroy-a-transfer"
        onSubmit={dispatchDestroyTransfer}
        action={CIRCULATION_ACTION_TYPES.TRANSFER.DESTROY}
        onSuccessRoute={ROUTES.CIRCULATION.TRANSFERS.ALL(true)}
        onSuccessAction={CIRCULATION_ACTION_TYPES.TRANSFER.DESTROYED}
        entityID={transfer.id}
      >
        <input type="hidden" name="id" defaultValue={transfer.id} />
        <SubmitButton color="blue-green" fill="solid" value="Delete" />
      </APIForm>
    </div>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  transfer: state.transfers.currentTransfer || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchTransfer: (id:number|string):void => {
    dispatch(fetchTransfer(id));
  },
  dispatchUpdateTransfer: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updateTransfer(data));
  },
  dispatchDestroyTransfer: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyTransfer(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditATransfer);
