import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROUTES } from '@routes';
import * as React from 'react';
import { useParams } from 'react-router-dom';

function ShowRecordListOptions() {
  const params = useParams();
  return (
    <HTMLLink color="blue-green" to={ROUTES.CATALOG.RECORDS.ADD_TO_LIST(parseInt(params.id, 10))}>
      <FontAwesomeIcon icon={faList} />
      {' '}
      Add To List
    </HTMLLink>
  );
}

export default ShowRecordListOptions;
