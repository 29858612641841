import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import {
  FetchHoldsAction,
  FetchHoldAction,
  CreateHoldAction,
  UpdateHoldAction,
  DestroyHoldAction,
} from '@store/circulation/holds/types';
import {
  holdsFetched,
  holdFetched,
  holdCreated,
  holdUpdated,
  holdDestroyed,
} from '@store/circulation/holds/actions';
import { Observable } from 'rxjs';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchHoldsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.HOLDS.FETCH),
  switchMap((action:FetchHoldsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/holds/`,
    method: 'GET',
    success: holdsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchHoldEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.HOLD.FETCH),
  switchMap((action:FetchHoldAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/holds/${action.id}/`,
    method: 'GET',
    success: holdFetched,
    auth: true,
    state$,
    action,
  })),
);

const createHoldEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.HOLD.CREATE),
  switchMap((action:CreateHoldAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/holds/`,
    method: 'POST',
    success: holdCreated,
    auth: true,
    state$,
    action,
    body: action.hold,
  })),
);

const updateHoldEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.HOLD.UPDATE),
  switchMap((action:UpdateHoldAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/holds/${action.id}/`,
    method: 'PUT',
    success: holdUpdated,
    auth: true,
    state$,
    action,
    body: action.hold,
  })),
);

const destroyHoldEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CIRCULATION_ACTION_TYPES.HOLD.DESTROY),
  switchMap((action:DestroyHoldAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CIRCULATION_URL}/holds/${action.id}/`,
    method: 'DELETE',
    success: holdDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchHoldsEpic,
  fetchHoldEpic,
  createHoldEpic,
  updateHoldEpic,
  destroyHoldEpic,
];
