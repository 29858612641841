import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTES } from '@routes';
import { connect } from 'react-redux';
import { RootState } from '@store/root';
import AnonymousMenuOptions from '@containers/menu/AnonymouseMenuOptions';

interface OwnProps { children:React.ReactNode }
interface StateProps { loggedIn:boolean }
type AnonymousContainerProps = StateProps & OwnProps;

function AnonymousContainer(props:AnonymousContainerProps) {
  const { loggedIn, children } = props;
  return (
    <div>
      { loggedIn && (<Navigate replace to={ROUTES.CATALOG.INDEX(true)} />) }
      <AnonymousMenuOptions />
      { children}
    </div>
  );
}

const mapStateToProps = (state:RootState):StateProps => (
  { loggedIn: state.auth.apiKey !== undefined }
);

export default connect(mapStateToProps, null)(AnonymousContainer);
