import * as React from 'react';

interface TableHeaderProps {
  children: React.ReactNode
}

function TableHeader(props:TableHeaderProps) {
  const { children } = props;
  return (
    <thead className="di-table-header">
      { children }
    </thead>
  );
}

export default TableHeader;
