import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  FetchInvoicesAction,
  FetchInvoiceAction,
  CreateInvoiceAction,
  UpdateInvoiceAction,
  DestroyInvoiceAction,
} from '@store/procurement/invoices/types';
import {
  invoicesFetched,
  invoiceFetched,
  invoiceCreated,
  invoiceUpdated,
  invoiceDestroyed,
} from '@store/procurement/invoices/actions';
import { Observable } from 'rxjs';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchInvoicesEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.INVOICES.FETCH),
  switchMap((action:FetchInvoicesAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/invoices/`,
    method: 'GET',
    success: invoicesFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchInvoiceEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.INVOICE.FETCH),
  switchMap((action:FetchInvoiceAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/invoices/${action.id}/`,
    method: 'GET',
    success: invoiceFetched,
    auth: true,
    state$,
    action,
  })),
);

const createInvoiceEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.INVOICE.CREATE),
  switchMap((action:CreateInvoiceAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/invoices/`,
    method: 'POST',
    success: invoiceCreated,
    auth: true,
    state$,
    action,
    body: action.invoice,
  })),
);

const updateInvoiceEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.INVOICE.UPDATE),
  switchMap((action:UpdateInvoiceAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/invoices/${action.id}/`,
    method: 'PUT',
    success: invoiceUpdated,
    auth: true,
    state$,
    action,
    body: action.invoice,
  })),
);

const destroyInvoiceEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.INVOICE.DESTROY),
  switchMap((action:DestroyInvoiceAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/invoices/${action.id}/`,
    method: 'DELETE',
    success: invoiceDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchInvoicesEpic,
  fetchInvoiceEpic,
  createInvoiceEpic,
  updateInvoiceEpic,
  destroyInvoiceEpic,
];
