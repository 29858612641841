import PageContainer from '@components/layout/generic/PageContainer';
import { destroyRecordLeases, fetchRecordLeases } from '@store/circulation/recordLeases/actions';
import { RecordLease } from '@store/circulation/recordLeases/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import RecordLeaseList from '@containers/circulation/leases/shared/RecordLeaseList';
import Button from '@components/base/buttons-and-links/Button';
import { ROUTES } from '@routes';
import MultiActionButton from '@components/base/buttons-and-links/MultiActionButton';
import MultiActionButtonItem from '@components/base/buttons-and-links/MultiActionButtonItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from '@components/compound/modals/Modal';

interface StateProps {
  recordLeases:RecordLease[]
  currentPage: number
  totalPages: number,
  numOfRecords: number,
 }
interface DispatchProps {
  dispatchFetchRecordLeases:()=>void
  dispatchDestroyRecordLeases: (recordLeases:RecordLease[])=>void
}
type ShowAllRecordLeasesProps = StateProps & DispatchProps;

function ShowAllRecordLeases(props:ShowAllRecordLeasesProps) {
  const [selected, setSelected] = React.useState([]);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const {
    dispatchFetchRecordLeases, dispatchDestroyRecordLeases,
    recordLeases, currentPage, totalPages, numOfRecords,
  } = props;
  React.useEffect(() => {
    dispatchFetchRecordLeases();
  }, []);

  const linkSelected = (selectedRecords:RecordLease[]):void => {
    setSelected(selectedRecords);
  };

  const actions = [
    <Button key={1} fill="solid" color="blue-green" to={ROUTES.CIRCULATION.LEASES.CHECK_OUT(true)}>New</Button>,
    (
      <MultiActionButton key={3} tabIndex={0} label="Bulk Actions" disabled={selected.length === 0} style={{ display: 'inline-block' }}>
        <MultiActionButtonItem onClick={() => setShowDeleteModal(true)}>
          <FontAwesomeIcon icon={faTrash} />
          Delete
        </MultiActionButtonItem>
      </MultiActionButton>
    ),
  ];

  return (
    <PageContainer>
      <RecordLeaseList
        recordLeases={recordLeases}
        currentPage={currentPage}
        totalPages={totalPages}
        numOfRecords={numOfRecords}
        getSelected={linkSelected}
        sortable
        selectable
        showHeader
        actions={actions}
      />

      <Modal title={`Confirm the deletion of ${selected.length} recordLeases?`} exitClick={() => setShowDeleteModal(false)} style={showDeleteModal ? {} : { display: 'none' }}>
        <p>Are you sure you want to delete these records?</p>
        <Button fill="solid" color="blue-green" onClick={() => { dispatchDestroyRecordLeases(selected); setShowDeleteModal(false); }}>Yes</Button>
      </Modal>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  recordLeases: state.recordLeases.recordLeases,
  totalPages: state.recordLeases.totalPages,
  currentPage: state.recordLeases.currentPage,
  numOfRecords: state.recordLeases.results,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchRecordLeases: ():void => {
    dispatch(fetchRecordLeases());
  },
  dispatchDestroyRecordLeases: (recordLeases:RecordLease[]):void => {
    dispatch(destroyRecordLeases(recordLeases));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllRecordLeases);
