import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export interface GlobalSearchProps {
  searchFunction:(term:string)=>void
  id: string
  name: string
  children:React.ReactNode
  searchResultRoute: string
}

interface OwnProps {
  defaultValue?: string
}

type SearchProps = GlobalSearchProps & OwnProps;

function GlobalSearch(props:SearchProps) {
  const [searchInputLength, setSearchInputLength] = React.useState(0);
  const [query, setQuery] = React.useState(undefined);
  const {
    searchResultRoute, searchFunction, id, name, defaultValue, children,
  } = props;
  const navigate = useNavigate();

  const handleFormSubmit = (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate(`${searchResultRoute}?q=${query}`);
  };

  const internalSearchFunction = (e:any) => {
    e.preventDefault();
    setSearchInputLength(e.currentTarget.value.length);
    setQuery(e.currentTarget.value);
    searchFunction(e.currentTarget.value);
  };

  return (
    <div className="di-global-search">
      <div className="di-global-search-container">
        <div className="di-global-search-subcontainer">
          <div className="di-global-search-icon-container">
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <div className="di-global-search-input-container">
            <div className="di-input-buffer" />
            <form onSubmit={handleFormSubmit}>
              <input
                id={`${id}-search`}
                name={name}
                onChange={internalSearchFunction}
                type="text"
                defaultValue={defaultValue}
              />
            </form>
          </div>
        </div>
      </div>
      <ol className={`di-global-search-results ${searchInputLength > 0 ? 'di-global-search-results-show' : ''}`}>
        { children }
      </ol>
    </div>
  );
}

GlobalSearch.defaultProps = {
  defaultValue: undefined,
};

export default GlobalSearch;
