import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';
import { createOrganization, checkOrganizationShortName } from '@store/organizations/organizations/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import Label from '@components/base/form-fields/Label';
import SubHeader from '@components/base/text/SubHeader';
import CenterContainer from '@components/layout/generic/CenterContainer';
import CheckBox from '@components/base/form-fields/CheckBox';
import { RootState } from '@store/root';

interface StateProps {
  alternative: string
}
interface DispatchProps {
  dispatchCreateOrganization:(e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchCheckOrganizationShortName: (shortName:string)=>void
}

type CreateAOrganizationProps = DispatchProps & StateProps;

function CreateAOrganization(props:CreateAOrganizationProps) {
  const base = 'https://apps.disembark.io/';
  const [shortName, setShortName] = React.useState(base);
  const [isPublic, setIsPublic] = React.useState(true);
  const { dispatchCreateOrganization, dispatchCheckOrganizationShortName, alternative } = props;

  const updateShortName = (e:React.ChangeEvent<HTMLInputElement>) => {
    setShortName(e.currentTarget.value.replace(/\s+/g, '-').toLowerCase());
    dispatchCheckOrganizationShortName(shortName);
  };

  React.useEffect(() => {
    if (alternative !== undefined) {
      setShortName(`${base}${alternative}/`);
    }
  }, [alternative]);

  return (
    <PageContainer>
      <CenterContainer maxWidth={400}>
        <TextHeader size="h1">Create your library:</TextHeader>
        <SubHeader> Please select a name for your library.</SubHeader>
        <APIForm
          id="di-add-a-organization-form"
          action={ORGANIZATION_ACTION_TYPES.ORGANIZATION.CREATE}
          onSubmit={dispatchCreateOrganization}
          onSuccessRoute={ROUTES.CATALOG.RECORDS.IMPORT(true)}
          onSuccessAction={ORGANIZATION_ACTION_TYPES.ORGANIZATION.CREATED}
        >
          <div>
            <Label htmlFor="title">Title:</Label>
            <InputText
              onChange={updateShortName}
              type="text"
              name="title"
              id="di-organization-title"
              required
            />
            <CheckBox
              name="isPublic"
              label="Public?"
              id="di-is-public"
              onChange={() => { setIsPublic(!isPublic); }}
              checked={isPublic}
            />
            { isPublic && (
              <div>
                <Label htmlFor="shortName">OPAC URL:</Label>
                <InputText id="di-slug" name="shortName" type="text" value={shortName} readonly />
              </div>
            )}
          </div>
          <SubmitButton color="blue-green" fill="solid" value="Let's Go" />
        </APIForm>
      </CenterContainer>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  alternative: state.organizations.alternativeShortName,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreateOrganization: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createOrganization(data));
  },
  dispatchCheckOrganizationShortName: (shortName:string):void => {
    dispatch(checkOrganizationShortName(shortName));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAOrganization);
