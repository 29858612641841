import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';
import { createFee } from '@store/circulation/fees/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';

interface DispatchProps { dispatchCreateFee:(e: React.MouseEvent<HTMLFormElement>) => void }
type CreateAFeeProps = DispatchProps;

function CreateAFee(props:CreateAFeeProps) {
  const { dispatchCreateFee } = props;
  return (
    <PageContainer>
      <TextHeader size="h1">Add a Fee</TextHeader>
      <APIForm
        onSubmit={dispatchCreateFee}
        action={CIRCULATION_ACTION_TYPES.FEE.CREATE}
        id="di-create-password-form"
        onSuccessRoute={ROUTES.CIRCULATION.FEES.DETAILS}
        onSuccessAction={CIRCULATION_ACTION_TYPES.FEE.CREATED}
      >
        <InputText id="di-fee-name" name="name" type="text" />
        <SubmitButton color="blue-green" fill="solid" value="Create" />
      </APIForm>
    </PageContainer>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreateFee: (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createFee(data));
  },
});

export default connect(null, mapDispatchToProps)(CreateAFee);
