import Button from '@components/base/buttons-and-links/Button';
import TextHeader from '@components/base/text/TextHeader';
import FlexContainer from '@components/layout/generic/FlexContainer';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import PageContainer from '@components/layout/generic/PageContainer';
import RightAlignedFlexContainer from '@components/layout/generic/RightAlignedFlexContainer';
import { ROUTES } from '@routes';
import { fetchBudget } from '@store/procurement/budgets/actions';
import { Budget } from '@store/procurement/budgets/types';
import { fetchFunds } from '@store/procurement/funds/actions';
import { Fund } from '@store/procurement/funds/types';
import { RootState } from '@store/root';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import FundList from '../funds/shared/FundList';

interface StateProps {
  budget:Budget
  funds: Fund[]
  currentPage: number
  totalPages: number
}
interface DispatchProps {
  dispatchFetchBudget:(id:number|string)=>void
  dispatchFetchFunds: (id:number|string)=>void
}
type ShowBudgetDetailsProps = StateProps & DispatchProps;

function ShowBudgetDetails(props:ShowBudgetDetailsProps) {
  const {
    dispatchFetchBudget, dispatchFetchFunds, budget, funds, currentPage, totalPages,
  } = props;
  const [selected, setSelected] = React.useState([]);
  const params = useParams();
  const id = parseInt(params.id, 10);

  React.useEffect(() => {
    dispatchFetchBudget(id);
    dispatchFetchFunds(id);
  }, []);

  const linkSelected = (selectedSuppliers:Budget[]) => {
    setSelected(selectedSuppliers);
    console.log(selected);
  };

  return (
    <PageContainer>
      <MultiColumnFlex>
        <FlexContainer flexValue={1}>
          <TextHeader size="h1">{budget.name}</TextHeader>
        </FlexContainer>
        <RightAlignedFlexContainer>
          <Button color="blue-green" fill="solid" to={ROUTES.PROCUREMENT.BUDGETS.EDIT(budget.id)}>Edit</Button>
        </RightAlignedFlexContainer>
      </MultiColumnFlex>
      <FundList
        sortable
        selectable
        getSelected={linkSelected}
        funds={funds}
        currentPage={currentPage}
        totalPages={totalPages}
        filterable
      />
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  budget: state.budgets.currentBudget || {},
  funds: state.funds.funds,
  currentPage: state.funds.currentPage,
  totalPages: state.funds.totalPages,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchBudget: (id:number|string):void => {
    dispatch(fetchBudget(id));
  },
  dispatchFetchFunds: (id:number|string):void => {
    dispatch(fetchFunds({ budgetId: id }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowBudgetDetails);
