import { ROUTES } from '@routes';
import { fetchRecordImages } from '@store/catalog/recordImages/actions';
import { RecordImage } from '@store/catalog/recordImages/types';
import { Record } from '@store/catalog/records/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import RecordImageGallery from '@components/compound/galleries/RecordImageGallery';
import { RootState } from '@store/root';

interface StateProps {
  recordImages: RecordImage[]
}
interface DispatchProps {
  dispatchFetchRecordImages:()=>void
}
interface OwnProps {
  record:Record
}

type ShowAllRecordImagesProps = StateProps & DispatchProps & OwnProps;

class ShowAllRecordImages extends React.Component<ShowAllRecordImagesProps, null> {
  componentDidMount() {
    const { dispatchFetchRecordImages } = this.props;
    dispatchFetchRecordImages();
  }

  render() {
    const { recordImages, record } = this.props;
    return (
      <div>
        <RecordImageGallery
          tabIndex={0}
          images={recordImages}
          addImagePath={ROUTES.CATALOG.RECORDS.RECORD_IMAGES.NEW(record.id)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state:RootState):StateProps => ({
  recordImages: state.recordImages.recordImages,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchRecordImages: ():void => {
    dispatch(fetchRecordImages());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllRecordImages);
