import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { createList } from '@store/catalog/lists/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import TextArea from '@components/base/form-fields/TextArea';
import Label from '@components/base/form-fields/Label';
import Modal from '@components/compound/modals/Modal';

interface DispatchProps { dispatchCreateList:(e:React.MouseEvent<HTMLFormElement>)=>void }
type CreateAListProps = DispatchProps;

function CreateAList(props:CreateAListProps) {
  const { dispatchCreateList } = props;
  return (
    <Modal title="Add a List" exitPath={ROUTES.CATALOG.LISTS.ALL(true)}>
      <APIForm
        onSubmit={dispatchCreateList}
        action={CATALOG_ACTION_TYPES.LIST.CREATE}
        id="di-create-password-form"
        onSuccessRoute={ROUTES.CATALOG.LISTS.DETAILS}
        onSuccessAction={CATALOG_ACTION_TYPES.LIST.CREATED}
      >
        <Label htmlFor="name">Name</Label>
        <InputText id="name" type="text" name="name" />
        <Label htmlFor="description">Description</Label>
        <TextArea id="description" name="description" defaultValue="Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus aperiam exercitationem expedita veniam laudantium! Vitae explicabo deleniti ea. Corporis repellendus assumenda, exercitationem dolore sapiente voluptatem tempora quo aperiam blanditiis possimus." />
        <SubmitButton fill="solid" color="blue-green" value="Submit" />
      </APIForm>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreateList: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createList(data));
  },
});

export default connect(null, mapDispatchToProps)(CreateAList);
