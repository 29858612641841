import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { PATRON_ACTION_TYPES } from '@store/patrons/actionTypes';
import { createPatronGroup } from '@store/patrons/groups/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import PageContainer from '@components/layout/generic/PageContainer';
import TextHeader from '@components/base/text/TextHeader';
import Label from '@components/base/form-fields/Label';

interface DispatchProps { dispatchCreatePatronGroup:(e:React.MouseEvent<HTMLFormElement>)=>void }
type CreateAnPatronGroupProps = DispatchProps;

function CreateAnPatronGroup(props:CreateAnPatronGroupProps) {
  const { dispatchCreatePatronGroup } = props;
  return (
    <PageContainer>
      <TextHeader size="h1">Add An Patron Group</TextHeader>
      <APIForm
        id="di-add-an-patron-group-form"
        action={PATRON_ACTION_TYPES.PATRON_GROUP.CREATE}
        onSubmit={dispatchCreatePatronGroup}
        onSuccessRoute={ROUTES.PATRONS.PATRON_GROUPS.DETAILS}
        onSuccessAction={PATRON_ACTION_TYPES.PATRON_GROUP.CREATED}
      >

        <Label htmlFor="name">Name:</Label>
        <InputText type="text" name="title" id="di-patron-group-name" />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>
    </PageContainer>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreatePatronGroup: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createPatronGroup(data));
  },
});

export default connect(null, mapDispatchToProps)(CreateAnPatronGroup);
