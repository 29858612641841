import SubHeader from '@components/base/text/SubHeader';
import TextHeader from '@components/base/text/TextHeader';
import FlexContainer from '@components/layout/generic/FlexContainer';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import { Branch } from '@store/organizations/branches/types';
import * as React from 'react';
import LocationMap from '@components/base/maps/LocationMap';

interface BranchSummaryProps {
  branch: Branch
}

function BranchSummary(props:BranchSummaryProps) {
  const {
    branch,
  } = props;
  return (
    <MultiColumnFlex>
      <FlexContainer style={{ padding: 10 }}>
        <LocationMap
          lat={branch.lat}
          lng={branch.lng}
        />
      </FlexContainer>
      <FlexContainer>
        <TextHeader size="h4">{branch?.title}</TextHeader>
        <SubHeader>
          { `${branch.physicalAddress.addressLine1} ${branch.physicalAddress.addressLine2}, ${branch.physicalAddress.city} ${branch.physicalAddress.stateOrProvince}` }
        </SubHeader>
        <SubHeader>
          {branch.phoneNumbers.find((phoneNumber) => phoneNumber.main).phoneNumber}
        </SubHeader>
      </FlexContainer>
    </MultiColumnFlex>
  );
}

export default BranchSummary;
