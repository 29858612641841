import * as React from 'react';
import TextHeader from '@components/base/text/TextHeader';

interface StepAccordionStepProps {
  children: React.ReactElement[]
  currentStep?: number
  nextStep?: ()=>void
  goToStep?: (step:number)=>void
  totalSteps?: number
  step: number
  title: string
}

function StepAccordionStep(props:StepAccordionStepProps) {
  const {
    children, currentStep, step, nextStep, goToStep,
    totalSteps, title,
  } = props;

  const cloned = children.map(
    (
      element:React.ReactElement,
      index:number,
    ) => React.cloneElement(element, {
      currentStep,
      key: `i-${1 + index}`,
      nextStep,
      goToStep,
      totalSteps,
      step,
    }),
  );

  return (
    <div
      className={`di-step-accordion-step ${step === currentStep ? 'di-step-accordion-step-open' : 'di-step-accordion-step-closed'}`}
      style={step > currentStep ? { opacity: 0.5 } : {}}
    >
      <TextHeader size="h3">{ `${step}. ${title}` }</TextHeader>
      {cloned}
    </div>
  );
}

StepAccordionStep.defaultProps = {
  currentStep: undefined,
  nextStep: undefined,
  goToStep: undefined,
  totalSteps: undefined,
};

export default StepAccordionStep;
