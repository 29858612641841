import * as React from 'react';

interface ModalFooterProps {
  children: React.ReactNode
}

function ModalFooter(props:ModalFooterProps) {
  const { children } = props;
  return (
    <div className="di-modal-footer">
      <div className="di-modal-footer-container">
        {children}
      </div>
    </div>
  );
}

export default ModalFooter;
