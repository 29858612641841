import { CIRCULATION_ACTION_TYPES } from '@store/circulation/actionTypes';

import {
  CreateSectionAction,
  DestroySectionAction,
  FetchSectionAction,
  FetchSectionsAction,
  FetchSectionsOptions,
  Section,
  SectionCreatedAction,
  SectionDestroyedAction,
  SectionFetchedAction,
  SectionsFetchedAction,
  SectionUpdatedAction,
  UpdateSectionAction,
} from '@store/circulation/sections/types';

export const fetchSections = (options:FetchSectionsOptions = undefined):FetchSectionsAction => ({
  type: CIRCULATION_ACTION_TYPES.SECTIONS.FETCH,
  options,
});

export const sectionsFetched = (response:Section[]):SectionsFetchedAction => ({
  type: CIRCULATION_ACTION_TYPES.SECTIONS.FETCHED,
  sections: response,
});

export const fetchSection = (id:number|string):FetchSectionAction => ({
  type: CIRCULATION_ACTION_TYPES.SECTION.FETCH,
  id,
});

export const sectionFetched = (section:Section):SectionFetchedAction => ({
  type: CIRCULATION_ACTION_TYPES.SECTION.FETCHED,
  section,
  id: section.id,
});

export const createSection = (section:FormData):CreateSectionAction => ({
  type: CIRCULATION_ACTION_TYPES.SECTION.CREATE,
  section,
});

export const sectionCreated = (section:Section):SectionCreatedAction => ({
  type: CIRCULATION_ACTION_TYPES.SECTION.CREATED,
  section,
  id: section.id,
});

export const updateSection = (section:FormData):UpdateSectionAction => ({
  type: CIRCULATION_ACTION_TYPES.SECTION.UPDATE,
  section,
  id: section.get('id') as string|number,
});

export const sectionUpdated = (section:Section):SectionUpdatedAction => ({
  type: CIRCULATION_ACTION_TYPES.SECTION.UPDATED,
  section,
  id: section.id,
});

export const destroySection = (section:FormData):DestroySectionAction => ({
  type: CIRCULATION_ACTION_TYPES.SECTION.DESTROY,
  id: section.get('id') as string|number,
});

export const sectionDestroyed = (id:number|string):SectionDestroyedAction => ({
  type: CIRCULATION_ACTION_TYPES.SECTION.DESTROYED,
  id,
});
