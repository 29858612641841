import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';
import { createAdmin } from '@store/organizations/admins/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PageContainer from '@components/layout/generic/PageContainer';
import Label from '@components/base/form-fields/Label';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import TextHeader from '@components/base/text/TextHeader';
import { useParams } from 'react-router-dom';

interface DispatchProps { dispatchCreateAdmin:(e:React.MouseEvent<HTMLFormElement>)=>void }
type CreateAnAdminProps = DispatchProps;

function CreateAnAdmin(props:CreateAnAdminProps) {
  const { dispatchCreateAdmin } = props;

  const params = useParams();
  const { organizationId } = params;
  return (
    <PageContainer>
      <TextHeader size="h1">Add An Admin</TextHeader>
      <APIForm
        id="di-add-an-admin-form"
        action={ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.CREATE}
        onSubmit={dispatchCreateAdmin}
        onSuccessRoute={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.STAFF_ADMINS.DETAILS}
        onSuccessAction={ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.CREATED}
      >

        <Label htmlFor="name">Name:</Label>
        <InputText type="text" name="name" id="di-admin-name" />
        <Label htmlFor="email">Email:</Label>
        <InputText type="email" name="email" id="di-admin-name" />
        <input type="hidden" name="organizationId" defaultValue={organizationId} />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>
    </PageContainer>
  );
}

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchCreateAdmin: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(createAdmin(data));
  },
});

export default connect(null, mapDispatchToProps)(CreateAnAdmin);
