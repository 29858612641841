import * as React from 'react';

interface TabPaneProps {
  children: React.ReactNode
}

function TabPane(props:TabPaneProps) {
  const { children } = props;
  return (
    <div className="di-tab-pane">
      <div className="di-tab-pane-body">
        {children}
      </div>
    </div>
  );
}

export default TabPane;
