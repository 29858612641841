import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  CreateContactAction,
  DestroyContactAction,
  FetchContactAction,
  FetchContactsAction,
  FetchContactsOptions,
  Contact,
  ContactCreatedAction,
  ContactDestroyedAction,
  ContactFetchedAction,
  ContactsFetchedAction,
  ContactUpdatedAction,
  UpdateContactAction,
} from '@store/procurement/contacts/types';

export const fetchContacts = (options:FetchContactsOptions = undefined):FetchContactsAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTACTS.FETCH,
  options,
});
export const contactsFetched = (response:Contact[]):ContactsFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTACTS.FETCHED,

  contacts: response,
});

export const fetchContact = (id:number|string):FetchContactAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTACT.FETCH,
  id,
});

export const contactFetched = (contact:Contact):ContactFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTACT.FETCHED,
  contact,
});

export const createContact = (contact:FormData):CreateContactAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTACT.CREATE,
  contact,
});

export const contactCreated = (contact:Contact):ContactCreatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTACT.CREATED,
  contact,
  id: contact.id,
});

export const updateContact = (contact:FormData):UpdateContactAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTACT.UPDATE,
  contact,
  id: contact.get('id') as string|number,
});

export const contactUpdated = (contact:Contact):ContactUpdatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTACT.UPDATED,
  contact,
  id: contact.id,
});

export const destroyContact = (contact:FormData):DestroyContactAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTACT.DESTROY,
  id: contact.get('id') as string|number,
});

export const contactDestroyed = (id:number|string):ContactDestroyedAction => ({
  type: PROCUREMENT_ACTION_TYPES.CONTACT.DESTROYED,
  id,
});
