import * as React from 'react';
import { login } from '@store/authentication/actions';
import { connect } from 'react-redux';
import AnonymousContainer from '@containers/application/AnonymousContainer';
import { ROUTES } from '@routes';
import { AUTHENTICATION_ACTION_TYPES } from '@store/authentication/actionTypes';
import APIForm from '@containers/application/APIForm';
import { Dispatch } from 'redux';
import InputText from '@components/base/form-fields/InputText';
import Label from '@components/base/form-fields/Label';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import HTMLLink from '@components/base/buttons-and-links/HTMLLink';
import CenterContainer from '@components/layout/generic/CenterContainer';

interface DispatchProps {
  dispatchLogin:(e:React.MouseEvent<HTMLFormElement>)=>void
}
type LoginProps = DispatchProps;

function Login(props: LoginProps) {
  const { dispatchLogin } = props;
  return (
    <AnonymousContainer>
      <CenterContainer>
        <APIForm
          onSubmit={dispatchLogin}
          action={AUTHENTICATION_ACTION_TYPES.LOGIN}
          id="di-login-form"
        >
          <div>
            <Label htmlFor="email">Email</Label>
            <InputText type="email" name="email" id="email" />
          </div>
          <HTMLLink color="blue-green" to={ROUTES.AUTHENTICATION.FORGOT_PASSWORD}>Forgot</HTMLLink>
          <div>
            <Label htmlFor="password">Password</Label>
            <InputText type="password" name="password" id="password" />
          </div>
          <SubmitButton fill="solid" color="blue-green" value="Login" />
        </APIForm>
        <p>
          Don&apos;t have an account?
          <br />
          <HTMLLink color="blue-green" to={ROUTES.AUTHENTICATION.SIGNUP}>Sign Up</HTMLLink>
        </p>

      </CenterContainer>
    </AnonymousContainer>
  );
}

const mapDispatchToProps = (dispatch:Dispatch) => ({
  dispatchLogin: (e:React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(login(data));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(Login);
