import FlexContainer from '@components/layout/generic/FlexContainer';
import MultiColumnFlex from '@components/layout/generic/MultiColumnFlex';
import * as React from 'react';
import { Link } from 'react-router-dom';
import TextHeader from '@components/base/text/TextHeader';
import { Patron } from '@store/patrons/patrons/types';

interface PatronSERPProps {
  to: string
  patron: Patron
}

function PatronSERP(props:PatronSERPProps) {
  const { to, patron } = props;
  return (
    <div className="di-serp">
      <MultiColumnFlex>
        <FlexContainer>
          <MultiColumnFlex>
            <Link to={to}>
              <TextHeader size="h3">{patron.name}</TextHeader>
            </Link>
          </MultiColumnFlex>
        </FlexContainer>
      </MultiColumnFlex>
    </div>
  );
}

export default PatronSERP;
