import SubHeader from '@components/base/text/SubHeader';
import TextHeader from '@components/base/text/TextHeader';
import * as React from 'react';

import Icon from '../../../img/search-icon.png';

interface MainSearchContainerProps {
  children: React.ReactNode
  header: string
  subheader: string
}

function MainSearchContainer(props:MainSearchContainerProps) {
  const { children, header, subheader } = props;
  return (
    <div className="di-main-search-container">
      <img src={Icon} alt="" width={200} />
      <TextHeader size="h1">{header}</TextHeader>
      <SubHeader>{subheader}</SubHeader>
      {children}
    </div>
  );
}

export default MainSearchContainer;
