import * as React from 'react';
import SettingsMenuItem from '@components/base/menu-items/SettingsMenuItem';
import MainMenu from '@components/compound/menus/MainMenu';
import MainMenuTab from '@components/base/menu-items/MainMenuTab';
import NotificationsMenuItem from '@components/base/menu-items/NotificationsMenuItem';
import CirculationMenu from '@containers/menu/CirculationMenu';
import CatalogMenu from '@containers/menu/CatalogMenu';
import PatronsMenu from '@containers/menu/PatronsMenu';
import ProcurementsMenu from '@containers/menu/ProcurementsMenu';
import AuthenticatedMenu from '@components/compound/menus/AuthenticatedMenu';
import LogoMenuItem from '@components/base/menu-items/LogoMenuItem';
import {
  faBookmark, faDollarSign, faMailBulk, faUsers,
} from '@fortawesome/free-solid-svg-icons';
import GlobalSearch from '@components/base/search/GlobalSearch';
import { Record } from '@store/catalog/records/types';
import { RootState } from '@store/root';
import { Dispatch } from 'redux';
import { searchRecords } from '@store/catalog/records/actions';
import { connect } from 'react-redux';
import SearchResult from '@components/base/search/SearchResult';
import { fetchNotifications } from '@store/notifications/actions';
import { Notification } from '@store/notifications/types';
import { fetchBookmarks } from '@store/catalog/bookmarks/actions';
import BookmarksMenuItem from '@components/base/menu-items/BookmarksMenuItem';
import { Bookmark } from '@store/catalog/bookmarks/types';
import { useTranslation } from 'react-i18next';
import UserMenu from '@containers/menu/UserMenu';
import { ROUTES } from '@routes';

interface OwnProps {
  section?: 'catalog' | 'circulation' | 'patrons' | 'procurement'
}

interface StateProps {
  results: Record[]
  notifications: Notification[]
  bookmarks: Bookmark[]
}

interface DispatchProps {
  dispatchFetchNotifications: ()=>void
  dispatchFetchBookmarks: ()=>void
  dispatchSearchRecords: (searchTerm:string)=> void;
}

type AuthenticatedMenuOptionsProps = OwnProps & StateProps & DispatchProps;

function AuthenticatedMenuOptions(props:AuthenticatedMenuOptionsProps) {
  let submenu; let primaryButton; let
    secondaryButtons;

  const { t } = useTranslation();
  const {
    section, dispatchSearchRecords, results,
    notifications, bookmarks, dispatchFetchBookmarks, dispatchFetchNotifications,
  } = props;
  // TODO: Prolly a better way to do this
  switch (section) {
    case 'circulation':
      submenu = <CirculationMenu />;
      primaryButton = (
        <MainMenuTab to={ROUTES.CIRCULATION.INDEX(true)} icon={faMailBulk} selected>
          {' '}
          { t('menu.authenticated.circulation') }
        </MainMenuTab>
      );
      secondaryButtons = (
        <div>
          <MainMenuTab to={ROUTES.CATALOG.INDEX(true)} icon={faBookmark}>
            {' '}
            { t('menu.authenticated.catalog') }
          </MainMenuTab>
          <MainMenuTab to={ROUTES.PATRONS.INDEX(true)} icon={faUsers}>
            {' '}
            { t('menu.authenticated.patrons') }
          </MainMenuTab>
          <MainMenuTab to={ROUTES.PROCUREMENT.INDEX(true)} icon={faDollarSign}>
            {' '}
            { t('menu.authenticated.procurement') }
          </MainMenuTab>
        </div>
      );
      break;
    case 'patrons':
      submenu = <PatronsMenu />;
      primaryButton = (
        <MainMenuTab to={ROUTES.PATRONS.INDEX(true)} icon={faUsers} selected>
          {' '}
          { t('menu.authenticated.patrons') }
        </MainMenuTab>
      );
      secondaryButtons = (
        <div>
          <MainMenuTab to={ROUTES.CATALOG.INDEX(true)} icon={faBookmark}>
            {' '}
            { t('menu.authenticated.catalog') }
          </MainMenuTab>
          <MainMenuTab to={ROUTES.PROCUREMENT.INDEX(true)} icon={faDollarSign}>
            {' '}
            { t('menu.authenticated.procurement') }
          </MainMenuTab>
          <MainMenuTab to={ROUTES.CIRCULATION.INDEX(true)} icon={faMailBulk}>
            {' '}
            { t('menu.authenticated.circulation') }
          </MainMenuTab>
        </div>
      );
      break;
    case 'procurement':
      submenu = <ProcurementsMenu />;
      primaryButton = (
        <MainMenuTab to={ROUTES.PROCUREMENT.INDEX(true)} icon={faDollarSign} selected>
          {' '}
          { t('menu.authenticated.procurement') }
        </MainMenuTab>
      );
      secondaryButtons = (
        <div>
          <MainMenuTab to={ROUTES.CATALOG.INDEX(true)} icon={faBookmark}>
            {' '}
            { t('menu.authenticated.catalog') }
          </MainMenuTab>
          <MainMenuTab to={ROUTES.PATRONS.INDEX(true)} icon={faUsers}>
            {' '}
            { t('menu.authenticated.patrons') }
          </MainMenuTab>
          <MainMenuTab to={ROUTES.CIRCULATION.INDEX(true)} icon={faMailBulk}>
            {' '}
            { t('menu.authenticated.circulation') }
          </MainMenuTab>
        </div>
      );
      break;
    default:
      submenu = <CatalogMenu />;
      primaryButton = (
        <MainMenuTab to={ROUTES.CATALOG.INDEX(true)} icon={faBookmark} selected>
          {' '}
          { t('menu.authenticated.catalog') }
        </MainMenuTab>
      );
      secondaryButtons = (
        <div>
          <MainMenuTab to={ROUTES.CIRCULATION.INDEX(true)} icon={faMailBulk}>{ t('menu.authenticated.circulation') }</MainMenuTab>
          <MainMenuTab to={ROUTES.PATRONS.INDEX(true)} icon={faUsers}>
            {' '}
            { t('menu.authenticated.patrons') }
          </MainMenuTab>
          <MainMenuTab to={ROUTES.PROCUREMENT.INDEX(true)} icon={faDollarSign}>
            {' '}
            { t('menu.authenticated.procurement') }
          </MainMenuTab>
        </div>
      );
      break;
  }
  return (
    <AuthenticatedMenu>
      <LogoMenuItem to={ROUTES.CATALOG.INDEX(true)} />
      <MainMenu>
        { primaryButton }

        { secondaryButtons }
      </MainMenu>
      { submenu }
      <GlobalSearch
        id="di-global-search"
        name="search-global"
        searchFunction={dispatchSearchRecords}
        searchResultRoute={ROUTES.CATALOG.RECORDS.SEARCH_RESULTS(true)}
      >
        { results.map(
          (result:Record) => (
            <SearchResult key={result.id} to={ROUTES.CATALOG.RECORDS.DETAILS(result.id)}>
              {result.title}
            </SearchResult>
          ),
        )}
      </GlobalSearch>
      <BookmarksMenuItem bookmarks={bookmarks} fetchBookmarks={dispatchFetchBookmarks} />
      <NotificationsMenuItem
        notifications={notifications}
        fetchNotifications={dispatchFetchNotifications}
      />
      <SettingsMenuItem to={ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.INDEX(true)} />
      <UserMenu />
    </AuthenticatedMenu>
  );
}

AuthenticatedMenuOptions.defaultProps = {
  section: 'catalog',
};

const mapStateToProps = (state:RootState):StateProps => ({
  results: state.records.searchResults,
  notifications: state.notifications.notifications,
  bookmarks: state.bookmarks.bookmarks,
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchNotifications: ():void => {
    dispatch(fetchNotifications());
  },
  dispatchSearchRecords: (searchTerm:string):void => {
    dispatch(searchRecords(searchTerm));
  },
  dispatchFetchBookmarks: ():void => {
    dispatch(fetchBookmarks());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedMenuOptions);
