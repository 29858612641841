import * as React from 'react';

interface ReviewsListProps {
  children: React.ReactNode
}

function Reviewsist(props:ReviewsListProps) {
  const { children } = props;
  return (
    <ul className="di-reviews-list">
      { children }
    </ul>
  );
}

export default Reviewsist;
