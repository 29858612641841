import PageContainer from '@components/layout/generic/PageContainer';
import { ROUTES } from '@routes';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import ImportGoodreadsRecords from './ImportGoodreadsRecords';
import ImportCSVRecords from './ImportCSVRecords';
import ImportRecordsHome from './ImportRecordsHome';

function ImportRecords() {
  const breadcrumbs = [
    { name: 'Catalog', to: ROUTES.CATALOG.INDEX(true) },
    { name: 'Records', to: ROUTES.CATALOG.RECORDS.ALL(true) },
    { name: 'Import', to: ROUTES.CATALOG.RECORDS.IMPORT(true) },
  ];
  return (
    <PageContainer breadcrumbs={breadcrumbs}>
      <Routes>
        <Route
          path={ROUTES.CATALOG.RECORDS.IMPORT_GOODREADS()}
          element={<ImportGoodreadsRecords />}
        />
        <Route
          path={ROUTES.CATALOG.RECORDS.IMPORT_CSV()}
          element={<ImportCSVRecords />}
        />
        <Route
          path="/"
          element={<ImportRecordsHome />}
        />
      </Routes>
    </PageContainer>
  );
}

export default ImportRecords;
