import APIForm from '@containers/application/APIForm';
import { ROUTES } from '@routes';
import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';
import { destroyAdmin, fetchAdmin, updateAdmin } from '@store/organizations/admins/actions';
import { Admin } from '@store/organizations/admins/types';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import TextHeader from '@components/base/text/TextHeader';
import InputText from '@components/base/form-fields/InputText';
import SubmitButton from '@components/base/form-fields/SubmitButton';
import PageContainer from '@components/layout/generic/PageContainer';
import { RootState } from '@store/root';

interface StateProps {
  admin:Admin
}
interface DispatchProps {
  dispatchFetchAdmin:(id:number|string, organizationId:number|string)=>void
  dispatchUpdateAdmin:(e:React.MouseEvent<HTMLFormElement>)=>void
  dispatchDestroyAdmin:(e:React.MouseEvent<HTMLFormElement>)=>void
}
type EditAnAdminProps = StateProps & DispatchProps;

function EditAnAdmin(props:EditAnAdminProps) {
  const {
    dispatchFetchAdmin, admin, dispatchUpdateAdmin, dispatchDestroyAdmin,
  } = props;

  const params = useParams();
  const { id, organizationId } = params;

  React.useEffect(() => {
    dispatchFetchAdmin(id, organizationId);
  }, []);

  return (
    <PageContainer>
      <TextHeader size="h1">Edit A Admin</TextHeader>
      <APIForm
        id="di-edit-an-admin"
        onSubmit={dispatchUpdateAdmin}
        action={ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.UPDATE}
        onSuccessRoute={
          ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.STAFF_ADMINS.DETAILS(id, organizationId)
        }
        onSuccessAction={ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.UPDATED}
        entityID={admin.id}
      >

        <input type="hidden" name="id" defaultValue={admin.id} />
        <input type="hidden" name="organizationId" defaultValue={organizationId} />
        <InputText id="di-admin-name" type="text" name="name" defaultValue={admin.name} />
        <SubmitButton color="blue-green" fill="solid" value="Submit" />
      </APIForm>

      <APIForm
        id="di-destroy-an-admin"
        onSubmit={dispatchDestroyAdmin}
        action={ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.DESTROY}
        onSuccessRoute={
          ROUTES.ORGANIZATION_MANAGEMENT.ORGANIZATIONS.STAFF_ADMINS.ALL(organizationId)
        }
        onSuccessAction={ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.DESTROYED}
        entityID={admin.id}
      >
        <input type="hidden" name="id" defaultValue={admin.id} />
        <input type="hidden" name="organizationId" defaultValue={organizationId} />
        <SubmitButton color="blue-green" fill="outline" value="Delete" />
      </APIForm>
    </PageContainer>
  );
}

const mapStateToProps = (state:RootState):StateProps => ({
  admin: state.admins.currentAdmin || {},
});

const mapDispatchToProps = (dispatch:Dispatch):DispatchProps => ({
  dispatchFetchAdmin: (id:string|number, organizationId:string|number):void => {
    dispatch(fetchAdmin(id, { organizationId }));
  },
  dispatchUpdateAdmin: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(updateAdmin(data));
  },
  dispatchDestroyAdmin: (e:React.MouseEvent<HTMLFormElement>):void => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    dispatch(destroyAdmin(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAnAdmin);
